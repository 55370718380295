import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';

const METER_USAGE_DATA_FETCHED = ActionTypes.METER_USAGE_DATA_FETCHED;
const ESTATE_ENERGY_LEADERBOARD_DATA_FETCHED = ActionTypes.ESTATE_ENERGY_LEADERBOARD_DATA_FETCHED;

let _meter_usage_data = {};
let _estate_leaderboard_data = {};

class CarbonEmissionsDashboard extends EventEmitter {

    constructor() {
        super();
        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
            case ActionTypes.METER_USAGE_DATA_FETCHED:
                this._storeMeterUsageData(action.payload);
                break;
            case ActionTypes.ESTATE_ENERGY_LEADERBOARD_DATA_FETCHED:
                this._storeEstateLeaderboardData(action.payload);
                break;
            default:
                break;
        }
    }

    // Hooks a React component's callback to the CHANGED event.
    addMeterUsageDataFetchListener(callback) {
        this.on(METER_USAGE_DATA_FETCHED, callback);
    }

    // Removes the listener from the CHANGED event.
    removeMeterUsageDataFetchListener(callback) {
        this.removeListener(METER_USAGE_DATA_FETCHED, callback);
    }

    // Hooks a React component's callback to the CHANGED event.
    addEstateEnergyLeaderboardListener(callback) {
        this.on(ESTATE_ENERGY_LEADERBOARD_DATA_FETCHED, callback);
    }

    // Removes the listener from the CHANGED event.
    removeEstateEnergyLeaderboardListener(callback) {
        this.removeListener(ESTATE_ENERGY_LEADERBOARD_DATA_FETCHED, callback);
    }

    clear() {
        _meter_usage_data = {};
        _estate_leaderboard_data = {};
    }

    _storeMeterUsageData(json) {
        _meter_usage_data = json;
        this.emit(METER_USAGE_DATA_FETCHED);
    }

    _storeEstateLeaderboardData(json) {
        _estate_leaderboard_data = json;
        this.emit(ESTATE_ENERGY_LEADERBOARD_DATA_FETCHED);
    }

    // Getters
    getMeterUsageData() {
        return _meter_usage_data;
    }

    // Getters
    getEstateEnergyLeaderboardData() {
        return _estate_leaderboard_data;
    }
}

export default new CarbonEmissionsDashboard();