import React, { Component } from "react";
import "./ModifyAssetPanel.scss";
import { Button } from 'antd';
import { Icon } from '../../../components/Icon';

class ModifyAssetPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      building: this.props.building,
      asset: this.props.asset,
      inputValue: "",
      expanded: this.props.expanded,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      building: nextProps.building,
      asset: nextProps.asset,
      expanded: nextProps.expanded,
    });
  }

  getAssetRow(asset) {
    return (
      <tr>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Name"
            type="text"
            className="inline-single-input"
            defaultValue={asset.name}
            onChange={this.props.changeAssetValues.bind(this, asset, "name")}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Description"
            type="text"
            className="inline-single-input"
            defaultValue={asset.description}
            onChange={this.props.changeAssetValues.bind(
              this,
              asset,
              "description"
            )}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Location"
            type="text"
            className="inline-single-input"
            defaultValue={asset.location}
            onChange={this.props.changeAssetValues.bind(
              this,
              asset,
              "location"
            )}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <select
            style={{ height: "25px" }}
            onChange={this.props.changeAssetValues.bind(this, asset, "zone_id")}
            value={asset.zone_id ? asset.zone_id : ""}
            className="inline-single-input"
          >
            <option value={null}>Select Zone</option>
            {this.props.zones.map((zone) => {
              return (
                <option
                  key={zone.zone_id}
                  value={zone.zone_id}
                >{`${zone.name} (${zone.zone_id})`}</option>
              );
            })}
          </select>
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <select
            style={{ height: "25px" }}
            onChange={this.props.changeAssetValues.bind(
              this,
              asset,
              "asset_type_id"
            )}
            value={asset.asset_type_id}
            className="inline-single-input"
          >
            <option value={null}>Select Asset Type</option>
            {this.props.asset_types.map((type) => {
              return (
                <option
                  key={type.asset_type_id}
                  value={type.asset_type_id}
                >{`${type.type} (${type.asset_type_id})`}</option>
              );
            })}
          </select>
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Reference ID"
            type="text"
            className="inline-single-input"
            defaultValue={asset.reference_id}
            onChange={this.props.changeAssetValues.bind(
              this,
              asset,
              "reference_id"
            )}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Virtual Values"
            type="text"
            className="inline-single-input"
            defaultValue={asset.virtual_values}
            onChange={this.props.changeAssetValues.bind(
              this,
              asset,
              "virtual_values"
            )}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <button
            className="btn btn-outline-info ht-20 d-flex align-items-center"
            onClick={() =>
              asset.asset_id && this.props.expansionHandler(asset.asset_id)
            }
          >
            {this.state.expanded ? "-" : "+"}
          </button>
        </td>
      </tr>
    );
  }
  getAssetDetailsRow(asset) {
    if (asset.asset_type_id === 23) {
      // chillers
      return this.getChillerAssetDetails(asset);
    } else if (
      asset.asset_type_id === 22 ||
      asset.asset_type_id === 28 ||
      asset.asset_type_id === 30
    ) {
      // boiler, chp, heat pump
      return this.getHeatGeneratorAssetDetails(asset);
    } else {
      return this.getOtherAssetDetails(asset);
    }
  }

  getChillerAssetDetails(asset) {
    return (
      <tr>
        <td
          className="valign-middle"
          colSpan="8"
          style={{ border: "none", padding: "0px" }}
        >
          <div className="d-flex align-items-center">
            <div style={{ width: "100%" }}>
              <table
                className="table mg-b-0 table-contact pd-l-0 pd-r-0"
                style={{ marginTop: "0px" }}
              >
                <thead>
                  <tr>
                    <th
                      className="wd-15p tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    ></th>
                    <th
                      className="wd-20p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    ></th>
                    <th
                      className="wd-15p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    ></th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    ></th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    ></th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    ></th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Efficiency
                    </th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Capacity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    ></td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    ></td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    ></td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    ></td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    ></td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    ></td>

                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="Efficiency"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.efficiency}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "efficiency"
                        )}
                      />
                    </td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="Capacity"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.capacity}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "capacity"
                        )}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  getHeatGeneratorAssetDetails(asset) {
    const meters = this.props.meters;

    const gasMeters = meters.filter((m) => m.type === "gm" || m.type === "mgm");
    const electricityMeters = meters.filter(
      (m) => m.type === "em" || m.type === "mem"
    );
    const heatMeters = meters.filter(
      (m) => m.type === "hm" || m.type === "mhm"
    );

    // BOILER (1) and chiller (gas / heat)
    if (asset.asset_type_id === 22) {
      let meter_in_gas = "";
      let meter_out_heat = "";
      if (asset.generator_meters.length) {
        meter_in_gas = asset.generator_meters.find(
          (m) => m.meter_role === "IN"
        );
        meter_out_heat = asset.generator_meters.find(
          (m) => m.meter_role === "OUT"
        );

        meter_in_gas = meter_in_gas ? meter_in_gas.meter_id : "";
        meter_out_heat = meter_out_heat ? meter_out_heat.meter_id : "";
      }

      return (
        <tr>
          <td
            className="valign-middle"
            colSpan="8"
            style={{ border: "none", padding: "0px" }}
          >
            <div className="d-flex align-items-center">
              <div style={{ width: "100%" }}>
                <table
                  className="table mg-b-0 table-contact pd-l-0 pd-r-0"
                  style={{ marginTop: "0px" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="wd-20p tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Generator Meter - In - Gas
                      </th>
                      <th
                        className="wd-20p tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Generator Meter - Out - Heat
                      </th>
                      <th
                        className="wd-5p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      ></th>
                      <th
                        className="wd-20p tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      ></th>
                      <th
                        className="wd-15p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      ></th>
                      <th
                        className="wd-10p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Efficiency
                      </th>
                      <th
                        className="wd-10p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Capacity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <select
                          style={{ height: "25px" }}
                          onChange={(e) =>
                            this.props.changeMeters(
                              e.target.value,
                              "IN",
                              asset.asset_id,
                              asset.asset_type_id,
                              meter_in_gas
                            )
                          }
                          value={meter_in_gas}
                          className="inline-single-input"
                        >
                          <option value={null}>Select Gas Meter</option>
                          {gasMeters.map((m) => {
                            return (
                              <option
                                key={m.meter_id}
                                value={m.meter_id}
                              >{`${m.description} (${m.meter_id})`}</option>
                            );
                          })}
                        </select>
                      </td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <select
                          style={{ height: "25px" }}
                          onChange={(e) =>
                            this.props.changeMeters(
                              e.target.value,
                              "OUT",
                              asset.asset_id,
                              asset.asset_type_id,
                              meter_out_heat
                            )
                          }
                          value={meter_out_heat}
                          className="inline-single-input"
                        >
                          <option value={null}>Select Heat Meter</option>
                          {heatMeters.map((m) => {
                            return (
                              <option
                                key={m.meter_id}
                                value={m.meter_id}
                              >{`${m.description} (${m.meter_id})`}</option>
                            );
                          })}
                        </select>
                      </td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      ></td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      ></td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      ></td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <input
                          placeholder="Efficiency"
                          type="number"
                          className="inline-single-input"
                          defaultValue={asset.efficiency}
                          onChange={this.props.changeAssetValues.bind(
                            this,
                            asset,
                            "efficiency"
                          )}
                        />
                      </td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <input
                          placeholder="Capacity"
                          type="number"
                          className="inline-single-input"
                          defaultValue={asset.capacity}
                          onChange={this.props.changeAssetValues.bind(
                            this,
                            asset,
                            "capacity"
                          )}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
      );
    }

    // HEAT PUMP (1) (elec/heat)
    if (asset.asset_type_id === 30) {
      let meter_in_elec = "";
      let meter_out_heat = "";
      if (asset.generator_meters.length) {
        meter_in_elec = asset.generator_meters.find(
          (m) => m.meter_role === "IN"
        );
        meter_out_heat = asset.generator_meters.find(
          (m) => m.meter_role === "OUT"
        );

        meter_in_elec = meter_in_elec ? meter_in_elec.meter_id : "";
        meter_out_heat = meter_out_heat ? meter_out_heat.meter_id : "";
      }

      return (
        <tr>
          <td
            className="valign-middle"
            colSpan="8"
            style={{ border: "none", padding: "0px" }}
          >
            <div className="d-flex align-items-center">
              <div style={{ width: "100%" }}>
                <table
                  className="table mg-b-0 table-contact pd-l-0 pd-r-0"
                  style={{ marginTop: "0px" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="wd-20p tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Generator Meter - In - Electricity
                      </th>
                      <th
                        className="wd-20p tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Generator Meter - Out - Heat
                      </th>
                      <th
                        className="wd-5p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      ></th>
                      <th
                        className="wd-20p tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      ></th>
                      <th
                        className="wd-15p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      ></th>
                      <th
                        className="wd-10p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Efficiency
                      </th>
                      <th
                        className="wd-10p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Capacity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <select
                          style={{ height: "25px" }}
                          onChange={(e) =>
                            this.props.changeMeters(
                              e.target.value,
                              "IN",
                              asset.asset_id,
                              asset.asset_type_id,
                              meter_in_elec
                            )
                          }
                          value={meter_in_elec}
                          className="inline-single-input"
                        >
                          <option value={null}>Select Electricity Meter</option>
                          {electricityMeters.map((m) => {
                            return (
                              <option
                                key={m.meter_id}
                                value={m.meter_id}
                              >{`${m.description} (${m.meter_id})`}</option>
                            );
                          })}
                        </select>
                      </td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <select
                          style={{ height: "25px" }}
                          onChange={(e) =>
                            this.props.changeMeters(
                              e.target.value,
                              "OUT",
                              asset.asset_id,
                              asset.asset_type_id,
                              meter_out_heat
                            )
                          }
                          value={meter_out_heat}
                          className="inline-single-input"
                        >
                          <option value={null}>Select Heat Meter</option>
                          {heatMeters.map((m) => {
                            return (
                              <option
                                key={m.meter_id}
                                value={m.meter_id}
                              >{`${m.description} (${m.meter_id})`}</option>
                            );
                          })}
                        </select>
                      </td>

                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      ></td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      ></td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      ></td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <input
                          placeholder="Efficiency"
                          type="number"
                          className="inline-single-input"
                          defaultValue={asset.efficiency}
                          onChange={this.props.changeAssetValues.bind(
                            this,
                            asset,
                            "efficiency"
                          )}
                        />
                      </td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <input
                          placeholder="Capacity"
                          type="number"
                          className="inline-single-input"
                          defaultValue={asset.capacity}
                          onChange={this.props.changeAssetValues.bind(
                            this,
                            asset,
                            "capacity"
                          )}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
      );
    }

    // CHP (2) (gas/ heat,elec)
    if (asset.asset_type_id === 28) {
      let meter_in_gas = "";
      let meter_out_heat = "";
      let meter_out_elec = "";
      if (asset.generator_meters.length) {
        meter_in_gas = asset.generator_meters.find(
          (m) => m.meter_role === "IN"
        );
        meter_out_heat = asset.generator_meters.find(
          (m) =>
            m.meter_role === "OUT" &&
            heatMeters.find((z) => z.meter_id === m.meter_id)
        );

        meter_out_elec = asset.generator_meters.find(
          (m) =>
            m.meter_role === "OUT" &&
            electricityMeters.find((z) => z.meter_id === m.meter_id)
        );

        meter_in_gas = meter_in_gas ? meter_in_gas.meter_id : "";
        meter_out_heat = meter_out_heat ? meter_out_heat.meter_id : "";
        meter_out_elec = meter_out_elec ? meter_out_elec.meter_id : "";
      }

      return (
        <tr>
          <td
            className="valign-middle"
            colSpan="8"
            style={{ border: "none", padding: "0px" }}
          >
            <div className="d-flex align-items-center">
              <div style={{ width: "100%" }}>
                <table
                  className="table mg-b-0 table-contact pd-l-0 pd-r-0"
                  style={{ marginTop: "0px" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="wd-20p tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Generator Meter - In - Gas
                      </th>
                      <th
                        className="wd-20p tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Generator Meter - Out - Heat
                      </th>
                      <th
                        className="wd-25p tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Generator Meter - Out - Electricity
                      </th>
                      <th
                        className="wd-5p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      ></th>
                      <th
                        className="wd-10p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      ></th>
                      <th
                        className="wd-10p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Efficiency
                      </th>
                      <th
                        className="wd-10p tx-10-force tx-mont tx-medium"
                        style={{ fontSize: "10px" }}
                      >
                        Capacity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <select
                          style={{ height: "25px" }}
                          onChange={(e) =>
                            this.props.changeMeters(
                              e.target.value,
                              "IN",
                              asset.asset_id,
                              asset.asset_type_id,
                              meter_in_gas
                            )
                          }
                          value={meter_in_gas}
                          className="inline-single-input"
                        >
                          <option value={null}>Select Gas Meter</option>
                          {gasMeters.map((m) => {
                            return (
                              <option
                                key={m.meter_id}
                                value={m.meter_id}
                              >{`${m.description} (${m.meter_id})`}</option>
                            );
                          })}
                        </select>
                      </td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <select
                          style={{ height: "25px" }}
                          onChange={(e) =>
                            this.props.changeMeters(
                              e.target.value,
                              "OUT",
                              asset.asset_id,
                              asset.asset_type_id,
                              meter_out_heat
                            )
                          }
                          value={meter_out_heat}
                          className="inline-single-input"
                        >
                          <option value={null}>Select Heat Meter</option>
                          {heatMeters.map((m) => {
                            return (
                              <option
                                key={m.meter_id}
                                value={m.meter_id}
                              >{`${m.description} (${m.meter_id})`}</option>
                            );
                          })}
                        </select>
                      </td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <select
                          style={{ height: "25px" }}
                          onChange={(e) =>
                            this.props.changeMeters(
                              e.target.value,
                              "OUT",
                              asset.asset_id,
                              asset.asset_type_id,
                              meter_out_elec
                            )
                          }
                          value={meter_out_elec}
                          className="inline-single-input"
                        >
                          <option value={null}>Select Electricity Meter</option>
                          {electricityMeters.map((m) => {
                            return (
                              <option
                                key={m.meter_id}
                                value={m.meter_id}
                              >{`${m.description} (${m.meter_id})`}</option>
                            );
                          })}
                        </select>
                      </td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      ></td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      ></td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <input
                          placeholder="Efficiency"
                          type="number"
                          className="inline-single-input"
                          defaultValue={asset.efficiency}
                          onChange={this.props.changeAssetValues.bind(
                            this,
                            asset,
                            "efficiency"
                          )}
                        />
                      </td>
                      <td
                        className="valign-middle"
                        style={{
                          border: "none",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <input
                          placeholder="Capacity"
                          type="number"
                          className="inline-single-input"
                          defaultValue={asset.capacity}
                          onChange={this.props.changeAssetValues.bind(
                            this,
                            asset,
                            "capacity"
                          )}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
      );
    }

    return null;
  }

  getOtherAssetDetails(asset) {
    return (
      <tr>
        <td
          className="valign-middle"
          colSpan="8"
          style={{ border: "none", padding: "0px" }}
        >
          <div className="d-flex align-items-center">
            <div style={{ width: "100%" }}>
              <table
                className="table mg-b-0 table-contact pd-l-0 pd-r-0"
                style={{ marginTop: "0px" }}
              >
                <thead>
                  <tr>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Heating Source ID
                    </th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Cooling Source ID
                    </th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Airflow Volume
                    </th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Supply Fan Motor
                    </th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Return Fan Motor
                    </th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Base Temperature
                    </th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Burner Capacity
                    </th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Burner Efficiency
                    </th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      DX Capacity
                    </th>
                    <th
                      className="wd-10p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      DX Efficiency
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="Heating Source ID"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.heating_source_id}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "heating_source_id"
                        )}
                      />
                    </td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="Cooling Source ID"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.cooling_source_id}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "cooling_source_id"
                        )}
                      />
                    </td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="Airflow Volume"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.airflow_volume}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "airflow_volume"
                        )}
                      />
                    </td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="Supply Fan Motor"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.supply_fan_motor}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "supply_fan_motor"
                        )}
                      />
                    </td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="Return Fan Motor"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.return_fan_motor}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "return_fan_motor"
                        )}
                      />
                    </td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="Base Temperature"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.base_temp}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "base_temp"
                        )}
                      />
                    </td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="Burner Capacity"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.burner_capacity}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "burner_capacity"
                        )}
                      />
                    </td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="Burner Efficiency"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.burner_efficiency}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "burner_efficiency"
                        )}
                      />
                    </td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="DX Capacity"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.dx_capacity}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "dx_capacity"
                        )}
                      />
                    </td>
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <input
                        placeholder="DX Efficiency"
                        type="number"
                        className="inline-single-input"
                        defaultValue={asset.dx_efficiency}
                        onChange={this.props.changeAssetValues.bind(
                          this,
                          asset,
                          "dx_efficiency"
                        )}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  render() {
    let asset = this.state.asset;
    return (
      <React.Fragment>
        {this.getAssetRow(asset)}
        {this.state.expanded && this.getAssetDetailsRow(asset)}
        {this.state.expanded && (
          <tr>
            <td style={{ border:"none" }} colSpan={8}>
              <div class="button-container">
                {!!asset.generator_meters.length && (
                  <Button
                    className="button red"
                    icon={<Icon name="Reset" color={'#fff'} size={18} />}
                    size="small"
                    onClick={() => this.props.clearMeters(asset.asset_id)}
                    style={{ marginLeft: '20px' }}
                  >Clear Meters</Button>
                )}
                {asset.asset_id ? (
                  <Button
                    className="button orange"
                    icon={<Icon name="Edit" color={'#fff'} size={18} />}
                    size="small"
                    onClick={this.props.updateAsset.bind(this, asset)}
                    style={{ marginLeft: '20px' }}
                  >Update</Button>
                ) : (
                  <Button
                    className="button green"
                    icon={<Icon name="Save" color={'#fff'} size={18} />}
                    size="small"
                    onClick={this.props.saveAsset.bind(this, asset)}
                    style={{ marginLeft: '20px' }}
                  >Save</Button>
                )}
                {asset.asset_id ? (
                  <Button
                    className="button red"
                    icon={<Icon name="Delete" color={'#fff'} size={18} />}
                    size="small"
                    onClick={this.props.deleteAsset.bind(this, asset.asset_id)}
                    style={{ marginLeft: '20px' }}
                  >Delete</Button>
                ) : null}
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}

export default ModifyAssetPanel;
