import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";
import _ from 'lodash';


class ConsumptionAnalysisActions {
    getAvailableMeters(ts_start, ts_end) {
        return GeneralUtils.get({
            url: `v2/meters${ts_start && ts_end ? `?from=${ts_start}&to=${ts_end}` : ""}`,
            actionType: ActionTypes.CONSUMPTION_ANALYSIS_METERS_FETCHED,
            failToast: "Unable to fetch meters overview, please try again",
            modifyResponse: (response) => {
                return response
                    .filter(meter => !meter.meter.type.includes('WATER_METER'))
                    .map((meter) => {
                        return {
                            label: `${meter.building.name} - ${meter.meter.name}`,
                            value: meter.meter.id
                        };
                    });
            }
        });
    }

    getConsumptionData(meter_id, ts_start, ts_end) {
        return GeneralUtils.get({
            url: `energy/consumption-analysis/meter/${meter_id}?from=${ts_start}&to=${ts_end}`,
            actionType: ActionTypes.CONSUMPTION_ANALYSIS_DATA_FETCHED,
        });
    }

    generateToken(tokenObject) {
        return GeneralUtils.post({
            url: "tokens/generate",
            object: tokenObject,
            actionType: ActionTypes.CONSUMPTION_ANALYSIS_TOKEN_GENERATED,
            modifyResponse: (response) => _.get(response, "token"),
            failToast: "Error creating sharing token.",
        });
    }

    getAnalysisByToken(token) {
        return GeneralUtils.get({
            url: `tokens/process?token=${token}`,
            actionType: ActionTypes.CONSUMPTION_ANALYSIS_TOKEN_DATA_FETCHED,
            failToast: "Error fetching analysis data",
        });
    }

}

export default new ConsumptionAnalysisActions();
