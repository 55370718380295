import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./AdminMenuItem.scss";

const AdminMenuItem = (props) => {
  const { category, pathName } = props;

  const [ShowItems, setShowItems] = useState(false);

  useEffect(() => {
    if (category.items.length > 0) {
      category.items.forEach((item) => {
        if (pathName.includes(item.link)) {
          setShowItems(true);
        }
      });
    }
  }, [category, pathName]);

  const getItems = () => {
    let itemsList = null;

    if (category.items) {
      itemsList = category.items.map((item) => {
        return (
          <li key={item.link} className="br-menu-item menu-item-building">
            <NavLink
              to={item.link}
              className="br-menu-link menu-item-navlink"
              style={{ height: "auto" }}
            >
              <ion-icon name={item.icon || "grid"} size="small" />
              <span className="menu-item-label">{item.label}</span>
            </NavLink>
          </li>
        );
      });
    }
    return (
      <ul
        className={`menu-item-wrapper ${
          ShowItems ? "menu-item-expanded" : "menu-item-hidden"
        }`}
      >
        {itemsList}
      </ul>
    );
  };

  return (
    <li
      key={category.label}
      id="AdminMenuItem"
      className="br-menu-item br-menu-link menu-item-group"
      style={{ display: "block" }}
      onClick={() => setShowItems(!ShowItems)}
    >
      <span className="menu-item-label menu-item-group-label">
        <span className="menu-item-icon">{`${ShowItems ? "- " : "+ "}`}</span>
        {category.label}
      </span>
      {getItems()}
    </li>
  );
};

export default AdminMenuItem;
