import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching HVAC details. This class interfaces with the API
 * to retrieve asset data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const hvacDetailsActions = new HvacDetailsActions();
 * hvacDetailsActions.getAsset('asset123');
 */
class HvacDetailsActions {
  /**
   * Fetches the details of a specific asset by its ID from the API and dispatches an action to store this data.
   *
   * @param {string} assetId - The unique identifier of the asset.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAsset(assetId) {
    return GeneralUtils.get({
      url: `asset/${assetId}`,
      actionType: ActionTypes.HVAC_DETAILS_INFO_FETCHED,
    });
  }

  /**
   * Fetches the asset details for a specific asset within a specified time range from the API
   * and dispatches an action to store this data. The data can be aggregated or detailed based on the mode.
   *
   * @param {string} assetId - The unique identifier of the asset.
   * @param {string} tsStart - The start timestamp of the data range.
   * @param {string} tsEnd - The end timestamp of the data range.
   * @param {string} [mode="aggregated"] - The mode of the data to fetch, either "aggregated" or "detailed".
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAssetDetails(assetId, tsStart, tsEnd, mode = "aggregated") {
    return GeneralUtils.get({
      url: `asset/${assetId}/chart/${tsStart}/${tsEnd}?data_type=${mode}`,
      actionType: ActionTypes.HVAC_DETAILS_CHARTS_FETCHED,
    });
  }
}

export default new HvacDetailsActions();
