import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";
import _ from "lodash";

const DASHBOARD_TOKEN_GENERATED = ActionTypes.DASHBOARD_TOKEN_GENERATED;
const DASHBOARD_DATA_FETCHED = ActionTypes.DASHBOARD_DATA_FETCHED;

let _token = null;
let _data = null;

class DashboardsStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case DASHBOARD_TOKEN_GENERATED:
        this._storeToken(action.payload);
        break;
      case DASHBOARD_DATA_FETCHED:
        this._storeData(action.payload);
        break;
      default:
        break;
    }
  }

  // Token Listeners
  addTokenGeneratedListener(callback) {
    this.on(DASHBOARD_TOKEN_GENERATED, callback);
  }
  removeTokenGeneratedListener(callback) {
    this.removeListener(DASHBOARD_TOKEN_GENERATED, callback);
  }

  // Token Listeners
  addDashboardDataFetchedListener(callback) {
    this.on(DASHBOARD_DATA_FETCHED, callback);
  }
  removeDashboardDataFetchedListener(callback) {
    this.removeListener(DASHBOARD_DATA_FETCHED, callback);
  }

  clear() {
    _token = null;
    _data = null;
  }

  _storeToken(json) {
    _token = json.token;
    this.emit(DASHBOARD_TOKEN_GENERATED);
  }

  _storeData(json) {
    _data = json;
    this.emit(DASHBOARD_DATA_FETCHED);
  }

  // Getters

  getToken() {
    return _token;
  }

  getData() {
    return _data;
  }

  //METER USAGE DASHBAORD
  getMeterUsageDashboardData() {
    const data = _.get(_data, "data");
    let usage = [0, 0, 0, 0, 0, 0, 0];
    let predictions = [0, 0, 0, 0, 0, 0, 0];
    let last_week = [0, 0, 0, 0, 0, 0, 0];
    let previous_week = 0;
    let week_so_far = 0;
    let unit = "";
    let type = "";

    if (data) {
      data.forEach((item) => {
        item.trend_data.forEach((trend, index) => {
          // Accumulate sums for the corresponding day of the week
          usage[index] += trend.total;

          if (trend.total === 0) {
            predictions[index] += trend.predicted_total;
          }

          last_week[index] += trend.prev_total;
        });

        previous_week += item.previous_week;
        week_so_far += item.week_so_far;
        unit = item.meter_unit;
        type = item.meter_type;
      });
    }

    let usage_difference = week_so_far - previous_week;
    let usage_difference_percentage = (usage_difference / previous_week) * 100;

    return {
      usage,
      predictions,
      last_week,
      unit,
      type,
      previous_week,
      week_so_far,
      usage_difference,
      usage_difference_percentage,
      ..._data,
    };
  }

  // INDOOR CONDITIONS
  getIndoorConditionsData() {
    const building = _.get(_data, "data.building");
    const zones = _.get(_data, "data.zones");
    const building_id = _.get(_data, "building_id");

    return {
      building_id,
      building,
      zones,
    };
  }

  // ZONE CONDITIONS
  getZoneConditionsData() {
    const name = _.get(_data, "data.name");
    const description = _.get(_data, "data.description");
    const timestamp = _.get(_data, "data.timestamp");

    const co2_value = _.get(_data, "data.co2_value");
    const co2_target = _.get(_data, "data.co2_target");

    const temperature_value = _.get(_data, "data.temperature_value");
    const temperature_lower_target = _.get(
      _data,
      "data.temperature_lower_target"
    );
    const temperature_upper_target = _.get(
      _data,
      "data.temperature_upper_target"
    );

    const occupancy_count = _.get(_data, "data.occupancy_count");
    const occupancy_percent = _.get(_data, "data.occupancy_percent");
    const occupancy_target_limit = _.get(_data, "data.occupancy_target_limit");

    return {
      name,
      description,
      timestamp,

      co2_value,
      co2_target,

      temperature_value,
      temperature_lower_target,
      temperature_upper_target,

      occupancy_count,
      occupancy_percent,
      occupancy_target_limit,
    };
  }

  // OCCUPANCY STATUS
  getOccupancyStatus() {
    return {
      ..._data.data,
    };
  }
}

export default new DashboardsStore();
