import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";

const CHANGE = "WELLNESS_CHANGE";

let _zones = [];
let _zone_building = {
  name: "",
  building_id: 0,
  description: "",
};

class WellnessStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case Constants.ZONES_FETCHED:
        this._storeZones(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }

  clear() {
    _zones = [];
  }

  _storeZones(json) {
    if (json.building) {
      _zone_building = json.building;
    }
    if (json.zones) {
      _zones = json.zones;
      this.emit(CHANGE);
    }
  }

  getZones() {
    return _zones;
  }

  getZoneBuilding() {
    return _zone_building;
  }
}

export default new WellnessStore();
