import React, { Component } from "react";
import "./EnergyReviewConfig.scss";

import EnergyReviewStore from "../../../stores/energyReviewStore";
import EnergyReviewActions from "../../../actions/energyReviewActions";
import UserStore from "../../../stores/userStore";
import UrlManager from "../../../utils/UrlManager";

import _ from "lodash";
import moment from "moment";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactHintFactory from "react-hint";

import { OrganisationSelect } from "../../../components/OrganisationSelect";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Filter } from "../../../components/Filter";
import { Modal } from "../../../components/Modal";

const ReactHint = ReactHintFactory(React);
class EnergyReviewConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      catalogue: [],
      meters: [],
      review_meters: [],

      selected_org: null,
      isSuper: UserStore.isSuper(),

      lists: [],
      selected_list: null,

      selected_meters: [],
      selected_type: { label: "All", accessor: "" },
      meter_types: [
        { label: "Electric", accessor: "em" },
        { label: "Gas", accessor: "gm" },
        { label: "Heat", accessor: "hm" },
        { label: "All", accessor: "" },
      ],

      // Modals
      modal_type: null,
      modal_object: null,

      new_list_name: "",
      target_year: moment().year(),
      consumption_per_day: "",
      regression_intercept: "",
      regression_hdd_slope: "",
      regression_cdd_slope: "",
      regression_edd_slope: "",
      regression_ehd_slope: "",
      baseline_mode: "CONSUMPTION_PER_DAY",
      is_meter_modified: false,
      review_meter_baselines: [],
      select_meter_after_review: false,
    };

    this.selectOrganisation = this.selectOrganisation.bind(this);
    this.getOrgId = this.getOrgId.bind(this);

    this.onCatalogueFetched = this.onCatalogueFetched.bind(this);
    this.onListsFetched = this.onListsFetched.bind(this);
    this.onReviewMetersFetched = this.onReviewMetersFetched.bind(this);
    this.onListCreation = this.onListCreation.bind(this);
    this.onListDeletion = this.onListDeletion.bind(this);
    this.onMeterCreation = this.onMeterCreation.bind(this);
    this.onMeterDeletion = this.onMeterDeletion.bind(this);

    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.collapseAllMeters = this.collapseAllMeters.bind(this);
    this.deleteList = this.deleteList.bind(this);
  }

  // Lifecycle

  componentDidMount() {
    const isSuper = this.state.isSuper;
    const org_id = this.getOrgId();

    if (this.state.catalogue.length === 0) {
      EnergyReviewActions.getMeters();
      EnergyReviewActions.getBuildings();
    }

    if (isSuper === false) {
      if (this.state.lists.length === 0) {
        EnergyReviewActions.getLists(org_id);
      }

      if (this.state.review_meters.length === 0) {
        EnergyReviewActions.getReviewMeters(org_id);
      }
    }
  }

  componentWillMount() {
    EnergyReviewStore.addCatalogueCreatedListener(this.onCatalogueFetched);
    EnergyReviewStore.addListsFetchedListener(this.onListsFetched);
    EnergyReviewStore.addReviewMetersFetchedListener(
      this.onReviewMetersFetched
    );
    EnergyReviewStore.addListCreatedListener(this.onListCreation);
    EnergyReviewStore.addListUpdatedListener(this.onListCreation);
    EnergyReviewStore.addListDeletedListener(this.onListDeletion);
    EnergyReviewStore.addReviewMeterCreatedListener(this.onMeterCreation);
    EnergyReviewStore.addReviewMeterUpdatedListener(this.onMeterCreation);
    EnergyReviewStore.addReviewMeterDeletedListener(this.onMeterDeletion);
  }

  componentWillUnmount() {
    EnergyReviewStore.removeCatalogueCreatedListener(this.onCatalogueFetched);
    EnergyReviewStore.removeListsFetchedListener(this.onListsFetched);
    EnergyReviewStore.removeListCreatedListener(this.onListCreation);
    EnergyReviewStore.removeListUpdatedListener(this.onListCreation);
    EnergyReviewStore.removeListDeletedListener(this.onListDeletion);
    EnergyReviewStore.removeReviewMeterCreatedListener(this.onMeterCreation);
    EnergyReviewStore.removeReviewMeterUpdatedListener(this.onMeterCreation);
    EnergyReviewStore.removeReviewMeterDeletedListener(this.onMeterDeletion);
  }

  // Callback Listeners

  onCatalogueFetched() {
    const catalogue = EnergyReviewStore.getCatalogue();
    const meters = EnergyReviewStore.getRawMeters();

    this.setState({ catalogue: catalogue, meters: meters });
  }

  onListsFetched() {
    const lists = EnergyReviewStore.getLists();
    this.setState({ lists: lists });
  }

  onReviewMetersFetched() {
    const review_meters = EnergyReviewStore.getReviewMeters();
    const last_added_meter = EnergyReviewStore.getLastAddedMeter();

    this.setState({ review_meters: review_meters }, () => {
      if (last_added_meter) {
        const meter_id = last_added_meter.meter_id;
        const building_id = this.state.meters.find(
          (m) => m.meter_id === meter_id
        ).building_id;

        this.toggleMeter(meter_id, building_id);
        EnergyReviewStore.clear();
      }
    });
  }

  onListCreation() {
    const org_id = this.getOrgId();
    EnergyReviewActions.getLists(org_id);
    this.closeModal();
  }
  onListDeletion() {
    const org_id = this.getOrgId();
    EnergyReviewActions.getLists(org_id);
    this.setState({ selected_list: null }, () => this.closeModal());
  }

  onMeterCreation() {
    const org_id = this.getOrgId();

    EnergyReviewActions.getReviewMeters(org_id);
    this.closeModal();
  }

  onMeterDeletion() {
    const org_id = this.getOrgId();
    // Refresh List of Reviewed Meters
    EnergyReviewActions.getReviewMeters(org_id);
    // Refresh List of Lists to reflect deleted meter
    EnergyReviewActions.getLists(org_id);
    this.closeModal();
  }

  // Handlers and Helpers

  getOrgId() {
    if (this.state.isSuper)
      return _.get(this.state, "selected_org.organisation_id");
    return UserStore.getProfile().fk_organisation_id;
  }

  selectOrganisation(org) {
    this.setState({ selected_org: org }, () => {
      EnergyReviewActions.getLists(org.organisation_id);
      EnergyReviewActions.getReviewMeters(org.organisation_id);
    });
  }

  toggleBuildingExpansion(building_id) {
    const modified_catalogue = _.cloneDeep(this.state.catalogue);

    let building = modified_catalogue.find(
      (b) => b.building_id === building_id
    );
    building.expanded = !building.expanded;
    this.setState({ catalogue: modified_catalogue });
  }

  toggleMeterExpansion(meter_id, building_id) {
    let modified_catalogue = _.cloneDeep(this.state.catalogue);
    let meters = modified_catalogue.find(
      (e) => e.building_id === building_id
    ).meters;

    const findIdAndToggle = (id, arr) => {
      arr.forEach((el) => {
        if (el.meter_id === id) {
          el.expanded = !el.expanded;
          return;
        } else {
          findIdAndToggle(id, el.submeters);
        }
      });
    };

    findIdAndToggle(meter_id, meters);
    this.setState({ catalogue: modified_catalogue });
  }

  toggleMeter(meter_id, building_id) {
    let modified_catalogue = _.cloneDeep(this.state.catalogue);
    let meters = modified_catalogue.find(
      (e) => e.building_id === building_id
    ).meters;

    const findIdAndSelect = (id, arr) => {
      arr.forEach((el) => {
        if (el.meter_id === id) {
          let modified_selected_meters = _.cloneDeep(
            this.state.selected_meters
          );

          if (modified_selected_meters.find((m) => m.meter_id === meter_id)) {
            this.setState(
              {
                selected_meters: modified_selected_meters.filter(
                  (m) => m.meter_id !== meter_id
                ),
              },
              () => this.makeListChange()
            );
          } else {
            const review_meter = this.state.review_meters.find(
              (rm) => rm.meter_id === el.meter_id
            );
            this.setState(
              {
                selected_meters: [...modified_selected_meters, review_meter],
              },
              () => this.makeListChange()
            );
          }
        } else {
          findIdAndSelect(id, el.submeters);
        }
      });
    };

    findIdAndSelect(meter_id, meters);

    this.setState({ catalogue: modified_catalogue });
  }

  makeListChange() {
    const selected_list = this.state.lists.find(
      (l) => l.energy_review_list_id === this.state.selected_list
    );

    EnergyReviewActions.updateList({
      ...selected_list,
      energy_review_meters_ids: this.state.selected_meters.map(
        (rm) => rm.energy_review_meter_id
      ),
    });
  }

  collapseAllMeters() {
    let modified_catalogue = _.cloneDeep(this.state.catalogue);

    function collapseAllRecursive(meter) {
      meter.expanded = false;
      if (meter.submeters) {
        for (const submeter of meter.submeters) {
          collapseAllRecursive(submeter);
        }
      }
    }

    modified_catalogue.forEach((b) => {
      b.expanded = false;

      b.meters.forEach((m) => {
        collapseAllRecursive(m);
      });
    });

    this.setState({ catalogue: modified_catalogue });
  }

  selectList(list) {
    // translate rm ids into meter ids

    let lists_meters_with_ids = this.state.review_meters
      .map((rm) => {
        if (list.energy_review_meters_ids.includes(rm.energy_review_meter_id)) {
          return rm.meter_id;
        }
        return null;
      })
      .filter((f) => f);

    // list meter for expansion
    const lists_meters = this.state.meters.filter((m) => {
      return lists_meters_with_ids.includes(m.meter_id);
    });

    // list meter for selection (rms)
    const selected_meters = this.state.review_meters.filter((m) => {
      return list.energy_review_meters_ids.includes(m.energy_review_meter_id);
    });

    // Find and expand meters from the list and all their ancestors

    let modified_catalogue = _.cloneDeep(this.state.catalogue);

    function expandAncestors(meterTree, targetId) {
      // Helper function to recursively expand ancestors
      function expandAncestorsRecursive(node) {
        if (node.meter_id === targetId) {
          node.expanded = true;
          return true;
        } else if (node.submeters) {
          for (const submeter of node.submeters) {
            if (expandAncestorsRecursive(submeter)) {
              node.expanded = true;
              return true;
            }
          }
        }
        return false;
      }

      // Iterate through each meter to find the targetId and expand ancestors
      for (const meter of meterTree) {
        expandAncestorsRecursive(meter);
      }
    }

    const open_building_ids = lists_meters.map((m) => m.building_id);

    lists_meters.forEach((m) => {
      // find the building
      modified_catalogue.forEach((b) => {
        if (open_building_ids.includes(b.building_id)) {
          b.expanded = true;
          expandAncestors(b.meters, m.meter_id);
        } else {
          b.expanded = false;
        }
      });
    });

    this.setState(
      {
        selected_list: list.energy_review_list_id,
        selected_meters: selected_meters,
        catalogue: modified_catalogue,
      },
      () => {
        if (lists_meters.length === 0) this.collapseAllMeters();
      }
    );
  }

  deleteList(list) {
    if (window.confirm("Do you wish to delete the list " + list.name + "?")) {
      EnergyReviewActions.deleteList(list.energy_review_list_id);
    }
  }

  viewReport(list) {
    window.open("/energy-review-report/" + list.ref, "_blank");
  }

  copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      toast("Link Copied", {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
        preventDuplicated: true,
      });
    });
  }

  clearAllFilters() {
    this.setState({
      selected_type: { label: "All", accessor: "" },
      filter: "",
    });
  }

  selectMeter(meter) {
    // check if meter is already reviewed
    const meter_reviewed = this.state.review_meters.find(
      (rm) => meter.meter_id === rm.meter_id
    );

    // If reviewed just select it - Warning: it selects the reviewed meter
    if (meter_reviewed) {
      this.toggleMeter(meter.meter_id, meter.building_id);
    } else {
      // If it's not reviewed force the user to create it
      this.setState({
        modal_type: "review_meter",
        modal_object: meter,
        review_meter_baselines: meter_reviewed ? meter_reviewed.baselines : [],
        select_meter_after_review: true,
      });
    }
  }

  editBaselines(meter) {
    // check if meter is already reviewed
    const meter_reviewed = this.state.review_meters.find(
      (rm) => meter.meter_id === rm.meter_id
    );

    this.setState({
      modal_type: "review_meter",
      modal_object: meter,
      review_meter_baselines: meter_reviewed ? meter_reviewed.baselines : [],
      select_meter_after_review: false,
    });
  }

  // Renders

  getLists() {
    const isSuper = this.state.isSuper;
    const sorted_list_of_lists = _.orderBy(this.state.lists, (list) =>
      moment(list.created_at)
    ).reverse();

    if (sorted_list_of_lists.length === 0) {
      return (
        <div className="list-of-lists">
          {isSuper ? (
            <div className="org-select">
              <OrganisationSelect
                selectedOrg={this.state.selected_org}
                onOrganisationSelect={this.selectOrganisation}
                placeholder={"Select Organisation"}
                white
              />
            </div>
          ) : (
            <div>Create a new list to get started.</div>
          )}
        </div>
      );
    }

    return (
      <div className="list-of-lists">
        {isSuper && (
          <div className="org-select">
            <OrganisationSelect
              selectedOrg={this.state.selected_org}
              onOrganisationSelect={this.selectOrganisation}
              placeholder={"Select Organisation"}
              white
            />
          </div>
        )}

        {sorted_list_of_lists.map((list) => {
          return this.getListItem(list);
        })}
      </div>
    );
  }

  getListItem(list) {
    const url = UrlManager.getUIUrl() + `energy-review-report/${list.ref}`;

    return (
      <div
        className={`list-item card ${this.state.selected_list &&
          this.state.selected_list === list.energy_review_list_id
          ? "selected"
          : ""
          }`}
        key={list.energy_review_list_id}
        onClick={() => this.selectList(list)}
      >
        <div className="top-row">
          <div className="name">{list.name}</div>
          <div className="meter">
            {list.energy_review_meters_ids.length === 1
              ? `${list.energy_review_meters_ids.length} meter`
              : `${list.energy_review_meters_ids.length} meters`}
          </div>
        </div>
        <div className="bottom-row">
          <div
            className="url"
            data-rh={url}
            onClick={() => this.copyToClipboard(url)}
          >
            {list.ref ? `Copy Report Link` : "-"}
            <ion-icon name="link" />
          </div>
          <div className="buttons">
            <div
              className="delete"
              onClick={() => this.deleteList(list)}
              data-rh={"Delete List"}
            >
              <ion-icon name="trash" />
            </div>
            <div
              className="view"
              onClick={() => this.viewReport(list)}
              data-rh={"View Report"}
            >
              <ion-icon name="eye" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getListPreview() {
    const list = this.state.lists.find(
      (l) => l.energy_review_list_id === this.state.selected_list
    );

    let lists_and_rms_are_synced = true;

    if (list) {
      list.energy_review_meters_ids.forEach((id) => {
        if (lists_and_rms_are_synced) {
          if (
            this.state.review_meters.find(
              (rm) => rm.energy_review_meter_id === id
            ) === undefined
          ) {
            lists_and_rms_are_synced = false;
          }
        }
      });
    }

    if (this.state.lists.length === 0) {
      return null;
    }

    if (list && lists_and_rms_are_synced) {
      return (
        <div className="list-preview">
          <div className="title">{list ? list.name : ""}</div>
          <div className="timestamp">
            Created: {moment(list.created_at).format("DD MMM, YYYY")}
          </div>
          <div className="timestamp">
            Last Updated: {moment(list.updated_at).format("DD MMM, YYYY")}
          </div>
          <div className="meters">
            <div className="meters-label">
              Current Meters ({list.energy_review_meters_ids.length}):
            </div>
            {list.energy_review_meters_ids.map((rm) => {
              const reviewed_meter = this.state.review_meters.find(
                (rms) => rms.energy_review_meter_id === rm
              );
              const meter = this.state.meters.find(
                (m) => reviewed_meter.meter_id === m.meter_id
              );

              return (
                <div className="meter" key={rm}>
                  <div className="label">- {meter.description}</div>
                  <div onClick={() => this.selectMeter(meter)}>
                    <ion-icon name="close" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return (
      <div className="list-preview">
        Select the list to see its details and start making changes.
      </div>
    );
  }

  getMeterTree() {
    const filter = this.state.filter.toLowerCase();

    const filterTree = (array, fn) => {
      return array.reduce((r, o) => {
        let submeters = filterTree(o.submeters || [], fn);
        if (fn(o) || submeters.length) {
          r.push(Object.assign({}, o, submeters.length && { submeters }));
        }
        return r;
      }, []);
    };

    const getSubMeters = (parent_meter, count, isDisabled = false) => {
      if (
        (parent_meter.expanded || this.state.filter.length > 0) &&
        parent_meter.submeters.length > 0
      ) {
        let indent = count * 15;
        count++;
        return parent_meter.submeters.map((meter, key) => {
          return (
            <div key={key} style={{ opacity: isDisabled ? 0.2 : 1 }}>
              {getRow(meter, indent, isDisabled)}
              {getSubMeters(meter, count, isDisabled)}
            </div>
          );
        });
      }
    };

    const getRow = (meter, indent, isDisabled = false) => {
      const meterSelected = this.state.selected_meters.find(
        (m) => m.meter_id === meter.meter_id
      );
      const virtualTag = meter.virtual ? (
        <div
          className="label-vr"
          data-rh="Virtually Calculated Meter"
          title="Virtually Calculated Meter"
        >
          VR
        </div>
      ) : undefined;

      let expandIcon = <div style={{ width: "18px" }}></div>;
      if (meter.submeters.length > 0)
        expandIcon = (
          <ion-icon
            onClick={this.toggleMeterExpansion.bind(
              this,
              meter.meter_id,
              meter.building_id
            )}
            name={
              meter.expanded ? "remove-circle-outline" : "add-circle-outline"
            }
          ></ion-icon>
        );
      if (this.state.filter !== "")
        expandIcon = <ion-icon name="return-right" />;

      const meter_reviewed = this.state.review_meters.find(
        (rm) => meter.meter_id === rm.meter_id
      );

      return (
        <div
          className="row-wrapper"
          style={{
            marginLeft: indent + "px",
            display: isDisabled ? "none" : "",
          }}
        >
          <div className="row-icon">{expandIcon}</div>
          <div
            className={`row-label ${meter_reviewed ? "reviewed" : ""}`}
            onClick={() => this.selectMeter(meter)}
            title={meter.description}
          >
            {virtualTag}
            {meter.description}
          </div>
          <div className="row-checkbox" onClick={() => this.selectMeter(meter)}>
            <div className="checkbox-outline">
              {meterSelected && meter_reviewed && (
                <div className="checkbox-fill"></div>
              )}
            </div>
          </div>

          <div
            className="review-meter"
            data-rh="Change baselines"
            onClick={() => this.editBaselines(meter)}
          >
            Edit Baselines
          </div>
        </div>
      );
    };

    const getMeterTree = (meters, building_id) => {
      if (filter) {
        const list_result = this.state.meters
          .filter((meter) => meter.building_id === building_id)
          .filter((meter) =>
            meter.type.includes(this.state.selected_type.accessor)
          )
          .filter((meter) => meter.description.toLowerCase().includes(filter));

        return list_result.map((meter, key) => {
          return (
            <div key={key} style={{ marginLeft: "20px", position: "relative" }}>
              {getRow(meter, 0)}
            </div>
          );
        });
      }

      let result = filterTree(meters, ({ description }) =>
        description.toLowerCase().includes(filter)
      );

      return result.map((meter, key) => {
        let disabled = false;
        // if type is selected, grey out every other type
        if (meter.type.includes(this.state.selected_type.accessor) === false) {
          disabled = true;
        }

        return (
          <div
            key={key}
            style={{
              marginLeft: "20px",
              position: "relative",
            }}
          >
            {getRow(meter, 0, disabled)}
            {getSubMeters(meter, 1, disabled)}
          </div>
        );
      });
    };

    const getBuildingMeters = (building) => {
      const buildingHasSelectedType = building.meters.find((meter) =>
        meter.type.includes(this.state.selected_type.accessor)
      );

      const building_belongs_to_selected_org =
        this.state.selected_org &&
        building.estate.organisation_id ===
        this.state.selected_org.organisation_id;

      const buildingFilteredMetersLackSelectedType =
        getMeterTree(building.meters, building.building_id).length === 0;

      const buildingFulfillsFilterCriteria =
        filterTree(building.meters, ({ description }) =>
          description.toLowerCase().includes(filter)
        ).length > 0;

      const building_is_disabled = building.status === "disabled";

      let expandIcon = (
        <ion-icon
          name={
            building.expanded ? "remove-circle-outline" : "add-circle-outline"
          }
        ></ion-icon>
      );

      if (this.state.filter !== "")
        expandIcon = <ion-icon name="business"></ion-icon>;

      if (
        buildingFilteredMetersLackSelectedType ||
        buildingHasSelectedType === undefined ||
        buildingFulfillsFilterCriteria === false ||
        building_is_disabled ||
        building_belongs_to_selected_org === false
      )
        return null;

      return (
        <div key={building.building_id} className="building-tree">
          <div
            className="building-wrapper"
            onClick={this.toggleBuildingExpansion.bind(
              this,
              building.building_id
            )}
          >
            {expandIcon} <span className="building-label">{building.name}</span>
          </div>
          {(building.expanded || this.state.filter !== "") &&
            getMeterTree(building.meters, building.building_id)}
        </div>
      );
    };

    return (
      <div className="meter-tree card">
        <div className="title-type-wrapper">
          <div className="label">Meter Tree</div>
          <div className="meter-type-wrapper">
            {this.state.meter_types.map((t) => {
              return (
                <button
                  key={t.accessor}
                  className={`btn ${this.state.selected_type.accessor === t.accessor
                    ? "btn-secondary"
                    : "btn-outline-secondary"
                    }`}
                  onClick={() => this.setState({ selected_type: t })}
                >
                  {t.label}
                </button>
              );
            })}
          </div>
        </div>

        <div className="filter">
          <div className="filter-wrapper">
            <Filter
              value={this.state.filter}
              placeholder={"Filter by Meter Name"}
              background="white"
              setFilter={(query) => this.setState({ filter: query })}
            />
          </div>
          <div className="clear-all-wrapper">
            <button
              className="clear-all-button"
              onClick={() => this.clearAllFilters()}
            >
              Clear All
            </button>
          </div>
        </div>
        <div className="tree-selector">
          <div className="inner-selector">
            {this.state.catalogue.map((building) =>
              getBuildingMeters(building)
            )}
          </div>
        </div>
      </div>
    );
  }

  // Modals and associated functions/calls

  getModal() {
    let element;
    const modal_type = this.state.modal_type;
    if (modal_type === null) return null;
    // New List
    if (modal_type === "new_list") element = this.getNewListModal();
    // Review Meter
    if (modal_type === "review_meter") element = this.getReviewMeterModal();

    return (
      <Modal
        hasExit
        backdropUnclickable
        style={{ minWidth: "50vw", overflow: "unset" }}
        toggleOpen={() => this.closeModal()}
        children={element}
      />
    );
  }

  closeModal() {
    this.setState({
      modal_type: null,
      modal_object: null,

      new_list_name: "",
      target_year: moment().year(),
      consumption_per_day: "",
      review_meter_baselines: [],
      select_meter_after_review: false,

      regression_intercept: "",
      regression_hdd_slope: "",
      regression_cdd_slope: "",
      regression_edd_slope: "",
      regression_ehd_slope: "",
      baseline_mode: "CONSUMPTION_PER_DAY",

      is_meter_modified: false,
    });
  }

  getNewListModal() {
    const disabled_save = !this.state.new_list_name;
    const org_name = this.state.selected_org
      ? this.state.selected_org.name
      : "";

    return (
      <div className="new-list-modal">
        <h5 className="title">
          Create New Meter List {org_name ? "for " + org_name : ""}
        </h5>
        <div className="form-group">
          <label className="form-control-label">List Name:</label>
          <input
            placeholder="Enter new name"
            type="text"
            className="form-control"
            value={this.state.new_list_name}
            onChange={(e) => this.setState({ new_list_name: e.target.value })}
          />
        </div>
        <div className="button-wrapper">
          <button
            className="btn btn-outline-danger"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-success"
            disabled={disabled_save}
            onClick={() => this.saveList()}
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  saveList() {
    const org_id = this.getOrgId();
    EnergyReviewActions.createList({
      name: this.state.new_list_name,
      organisation_id: org_id,
    });
  }

  getReviewMeterModal() {
    let meter = this.state.modal_object;
    const mode = this.state.baseline_mode;
    const baselines = this.state.review_meter_baselines;
    const consumption_per_day = this.state.consumption_per_day;
    const regression_intercept = this.state.regression_intercept;
    const regression_hdd_slope = this.state.regression_hdd_slope;
    const regression_cdd_slope = this.state.regression_cdd_slope;
    const regression_edd_slope = this.state.regression_edd_slope;
    const regression_ehd_slope = this.state.regression_ehd_slope;

    // Check if the save action also adds the meter to the list
    const select_meter_after_review = this.state.select_meter_after_review;

    // Check if meter is already reviewed
    const reviewed_meter = this.state.review_meters.find(
      (el) => el.meter_id === meter.meter_id
    );

    let disabled_adding = false;

    if (mode === "CONSUMPTION_PER_DAY") {
      if (consumption_per_day === "") {
        disabled_adding = true;
      }
    } else if (mode === "REGRESSION") {
      if (regression_intercept === "") {
        disabled_adding = true;
      }

      if (regression_hdd_slope === "" && regression_cdd_slope === "" && regression_edd_slope === "" && regression_ehd_slope === "") {
        disabled_adding = true;
      }
    }

    // Check if the year has been already set up
    if (baselines.map((b) => b.target_year).includes(this.state.target_year)) {
      disabled_adding = true;
    }

    return (
      <div className="review-meter-modal">
        <h5 className="title">
          Please assign baselines to {meter.description} ({meter.meter_id})
        </h5>

        <div className="baseline-mode-switch">
          <label className="form-control-label">Baseline Type:</label>
          <div className="radio-wrapper">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="baselineMode"
                id="consumptionPerDay"
                value="CONSUMPTION_PER_DAY"
                checked={this.state.baseline_mode === "CONSUMPTION_PER_DAY"}
                onChange={(e) =>
                  this.setState({ baseline_mode: e.target.value })
                }
              />
              <label className="form-check-label" htmlFor="consumptionPerDay">
                Daily Average
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="baselineMode"
                id="regression"
                value="REGRESSION"
                checked={this.state.baseline_mode === "REGRESSION"}
                onChange={(e) =>
                  this.setState({ baseline_mode: e.target.value })
                }
              />
              <label className="form-check-label" htmlFor="regression">
                Regression
              </label>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="year">
            <label className="form-control-label">Target Year:</label>
            <input
              placeholder="Enter the target year"
              type="number"
              className="form-control"
              value={this.state.target_year}
              onChange={(e) => this.setState({ target_year: +e.target.value })}
            />
          </div>
          {this.state.baseline_mode === "CONSUMPTION_PER_DAY" && (
            <div className="consumption">
              <label className="form-control-label">
                Energy Consumption (kWh) per Day:
              </label>
              <input
                placeholder="Enter target consumption per day"
                type="number"
                className="form-control"
                value={this.state.consumption_per_day}
                onChange={(e) =>
                  this.handleBaselineChange("consumption_per_day", e)
                }
              />
            </div>
          )}
          {this.state.baseline_mode === "REGRESSION" && (
            <div className="regression">
              <div>
                <label className="form-control-label">
                  Baseload Energy (kWh) per Day:
                </label>
                <input
                  placeholder="Enter Baseload Energy"
                  type="number"
                  className="form-control"
                  value={this.state.regression_intercept}
                  onChange={(e) =>
                    this.handleBaselineChange("regression_intercept", e)
                  }
                />
              </div>
              <div>
                <label className="form-control-label">HDD (kWh) Slope:</label>
                <input
                  placeholder="Enter HDD slope"
                  type="number"
                  className="form-control"
                  value={this.state.regression_hdd_slope}
                  onChange={(e) =>
                    this.handleBaselineChange("regression_hdd_slope", e)
                  }
                />
              </div>
              <div>
                <label className="form-control-label">CDD (kWh) Slope:</label>
                <input
                  placeholder="Enter CDD slope"
                  type="number"
                  className="form-control"
                  value={this.state.regression_cdd_slope}
                  onChange={(e) =>
                    this.handleBaselineChange("regression_cdd_slope", e)
                  }
                />
              </div>
              <div>
                <label className="form-control-label">EDD (kWh) Slope:</label>
                <input
                  placeholder="Enter EDD slope"
                  type="number"
                  className="form-control"
                  value={this.state.regression_edd_slope}
                  onChange={(e) =>
                    this.handleBaselineChange("regression_edd_slope", e)
                  }
                />
              </div>
              <div>
                <label className="form-control-label">EHD (kWh) Slope:</label>
                <input
                  placeholder="Enter EHD slope"
                  type="number"
                  className="form-control"
                  value={this.state.regression_ehd_slope}
                  onChange={(e) =>
                    this.handleBaselineChange("regression_ehd_slope", e)
                  }
                />
              </div>
            </div>
          )}
          <div className="add-baseline">
            <button
              className="btn btn-success"
              disabled={disabled_adding}
              onClick={() => this.addBaseline()}
            >
              Add
            </button>
          </div>
        </div>

        <div className="baselines">
          <label className="form-control-label label">Baselines:</label>
          <div className="baseline-row-wrapper">
            {_.sortBy(this.state.review_meter_baselines, "target_year")
              .reverse()
              .map((b) => {
                return this.getBaselineRow(b);
              })}
          </div>
        </div>

        <div className="button-wrapper">
          <button
            className="btn btn-outline-danger"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          {reviewed_meter && (
            <button
              className="btn btn-outline-danger"
              onClick={() =>
                this.deleteMeter(reviewed_meter.energy_review_meter_id)
              }
            >
              Delete All Baselines
            </button>
          )}
          {reviewed_meter ? (
            <button
              className={`btn ${this.state.is_meter_modified === false
                ? "btn-outline-success"
                : "btn-success"
                } `}
              onClick={() => this.updateMeter()}
              disabled={this.state.is_meter_modified === false}
            >
              Update
            </button>
          ) : (
            <button
              className={`btn ${this.state.is_meter_modified === false
                ? "btn-outline-success"
                : "btn-success"
                } `}
              onClick={() => this.saveMeter(select_meter_after_review)}
              disabled={this.state.is_meter_modified === false}
            >
              Save
            </button>
          )}
        </div>
      </div>
    );
  }

  handleBaselineChange(field, event) {
    const newValue = event.target.value.trim();
    if (newValue === "" || !isNaN(newValue)) {
      this.setState({
        [field]: newValue,
      });
    }
  }

  addBaseline() {
    const baselines = this.state.review_meter_baselines;

    if (!baselines.map((b) => b.target_year).includes(this.state.target_year)) {
      const newBaseline =
        this.state.baseline_mode === "CONSUMPTION_PER_DAY"
          ? {
            target_year: this.state.target_year,
            consumption_per_day: this.state.consumption_per_day,
            baseline_type: "CONSUMPTION_PER_DAY",
          }
          : {
            target_year: this.state.target_year,
            baseline_type: "REGRESSION",
            regression_intercept: this.state.regression_intercept,
            regression_hdd_slope: this.state.regression_hdd_slope,
            regression_cdd_slope: this.state.regression_cdd_slope,
            regression_edd_slope: this.state.regression_edd_slope,
            regression_ehd_slope: this.state.regression_ehd_slope,
          };

      this.setState({
        is_meter_modified: true,
        review_meter_baselines: [...baselines, newBaseline],
      });
    }
  }

  removeBaseline(year) {
    this.setState({
      is_meter_modified: true,
      review_meter_baselines: [
        ...this.state.review_meter_baselines.filter(
          (b) => b.target_year !== year
        ),
      ],
    });
  }

  saveMeter(selectMeter = false) {
    const org_id = this.getOrgId();

    EnergyReviewActions.createReviewMeter(
      {
        meter_id: this.state.modal_object.meter_id,
        organisation_id: org_id,
        baselines: this.state.review_meter_baselines,
      },
      selectMeter
    );
  }

  updateMeter() {
    const review_meter = this.state.review_meters.find(
      (rm) => rm.meter_id === this.state.modal_object.meter_id
    );

    EnergyReviewActions.updateReviewMeter({
      ...review_meter,
      baselines: this.state.review_meter_baselines,
    });
  }

  deleteMeter(review_meter_id) {
    if (
      window.confirm(
        "This will also remove this meter's baselines from any lists it might be associated with. Proceed?"
      )
    ) {
      this.setState({
        selected_meters: this.state.selected_meters.filter(
          (rm) => rm.energy_review_meter_id !== review_meter_id
        ),
      });
      EnergyReviewActions.deleteReviewMeter(review_meter_id);
    }
  }

  getBaselineRow(b) {
    let label = `${b.target_year} - `;
    if (b.baseline_type === "CONSUMPTION_PER_DAY") {
      label += `Daily Average - Consumption: ${b.consumption_per_day} kWh/day`;
    } else {
      label += `Regression - Baseload Energy: ${b.regression_intercept} kWh/day`;
      if (b.regression_hdd_slope) {
        label += `, HDD Slope: ${b.regression_hdd_slope} kWh`;
      }
      if (b.regression_cdd_slope) {
        label += `, CDD Slope: ${b.regression_cdd_slope} kWh`;
      }
      if (b.regression_edd_slope) {
        label += `, EDD Slope: ${b.regression_edd_slope} kWh`;
      }
      if (b.regression_ehd_slope) {
        label += `, EHD Slope: ${b.regression_ehd_slope} kWh`;
      }
    }

    return (
      <div key={b.target_year} className="baseline">
        <span
          className="remove-baseline"
          onClick={() => this.removeBaseline(b.target_year)}
        >
          <ion-icon name="close" />
        </span>
        {label}
      </div>
    );
  }

  // Render

  render() {
    return (
      <div className="br-mainpanel br-profile-page">
        <div
          id="EnergyReviewConfig"
          style={{
            marginTop: "0px",
            paddingTop: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <DocumentTitle title="Energy Review Configuration" />
          <ReactHint events autoPosition />
          {this.getModal()}
          <div className="br-pagetitle mg-t-15 mg-b-0">
            <div className="header">
              <div className="title-wrapper">
                <div>
                  <h4>Energy Review Configuration</h4>
                  <p className="mg-b-0">Configure your energy review lists.</p>
                </div>
                <div className="mg-t-10"></div>
              </div>
              <div className="new-list-wrapper">
                <button
                  className="new-list-button btn btn-success"
                  disabled={!this.getOrgId()}
                  onClick={() => {
                    if (this.getOrgId())
                      this.setState({ modal_type: "new_list" });
                  }}
                >
                  <ion-icon name="add-circle-outline" />
                  Add a New List
                </button>
              </div>
            </div>
          </div>
          <div className="row pd-l-50 pd-r-50 mg-t-15">
            {/* LIST OF LISTS */}
            <div className="col-4">{this.getLists()}</div>
            {/* LIST PREVIEW */}
            <div className="col-3">{this.getListPreview()}</div>
            {/* METER TREE */}
            {this.state.selected_list && (
              <div className="col-5">{this.getMeterTree()}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EnergyReviewConfig;
