import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";
import _ from "lodash";

const CHANGE = "BUILDING_CHANGE";
const SAVE = "BUILDING_SAVED";
const UPDATE = "BUILDING_UPDATED";
const DELETE = "BUILDING_DELETED";
let _buildings = [];
let _estates = [];
let _new_building = {
  name: "",
  fk_estate_id: 0,
  status: "active",
  description: "",
  available_features: "",
  category: "",
  currency: "EUR",
  address: {
    address_line: "",
  },
  energy_targets: [],
  size: 0,
  square_meter_cost: 0,
  effective_elec_rate: 0.11,
  effective_elec_carbon: 0.259,
  effective_gas_rate: 0.05,
  effective_gas_carbon: 0.204,
  effective_water_rate: 0.01,
  effective_water_carbon: 0.089,
  hdd_base_temp: null,
  cdd_base_temp: null,
  humidification_base_enthalpy: null,
  dehumidification_base_enthalpy: null,
};
let _building = {
  building_id: 0,
  fk_estate_id: 0,
  available_features: "",
  status: "active",
  name: "",
  description: "",
  category: "",
  address: {
    address_line: "",
  },
  energy_targets: [],
  size: 0,
  square_meter_cost: 0,
  effective_elec_rate: 0.11,
  effective_elec_carbon: 0.259,
  effective_gas_rate: 0.05,
  effective_gas_carbon: 0.204,
  effective_water_rate: 0.01,
  effective_water_carbon: 0.089,
  hdd_base_temp: null,
  cdd_base_temp: null,
  humidification_base_enthalpy: null,
  dehumidification_base_enthalpy: null,
};

class BuildingStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.BUILDINGS_FETCHED:
        this._storeBuildings(action.payload);
        break;
      case ActionTypes.BUILDING_SAVED:
        this._saveBuilding(action.payload);
        break;
      case ActionTypes.BUILDING_UPDATED:
        this._updateBuilding(action.payload);
        break;
      case ActionTypes.BUILDING_DELETED:
        this._deleteBuilding(action.payload);
        break;
      case ActionTypes.BUILDING_FETCHED:
        this._storeBuilding(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }

  addSaveListener(callback) {
    this.on(SAVE, callback);
  }

  addUpdateListener(callback) {
    this.on(UPDATE, callback);
  }

  addDeleteListener(callback) {
    this.on(DELETE, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }

  removeSaveListener(callback) {
    this.removeListener(SAVE, callback);
  }

  removeUpdateListener(callback) {
    this.removeListener(UPDATE, callback);
  }

  removeDeleteListener(callback) {
    this.removeListener(DELETE, callback);
  }

  clear() {
    _new_building = {
      name: "",
      fk_estate_id: 0,
      status: "active",
      description: "",
      available_features: "",
      category: "",
      currency: "EUR",
      address: {
        address_line: "",
      },
      energy_targets: [],
      size: 0,
      square_meter_cost: 0,
      effective_elec_rate: 0.11,
      effective_elec_carbon: 0.259,
      effective_gas_rate: 0.05,
      effective_gas_carbon: 0.204,
      effective_water_rate: 0.01,
      effective_water_carbon: 0.089,
    };

    _building = {
      building_id: 0,
      name: "",
      fk_estate_id: 0,
      status: "active",
      description: "",
      available_features: "",
      category: "",
      currency: "EUR",
      address: {
        address_line: "",
      },
      size: 0,
      square_meter_cost: 0,
      effective_elec_rate: 0.11,
      effective_elec_carbon: 0.259,
      effective_gas_rate: 0.05,
      effective_gas_carbon: 0.204,
      effective_water_rate: 0.01,
      effective_water_carbon: 0.089,
    };
  }

  _saveBuilding(json) {
    _new_building = {
      name: "",
      fk_estate_id: 0,
      status: "active",
      description: "",
      category: "",
      address: {
        address_line: "",
      },
      energy_targets: [],
      size: 0,
      square_meter_cost: 0,
      effective_elec_rate: 0.11,
      effective_elec_carbon: 0.259,
      effective_gas_rate: 0.05,
      effective_gas_carbon: 0.204,
      effective_water_rate: 0.01,
      effective_water_carbon: 0.089,
    };
    this.emit(SAVE);
  }

  _updateBuilding(json) {
    _new_building = {
      name: "",
      fk_estate_id: 0,
      status: "active",
      description: "",
      category: "",
      address: {
        address_line: "",
      },
      energy_targets: [],
      size: 0,
      square_meter_cost: 0,
      effective_elec_rate: 0.11,
      effective_elec_carbon: 0.259,
      effective_gas_rate: 0.05,
      effective_gas_carbon: 0.204,
      effective_water_rate: 0.01,
      effective_water_carbon: 0.089,
    };
    _building = {
      building_id: 0,
      name: "",
      fk_estate_id: 0,
      status: "active",
      description: "",
      category: "",
      address: {
        address_line: "",
      },
      size: 0,
      square_meter_cost: 0,
      effective_elec_rate: 0.11,
      effective_elec_carbon: 0.259,
      effective_gas_rate: 0.05,
      effective_gas_carbon: 0.204,
      effective_water_rate: 0.01,
      effective_water_carbon: 0.089,
    };
    this.emit(UPDATE);
  }

  _deleteBuilding(json) {
    _building = {
      building_id: 0,
      name: "",
      fk_estate_id: 0,
      status: "active",
      description: "",
      category: "",
      address: {
        address_line: "",
      },
      energy_targets: [],
      size: 0,
      square_meter_cost: 0,
      effective_elec_rate: 0.11,
      effective_elec_carbon: 0.259,
      effective_gas_rate: 0.05,
      effective_gas_carbon: 0.204,
      effective_water_rate: 0.01,
      effective_water_carbon: 0.089,
    };
    this.emit(DELETE);
  }

  _storeBuilding(json) {
    if (json) {
      _building = json;
      this.emit(CHANGE);
    }
  }

  _storeBuildings(json) {
    _buildings = [];
    for (let i = 0; i < json.length; i++) {
      if (json[i].status !== "disabled") {
        let building = json[i];
        if (
          building.available_features &&
          building.available_features.indexOf("simple_nav") > -1
        ) {
          building.show_simple_nav = true;
        }
        _buildings.push(building);
      }
    }
    this._mapEstates(_buildings);
    this.emit(CHANGE);
  }

  _mapEstates(buildings) {
    let estates_Mapped = [];

    for (let x = 0; x < buildings.length; x++) {
      let estate = buildings[x].estate;
      estate.buildings = [];
      let result = _.find(estates_Mapped, ["estate_id", estate.estate_id]);
      if (!result) {
        estates_Mapped.push(estate);
      }
    }

    for (let i = 0; i < estates_Mapped.length; i++) {
      for (let y = 0; y < buildings.length; y++) {
        if (buildings[y].estate.estate_id === estates_Mapped[i].estate_id) {
          if (buildings[y].building_id !== 13) {
            estates_Mapped[i].buildings.push(buildings[y]);
          }
        }
      }
    }

    _estates = estates_Mapped;
  }

  getBuildings() {
    return _buildings;
  }

  getEstates() {
    return _estates;
  }

  getBuilding() {
    return _building;
  }

  getNewBuilding() {
    return _new_building;
  }
}

export default new BuildingStore();
