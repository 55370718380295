import React from 'react';
import './BenchmarkReportTable.scss'
import GeneralUtils from '../../utils/GeneralUtils'
import Constants from '../../constants'

const BenchmarkReportTable = (props) => {
  const { items, title, itemListButton, unit, openItemHandler } = props;

  const _itemClickHandler = (zone_id) => {
    openItemHandler(zone_id);
  }

  const getTextColorClassForScore = (score) => {
    if (isNaN(score)) {
      return 'wd-15p valign-middle tx-right tx-gray-500';
    } else if (score < 50) {
      return "wd-15p valign-middle tx-right tx-danger";
    } else if (score < 80) {
      return "wd-15p valign-middle tx-right tx-warning";
    } else {
      return "wd-15p valign-middle tx-right tx-success";
    }
  }

  const getTextColorClassForScoreCentered = (score) => {
    if (isNaN(score)) {
      return 'wd-15p valign-middle tx-center tx-gray-500';
    } else if (score < 50) {
      return "wd-10p valign-middle tx-center tx-danger";
    } else if (score < 80) {
      return "wd-10p valign-middle tx-center tx-warning";
    } else {
      return "wd-10p valign-middle tx-center tx-success";
    }
  }
  const getWellnessItems = () => {
    return <div className="col-lg-12 mg-t-15 pd-x-0 mg-b-15">
      <div className="card shadow-base bd-0">
        <div className="card-header pd-20 bg-transparent">
          <h6 className="card-title tx-uppercase tx-12 mg-b-0">{title}</h6>
        </div>
        <table className="table mg-b-0 tx-12">
          <tbody>
            {
              items.map((zone, key) => {
                let percent_change = zone[`score_change`];
                let percent_row_value = <span className="tx-success"><ion-icon name="arrow-up" style={{ verticalAlign: "text-top", paddingRight: '5px' }} />{GeneralUtils.getFormattedNumberWithUnit(percent_change, '%')}</span>;
                if (unit === 'co2') {
                  if (percent_change <= -1) percent_row_value = <span className="tx-danger"><ion-icon name="arrow-down" style={{ verticalAlign: "text-top", paddingRight: '5px' }} />{GeneralUtils.getFormattedNumberWithUnit(percent_change, '%')}</span>
                  if (isNaN(percent_change)) percent_row_value = <span className="tx-gray-500">N/A</span>

                  return <tr key={key} className="hover-row" onClick={() => _itemClickHandler(zone.zone_id)}>
                    <td className="wd-45p">
                      <span className="tx-inverse tx-14 tx-medium d-block">{zone.name}</span>
                    </td>
                    <td className={getTextColorClassForScore(zone.score)}>{GeneralUtils.getFormattedNumberWithUnit(zone.value, 'ppm')}</td>
                    <td className={getTextColorClassForScore(zone.score)}>{GeneralUtils.getFormattedNumberWithUnit(zone.score, '/100')}</td>
                    <td className="wd-25p valign-middle tx-right" style={{ paddingRight: '20px' }}>{percent_row_value} from previous week</td>
                  </tr>
                } else if (unit === 'humidity') {
                  if (percent_change <= -1) percent_row_value = <span className="tx-danger"><ion-icon name="arrow-down" style={{ verticalAlign: "text-top", paddingRight: '5px' }} />{GeneralUtils.getFormattedNumberWithUnit(percent_change, '%')}</span>
                  if (isNaN(percent_change)) percent_row_value = <span className="tx-gray-500">N/A</span>

                  return <tr key={key} className="hover-row" onClick={() => _itemClickHandler(zone.zone_id)}>
                    <td className="wd-45p">
                      <span className="tx-inverse tx-14 tx-medium d-block">{zone.name}</span>
                    </td>
                    <td className={getTextColorClassForScore(zone.score)}>{GeneralUtils.getFormattedNumberWithUnit(zone.value, '%')}</td>
                    <td className={getTextColorClassForScore(zone.score)}>{GeneralUtils.getFormattedNumberWithUnit(zone.score, '/100')}</td>
                    <td className="wd-25p valign-middle tx-right" style={{ paddingRight: '20px' }}>{percent_row_value} from previous week</td>
                  </tr>
                } else {
                  if (percent_change <= -1) percent_row_value = <span className="tx-danger"><ion-icon name="arrow-down" style={{ verticalAlign: "text-top", paddingRight: '5px' }} />{GeneralUtils.getFormattedNumberWithUnit(percent_change, '%')}</span>
                  if (isNaN(percent_change)) percent_row_value = <span className="tx-gray-500">N/A</span>

                  return <tr key={key} className="hover-row" onClick={() => _itemClickHandler(zone.zone_id)}>
                    <td className="wd-45p">
                      <span className="tx-inverse tx-14 tx-medium d-block">{zone.name}</span>
                    </td>
                    <td className={getTextColorClassForScore(zone.score)}>{GeneralUtils.getFormattedNumberWithUnit(zone.value, '°C')}</td>
                    <td className={getTextColorClassForScore(zone.score)}>{GeneralUtils.getFormattedNumberWithUnit(zone.score, '/100')}</td>
                    <td className="wd-25p valign-middle tx-right" style={{ paddingRight: '20px' }}>{percent_row_value} from previous week</td>
                  </tr>
                }
              }
              )
            }
          </tbody>
        </table>
        <div className="card-footer tx-12 pd-y-15 bg-transparent">
          {itemListButton}
        </div>
      </div>
    </div>
  };

  const getEnergyItems = () => {
    let rate;
    let consumptionType = ' kWh';
    if (unit === 'water') consumptionType = " litres";
    if (unit === 'electricty') rate = Constants.ELECTRICITY_CARBON_COST;
    if (unit === 'gas') rate = Constants.GAS_CARBON_COST;
    if (unit === 'water') rate = Constants.WATER_CARBON_COST;
    return <div className="col-lg-12 mg-t-0 mg-lg-t-0 pd-x-0 mg-b-15">
      <div className="card shadow-base bd-0">
        <div className="card-header pd-20 bg-transparent">
          <h6 className="card-title tx-uppercase tx-12 mg-b-0">{title}</h6>
        </div>
        <table className="table mg-b-0 tx-12">
          <tbody>
            {items.map((meter, key) => {
              let percent_change = meter.percent_change;
              let percent_row_value = <span className="tx-success"><ion-icon name="arrow-up" style={{ verticalAlign: "text-top", paddingRight: '5px' }} />{GeneralUtils.getFormattedNumberWithUnit(percent_change, '%')}</span>
              if (percent_change < 0) percent_row_value = <span className="tx-danger"><ion-icon name="arrow-down" style={{ verticalAlign: "text-top", paddingRight: '5px' }} />{GeneralUtils.getFormattedNumberWithUnit(percent_change, '%')}</span>
              if (isNaN(percent_change)) percent_row_value = <span className="tx-gray-500">N/A</span>
              return <tr key={key} className="hover-row" onClick={() => _itemClickHandler(meter.meter_id)}>
                <td className="wd-40p">
                  <span className="tx-inverse tx-14 tx-medium d-block">{meter.name}</span>
                </td>
                <td className="wd-10p valign-middle tx-right">{GeneralUtils.getFormattedNumberWithUnit(meter.total_kwh, consumptionType)}</td>
                <td className="wd-10p valign-middle tx-right">€ {GeneralUtils.getFormattedNumberWithUnit(meter.total_cost)}</td>
                {rate > 0 ? <td className="wd-15p valign-middle tx-right">{GeneralUtils.getFormattedNumberWithUnit((meter.total_kwh * rate, ` ${Constants.KG_CO2_EMISSIONS}`))}</td> : null}
                <td className="wd-25p valign-middle tx-right" style={{ paddingRight: '20px' }}>{percent_row_value} from previous week</td>
              </tr>
            })
            }
          </tbody>
        </table>
        <div className="card-footer tx-12 pd-y-15 bg-transparent">
          {itemListButton}
        </div>
      </div>
    </div>
  }

  const getAssetsItems = () => {
    return (
      <div className="col-lg-12 mg-t-15 pd-x-0 mg-b-15">
        <div className="card shadow-base bd-0">
          <table className="table mg-b-0 tx-12">
            <thead>
              <tr>
                <th style={{ fontSize: '12px' }} className="wd-25p tx-bold">{title}</th>
                <th style={{ fontSize: '8px' }} className="wd-10p tx-mont tx-medium tx-center hidden-xs-down">Run Cost</th>
                <th style={{ fontSize: '8px' }} className="wd-10p tx-mont tx-medium tx-center hidden-xs-down">Runtime</th>
                <th style={{ fontSize: '8px' }} className="wd-10p tx-mont tx-medium tx-center" >Downtime</th>
                <th style={{ fontSize: '8px' }} className="wd-10p tx-mont tx-medium tx-center" >Deviation </th>
                <th style={{ fontSize: '8px' }} className="wd-10p tx-mont tx-medium tx-center">Score </th>
                <th style={{ fontSize: '8px' }} className="wd-25p tx-mont tx-medium tx-center"></th>
              </tr>
            </thead>
            <tbody>
              {
                items.map((asset, key) => {
                  let percent_change = asset.score_percent_change;
                  let percent_row_value = <span className="tx-success"><ion-icon name="arrow-up" style={{ verticalAlign: "text-top", paddingRight: '5px' }} />{GeneralUtils.getFormattedNumberWithUnit(percent_change, '%')}</span>
                  if (percent_change < 0) percent_row_value = <span className="tx-danger"><ion-icon name="arrow-down" style={{ verticalAlign: "text-top", paddingRight: '5px' }} />{GeneralUtils.getFormattedNumberWithUnit(percent_change, '%')}</span>
                  if (isNaN(percent_change)) percent_row_value = <span className="tx-gray-500">N/A</span>

                  return <tr key={key} className="hover-row" onClick={() => _itemClickHandler(asset.asset_id)}>
                    <td className="wd-25p">
                      <span className="tx-inverse tx-14 tx-medium d-block">{asset.name}</span>
                    </td>
                    <td className="wd-10p valign-middle tx-center">{!isNaN(asset.total_running_cost) ? Constants.Moneyformatter().format(asset.total_running_cost) : 'N/A'}</td>
                    <td className="wd-10p valign-middle tx-center">{GeneralUtils.getFormattedNumberWithUnit(asset.total_scheduled_time, ' Hrs')}</td>
                    <td className="wd-10p valign-middle tx-center">{GeneralUtils.getFormattedNumberWithUnit(asset.total_downtime, ' Hrs')}</td>
                    <td className="wd-10p valign-middle tx-center">{GeneralUtils.getFormattedNumberWithUnit(asset.deviation, '°C')}</td>
                    <td className={getTextColorClassForScoreCentered(asset.score)}>{GeneralUtils.getFormattedNumberWithUnit(asset.score)}</td>
                    <td className="wd-25p valign-middle tx-right" style={{ paddingRight: '20px' }}>{percent_row_value} from previous week</td>
                  </tr>
                })
              }
            </tbody>
          </table>
          <div className="card-footer tx-12 pd-y-15 bg-transparent">
            {itemListButton}
          </div>
        </div>
      </div>
    )
  }

  const getItemsTable = () => {
    if (unit === 'temperature' || unit === 'co2' || unit === 'humidity') {
      return getWellnessItems();
    } else if (unit === 'electricity' || unit === 'gas' || unit === 'water') {
      return getEnergyItems();
    } else if (unit === 'cooling' || unit === 'heating' || unit === 'AHU') {
      return getAssetsItems();
    }
  }

  const noItemsAvailable = () => {
    let items = 'Items';
    if (unit === 'temperature' || unit === 'co2' || unit === 'humidity') {
      items = 'Zones'
    } else if (unit === 'electricity' || unit === 'gas' || unit === 'water') {
      items = 'Submeters'
    } else if (unit === 'cooling' || unit === 'heating' || unit === 'AHU') {
      items = 'Assets'
    }
    return (
      <div className="col-lg-12 mg-t-15 pd-x-0 mg-b-15">
        <div className="card shadow-base bd-0">
          <div className="card-header pd-20 bg-transparent">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">{title}</h6>
            <span
              className="tx-inverse tx-12 d-block"
              style={{ position: "absolute", right: "20px", top: "20px" }}
            >
              No {items} Available
              </span>
          </div>
        </div>
      </div>
    );
  };

  return items && items.length > 0 ? getItemsTable() : noItemsAvailable()
};

export default BenchmarkReportTable;