import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";
import _ from "lodash";

const CHANGE = "ENERGY_CHANGE";
const DAY_NIGHT_CHANGE = "DAY_NIGHT_CHANGE";

let _real_time_heat_generation_data = {};
let _current_day_heat_generation_data = {};
let _current_week_heat_generation_data = {};
let _all_time_heat_generation_data = {};

let _real_time_energy_input_data = {};
let _current_day_energy_input_data = {};
let _current_week_energy_input_data = {};
let _all_time_energy_input_data = {};
let _meters = [];

class EnergyStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case Constants.BUILDING_METERS_FETCHED:
        this._storeBuildingMeters(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }

  // Hooks a React component's callback to the CHANGED event.
  addDayNightChangeListener(callback) {
    this.on(DAY_NIGHT_CHANGE, callback);
  }

  // Removes the listener from the CHANGED event.
  removeDayNightChangeListener(callback) {
    this.removeListener(DAY_NIGHT_CHANGE, callback);
  }

  clear() {
    _meters = [];
  }

  _storeBuildingMeters(json) {
    if (json && json.length > 0) {
      _meters = _.filter(json, function (o) {
        return o.description.toLowerCase().indexOf("hide") === -1;
      });
    }
    this.emit(CHANGE);
  }

  getMeters() {
    return _meters;
  }

  getRealTimeHeatGeneration() {
    return _real_time_heat_generation_data;
  }

  getTodayHeatGeneration() {
    return _current_day_heat_generation_data;
  }

  getCurrentWeekHeatGeneration() {
    return _current_week_heat_generation_data;
  }

  getAllTimeHeatGeneration() {
    return _all_time_heat_generation_data;
  }

  getRealTimeEnergyInput() {
    return _real_time_energy_input_data;
  }

  getTodayEnergyInput() {
    return _current_day_energy_input_data;
  }

  getCurrentWeekEnergyInput() {
    return _current_week_energy_input_data;
  }

  getAllTimeEnergyInput() {
    return _all_time_energy_input_data;
  }
}

export default new EnergyStore();
