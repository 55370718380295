import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to managing zones. This class interfaces with the API
 * to retrieve, create, update, and delete zone data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const zonesActions = new ZonesActions();
 * zonesActions.getZones('building123');
 */
class ZonesActions {
  /**
   * Fetches the zones for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getZones(buildingId) {
    return GeneralUtils.get({
      url: `zone/building/${buildingId}`,
      actionType: ActionTypes.ZONES_FETCHED,
      failToast: "Unable to fetch zones, please try again",
    });
  }

  /**
   * Saves a new zone to the API and dispatches an action to store the new zone data.
   *
   * @param {Object} zone - The zone object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveZone(zone) {
    return GeneralUtils.post({
      url: "zone/",
      object: zone,
      actionType: ActionTypes.ZONE_SAVED,
      successToast: `Zone ${zone.name} saved successfully`,
      failToast: `Unable to save zone ${zone.name}, please try again`,
    });
  }

  /**
   * Updates an existing zone in the API and dispatches an action to store the updated data.
   *
   * @param {Object} zone - The zone object to be updated.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateZone(zone) {
    return GeneralUtils.put({
      url: "zone/",
      object: zone,
      actionType: ActionTypes.ZONE_UPDATED,
      successToast: `Zone ${zone.name} updated successfully`,
      failToast: `Unable to update zone ${zone.name}, please try again`,
    });
  }

  /**
   * Deletes a specific zone by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} zoneId - The unique identifier of the zone to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteZone(zoneId) {
    return GeneralUtils.delete({
      url: `zone/${zoneId}`,
      actionType: ActionTypes.ZONE_DELETED,
      successToast: `Zone ${zoneId} deleted successfully`,
      failToast: "Unable to delete zone, please try again",
    });
  }
}

export default new ZonesActions();
