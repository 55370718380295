import React from 'react';
import { Card, Typography } from 'antd';
import _ from 'lodash';
import COLOR_PALETTE from './ColorPalette';

const { Title } = Typography;

const GaugeCard = ({ data }) => {
    const current = _.get(data, 'current');
    const electricityConsumption = _.get(current, 'electricity.consumption', 0);
    const thermalConsumption = _.get(current, 'thermal.consumption', 0);
    const totalConsumption = electricityConsumption + thermalConsumption;

    const thresholds = _.get(data, 'grade_boundaries');

    if (!thresholds) {
        return null;
    }

    const axisStyle = {
        width: '100%',
        height: '30px',
        display: 'flex',
        marginTop: '20px',
        borderRadius: '15px',
        overflow: 'hidden',
    };

    const secondaryAxisStyle = {
        width: '100%',
        height: '30px',
        display: 'flex',
        marginTop: '20px',
        borderRadius: '15px',
        overflow: 'hidden',
    };

    const segmentStyle = (color, width) => ({
        backgroundColor: color,
        width: `${width}%`,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontWeight: 'bold',
    });

    const markerStyle = {
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid black',
        position: 'absolute',
        top: '-15px',
        left: '50%',
        transform: 'translateX(-50%)'
    };

    const getSegmentWidths = (type) => {
        const typeThresholds = thresholds[type];
        const totalWidth = typeThresholds.D - typeThresholds.A + (typeThresholds.B - typeThresholds.A);

        return {
            D: ((typeThresholds.D - typeThresholds.C) / totalWidth) * 100,
            C: ((typeThresholds.C - typeThresholds.B) / totalWidth) * 100,
            B: ((typeThresholds.B - typeThresholds.A) / totalWidth) * 100,
            A: ((typeThresholds.B - typeThresholds.A) / totalWidth) * 100,
        };
    };

    const getMarkerPosition = (consumption, type) => {
        const typeThresholds = thresholds[type];
        const segmentWidths = getSegmentWidths(type);
        const totalWidth = segmentWidths.A + segmentWidths.B + segmentWidths.C + segmentWidths.D;

        let position;
        if (consumption <= typeThresholds.A) {
            position = segmentWidths.A + segmentWidths.B + segmentWidths.C + segmentWidths.D -
                (consumption / typeThresholds.A) * segmentWidths.A;
        } else if (consumption <= typeThresholds.B) {
            position = segmentWidths.B + segmentWidths.C + segmentWidths.D -
                ((consumption - typeThresholds.A) / (typeThresholds.B - typeThresholds.A)) * segmentWidths.B;
        } else if (consumption <= typeThresholds.C) {
            position = segmentWidths.C + segmentWidths.D -
                ((consumption - typeThresholds.B) / (typeThresholds.C - typeThresholds.B)) * segmentWidths.C;
        } else {
            position = segmentWidths.D -
                ((consumption - typeThresholds.C) / (typeThresholds.D - typeThresholds.C)) * segmentWidths.D;
        }

        return Math.max(1, Math.min(99, (position / totalWidth) * 100));
    };

    const renderGauge = (consumption, type, title, secondary) => {
        const segmentWidths = getSegmentWidths(type);
        return (
            <div style={{ marginBottom: '20px', flex: 1 }}>
                <Title level={5}>{title}</Title>
                <div style={{ position: 'relative' }}>
                    <div style={{ ...markerStyle, left: `${getMarkerPosition(consumption, type)}%` }} />
                    <div style={secondary ? secondaryAxisStyle : axisStyle}>
                        <div style={segmentStyle(COLOR_PALETTE.grades.D, segmentWidths.D)}>
                            D
                            <span style={{ position: 'absolute', bottom: '-25px', fontSize: '12px', color: 'black' }}>Poor</span>
                        </div>
                        <div style={segmentStyle(COLOR_PALETTE.grades.C, segmentWidths.C)}>
                            C
                            <span style={{ position: 'absolute', bottom: '-25px', fontSize: '12px', color: 'black' }}>Typical</span>
                        </div>
                        <div style={segmentStyle(COLOR_PALETTE.grades.B, segmentWidths.B)}>
                            B
                            <span style={{ position: 'absolute', bottom: '-25px', fontSize: '12px', color: 'black' }}>Good</span>
                        </div>
                        <div style={segmentStyle(COLOR_PALETTE.grades.A, segmentWidths.A)}>
                            A
                            <span style={{ position: 'absolute', bottom: '-25px', fontSize: '12px', color: 'black' }}>Perfect</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Card bordered={false} style={{ height: '100%', width: '100%' }} styles={{ body: { height: '100%', display: 'flex', flexDirection: 'column' } }}>
            {renderGauge(totalConsumption, 'overall', 'Total Energy')}
            {renderGauge(electricityConsumption, 'electricity', 'Electricity', true)}
            {renderGauge(thermalConsumption, 'thermal', 'Thermal', true)}
        </Card>
    );
};

export default GaugeCard;