import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching energy data such as heat load analysis and building meters. 
 * This class interfaces with the API to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const energyActions = new EnergyActions();
 * energyActions.getHeatLoadAnalysisData('building123');
 */
class EnergyActions {
  /**
   * Fetches heat load analysis data for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getHeatLoadAnalysisData(buildingId) {
    return GeneralUtils.get({
      url: `energy/dashboard/heat-load/building/${buildingId}`,
      actionType: ActionTypes.BUILDING_HEAT_LOAD_ANALYSIS_FETCHED,
    });
  }

  /**
   * Fetches the meters for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildingMeters(buildingId) {
    return GeneralUtils.get({
      url: `meter/building/${buildingId}`,
      actionType: ActionTypes.BUILDING_METERS_FETCHED,
    });
  }
}

export default new EnergyActions();
