import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";

const ZONES_OVERVIEW_FETCH = Constants.ZONES_OVERVIEW_FETCH;

let _zones = [];

class ZonesOverviewStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ZONES_OVERVIEW_FETCH:
        this._storeData(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addZonesOverviewFetchListener(callback) {
    this.on(ZONES_OVERVIEW_FETCH, callback);
  }
  removeZonesOverviewFetchListener(callback) {
    this.removeListener(ZONES_OVERVIEW_FETCH, callback);
  }

  clear() {
    _zones = [];
  }

  _storeData(json) {
    _zones = json;

    this.emit(ZONES_OVERVIEW_FETCH);
  }

  getZonesOverview() {
    return _zones;
  }
}

export default new ZonesOverviewStore();
