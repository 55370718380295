import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from 'moment';

import "./Meter.scss";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Chart } from "../../../components/Chart";
import { LogoSpinner } from "../../../components/LogoSpinner";
import GeneralStore from "../../../stores/generalStore";
import GeneralActions from "../../../actions/generalActions";
import MeterDetailsStore from "../../../stores/meterDetailsStore";
import MeterDetailsActions from "../../../actions/meterDetailsActions";
import GeneralUtils from "../../../utils/GeneralUtils";
import Constants from "../../../constants";

/**
 * Represents a Meter component in a React application that displays detailed consumption metrics
 * for a selected meter. The component provides visualizations for metrics such as kilowatts, kilovolt-ampere hours,
 * and overall consumption using dynamic time bucketing that adjusts based on user-selected date ranges.
 *
 * Functionality:
 * - Fetches and displays data based on a meter ID obtained from the URL parameters.
 * - Allows users to adjust the granularity of displayed data through interactive controls.
 * - Reacts to changes in date range selections by updating the data aggregation granularity and fetching new data accordingly.
 * - Provides visual feedback during data fetching operations and indicates when no data is available for the selected parameters.
 *
 * Usage:
 * This component is typically used in a dashboard or data visualization context within an energy management system,
 * where users need to monitor and analyze energy consumption over time.
 *
 * Example:
 * ```jsx
 * <Route path="/meter/:id" component={Meter} />
 * ```
 *
 * @component
 * @example
 * return <Meter />
 *
 * Dependencies:
 * - React Router (useParams, useHistory) for routing and navigation.
 * - Custom stores (GeneralStore, MeterDetailsStore) for state management related to application data.
 * - Utility functions from GeneralUtils for date formatting and calculations.
 * - Constants for predefined configurations like bucket sizes.
 */
function Meter() {
  const { id: meterId } = useParams();
  const history = useHistory();

  // Calculate initial dates
  const initialStart = GeneralStore.getStartDate();
  const initialEnd = GeneralStore.getEndDate();
  const daysDifference = GeneralUtils.getDifferenceBetweenDates(
    initialStart,
    initialEnd
  );

  // Determine initial bucket size based on days difference
  let initialBucket = Constants.BUCKET_FIFTEEN_MIN; // Default bucket size
  if (daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET) {
    initialBucket = Constants.BUCKET_DAY;
  } else if (daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) {
    initialBucket = Constants.BUCKET_HOUR;
  }

  // Define initial state using calculated values
  const [state, setState] = useState({
    entity: {},
    consumptionData: [],
    consumptionUnit: "",
    kwData: [],
    kvarhData: [],
    tsStart: initialStart,
    tsEnd: initialEnd,
    bucket: initialBucket,
    loading: true,
  });

  /**
   * Fetches the meter consumption data based on the current state parameters. This function constructs
   * an API request using the start and end dates, bucket type, and meter ID from the state, then initiates
   * the data fetching process. It sets the loading state to true before the API call to indicate data is
   * being fetched.
   *
   * This function is wrapped in `useCallback` to prevent unnecessary re-creations except when certain
   * state values change, optimizing performance especially during re-renders.
   *
   * @param {string} meterId - The unique identifier for the meter.
   * @param {Date} tsStart - Start date for the data fetching period.
   * @param {Date} tsEnd - End date for the data fetching period.
   * @param {string} bucket - The aggregation level for the data (e.g., 'DAY', 'HOUR', 'FIFTEEN_MIN').
   * @uses useCallback - React hook that returns a memoized callback function.
   * @uses setState - React state function to update component state.
   */
  const fetchConsumptionData = useCallback(() => {
    const start = GeneralUtils.getFormattedDate(state.tsStart);
    const end = GeneralUtils.getFormattedDate(state.tsEnd, true);

    if (meterId && start && end && state.bucket) {
      setState((prevState) => ({ ...prevState, loading: true }));
      MeterDetailsActions.getMeterConsumption(
        meterId,
        start,
        end,
        state.bucket
      );
    }
  }, [meterId, state.bucket, state.tsStart, state.tsEnd]);

  /**
   * Handles changes in the date range selected by the user.
   * Updates the state with new start and end dates, and adjusts the bucket size
   * based on the number of days between these dates to ensure data is appropriately aggregated.
   *
   * The bucket size is set to daily if the range exceeds 120 days for both hourly and fifteen-minute
   * buckets, and to hourly if the range is over 30 days for fifteen-minute buckets.
   *
   * @listens GeneralStore#getStartDate - Listens for updates to the start date from GeneralStore.
   * @listens GeneralStore#getEndDate - Listens for updates to the end date from GeneralStore.
   * @uses setState - Updates the component's state based on new date ranges and calculated bucket sizes.
   */
  const onDateChange = () => {
    const newStart = GeneralStore.getStartDate();
    const newEnd = GeneralStore.getEndDate();
    const daysDifference = GeneralUtils.getDifferenceBetweenDates(
      newStart,
      newEnd
    );

    setState((prevState) => {
      let newBucket = prevState.bucket;
      if (daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET) {
        newBucket = Constants.BUCKET_DAY;
      } else if (daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) {
        newBucket = Constants.BUCKET_HOUR;
      }

      return {
        ...prevState,
        tsStart: newStart,
        tsEnd: newEnd,
        bucket: newBucket,
      };
    });
  };

  /**
   * Callback function that updates the component state with the latest consumption data
   * fetched from MeterDetailsStore. It updates state attributes related to the meter entity,
   * various data arrays for consumption, kW, kVarh, and the unit of consumption. It also sets
   * the loading state to false indicating that data fetching has completed.
   *
   * @listens MeterDetailsStore#getEntity - Retrieves the current meter entity details.
   * @listens MeterDetailsStore#getConsumptionData - Retrieves consumption data.
   * @listens MeterDetailsStore#getConsumptionUnit - Retrieves the unit of consumption data.
   * @listens MeterDetailsStore#getKwData - Retrieves kW data.
   * @listens MeterDetailsStore#getKvarhData - Retrieves kVarh data.
   * @uses setState - Updates the component state with new data fetched from the store.
   */
  const onConsumptionFetch = () => {
    setState((prevState) => ({
      ...prevState,
      entity: MeterDetailsStore.getEntity(),
      consumptionData: MeterDetailsStore.getConsumptionData(),
      consumptionUnit: MeterDetailsStore.getConsumptionUnit(),
      kwData: MeterDetailsStore.getKwData(),
      kvarhData: MeterDetailsStore.getKvarhData(),
      loading: false,
    }));
  };

  /**
   * Registers event listeners for data fetching and date range changes when the component mounts.
   * Removes these listeners when the component unmounts to prevent memory leaks and unnecessary operations.
   * This hook mimics the behavior of componentDidMount and componentWillUnmount lifecycle methods in class components.
   *
   * Dependencies:
   * - None, this effect only runs once on mount and cleanup on unmount.
   */
  useEffect(() => {
    MeterDetailsStore.addMeterDetailsFetchListener(onConsumptionFetch);
    GeneralStore.addChangeListener(onDateChange);

    return () => {
      MeterDetailsStore.removeMeterDetailsFetchListener(onConsumptionFetch);
      GeneralStore.removeChangeListener(onDateChange);
    };
  }, []);

  /**
   * Executes fetchConsumptionData when there are changes to the bucket size or the selected date range (start and end dates).
   * This ensures that the data displayed is always current and aligned with user-selected parameters.
   *
   * This effect also depends on the fetchConsumptionData function itself to capture any changes in its definition,
   * which could include updated logic or dependencies that affect how data is fetched.
   *
   * Dependencies:
   * - fetchConsumptionData: Ensures the latest logic is used when fetching data.
   * - state.bucket: Triggers the effect when the bucket size changes, affecting the granularity of the displayed data.
   * - state.tsStart: Triggers the effect when the start date changes, affecting the data range.
   * - state.tsEnd: Triggers the effect when the end date changes, affecting the data range.
   */
  useEffect(() => {
    fetchConsumptionData();
  }, [fetchConsumptionData, state.bucket, state.tsStart, state.tsEnd]);

  /**
   * Sets the new bucket size for data aggregation.
   * This is invoked to handle user actions that require a change in the granularity of the data aggregation.
   *
   * @param {string} bucket - The new bucket size to set (e.g., 'DAY', 'HOUR', 'FIFTEEN_MIN').
   * @uses setState - Updates the component's state based on selected bucket.
   */
  const setBucketSize = (bucket) => {
    setState((prevState) => ({ ...prevState, bucket }));
  };

  /**
   * Determines if a specific bucket size should be disabled based on the difference in days between start and end dates.
   * This prevents users from selecting a granularity that may not be practical for large date ranges.
   *
   * @param {string} bucket - The bucket size to check.
   * @returns {boolean} - True if the bucket size should be disabled, otherwise false.
   */
  const isBucketDisabled = (bucket) => {
    const { tsStart, tsEnd } = state;
    const daysDifference = GeneralUtils.getDifferenceBetweenDates(
      tsStart,
      tsEnd
    );

    return (
      (bucket === Constants.BUCKET_FIFTEEN_MIN &&
        daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) ||
      (bucket === Constants.BUCKET_HOUR &&
        daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET)
    );
  };

  /**
   * Creates a chart section for displaying data such as Consumption, Kilowatts, or Kilovolt-ampere hours.
   * Each section contains interactive buttons that allow users to change the data aggregation level.
   *
   * @param {string} title - The data type title, which determines the dataset to be displayed.
   * @returns {JSX.Element|null} - The section component with charts or null if no data is available.
   */
  const getSection = (title) => {
    const { consumptionData, consumptionUnit, kwData, kvarhData, bucket } =
      state;

    const dataMapping = {
      Consumption: {
        data: consumptionData,
        unit: consumptionUnit,
        tooltipDecimals: 0,
      },
      Kilowatts: { data: kwData, unit: " kW", tooltipDecimals: 0 },
      "Kilovolt-ampere hour": {
        data: kvarhData,
        unit: " kVarh",
        tooltipDecimals: 0,
      },
    };

    const { data, unit, tooltipDecimals } = dataMapping[title] || {};

    if (!data || data.length === 0) return null;

    const buttons = [
      {
        label: "15 min",
        activeCondition: bucket === Constants.BUCKET_FIFTEEN_MIN,
        disabledCondition: isBucketDisabled(Constants.BUCKET_FIFTEEN_MIN),
        handler: () => setBucketSize(Constants.BUCKET_FIFTEEN_MIN),
      },
      {
        label: "Hour",
        activeCondition: bucket === Constants.BUCKET_HOUR,
        disabledCondition: isBucketDisabled(Constants.BUCKET_HOUR),
        handler: () => setBucketSize(Constants.BUCKET_HOUR),
      },
      {
        label: "Day",
        activeCondition: bucket === Constants.BUCKET_DAY,
        disabledCondition: false,
        handler: () => setBucketSize(Constants.BUCKET_DAY),
      },
    ];

    return (
      <div className="col-12 mg-t-30">
        <Chart
          title={title}
          data={data}
          tooltipDecimals={tooltipDecimals}
          unit={unit}
          buttons={buttons}
        />
      </div>
    );
  };

  /**
   * Renders a message indicating that no data is available based on the current query parameters.
   * This is displayed when there are no data points to show in the charts.
   *
   * @returns {JSX.Element|null} - A component displaying a 'no data available' message or null if data is present.
   */
  const getNoDataMessage = () => {
    const { consumptionData, kwData, kvarhData, loading } = state;
    const series = [...consumptionData, ...kwData, ...kvarhData];

    if (!series.length && !loading) {
      return (
        <div className="col-12 mg-t-30">
          <Chart noData />
        </div>
      );
    }
    return null;
  };

  /**
   * Retrieves the page title based on the current meter entity's name, defaulting to 'No Data' if not available.
   *
   * @returns {string} - The title of the page.
   */
  const getPageTitle = () => {
    return state.entity ? state.entity.name : "No Data";
  };

  /**
   * Generates a navigation title element with a back arrow icon. Clicking this navigates back to the previous or default page.
   *
   * @returns {JSX.Element} - The navigation title element.
   */
  const getTitle = () => {
    const lastLocation = sessionStorage.getItem("meterLastLoc");
    const defaultLocation = "/meters";
    const backText = state.entity ? state.entity.name : "Back";

    return (
      <div
        className="title tx-18 tx-archivo-semibold shadow-base"
        onClick={() => history.push(lastLocation || defaultLocation)}
      >
        <span className="icon-wrapper">
          <ion-icon name="arrow-dropleft" />
        </span>
        {backText}
      </div>
    );
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const fromDate = searchParams.get('from');
    const toDate = searchParams.get('to');

    if (fromDate && toDate) {
      const fromTimestamp = moment(fromDate).unix();
      const toTimestamp = moment(toDate).subtract(1, 'seconds').unix();
      GeneralActions.setDates(fromTimestamp, toTimestamp);
    }
  }, [history.location.search]);

  return (
    <div
      id="Meter"
      className="br-mainpanel br-profile-page floorplan-background"
      style={{ overflowY: "scroll" }}
    >
      <DocumentTitle title={getPageTitle()} />
      <LogoSpinner loading={state.loading} />
      <div className="br-container">
        <div className="header row">
          <div className="col mg-t-30">{getTitle()}</div>
        </div>
        <div className="charts row">
          {getNoDataMessage()}
          {getSection("Consumption")}
          {getSection("Kilowatts")}
          {getSection("Kilovolt-ampere hour")}
        </div>
      </div>
    </div>
  );
}

export default Meter;
