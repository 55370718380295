import React, { Component } from "react";
import styles from "./OrganisationSelect.module.scss";
import { SearchSelect } from "../SearchSelect";
import PropTypes from "prop-types";

import OrganisationActions from "../../actions/organisationActions";

class OrganisationSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgs: [],
    };
  }

  componentDidMount() {
    this.fetchOrgs();
  }

  async fetchOrgs() {
    try {
      const orgs = await OrganisationActions.getOrganisations();
      this.setState({ orgs: orgs });
    } catch (err) {
      throw new Error(err);
    }
  }

  render() {
    let selectedOrg = this.props.selectedOrg;

    if (typeof selectedOrg === "number") {
      selectedOrg = this.state.orgs.find(
        (org) => org.organisation_id === selectedOrg
      );
    }

    const filteredOrgs = this.props.filter
      ? this.state.orgs.filter(this.props.filter)
      : this.state.orgs;

    return (
      <div
        className={
          this.props.white
            ? styles["OrganisationSelectWhite"]
            : styles["OrganisationSelect"]
        }
      >
        <SearchSelect
          options={filteredOrgs}
          placeholder={this.props.placeholder || "Select the Organisation..."}
          defaultValue={selectedOrg}
          actionOnSelectedOption={this.props.onOrganisationSelect}
        />
      </div>
    );
  }
}

export default OrganisationSelect;

OrganisationSelect.propTypes = {
  selectedOrg: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onOrganisationSelect: PropTypes.func,
  placeholder: PropTypes.string,
  filter: PropTypes.func,
  white: PropTypes.bool,
};
