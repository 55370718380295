import React from "react";
import "./DashboardCard.scss";
import PropTypes from "prop-types";

const DashboardCard = ({
  title,
  imgsrc,
  description,
  clickHandler,
  empty,
  disabled,
  disabledMessage = "Coming Soon",
}) => {
  if (empty) return <div className="DashboardCard empty"></div>;

  return (
    <div
      className={`DashboardCard shadow-base ${
        disabled ? "disabled" : "hoverable"
      }`}
      onClick={clickHandler && !disabled ? clickHandler : undefined}
    >
      <div className="picture">
        <img src={imgsrc} alt="#" />
      </div>
      <div className="title">{title}</div>
      <div className="description">{description}</div>
      {disabled && (
        <div className="disabled-overlay">
          <div className="belt">
            <ion-icon name="construct"></ion-icon>
            <div>{disabledMessage}</div>
          </div>
        </div>
      )}
    </div>
  );
};

DashboardCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imgsrc: PropTypes.string,
  clickHandler: PropTypes.func,
  empty: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
};

export default DashboardCard;
