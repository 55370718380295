import React, { Component } from "react";
import "./ManageMeters.scss";
import MeterActions from "../../actions/meterActions";
import MeterStore from "../../stores/meterStore";
import OrganisationActions from "../../actions/organisationActions";
import OrganisationStore from "../../stores/organisationStore";
import TenantActions from "../../actions/tenantActions";
import TenantStore from "../../stores/tenantStore";
import UserStore from "../../stores/userStore";
import GeneralUtils from "../../utils/GeneralUtils";
import Constants from "../../constants";
import { SearchSelect } from "../../components/SearchSelect";
import { DocumentTitle } from "../../components/DocumentTitle";
import { ModifyMeterPanel } from "./ModifyMeterPanel";
import _ from "lodash";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button } from 'antd';
import { Icon } from '../../components/Icon';

class ManageMeters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_meter: {},
      items_expanded: [],
      meters: MeterStore.getMeters(),
      buildings: OrganisationStore.getBuildings(),
      meter_creation: false,
      selected_building: null,
      tenants: [],
    };
    // listener callbacks
    this._onOrganisationChange = this._onOrganisationChange.bind(this);
    this._onMetersChange = this._onMetersChange.bind(this);
    this._onMeterOperation = this._onMeterOperation.bind(this);
    this._onTenantsFetch = this._onTenantsFetch.bind(this);

    this._setTargetBuilding = this._setTargetBuilding.bind(this);
    this._changeMeterValues = this._changeMeterValues.bind(this);
    this._validateMeterInput = this._validateMeterInput.bind(this);
    this._toggleMeterCreation = this._toggleMeterCreation.bind(this);
    this._expansionHandler = this._expansionHandler.bind(this);
    this._massExpansionHandler = this._massExpansionHandler.bind(this);

    this._saveMeter = this._saveMeter.bind(this);
    this._updateMeter = this._updateMeter.bind(this);
    this._deleteMeter = this._deleteMeter.bind(this);
  }

  _onOrganisationChange() {
    this.setState({
      buildings: OrganisationStore.getBuildings(),
    });
  }

  _onMetersChange() {
    this.setState({
      meters: MeterStore.getMeters(),
      selected_building: this.state.selected_building,
    });
  }

  _onMeterOperation() {
    this._toggleMeterCreation(false);
    MeterActions.getMetersByBuildingId(
      this.state.selected_building.building_id
    );
  }

  _onTenantsFetch() {
    this.setState({ tenants: TenantStore.getTenants() });
  }

  componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
    MeterStore.addChangeListener(this._onMetersChange);
    MeterStore.addMeterOperationListener(this._onMeterOperation);
    TenantStore.addTenantsFetchedListener(this._onTenantsFetch);
  }

  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
  }

  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
    MeterStore.removeChangeListener(this._onMetersChange);
    MeterStore.removeMeterOperationListener(this._onMeterOperation);
    TenantStore.removeTenantsFetchedListener(this._onTenantsFetch);

    MeterStore.clear();
    TenantStore.clear();
  }

  _setTargetBuilding(selected_building) {
    MeterActions.getMetersByBuildingId(selected_building.building_id);
    TenantActions.getOrganisationTenants(
      selected_building.entity_type.organisation_id
    );
    this.setState({
      selected_building: selected_building,
      new_meter: {
        ...this.state.new_meter,
        building_id: selected_building.building_id,
      },
    });
  }

  _expansionHandler(meter_id = null) {
    if (this.state.items_expanded.includes(meter_id)) {
      this.setState({
        items_expanded: this.state.items_expanded.filter(
          (item) => item !== meter_id
        ),
      });
    } else {
      this.setState({
        items_expanded: [...this.state.items_expanded, meter_id],
      });
    }
  }

  _massExpansionHandler() {
    if (this.state.items_expanded.length > 0) {
      this.setState({ items_expanded: [] });
    } else {
      this.setState({
        items_expanded: [...this.state.meters.map((el) => el.meter_id)],
      });
    }
  }

  noRowsAvailable(numRows) {
    let rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(<td key={i} className="valign-middle"></td>);
    }
    return (
      <tr>
        <td className="valign-middle" colSpan={6}>
          <div className="d-flex align-items-center">
            <div>
              <div className="tx-inverse">
                {this.state.selected_building == null
                  ? "No meters available, please select a target building first."
                  : "No meters available in the selected building. Please contact support to create new meters."}
              </div>
            </div>
          </div>
        </td>
        {rows}
      </tr>
    );
  }
  _validateMeterInput(meter) {
    const validBuilding = parseInt(meter.building_id) > 0;
    const validDescription = GeneralUtils.stringNotEmpty(meter.description);
    const validType = GeneralUtils.stringNotEmpty(meter.type);
    let validVirtualCalc =
      meter.virtual_calc && meter.virtual_calc.trim().length > 0 ? false : true;
    const idArrays = this.state.meters.map((meter) => `${meter.meter_id}`);
    idArrays.forEach((id) => {
      if (meter.virtual_calc && meter.virtual_calc.includes(id)) {
        validVirtualCalc = true;
      } else {
      }
    });

    if (validVirtualCalc === false)
      toast("Invalid Input, Please check fields and try again", {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });

    return validBuilding && validDescription && validType && validVirtualCalc;
  }

  _changeMeterValues(meter, target_property, event) {
    let meters = this.state.meters;
    let new_meter = this.state.new_meter;
    const numericalValues = [
      "conversion_rate",
      "parent_id",
      "tenant_id",
    ];
    let value = numericalValues.includes(target_property)
      ? +event.target.value
      : event.target.value;

    if (target_property === "category" && value === "") {
      value = null;
    }

    let editExisting = false;
    for (let i = 0; i < meters.length; i++) {
      if (meters[i].meter_id === meter.meter_id) {
        meters[i][target_property] = value;
        editExisting = true;
        break;
      }
    }
    if (!editExisting) {
      new_meter[target_property] = value;
    }
    this.setState({ meters: meters, new_meter: new_meter });
  }

  _saveMeter(meter) {
    if (this._validateMeterInput(meter)) {
      const new_meter = {
        ...meter,
        unit: meter.type.includes("wm") ? "litres" : "kwh",
      };
      MeterActions.saveMeter(new_meter);
    } else {
      toast("Invalid Input, Please check fields and try again", {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });
    }
  }

  _updateMeter(meter) {
    if (this._validateMeterInput(meter) && meter.meter_id) {
      const updated_meter = {
        ...meter,
        unit: meter.type.includes("wm") ? "litres" : "kwh",
      };
      MeterActions.updateMeter(updated_meter);
    } else {
      toast("Invalid Input, Please check fields and try again", {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });
    }
  }

  _deleteMeter(meter_id) {
    let message = "Are you sure you wish to delete this meter?";
    const meters_referencing_deleted_meter = this.state.meters.filter(
      (m) =>
        m.virtual_calc &&
        m.virtual_calc
          .replace(/[+*/-]/g, "$")
          .split("$")
          .includes(`${meter_id}`)
    );

    if (meters_referencing_deleted_meter.length) {
      message =
        "Are you sure you wish to delete this meter?\nFollowing meters are referencing it in their virtual calculations:";
      meters_referencing_deleted_meter.forEach((m) => {
        message += `\n* ${m.description} (${m.meter_id})`;
      });
    }

    if (window.confirm(message)) {
      MeterActions.deleteMeter(meter_id);
    }
  }

  _toggleMeterCreation(target_state = null) {
    if (this.state.meter_creation || target_state === false) {
      this.setState({
        meter_creation: false,
        new_meter: {
          description: "",
          building_id: this.state.selected_building.building_id,
          type: "",
          parent_id: null,
          conversion_rate: 1,
          unit: "",
          virtual_calc: "",
          tenant_id: null,
        },
      });
    } else {
      this.setState({ meter_creation: true });
    }
  }

  getMeterTable() {
    let meterRows = this.noRowsAvailable(7);
    let newMeterRow = null;
    let meters = _.orderBy(this.state.meters, "name", "asc");
    let buildingsFormattedArray = this.state.buildings.map((building) => {
      return {
        ...building,
        name: building.name + ` (building id: ${building.building_id})`,
      };
    });
    let button = (
      <Button
        className="button green"
        icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
        size="small"
        onClick={this._toggleMeterCreation}
        style={{ height: '38px' }}
      >Add Meter</Button>
    );
    if (this.state.meter_creation) {
      button = (
        <Button
          className="button red"
          icon={<Icon name="Close" color={'#fff'} size={18} />}
          size="small"
          onClick={this._toggleMeterCreation}
          style={{ height: '38px' }}
        >Cancel</Button>
      );
    }
    const building_tenants = this.state.tenants.filter((t) => {
      if (
        t.buildings.find(
          (b) => b.building_id === this.state.selected_building.building_id
        )
      ) {
        return true;
      }
      return false;
    });

    if (this.state.meter_creation) {
      newMeterRow = (
        <ModifyMeterPanel
          categories={Constants.METER_CATEGORIES}
          tenants={building_tenants}
          building={this.state.selected_building}
          expanded={true}
          key={"new_meter"}
          changeMeterValues={this._changeMeterValues}
          meter={this.state.new_meter}
          meters={this.state.meters}
          updateMeter={this._updateMeter}
          saveMeter={this._saveMeter}
          cancelMeter={this._toggleMeterCreation}
        />
      );
    }

    if (meters.length > 0) {
      meterRows = meters.reverse().map((meter, key) => {
        if (meter.meter_id) {
          return (
            <ModifyMeterPanel
              categories={Constants.METER_CATEGORIES}
              tenants={building_tenants}
              key={meter.meter_id}
              expanded={this.state.items_expanded.includes(meter.meter_id)}
              expansionHandler={this._expansionHandler}
              changeMeterValues={this._changeMeterValues}
              deleteMeter={this._deleteMeter}
              meter={meter}
              meters={this.state.meters}
              building={this.state.selected_building}
              updateMeter={this._updateMeter}
              saveMeter={this._saveMeter}
            />
          );
        } else {
          return null;
        }
      });
    }

    return (
      <div>
        <div
          className="br-pagetitle pd-x-0 pd-b-0 mg-b-10"
          style={{ width: "100%" }}
        >
          <span className="col-6 col-lg-5 pd-l-0">
            <SearchSelect
              limit={100}
              options={buildingsFormattedArray}
              placeholder={"Select Building"}
              defaultValue={this.state.selected_building}
              actionOnSelectedOption={this._setTargetBuilding}
            />
          </span>
          {this.state.selected_building && button}
        </div>
        <div className="card bd-0 shadow-base mg-t-5 overflow-auto">
          {this.state.selected_building && (
            <table className="table mg-b-0 table-contact">
              <thead>
                <tr>
                  <th className="wd-5p tx-10-force tx-mont tx-medium">
                    ID
                  </th>
                  <th className="wd-15p tx-10-force tx-mont tx-medium">
                    Type<span style={{ color: "red" }}>*</span>
                  </th>
                  <th className="wd-15p tx-10-force tx-mont tx-medium">
                    Description<span style={{ color: "red" }}>*</span>
                  </th>
                  <th className="wd-15p tx-10-force tx-mont tx-medium">
                    Parent
                  </th>
                  <th className="wd-10p tx-10-force tx-mont tx-medium">
                    Pulse Rate
                  </th>
                  <th className="wd-30p tx-10-force tx-mont tx-medium">
                    Virtual Calculation
                  </th>
                  <th
                    className="wd-5p tx-10-force tx-mont tx-medium"
                    style={{ paddingBottom: "5px" }}
                  >
                    <button
                      className="btn btn-info ht-30 d-flex align-items-center"
                      onClick={this._massExpansionHandler}
                    >
                      {this.state.items_expanded.length > 0 ? "-" : "+"}
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {newMeterRow}
                {meterRows}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="br-mainpanel pd-15">
        <DocumentTitle title="Manage Meters" />
        <div className="br-pagetitle mg-b-0">
          <div>
            <h4>Update Meter Details</h4>
            <p className="mg-b-0">
              Update Meter Details, Change Criticality Level and Target KPI
              Thresholds.
            </p>
          </div>
        </div>

        <div>
          <div className="col-12">{this.getMeterTable()}</div>
        </div>
      </div>
    );
  }
}

export default ManageMeters;
