import React from "react";
import _ from "lodash";
import "./Dashboards.scss";

//components
import { DocumentTitle } from "../../components/DocumentTitle";
import { LogoSpinner } from "../../components/LogoSpinner";
import { PageTitle } from "../../components/PageTitle";
import { DashboardCard } from "../../components/DashboardCard";
import { Modal } from "../../components/Modal";

import { EnergyConsumptionTrackingConfig } from "./DashboardConfigs/EnergyConsumptionTrackingConfig";
import { EnvironmentalConditionsConfig } from "./DashboardConfigs/EnvironmentalConditionsConfig";
import { ZoneConditionsConfig } from "./DashboardConfigs/ZoneConditionsConfig";
import { OccupancyStatusConfig } from "./DashboardConfigs/OccupancyStatusConfig";

class Dashboards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboards: [
        {
          title: "Occupancy Status",
          description:
            "Live dashboard showing current occupancy levels to manage space capacity effectively.",
          imgsrc: "/img/dashboardsThumbnails/occupancy-status.png",
          type: "wellness",
          disabled: false,
        },
        {
          title: "Zone Conditions",
          description:
            "Live dashboard showing real-time CO2 levels, temperature, and humidity for optimal environmental monitoring and control.",
          imgsrc: "/img/dashboardsThumbnails/zone-conditions.png",
          type: "wellness",
          disabled: false,
        },
        {
          title: "Energy Consumption Tracking",
          description:
            "Dashboard showcasing weekly energy savings and daily gas usage with a contextual fact for awareness.",
          imgsrc: "/img/dashboardsThumbnails/energy-consumption-tracking.png",
          type: "energy",
          disabled: false,
        },
        {
          title: "Environmental Conditions",
          description:
            "Real-time CO2 and temperature levels across multiple spaces for health and safety monitoring.",
          imgsrc: "/img/dashboardsThumbnails/environmental-conditions.png",
          type: "wellness",
          disabled: false,
        }
      ],
      selected_filter: null,
      selected_modal: null,
    };
  }

  getCards() {
    const filter = this.state.selected_filter;
    let list = this.state.dashboards;

    if (filter) {
      list = list.filter((db) => {
        return db.type.includes(filter);
      });
    }
    list = _.sortBy(list, "disabled");

    return list.map((db, i) => {
      return (
        <DashboardCard
          key={db.title}
          title={db.title}
          imgsrc={db.imgsrc}
          description={db.description}
          clickHandler={() => this.selectModal(db.title)}
          disabled={db.disabled}
        />
      );
    });
  }

  selectFilter(filter) {
    const selected_filter = this.state.selected_filter;
    this.setState({
      selected_filter: selected_filter === filter ? null : filter,
    });
  }

  selectModal(modal) {
    const selected_modal = this.state.selected_modal;
    this.setState({
      selected_modal: selected_modal === modal ? null : modal,
    });
  }

  getModals() {
    const modal = this.state.selected_modal;
    if (modal === null) return null;
    return (
      <Modal hasExit toggleOpen={() => this.selectModal(null)}>
        {modal === "Energy Consumption Tracking" &&
          this.getEnergyConsumptionDashboardSetup()}
        {modal === "Environmental Conditions" &&
          this.getEnvironmentalConditionsDashboardSetup()}
        {modal === "Zone Conditions" && this.getZoneConditionsDashboardSetup()}
        {modal === "Occupancy Status" && this.getOccupancyDashboardSetup()}
      </Modal>
    );
  }

  getEnergyConsumptionDashboardSetup() {
    return <EnergyConsumptionTrackingConfig />;
  }

  getEnvironmentalConditionsDashboardSetup() {
    return <EnvironmentalConditionsConfig />;
  }

  getZoneConditionsDashboardSetup() {
    return <ZoneConditionsConfig />;
  }

  getOccupancyDashboardSetup() {
    return <OccupancyStatusConfig />;
  }

  render() {
    return (
      <div
        id="Dashboards"
        className="br-mainpanel br-profile-page floorplan-background"
        style={{ scrollY: "scroll" }}
      >
        <DocumentTitle title="Dashboards" />
        <LogoSpinner loading={this.state.loading} />
        {this.getModals()}
        <div className="br-container">
          <div className="row">
            <div className="col mg-t-30">
              <PageTitle
                buttons={[
                  {
                    label: "All",
                    trueCondition: this.state.selected_filter === null,
                    clickHandler: () => this.selectFilter(null),
                  },
                  {
                    label: "Energy",
                    trueCondition: this.state.selected_filter === "energy",
                    clickHandler: () => this.selectFilter("energy"),
                  },
                  {
                    label: "Wellness",
                    trueCondition: this.state.selected_filter === "wellness",
                    clickHandler: () => this.selectFilter("wellness"),
                  },
                ]}
              />
            </div>
          </div>
          <div className="row mg-t-10">
            <div className="col">
              <div className="cards-wrapper">
                {this.getCards()}
                {/* Empty cards to execute the correct layout */}
                <DashboardCard empty />
                <DashboardCard empty />
                <DashboardCard empty />
                <DashboardCard empty />
                <DashboardCard empty />
                <DashboardCard empty />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboards;
