import React from "react";
import GeneralUtils from "../../../../utils/GeneralUtils";
import UrlManager from "../../../../utils/UrlManager";
import _ from "lodash";
import moment from "moment";

import "./EnvironmentalConditionsConfig.scss";
import ZonesOverviewActions from "../../../../actions/zonesOverviewActions";
import DashboardsActions from "../../../../actions/dashboardsActions";
import { PageTitle } from "../../../../components/PageTitle";
import { BuildingSelect } from "../../../../components/BuildingSelect";

class EnvironmentalConditionsConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_building: null,
      token: null,
      tokenStale: true,
      zones: [],
    };

    this.copyUrl = this.copyUrl.bind(this);
    this.fetchToken = this.fetchToken.bind(this);
    this.fetchZones = this.fetchZones.bind(this);
    this.viewDashboard = this.viewDashboard.bind(this);
    this.selectBuilding = this.selectBuilding.bind(this);
  }

  componentDidMount() {
    this.fetchZones();
  }

  async fetchZones() {
    const yesterdayTimestamp = moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    const tomorrowTimestamp = moment().add(1, "days").format("YYYY-MM-DD");

    try {
      let zones = await ZonesOverviewActions.getZonesOverview(
        yesterdayTimestamp,
        tomorrowTimestamp
      );
      this.setState({ zones: zones });
    } catch (err) {
      throw new Error(err);
    }
  }

  componentDidUpdate(_, prevState) {
    const prev_id =
      prevState.selected_building && prevState.selected_building.building_id;
    const current_id =
      this.state.selected_building && this.state.selected_building.building_id;

    const inputsChanged = prev_id !== current_id;

    if (inputsChanged) {
      this.setState({ tokenStale: true });
    }
  }

  async fetchToken() {
    const building_id = _.get(this.state, "selected_building.building_id");

    if (building_id) {
      const object = {
        type: "BUILDING_CONDITIONS_DASHBOARD",
        building_id: building_id,
      };

      try {
        const token = await DashboardsActions.generateToken(object);
        this.setState({ token: token, tokenStale: false });
        return token;
      } catch (error) {
        console.error("Error generating token");
        throw error;
      }
    }
  }

  async copyUrl() {
    const token =
      this.state.tokenStale || !this.state.token
        ? await this.fetchToken()
        : this.state.token;
    const url =
      UrlManager.getUIUrl() + `dashboards/building-conditions/${token}`;
    GeneralUtils.copyToClipboard(url);
  }

  async viewDashboard() {
    const token =
      this.state.tokenStale || !this.state.token
        ? await this.fetchToken()
        : this.state.token;
    const url = `dashboards/building-conditions/${token}`;
    window.open(url, "_blank");
  }

  selectBuilding(building) {
    this.setState({ selected_building: building });
  }

  render() {
    const generationDisabled = !this.state.selected_building;

    const buildingsWithCo2orTemp = _.uniq(
      this.state.zones
        .filter((z) => z.wellness && (z.wellness.co2 || z.wellness.temperature))
        .map((y) => y.building.id)
    );

    return (
      <div id="EnvironmentalConditionsConfig">
        <div className="row mg-b-20">
          <div className="col-12">
            <PageTitle
              title={"Environmental Conditions - Dashboard Configuration"}
            />
          </div>
        </div>
        <hr></hr>
        <div className="row mg-b-20">
          <div className="col-12 col-lg-6">
            <div className="left-panel">
              <div>
                <div className="label">
                  Target Building{" "}
                  <span className="field-status">(required)</span>
                </div>
                <BuildingSelect
                  selectedBuilding={this.state.selected_building}
                  onBuildingSelect={this.selectBuilding}
                  filter={(building) =>
                    buildingsWithCo2orTemp.includes(building.building_id)
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="right-panel">
              <div className="label">Preview</div>
              <div className="placeholder">
                <div className="image">
                  <img
                    alt="dashboard"
                    src="/img/dashboardsThumbnails/environmental-conditions.png"
                  />
                  <div className="select-left"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="button-bottom-panel">
              <div className="buttons">
                <button
                  className={`btn btn-info copy-button ${
                    generationDisabled ? "disabled" : ""
                  }`}
                  disabled={generationDisabled}
                  onClick={this.copyUrl}
                >
                  Copy Link
                </button>
                <button
                  className={`btn btn-info view-button ${
                    generationDisabled ? "disabled" : ""
                  }`}
                  disabled={generationDisabled}
                  onClick={this.viewDashboard}
                >
                  View Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EnvironmentalConditionsConfig;
