import React, { Component } from "react";
import { Link } from "react-router-dom";
import { TickingTime } from "../../../components/TickingTime";
import "./EnergyLeaderboardDashboard.scss";
import _ from "lodash";
import moment from "moment";
import GeneralUtils from "../../../utils/GeneralUtils";
import Constants from "../../../constants/index";

// stores and actions
import CarbonEmissionsStore from "../../../stores/carbonEmissionsStore";
import CarbonEmissionsActions from "../../../actions/carbonEmissionsActions";

//charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spinner } from "../../../components/Spinner";
import { DashboardSlideshow } from "../../../components/DashboardSlideshow";

class EnergyLeaderboardDashboard extends Component {
  interval = 0;
  constructor(props) {
    super(props);
    this.state = {
      estate: {},
      loading: true,
      name: "",
      total_consumption: 0,
      total_emissions: 0,
      heaviest_users: [],
      intensity_metrics: [],
      advice: [
        {
          title: "Turn Off",
          text: "Turn non-essential equipment off when not required, especially on evenings and weekends.",
          image: "/img/turnoff.png",
        },
        {
          title: "Challenge",
          text: "Challenge yourself & colleagues to review the requirement to run equipment in labs, cleanrooms and offices.",
          image: "/img/challenge.png",
        },
        {
          title: "Purchasing",
          text: "Ensure that energy use in operation is considered for all equipment purchased.",
          image: "/img/purchase.png",
        },
        {
          title: "Insulate",
          text: "Ensure any heat generating equipment is well insulated.",
          image: "/img/insulate.png",
        },
        {
          title: "Close Windows",
          text: "Keep windows and doors closed when heating and air conditioning is on.",
          image: "/img/close.png",
        },
        {
          title: "Cleanrooms",
          text: "Minimise air loss from cleanrooms by closing fumehoods and fixing or reporting any holes in cleanroom floors, walls, ceilings or doors.",
          image: "/img/cleanrooms.png",
        },
        {
          title: "Natural Sources",
          text: "Make use of natural daylight and ventilation when possible - they don't cost the earth.",
          image: "/img/natural.png",
        },
        {
          title: "Keep Warm",
          text: "Dress for the season and avoid using unnecessary heating where possible.",
          image: "/img/keepwarm.png",
        },
        {
          title: "Report",
          text: "Report any energy wastage or opportunities to the facilities department.",
          image: "/img/report.png",
        },
        {
          title: "Educate",
          text: "Make yourself energy & carbon aware - become a driver and contributor of high standards where you work and live.",
          image: "/img/educate.png",
        },
      ],
      emission_targets: [],
    };
    this._onDataFetch = this._onDataFetch.bind(this);
    this._fetchData = this._fetchData.bind(this);
  }

  componentDidMount() {
    if (this.props.estate_id) {
      let estate_id = Number.parseInt(this.props.estate_id);
      this._fetchData(estate_id);
      if (this.interval === 0) {
        this.interval = setInterval(() => this._fetchData(estate_id), 900000);
      }
    }
  }

  componentWillMount() {
    CarbonEmissionsStore.addEstateEnergyLeaderboardListener(this._onDataFetch);
  }

  componentWillUnmount() {
    CarbonEmissionsStore.removeEstateEnergyLeaderboardListener(
      this._onDataFetch
    );
    clearInterval(this.interval);
  }

  _fetchData(estate_id) {
    CarbonEmissionsActions.getEstateEnergyLeaderboardData(estate_id);
  }

  _onDataFetch() {
    const data = CarbonEmissionsStore.getEstateEnergyLeaderboardData();
    this.setState({
      loading: false,
      estate: data.estate,
      name: data.name,
      total_consumption: data.total_consumption,
      total_emissions: data.total_emissions,
      heaviest_users: data.heaviest_users,
      intensity_metrics: data.intensity_metrics,
      emission_targets: data.emission_targets,
    });
  }

  // getters for Components

  getLeaderboard() {
    let estate_id = Number.parseInt(this.props.estate_id);
    const COLORS_ARRAY = [
      "#56e2cf",
      "#e25668",
      "#56aee2",
      "#e28956",
      "#5668e2",
      "#e2cf56",
      "#8a56e2",
      "#aee256",
      "#cf56e2",
      "#68e256",
      "#e256ae",
      "#56e289",
      "#e2cf56",
    ];
    let data = [];
    if (this.state.heaviest_users) {
      // Preparing series by attaching name and consumption
      data = this.state.heaviest_users.map((el) => {
        return { name: el.name, y: Math.round(el.consumption) };
      });
      // Attaching color based on the name
      data = _.orderBy(data, "name").map((e, index) => {
        return { ...e, color: COLORS_ARRAY[index] };
      });

      // Ordering based on consumption
      data = _.orderBy(data, "y").reverse();
    }

    let remaining_consumption = 0;
    const label =
      estate_id === 20 ? "Other Electricity" : "All Other Consumption";
    data.forEach((el) => (remaining_consumption += el.y));
    remaining_consumption =
      this.state.total_consumption - remaining_consumption;
    data.push({
      name: label,
      color: `#949494`,
      y: Math.round(remaining_consumption),
    });

    const series = {
      data: data,
    };

    const getIcon = (color) => {
      let icon = <ion-icon name={"flash"} />;
      return (
        <div className="icon" style={{ background: color }}>
          {icon}
        </div>
      );
    };

    const getChart = () => {
      const screenWidth = window.innerWidth;
      const getFontSizes = (screenWidth) => {
        let label = "12px";
        let value = "30px";
        let height = "65%";

        if (screenWidth < 1920) {
          label = "12px";
          value = "25px";
        }
        if (screenWidth > 1920) {
          label = "20px";
          value = "40px";
        }

        if (screenWidth > 2560) {
          label = "35px";
          value = "55px";
        }

        return {
          label,
          value,
          height,
        };
      };

      let options = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: getFontSizes(screenWidth).height,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: this.state.total_consumption
            ? `<span class="central">
              <span class="label" style="color: gray; font-size: ${
                getFontSizes(screenWidth).label
              }; font-family: 'Roboto'; padding-bottom: 10px;">Total Electric (kWh)</span><br/>
              <span class="value" style="font-size: ${
                getFontSizes(screenWidth).value
              }; font-family: 'Roboto'; color: #869099;">${GeneralUtils.getFormattedNumberWithUnit(
                this.state.total_consumption,
                "",
                0
              )}</span>
            </span>`
            : "",
          align: "center",
          verticalAlign: "middle",
        },
        tooltip: {
          headerFormat: "",
          pointFormat:
            "{point.name}<br/>Consumption: <b>{point.y}</b> kWH<br/>Percent: <b>{point.percentage:.1f}%</b>",
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            center: ["50%", "50%"],
            innerSize: "75%",
            dataLabels: {
              enabled: false,
              format: "{point.y}",
              distance: -30,
              color: "white",
            },
          },
        },
        series: series,
        navigation: {
          buttonOptions: {
            verticalAlign: "bottom",
            y: 0,
            x: 10,
            theme: {
              style: {
                color: "#17A2B8",
                textDecoration: "underline",
              },
            },
          },
        },
        exporting: {
          enabled: false,
          buttons: {
            contextButton: {
              enabled: false,
            },
            exportButton: {
              text: "Export",
              menuItems: ["downloadCSV", "downloadPNG", "downloadPDF"],
            },
          },
        },
      };

      return (
        <HighchartsReact
          containerProps={{ className: "pie-container" }}
          highcharts={Highcharts}
          options={options}
        />
      );
    };

    const getSummary = () => {
      if (this.state.loading) return <Spinner height={"100%"} />;
      const categories = data.map((entry) => {
        return {
          ...entry,
          y: GeneralUtils.getFormattedNumberWithUnit(entry.y, " kWh", 0),
          percent: GeneralUtils.getFormattedNumberWithUnit(
            (entry.y / this.state.total_consumption) * 100,
            "%",
            1
          ),
        };
      });

      return (
        <div className="summary">
          {categories.map((cat, index) => {
            if (index > 10) return null;
            return (
              <div key={index} className="category-wrapper">
                <div className="icon-wrapper">{getIcon(cat.color)}</div>
                <div className="name">{cat.name}</div>
                <div className="value">{cat.y}</div>
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <div id="Leaderboard">
        <div className="header">
          <div className="title">Significant Energy Users</div>
        </div>
        <div className="body">
          {getChart()}
          {getSummary()}
        </div>
      </div>
    );
  }

  getIntensity() {
    let estate_id = Number.parseInt(this.props.estate_id);
    if (this.state.loading) return <Spinner height={"100%"} />;
    return (
      <div id="Intensity">
        <div
          id="intensity-carousel"
          className="carousel ride"
          data-ride="carousel"
          data-interval="8000"
        >
          <ol className="carousel-indicators">
            {this.state.intensity_metrics.map((intensity, index) => {
              if (index > 10) return null;
              return (
                <li
                  key={intensity.name}
                  data-target="#intensity-carousel"
                  data-slide-to={index}
                  className={index === 0 ? "active" : ""}
                ></li>
              );
            })}
          </ol>
          <div className="carousel-inner">
            {this.state.intensity_metrics.map((intensity, index) => {
              if (index > 10) return null;
              const currentPerSpaceFormatted =
                GeneralUtils.getFormattedNumberWithUnit(
                  intensity.current,
                  "",
                  2
                );
              const previousPerSpaceFormatted =
                GeneralUtils.getFormattedNumberWithUnit(
                  intensity.previous,
                  "",
                  2
                );
              const perSpaceChange = intensity.current - intensity.previous;
              const perSpaceChangeFormatted =
                GeneralUtils.getFormattedNumberWithUnit(perSpaceChange, "%", 2);
              const reductionIcon = (
                <ion-icon name="arrow-down" style={{ paddingRight: "5px" }} />
              );
              const increaseIcon = (
                <ion-icon name="arrow-up" style={{ paddingRight: "5px" }} />
              );
              return (
                <div
                  key={intensity.name}
                  className={
                    index === 0 ? "carousel-item active" : "carousel-item"
                  }
                >
                  <div className="per-space-wrapper">
                    <div className="header">
                      <div className="title">Energy Intensity Per m2</div>
                    </div>
                    <div className="per-space-body">
                      <div className="benchmark">
                        <div className="label">Last 30 Days</div>
                        <div className="value">{previousPerSpaceFormatted}</div>
                        <div className="subtext">kWh</div>
                      </div>
                      <div className="icon">
                        {estate_id !== 20 ? (
                          <span style={{ marginBottom: "5px" }}>
                            {intensity.name}
                          </span>
                        ) : null}
                        <ion-icon className="icon" name="business" />
                      </div>
                      <div className="this-period">
                        <div className="label">Current 30 Days</div>
                        <div className="value">{currentPerSpaceFormatted}</div>
                        <div
                          className="subtext"
                          style={{
                            color:
                              perSpaceChange > 0
                                ? Constants.PASTEL_RED
                                : Constants.PASTEL_GREEN,
                          }}
                        >
                          {perSpaceChange > 0 ? increaseIcon : reductionIcon}
                          {perSpaceChangeFormatted}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  getTriviaTile() {
    if (this.state.loading) return <Spinner height={"100%"} />;
    return (
      <DashboardSlideshow
        title={"Play Your Part"}
        data={this.state.advice}
        interval={8000}
      />
    );
  }

  getEmissionsTargets() {
    let estate_id = Number.parseInt(this.props.estate_id);
    let actual_emissions = [];
    let years = [];

    let max = 0;
    let target = 0;
    if (this.state.emission_targets && this.state.emission_targets.length > 0) {
      let values = this.state.emission_targets;
      //Convert to Tons for Tyndall
      if (estate_id === 20) {
        for (let i = 0; i < values.length; i++) {
          values[i].total_emissions = values[i].total_emissions / 1000;
        }
      }

      for (let i = 0; i < values.length; i++) {
        years.push(values[i].year);
        actual_emissions.push(Math.round(values[i].total_emissions));

        if (values[i].total_emissions > max) {
          max = values[i].total_emissions;
        }
        target = values[i].total_emissions;
      }
    }
    let now = new Date(Date.now());
    let deduct = 4.2522 * (now.getFullYear() - 2018);
    target = max - max * (deduct / 100);

    const getChart = () => {
      let options = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "bar",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
          align: "center",
          verticalAlign: "middle",
          y: -15,
        },
        xAxis: {
          categories: years,
          endOnTick: true,
          startOnTick: true,
        },
        yAxis: {
          min: 0,
          max: max,
          title: {
            text: estate_id === 20 ? "tCO2e" : "kgCO2e",
          },
          plotLines: [
            {
              color: "#f7696196",
              width: 2,
              value: target,
              zIndex: 100,
            },
          ],
        },
        legend: { enabled: false },
        plotOptions: {
          series: {
            dataLabels: {
              align: "right",
              enabled: true,
            },
            stacking: "normal",
          },
        },
        series: [
          {
            name: "Total Emissions",
            data: actual_emissions,
            color: "#5f83b7",
          },
        ],
      };

      return (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          containerProps={{ style: { height: "98%", width: "100%" } }}
        />
      );
    };

    return (
      <div id="EmissionsTargets" style={{ height: "100%" }}>
        <div className="header">
          <div className="title">
            Carbon Emissions (tCO2e)
            <span
              style={{
                float: "right",
                fontSize: "smaller",
                borderLeft: "2px solid #f7696196",
                paddingLeft: "5px",
                marginRight: "5px",
              }}
            >
              {moment().year()} Target (-51% By 2030)
            </span>
          </div>
        </div>
        <div className="body" style={{ height: "100%" }}>
          {getChart()}
        </div>
      </div>
    );
  }
  getHeader() {
    return (
      <div className="dashboard-title">
        <div className="building-name">
          {this.state.name ? this.state.name : ""}
        </div>
        <div className="title">
          Last 30 Days - SEU &amp; Emissions Dashboard
        </div>
      </div>
    );
  }

  render() {
    let estate_id = Number.parseInt(this.props.estate_id);
    let intensityTile =
      estate_id === 20 ? null : (
        <div className="intensity tile">
          <div className="content-wrapper">{this.getIntensity()}</div>
        </div>
      );
    let stretchStyle = estate_id === 20 ? { gridArea: "1 / 2 / 1 / 4" } : null;

    return (
      <div id="EnergyLeaderboardDashboard">
        <div className="background">
          <img
            className="background-image"
            alt="background"
            src="/img/OPNHero.png"
          />
        </div>
        {this.getHeader()}
        <div className="leaderboard tile">
          <div className="content-wrapper">{this.getLeaderboard()}</div>
        </div>
        {intensityTile}
        <div className="trivia-tile tile" style={stretchStyle}>
          <div className="content-wrapper">{this.getTriviaTile()}</div>
        </div>
        <div className="emissions-targets tile">
          <div className="content-wrapper">{this.getEmissionsTargets()}</div>
        </div>
        <div className="dashboard-footer">
          <div className="clock-wrapper">
            <TickingTime format="MMMM Do YYYY, h:mm:ss a" />
          </div>
          <div></div>
          <div className="powered-by">
            <h3>
              Powered by{" "}
              <Link
                to={{ pathname: "https://www.opnbuildings.com" }}
                target="_blank"
              >
                <span>
                  <img
                    className="logo-image"
                    alt="logo"
                    src="/img/OPNLogoWhite.png"
                  />
                </span>
              </Link>
            </h3>
          </div>
        </div>
        <div></div>
      </div>
    );
  }
}

export default EnergyLeaderboardDashboard;
