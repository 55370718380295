import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ShareButton.module.scss';

import { Button } from 'antd';
import { Icon } from '../Icon'

import UrlManager from '../../utils/UrlManager';
import GeneralUtils from '../../utils/GeneralUtils';

const ShareButton = ({ condition, object, generateToken, urlPath }) => {
    const { token } = useParams();
    const [isSharing, setIsSharing] = useState(false);

    const fetchToken = async () => {
        if (condition) {
            try {
                const token = await generateToken(object);
                return token;
            } catch (error) {
                console.error("Error generating token: ", error);
                throw error;
            }
        }
    };

    const copyUrl = async () => {
        let url = '';

        try {
            const newToken = await fetchToken();

            if (newToken) {
                setIsSharing(true);

                url = UrlManager.getUIUrl() + urlPath.replace('{newToken}', newToken);
                GeneralUtils.copyToClipboard(url, null);
            }
        } catch (error) {
            console.error("Error copying URL: ", error);
        } finally {
            setTimeout(() => {
                setIsSharing(false);
            }, 2000);
        }
    }

    const copyUrlNoLogin = () => {
        let url = '';

        try {
            const newToken = token;

            if (newToken) {
                setIsSharing(true);

                url = UrlManager.getUIUrl() + urlPath.replace('{newToken}', newToken);
                GeneralUtils.copyToClipboard(url, null);
            }
        } catch (error) {
            console.error("Error copying URL: ", error);
        } finally {
            setTimeout(() => {
                setIsSharing(false);
            }, 2000);
        }
    }

    return (
        <div className={styles.shareButton}>
            {token ? (
                <Button
                    icon={<Icon name={isSharing ? "Check" : "Share"} color={'#fff'} size={18} />}
                    size="small"
                    onClick={copyUrlNoLogin}
                    style={{ background: isSharing ? '#1c7430' : '#415fa9' }}
                >
                    {isSharing ? "Link Copied" : "Share"}
                </Button>
            ) : (
                <Button
                    icon={<Icon name={isSharing ? "Check" : "Share"} color={'#fff'} size={18} />}
                    size="small"
                    onClick={copyUrl}
                    disabled={!condition}
                    style={{ background: isSharing ? '#1c7430' : '#415fa9' }}
                >
                    {isSharing ? "Link Copied" : "Share"}
                </Button>
            )}
        </div>
    );
};

export default ShareButton;