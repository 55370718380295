import React, { Component } from "react";
import "./DateRangeNavigation.scss";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

class DateRangeNavigation extends Component {
  constructor(props) {
    super(props);
    this.selectDateEvent = this.selectDateEvent.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
  }
  dateChanged() {
    //Do Nothing
  }
  selectDateEvent(event, picker) {
    let endDate = picker.endDate.unix();

    // Bug fix catch. Ranges selection below do not work correctly for current day. Instead of taking current moment it takes end of day.
    // This forces the max to be the current moment.
    if (endDate > moment().unix()) {
      endDate = moment().unix();
    }

    let numDaysDif = picker.endDate.diff(picker.startDate, "days") + 1;
    if (numDaysDif > picker.maxSpan.days) {
      endDate = endDate + 86399;
    }
    if (picker.chosenLabel === "Last 7 Days") {
      endDate = moment().unix();
    }

    let startDate = picker.startDate.unix();
    let ts_CurrentDate_Start = moment().startOf("day").unix();
    let nextDayDisabled = false;
    if (picker.endDate.startOf("day").unix() >= ts_CurrentDate_Start) {
      nextDayDisabled = true;
    }
    this.props.dateChangeCallback(startDate, endDate);
    this.setState({ nextDayDisabled: nextDayDisabled });
  }
  showCalendar(event, picker) {
    window.$(".notice-help").remove();
    window
      .$(
        "<p class='notice-help' style='font-style:italic;margin-left:8px;width:140px;font-size:10px;'>Click a date twice to select a 24hr period.</p>"
      )
      .appendTo(".range_inputs");
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.ts_start !== nextProps.ts_start ||
      this.props.ts_end !== nextProps.ts_end ||
      this.props.disabled !== nextProps.disabled
    );
  }
  render() {
    let startDate = moment.unix(this.props.ts_start);
    let endDate = moment.unix(this.props.ts_end);

    if (this.props.ts_end === null || this.props.ts_start === null) {
      startDate = moment().startOf("day");
      endDate = moment();
    }

    let maxDate = moment();
    if (this.props.noMaxDate) {
      maxDate = null;
    }

    let start = startDate.format("DD MMM, YYYY");
    let end = endDate.format("DD MMM, YYYY");
    let label = start + " - " + end;
    if (this.props.forcedLabel) label = this.props.forcedLabel;
    let ranges = {};
    if (window.innerWidth > 900) {
      ranges = this.props.ranges || {
        Today: [moment().startOf("day"), moment()],
        Yesterday: [
          moment().subtract(1, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"),
        ],
        "This Week": [
          moment().startOf("isoWeek").isoWeekday(1),
          moment().endOf("day"),
        ],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek").isoWeekday(1),
          moment().subtract(1, "weeks").endOf("isoWeek").isoWeekday(7),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("day")],
        "Last Month": [
          moment().subtract(1, "months").startOf("month"),
          moment().subtract(1, "months").endOf("month"),
        ],
        "Year Ago": [
          moment().subtract(1, "years").startOf("day"),
          moment().subtract(1, "years").endOf("day"),
        ],
        "Year to Date": [moment().startOf("year"), moment().endOf("day")],
      };
    }
    if (this.props.disabled) {
      return (
        <div id="calenderSelect" className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <ion-icon name="calendar" style={{ color: "#17A2B8" }} />
            </div>
          </div>
          <input
            disabled
            type="text"
            ref="datepicker"
            className="form-control"
            value={this.props.disabledMessage || "Date Selection Disabled"}
            style={{
              cursor: "disabled",
              color: "transparent",
              textShadow: "0 0 0 #16a2b8",
              caretColor: "transparent",
              fontWeight: "700",
            }}
          />
        </div>
      );
    } else {
      let selectionStyle = {
        cursor: "pointer",
        color: "transparent",
        textShadow: "0 0 0 #16a2b8",
        caretColor: "transparent",
        fontWeight: "700",
      };
      let calendarStyle = { color: "#17A2B8" };
      if (this.props.normalStyle) {
        selectionStyle = {
          cursor: "pointer",
        };
        calendarStyle = {};
      }
      return (
        <DateRangePicker
          onShow={this.showCalendar}
          opens={this.props.opens ? this.props.opens : "center"}
          linkedCalendars={false}
          alwaysShowCalendars={true}
          autoApply={true}
          showCustomRangeLabel={false}
          onApply={this.selectDateEvent}
          maxDate={maxDate}
          startDate={startDate}
          endDate={endDate}
          ranges={ranges}
          locale={{
            format: "DD-MMM-YYYY",
            separator: " - ",
            applyLabel: "Apply",
            cancelLabel: "Cancel",
            fromLabel: "From",
            toLabel: "To",
            weekLabel: "W",
            firstDay: 1,
            monthNames: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
          }}
        >
          <div id="calenderSelect" className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <ion-icon name="calendar" style={calendarStyle} />
              </div>
            </div>
            <input
              type="text"
              ref="datepicker"
              onChange={this.dateChanged}
              className="form-control"
              value={label}
              style={selectionStyle}
            />
          </div>
        </DateRangePicker>
      );
    }
  }
}

export default DateRangeNavigation;
