import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import GeneralUtils from "../../../utils/GeneralUtils";
import Constants from "../../../constants";
import "./BuildingConditionsDashboard.scss";

// stores and actions
import DashboardsStore from "../../../stores/dashboardsStore";
import DashboardsActions from "../../../actions/dashboardsActions";

import { NavLink, Link } from "react-router-dom";
import { TickingTime } from "../../../components/TickingTime";
import { RefreshProgressBar } from "../../../components/RefreshProgressBar";
import { ButtonToggle } from "../../../components/ButtonToggle";
import { DocumentTitle } from "../../../components/DocumentTitle";

const REFRESH_SECONDS = 60;

class BuildingConditionsDashboard extends Component {
  interval = 0;
  constructor(props) {
    super(props);
    this.state = {
      building_id: null,
      zones: [],
      building: null,
      last_updated: moment(),
      mode: this.props.location.search.includes("temp") ? "temp" : "co2",
      modeLock: this.props.location.search.includes("temp") ? true : false,
    };
    this._getColorForCO2Target = this._getColorForCO2Target.bind(this);
    this._getColorForTempTarget = this._getColorForTempTarget.bind(this);
    this._onDataFetched = this._onDataFetched.bind(this);
    this._fetchData = this._fetchData.bind(this);
    this.zoneClickHandler = this.zoneClickHandler.bind(this);
    this.toggleModes = this.toggleModes.bind(this);
  }

  componentWillMount() {
    DashboardsStore.addDashboardDataFetchedListener(this._onDataFetched);
  }

  componentWillUnmount() {
    DashboardsStore.removeDashboardDataFetchedListener(this._onDataFetched);

    clearInterval(this.interval);
  }

  componentDidMount() {
    const token = _.get(this.props, "match.params.token");

    if (token) {
      this._fetchData(token);
      if (this.interval === 0) {
        this.interval = setInterval(
          () => this._fetchData(token),
          REFRESH_SECONDS * 1000
        );
      }
    }
  }

  _fetchData(token) {
    DashboardsActions.getDataWithToken(token);
  }

  _onDataFetched() {
    const data = DashboardsStore.getIndoorConditionsData();
    const building = _.get(data, "building");
    const zones = _.get(data, "zones");
    const building_id = _.get(data, "building_id");
    const modeLock = this.state.modeLock;

    let mode = this.state.mode;

    if (modeLock === false) {
      const co2_monitored_zones = zones.filter((z) => {
        return _.get(z, "agg_wellness.co2_val");
      });

      const temp_monitored_zones = zones.filter((z) => {
        return _.get(z, "agg_wellness.temperature_val");
      });

      if (temp_monitored_zones.length > co2_monitored_zones.length) {
        mode = "temp";
      } else {
        mode = "co2";
      }
    }

    this.setState({
      building_id: building_id,
      building: building,
      zones: zones,
      last_updated: moment(),
      modeLock: true,
      mode: mode,
    });
  }

  toggleModes() {
    this.setState({
      mode: this.state.mode === "co2" ? "temp" : "co2",
      modeLock: true,
    });
  }

  _getColorForCO2Target(value, lower, upper) {
    let diff = upper - lower;
    // if lower/upper is wrongly configured - background will be gray
    if (diff < 0) {
      return "gray";
    }

    if (value <= lower + diff * 0.15) {
      return Constants.GREEN;
    } else if (value >= upper - diff * 0.5) {
      return Constants.RED;
    } else {
      return Constants.ORANGE;
    }
  }

  _getColorForTempTarget(value, lower, upper) {
    if (upper < lower) {
      return "gray";
    }

    if (value <= lower || value > upper) {
      return Constants.RED;
    } else if (value > lower && value < lower + 1) {
      return Constants.ORANGE;
    } else if (value <= upper && value > upper - 1) {
      return Constants.ORANGE;
    } else if (value >= lower + 1 && value <= upper - 1) {
      return Constants.GREEN;
    }

    return "gray";
  }

  zoneClickHandler(zone) {
    return "/zones/" + zone.zone_id;
  }

  getCo2ZoneCards(isMobile) {
    if (this.state.zones && this.state.zones.length > 0) {
      // filtering out the zones without the CO2 values or agg.wellness object
      let zones = this.state.zones.filter((z) => {
        return z.agg_wellness && z.agg_wellness.co2_val;
      });

      const colorsOrder = {
        [Constants.RED]: 0,
        [Constants.ORANGE]: 1,
        [Constants.GREEN]: 2,
        gray: 3,
      };

      // Sort by colors -> red first, invalid gray at the end
      zones = _.sortBy(
        zones,
        (zone) =>
          colorsOrder[
            this._getColorForCO2Target(
              zone.agg_wellness.co2_val,
              zone.co2_target_lower,
              zone.co2_target_upper
            )
          ]
      );

      return zones.map((zone, key) => {
        if (zone.agg_wellness && zone.agg_wellness.co2_val) {
          if (isMobile) {
            return (
              <NavLink
                key={key}
                className="col-12 zone-tile"
                to={this.zoneClickHandler(zone)}
                target="_blank"
                title={zone.name}
              >
                <div className="card shadow-base card-body pd-0 bd-0 mg-b-15">
                  <div
                    className="left pd-y-15 pd-x-0"
                    style={{
                      backgroundColor: this._getColorForCO2Target(
                        zone.agg_wellness.co2_val,
                        zone.co2_target_lower,
                        zone.co2_target_upper
                      ),
                    }}
                  >
                    <div className="co2-value-wrapper">
                      <div className="label">CO2 Level</div>
                      <div className="unit">
                        {zone.agg_wellness.co2_val &&
                          Math.round(zone.agg_wellness.co2_val)}
                      </div>
                      <div className="label">ppm</div>
                    </div>
                  </div>
                  <div className="right pd-15">
                    <div
                      style={{
                        height: "30px",
                        overflow: "hidden",
                      }}
                    >
                      <h6
                        className="card-title tx-uppercase tx-12 mg-b-8"
                        style={{
                          width: "90%",
                        }}
                      >
                        {zone.name}
                      </h6>
                    </div>
                    <p className="tx-medium mg-b-0 tx-lato property-label">
                      Temperature
                    </p>
                    <p className="tx-medium tx-inverse mg-b-5 tx-lato">
                      {GeneralUtils.roundNumber(
                        zone.agg_wellness.temperature_val,
                        1
                      )}{" "}
                      °C{" "}
                      <span
                        className="square-10 mg-r-15"
                        style={{
                          backgroundColor: this._getColorForTempTarget(
                            zone.agg_wellness.temperature_val,
                            zone.temp_target_lower,
                            zone.temp_target_upper
                          ),
                          float: "right",
                          marginTop: "5px",
                        }}
                      ></span>
                    </p>
                  </div>
                </div>
              </NavLink>
            );
          } else {
            return (
              <NavLink
                key={key}
                className="zone-tile col"
                to={this.zoneClickHandler(zone)}
                target="_blank"
                title={zone.name}
              >
                <div className="card shadow-base card-body pd-0 bd-0">
                  <div
                    className="left pd-y-15 pd-x-0"
                    style={{
                      backgroundColor: this._getColorForCO2Target(
                        zone.agg_wellness.co2_val,
                        zone.co2_target_lower,
                        zone.co2_target_upper
                      ),
                    }}
                  >
                    <div className="co2-value-wrapper">
                      <div className="label">CO2 Level</div>
                      <div className="unit">
                        {zone.agg_wellness.co2_val &&
                          Math.round(zone.agg_wellness.co2_val)}
                      </div>
                      <div className="label">ppm</div>
                    </div>
                  </div>
                  <div className="right pd-15">
                    <div
                      style={{
                        height: "30px",
                        overflow: "hidden",
                      }}
                    >
                      <h6
                        className="card-title tx-uppercase tx-12 mg-b-8"
                        style={{
                          width: "90%",
                        }}
                      >
                        {zone.name}
                      </h6>
                    </div>

                    <p className="tx-medium mg-b-0 tx-lato property-label">
                      Temperature
                    </p>
                    <p className="tx-medium tx-inverse mg-b-5 tx-lato">
                      {GeneralUtils.roundNumber(
                        zone.agg_wellness.temperature_val,
                        1
                      )}{" "}
                      °C{" "}
                      <span
                        className="square-10 mg-r-15"
                        style={{
                          backgroundColor: this._getColorForTempTarget(
                            zone.agg_wellness.temperature_val,
                            zone.temp_target_lower,
                            zone.temp_target_upper
                          ),
                          float: "right",
                          marginTop: "5px",
                        }}
                      ></span>
                    </p>
                  </div>
                </div>
              </NavLink>
            );
          }
        } else {
          return null;
        }
      });
    } else {
      return (
        <div
          className="col-12"
          style={{
            margin: "auto",
            marginTop: "15vh",
            height: "76vh",
          }}
        >
          <div
            className="card bd-0 shadow-base"
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className="card-body bd pd-b-0 pd-t-0">
              <p className="mg-t-100 mg-b-20 mg-x-20 tx-center">
                <ion-icon name="pulse" style={{ fontSize: "48px" }} />
              </p>
              <h4 className="tx-default mg-b-20 tx-center">
                {" "}
                No zone conditions data available for monitoring
              </h4>
              <p className="mg-b-120 mg-x-20 tx-center">
                Please try again or contact your administrator for support.
              </p>
            </div>
          </div>
        </div>
      );
    }
  }

  getTempZoneCards(isMobile) {
    if (this.state.zones && this.state.zones.length > 0) {
      // filtering out the zones without the CO2 values or agg.wellness object
      let zones = this.state.zones.filter((z) => {
        return z.agg_wellness && z.agg_wellness.temperature_val;
      });

      const colorsOrder = {
        [Constants.RED]: 0,
        [Constants.ORANGE]: 1,
        [Constants.GREEN]: 2,
        gray: 3,
      };

      // Sort by colors -> red first, invalid gray at the end
      zones = _.sortBy(
        zones,
        (zone) =>
          colorsOrder[
            this._getColorForTempTarget(
              zone.agg_wellness.temperature_val,
              zone.temp_target_lower,
              zone.temp_target_upper
            )
          ]
      );

      return zones.map((zone, key) => {
        if (zone.agg_wellness && zone.agg_wellness.temperature_val) {
          if (isMobile) {
            return (
              <NavLink
                key={key}
                className="col-12 zone-tile"
                to={this.zoneClickHandler(zone)}
                target="_blank"
                title={
                  zone.name +
                  "\nZone Targets: " +
                  zone.temp_target_lower +
                  "°C - " +
                  zone.temp_target_upper +
                  "°C"
                }
              >
                <div className="card shadow-base card-body pd-0 bd-0 mg-b-15">
                  <div
                    className="left pd-y-15 pd-x-0"
                    style={{
                      backgroundColor: this._getColorForTempTarget(
                        zone.agg_wellness.temperature_val,
                        zone.temp_target_lower,
                        zone.temp_target_upper
                      ),
                    }}
                  >
                    <div className="co2-value-wrapper">
                      <div className="label">Temperature</div>
                      <div className="unit">
                        {zone.agg_wellness.temperature_val &&
                          GeneralUtils.roundNumber(
                            zone.agg_wellness.temperature_val,
                            1
                          ) + "°C"}
                      </div>
                    </div>
                  </div>
                  <div className="right pd-15">
                    <div
                      style={{
                        height: "30px",
                        overflow: "hidden",
                      }}
                    >
                      <h6
                        className="card-title tx-uppercase tx-12 mg-b-8"
                        style={{
                          width: "90%",
                        }}
                      >
                        {zone.name}
                      </h6>
                    </div>
                    <p className="tx-medium mg-b-0 tx-lato property-label">
                      CO2
                    </p>
                    <p className="tx-medium tx-inverse mg-b-5 tx-lato">
                      {zone.agg_wellness.co2_val
                        ? GeneralUtils.roundNumber(
                            zone.agg_wellness.co2_val,
                            0
                          ) + " ppm"
                        : "N/A"}
                      {zone.agg_wellness.co2_val && (
                        <span
                          className="square-10 mg-r-15"
                          style={{
                            backgroundColor: this._getColorForCO2Target(
                              zone.agg_wellness.co2_val,
                              zone.co2_target_lower,
                              zone.co2_target_upper
                            ),
                            float: "right",
                            marginTop: "5px",
                          }}
                        ></span>
                      )}
                    </p>
                  </div>
                </div>
              </NavLink>
            );
          } else {
            return (
              <NavLink
                key={key}
                className="zone-tile col"
                to={this.zoneClickHandler(zone)}
                target="_blank"
                title={
                  zone.name +
                  "\nZone Targets: " +
                  zone.temp_target_lower +
                  "°C - " +
                  zone.temp_target_upper +
                  "°C"
                }
              >
                <div className="card shadow-base card-body pd-0 bd-0">
                  <div
                    className="left pd-y-15 pd-x-0"
                    style={{
                      backgroundColor: this._getColorForTempTarget(
                        zone.agg_wellness.temperature_val,
                        zone.temp_target_lower,
                        zone.temp_target_upper
                      ),
                    }}
                  >
                    <div className="co2-value-wrapper">
                      <div className="label">Temperature</div>
                      <div className="unit">
                        {zone.agg_wellness.temperature_val &&
                          GeneralUtils.roundNumber(
                            zone.agg_wellness.temperature_val,
                            1
                          ) + "°C"}
                      </div>
                    </div>
                  </div>
                  <div className="right pd-15">
                    <div
                      style={{
                        height: "30px",
                        overflow: "hidden",
                      }}
                    >
                      <h6
                        className="card-title tx-uppercase tx-12 mg-b-8"
                        style={{
                          width: "90%",
                        }}
                      >
                        {zone.name}
                      </h6>
                    </div>
                    <p className="tx-medium mg-b-0 tx-lato property-label">
                      CO2 Level
                    </p>
                    <p className="tx-medium tx-inverse mg-b-5 tx-lato">
                      {zone.agg_wellness.co2_val
                        ? GeneralUtils.roundNumber(
                            zone.agg_wellness.co2_val,
                            0
                          ) + " ppm"
                        : "N/A"}
                      {zone.agg_wellness.co2_val && (
                        <span
                          className="square-10 mg-r-15"
                          style={{
                            backgroundColor: this._getColorForCO2Target(
                              zone.agg_wellness.co2_val,
                              zone.co2_target_lower,
                              zone.co2_target_upper
                            ),
                            float: "right",
                            marginTop: "5px",
                          }}
                        ></span>
                      )}
                    </p>
                  </div>
                </div>
              </NavLink>
            );
          }
        } else {
          return null;
        }
      });
    } else {
      return (
        <div
          className="col-12"
          style={{
            margin: "auto",
            marginTop: "15vh",
            height: "76vh",
          }}
        >
          <div
            className="card bd-0 shadow-base"
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className="card-body bd pd-b-0 pd-t-0">
              <p className="mg-t-100 mg-b-20 mg-x-20 tx-center">
                <ion-icon name="pulse" style={{ fontSize: "48px" }} />
              </p>
              <h4 className="tx-default mg-b-20 tx-center">
                {" "}
                No zone conditions data available for monitoring
              </h4>
              <p className="mg-b-120 mg-x-20 tx-center">
                Please try again or contact your administrator for support.
              </p>
            </div>
          </div>
        </div>
      );
    }
  }

  getBuildingDetail() {
    let rowClass = "row";
    let marginBottom = "15vh";
    let colClass = "col-12";
    let width = "320px";
    let building_id = _.get(this.state, "building_id");

    if (building_id === 7) {
      return (
        <div className="overlay" onClick={() => this.toggleModes()}>
          <img
            src="/img/ucc-library.jpg"
            style={{ maxHeight: "400px" }}
            className="img-fluid"
            alt="UCC Library"
          />
          <div className="overlay-body bg-white-7 d-flex align-items-start flex-column pd-20">
            <div className={rowClass} style={{ marginBottom: marginBottom }}>
              <div className={colClass}>
                <img
                  src="/img/ucc-logo-1.png"
                  alt="UCC Logo"
                  style={{ width: width, marginTop: "1vh" }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="overlay" onClick={() => this.toggleModes()}>
          <img
            src="https://freedesignfile.com/upload/2012/03/001c0674439.jpg"
            style={{ minHeight: "35vh" }}
            className="img-fluid"
            alt="Misc Building"
          />
        </div>
      );
    }
  }

  getLegend() {
    return (
      <>
        <div className="row pd-x-30">
          <div className="col-12">
            <p className="tx-normal tx-14 pd-l-0 mg-t-15 mg-b-0">LEGEND</p>
          </div>
        </div>
        <div className="row pd-x-30">
          <div className="col-12">
            <p className="tx-inverse tx-14 pd-l-0 mg-t-5 mg-b-0">
              <span
                className="square-8 bg-success"
                style={{ marginTop: "5px" }}
              ></span>{" "}
              Normal{" "}
              <span style={{ opacity: 0.6, fontSize: "12px" }}>
                (CO2 levels are healthy)
              </span>
            </p>
          </div>
          <div className="col-12">
            <p className="tx-inverse tx-14 pd-l-0 mg-t-5 mg-b-0">
              <span
                className="square-8 bg-warning"
                style={{ marginTop: "5px" }}
              ></span>{" "}
              Warning{" "}
              <span style={{ opacity: 0.6, fontSize: "12px" }}>
                (CO2 levels are elevated)
              </span>
            </p>
          </div>
          <div className="col-12">
            <p className="tx-inverse tx-14 pd-l-0 mg-t-5 mg-b-0">
              <span
                className="square-8 bg-danger"
                style={{ marginTop: "5px" }}
              ></span>{" "}
              Critical{" "}
              <span style={{ opacity: 0.6, fontSize: "12px" }}>
                (CO2 levels are high)
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }

  getTempLegend() {
    return (
      <>
        <div className="row pd-x-30">
          <div className="col-12">
            <p className="tx-normal tx-14 pd-l-0 mg-t-15 mg-b-0">LEGEND</p>
          </div>
        </div>
        <div className="row pd-x-30">
          <div className="col-12">
            <p className="tx-inverse tx-14 pd-l-0 mg-t-5 mg-b-0">
              <span
                className="square-8 bg-success"
                style={{ marginTop: "5px" }}
              ></span>{" "}
              Normal{" "}
              <span style={{ opacity: 0.6, fontSize: "12px" }}>
                (optimal control)
              </span>
            </p>
          </div>
          <div className="col-12">
            <p className="tx-inverse tx-14 pd-l-0 mg-t-5 mg-b-0">
              <span
                className="square-8 bg-warning"
                style={{ marginTop: "5px" }}
              ></span>{" "}
              Warning{" "}
              <span style={{ opacity: 0.6, fontSize: "12px" }}>
                (average control)
              </span>
            </p>
          </div>
          <div className="col-12">
            <p className="tx-inverse tx-14 pd-l-0 mg-t-5 mg-b-0">
              <span
                className="square-8 bg-danger"
                style={{ marginTop: "5px" }}
              ></span>{" "}
              Critical{" "}
              <span style={{ opacity: 0.6, fontSize: "12px" }}>
                (poor control)
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }

  getBuildingOccupancy() {
    if (
      _.get(this.state.building, "occupancy") &&
      _.get(this.state.building, "occupancy.running_count") &&
      _.get(this.state.building, "building_capacity")
    ) {
      let percentage =
        Math.ceil(
          ((this.state.building.building_occupancy.running_count /
            this.state.building.building_capacity) *
            100) /
            5
        ) * 5;
      let classNamePercentage;
      if (percentage > 95) {
        classNamePercentage = "progress-bar bg-danger wd-" + percentage + "p";
      } else if (percentage > 90) {
        classNamePercentage = "progress-bar bg-warning wd-" + percentage + "p";
      } else {
        classNamePercentage = "progress-bar bg-success wd-" + percentage + "p";
      }
      return (
        <div className="row pd-x-30">
          <div className="col-12">
            <p className="tx-normal tx-14 pd-l-0 mg-t-0 mg-b-0">
              CURRENT OCCUPANCY (
              {this.state.building.building_occupancy.running_count}/
              {this.state.building.building_capacity})
            </p>
            <p className="tx-inverse tx-14 pd-l-0 mg-t-0 mg-b-0"></p>
            <div className="progress mg-b-0">
              <div
                className={classNamePercentage}
                role="progressbar"
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {percentage}%
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    let zoneCount = 0;
    let redZones = 0;
    let orangeZones = 0;
    let greenZones = 0;

    if (this.state.mode === "co2" && _.get(this.state, "zones")) {
      for (let i = 0; i < this.state.zones.length; i++) {
        let z = this.state.zones[i];
        if (z.agg_wellness && z.agg_wellness.co2_val) {
          zoneCount++;

          const color = this._getColorForCO2Target(
            z.agg_wellness.co2_val,
            z.co2_target_lower,
            z.co2_target_upper
          );

          if (color === Constants.RED) {
            redZones++;
          } else if (color === Constants.ORANGE) {
            orangeZones++;
          } else if (color === Constants.GREEN) {
            greenZones++;
          }
        }
      }
    } else if (this.state.mode === "temp" && _.get(this.state, "zones")) {
      for (let i = 0; i < this.state.zones.length; i++) {
        let z = this.state.zones[i];
        if (z.agg_wellness && z.agg_wellness.temperature_val) {
          zoneCount++;

          const color = this._getColorForTempTarget(
            z.agg_wellness.temperature_val,
            z.temp_target_lower,
            z.temp_target_upper
          );

          if (color === Constants.RED) {
            redZones++;
          } else if (color === Constants.ORANGE) {
            orangeZones++;
          } else if (color === Constants.GREEN) {
            greenZones++;
          }
        }
      }
    }

    let isMobile = false;
    // device detection
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      isMobile = true;
    }

    if (isMobile) {
      return (
        <div
          id="BuildingConditionsDashboard"
          className="row no-gutters ht-100v wd-100v floorplan-background"
        >
          <DocumentTitle
            title="Live Conditions Report"
            notifications={redZones + orangeZones}
          />
          <div className="col-12">
            <div className="row no-gutters wd-100v pd-x-15">
              <div className="col-12">
                <div className="row pd-x-20 mg-t-15 zone-wrapper">
                  {this.state.mode === "co2"
                    ? this.getCo2ZoneCards(isMobile)
                    : this.getTempZoneCards(isMobile)}
                </div>
              </div>
            </div>
            <div className="row no-gutters wd-100v mobile-bottom-bar">
              <div className="col-12">
                <div className="mobile-powered-by">
                  <div className="mode-toggle">
                    <ButtonToggle
                      id="mode-toggle"
                      name="mode-toggle"
                      onChange={this.toggleModes}
                      optionLabels={["Temp", "CO2"]}
                      checked={this.state.mode === "temp"}
                      colorRight={"#43a047"}
                      colorLeft={"#43a047"}
                    />
                  </div>

                  <div> Powered By</div>
                  <Link
                    to={{ pathname: "https://www.opnbuildings.com" }}
                    target="_blank"
                  >
                    <div></div>
                    <img alt="OPNBuildings Logo" src="/img/OPNLogoWhite.png" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="BuildingConditionsDashboard"
          className="row no-gutters ht-100v wd-100v floorplan-background"
        >
          <DocumentTitle
            title="Live Conditions Report"
            notifications={redZones + orangeZones}
          />
          <div className="col-12">
            <div className="wrapper wd-100v pd-x-15 pd-t-20 pd-b-20">
              <div className="main">
                <div className="zone-wrapper pd-x-20">
                  {this.state.mode === "co2"
                    ? this.getCo2ZoneCards(isMobile)
                    : this.getTempZoneCards(isMobile)}
                  <div className="zone-tile col"></div>
                  <div className="zone-tile col"></div>
                  <div className="zone-tile col"></div>
                  <div className="zone-tile col"></div>
                </div>
              </div>
              <div
                className="side bg-white ht-100p"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.15)",
                  borderRadius: "12px",
                  marginTop: "5px",
                  overflow: "hidden",
                }}
              >
                {this.getBuildingDetail()}
                <p className="tx-inverse tx-14 pd-l-30 mg-t-25 mg-b-0">
                  <strong>{zoneCount}</strong> monitored spaces across the
                </p>
                <p className="tx-black tx-20 pd-l-30 tx-uppercase mg-b-0">
                  {_.get(this.state, "building.name")}
                </p>
                <div className="row pd-x-30">
                  <div className="col mg-y-20">
                    <div className="card bg-success bd-0 pd-15 rounded-12">
                      <p className="tx-white tx-center mg-0">
                        <strong className="tx-20 tx-medium">
                          {greenZones}
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div className="col mg-y-20">
                    <div className="card bg-warning bd-0 pd-15 rounded-12">
                      <p className="tx-white tx-center mg-0">
                        <strong className="tx-20 tx-medium">
                          {orangeZones}
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div className="col mg-y-20">
                    <div className="card bg-danger bd-0 pd-15 rounded-12">
                      <p className="tx-white tx-center mg-0">
                        <strong className="tx-20 tx-medium">{redZones}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                {this.getBuildingOccupancy()}
                {this.state.mode === "co2"
                  ? this.getLegend()
                  : this.getTempLegend()}
                <div className="row pd-x-30">
                  <div className="col-12">
                    <p className="tx-normal tx-14 pd-l-0 mg-t-25 mg-b-0">
                      LAST UPDATED
                    </p>
                    <p className="tx-normal tx-14 pd-l-0 mg-t-0 mg-b-0">
                      {this.state.last_updated.format(
                        "MMMM Do YYYY, h:mm:ss A"
                      )}
                    </p>
                    <RefreshProgressBar
                      countdownSeconds={REFRESH_SECONDS}
                      animated
                      striped
                      height={"8px"}
                    />
                  </div>
                </div>
                <div className="corner-switch">
                  <ButtonToggle
                    id="mode-toggle"
                    name="mode-toggle"
                    onChange={this.toggleModes}
                    optionLabels={["Temp", "CO2"]}
                    checked={this.state.mode === "temp"}
                    colorRight={"#d5d5d5"}
                    colorLeft={"#d5d5d5"}
                  />
                </div>
              </div>
            </div>
            <div className="row no-gutters wd-100v bottom-bar">
              <div className="col-8 clock-wrapper">
                <div className="clock">
                  <TickingTime format="MMMM Do YYYY, h:mm:ss A" />
                </div>
              </div>
              <div className="col-4 tx-right powered-by-wrapper">
                <div className="powered-by">
                  Powered By{" "}
                  <Link
                    to={{ pathname: "https://www.opnbuildings.com" }}
                    target="_blank"
                  >
                    <span>
                      <img alt="OPNBuildings Logo" src="/img/OPNLogo.png" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default BuildingConditionsDashboard;
