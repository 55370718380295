import React, { Component } from "react";
import "./ManageTenants.scss";
import OrganisationActions from "../../actions/organisationActions";
import OrganisationStore from "../../stores/organisationStore";
import TenantActions from "../../actions/tenantActions";
import TenantStore from "../../stores/tenantStore";
import UserStore from "../../stores/userStore";
import _ from "lodash";

// Components
import { SearchSelect } from "../../components/SearchSelect";
import { DocumentTitle } from "../../components/DocumentTitle";
import { Filter } from "../../components/Filter";
import { Modal } from "../../components/Modal";
import { Spinner } from "../../components/Spinner";
import "react-toastify/dist/ReactToastify.css";

class ManageTenants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: UserStore.getUser(),
      orgs: [],

      selected_org: null,
      selected_building: null,
      selected_tenant: null,

      // Filter Row
      tenant_filter: "",
      building_filter: null,
      has_zones_filter: false,
      has_meters_filter: false,

      building_filter_options: [],

      tenants: [],

      // Alternative state
      opened_sections: ["buildings"],

      // modal controller
      modal_type: null,
      modal_object: null,

      // Create Tenant
      new_tenant_name: "",
      new_tenant_description: "",
      // Update Tenant
      updated_tenant_name: null,
      updated_tenant_description: null,

      linked_building: null,
      linked_building_size: 0,

      updated_building_size: 0,

      available_zones: [],
      link_zone_building: null,
      link_zone_selected_zone: null,

      available_meters: [],
      link_meter_building: null,
      link_meter_selected_meter: null,
    };
    // listener callbacks
    this.onOrganisationChange = this.onOrganisationChange.bind(this);
    this.onTenantsFetched = this.onTenantsFetched.bind(this);
    this.getFilterRow = this.getFilterRow.bind(this);
    this.onAction = this.onAction.bind(this);
    this.onLinkableZonesFetched = this.onLinkableZonesFetched.bind(this);
    this.onLinkableMetersFetched = this.onLinkableMetersFetched.bind(this);
    this.setBuildingFilter = this.setBuildingFilter.bind(this);
  }

  componentWillMount() {
    OrganisationStore.addChangeListener(this.onOrganisationChange);

    // TENANTS LISTENERS
    TenantStore.addTenantsFetchedListener(this.onTenantsFetched);
    TenantStore.addTenantSavedListener(this.onAction);
    TenantStore.addTenantUpdatedListener(this.onAction);
    TenantStore.addTenantDeletedListener(this.onAction);

    // BUILDING LISTENERS
    TenantStore.addBuildingLinkedListener(this.onAction);
    TenantStore.addLinkedBuildingUpdatedListener(this.onAction);
    TenantStore.addLinkedBuildingDeletedListener(this.onAction);

    // ZONE LISTENERS
    TenantStore.addLinkableZonesFetchedListener(this.onLinkableZonesFetched);
    TenantStore.addZoneLinkedListener(this.onAction);
    TenantStore.addZoneUnlinkedListener(this.onAction);

    //METERS LISTENERS
    TenantStore.addLinkableMetersFetchedListener(this.onLinkableMetersFetched);
    TenantStore.addMeterLinkedListener(this.onAction);
    TenantStore.addMeterUnlinkedListener(this.onAction);
  }

  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this.onOrganisationChange);

    // TENANTS LISTENERS
    TenantStore.removeTenantsFetchedListener(this.onTenantsFetched);
    TenantStore.removeTenantSavedListener(this.onAction);
    TenantStore.removeTenantUpdatedListener(this.onAction);
    TenantStore.removeTenantDeletedListener(this.onAction);

    // BUILDING LISTENERS
    TenantStore.removeBuildingLinkedListener(this.onAction);
    TenantStore.removeLinkedBuildingUpdatedListener(this.onAction);
    TenantStore.removeLinkedBuildingDeletedListener(this.onAction);

    // ZONE LISTENERS
    TenantStore.removeLinkableZonesFetchedListener(this.onLinkableZonesFetched);
    TenantStore.removeZoneLinkedListener(this.onAction);
    TenantStore.removeZoneUnlinkedListener(this.onAction);

    //METERS LISTENERS
    TenantStore.removeLinkableMetersFetchedListener(
      this.onLinkableMetersFetched
    );
    TenantStore.removeMeterLinkedListener(this.onAction);
    TenantStore.removeMeterUnlinkedListener(this.onAction);

    TenantStore.clear();
  }

  componentDidMount() {
    OrganisationActions.getOrganisations(true);
    this.fetchOrganisationTenants(this.state.user.fk_organisation_id);
  }

  // Callbacks

  onOrganisationChange() {
    const default_org = OrganisationStore.getOrganisations().find((org) => {
      return org.organisation_id === this.state.user.fk_organisation_id;
    });

    let available_buildings = [];
    if (default_org && default_org.estates.length) {
      default_org.estates.forEach((est) => {
        available_buildings.push(...est.buildings);
      });
    }

    this.setState({
      orgs: OrganisationStore.getOrganisations(),
      selected_org: default_org,
      loading: false,
      available_buildings: available_buildings,
    });
  }

  onTenantsFetched() {
    const tenants = TenantStore.getTenants();

    const getFilterBuildings = () => {
      let building_filter_options = [];
      tenants.forEach((t) => {
        building_filter_options.push(...t.buildings);
      });
      return _.uniqBy(building_filter_options, "building_id");
    };

    this.setState({
      tenants: tenants,
      building_filter_options: getFilterBuildings(),
      loading: false,
    });
  }

  onAction() {
    this.closeModal();
    this.fetchOrganisationTenants(this.state.selected_org.organisation_id);
  }

  onLinkableZonesFetched() {
    this.setState({ available_zones: TenantStore.getLinkableZones() });
  }
  onLinkableMetersFetched() {
    this.setState({ available_meters: TenantStore.getLinkableMeters() });
  }

  fetchOrganisationTenants(id) {
    if (id) {
      this.setState({ loading: true });
      TenantActions.getOrganisationTenants(id);
    }
  }

  // DOM elements

  getOrganisationSelector() {
    const organisation_options = this.state.orgs.map((org) => {
      return { ...org, name: org.name + ` (org id: ${org.organisation_id})` };
    });

    const getBuildings = (org) => {
      let available_buildings = [];
      org.estates.forEach((est) => {
        available_buildings.push(...est.buildings);
      });
      return available_buildings;
    };

    return (
      <SearchSelect
        limit={100}
        options={organisation_options}
        placeholder={"Select Organisation"}
        isDisabled={UserStore.isSuper() === false || this.state.loading}
        defaultValue={this.state.selected_org}
        actionOnSelectedOption={(org) => {
          this.setState(
            { selected_org: org, available_buildings: getBuildings(org) },
            this.fetchOrganisationTenants(org.organisation_id)
          );
        }}
      />
    );
  }

  getTenantCard(tenant) {
    const hasBuildings = tenant.buildings.length > 0;
    const isSelected =
      this.state.selected_tenant &&
      this.state.selected_tenant.tenant_id === tenant.tenant_id;

    const buildings_expanded = this.state.opened_sections.includes("buildings");
    const zones_expanded = this.state.opened_sections.includes("zones");
    const meters_expanded = this.state.opened_sections.includes("meters");

    const getAllMeters = (tenant, filtered = false) => {
      let meters = [];

      tenant.buildings.forEach((b) => {
        if (b.meters.length) {
          meters = [
            ...meters,
            ...b.meters.map((el) => {
              return {
                ...el,
                building: b.name,
              };
            }),
          ];
        }
      });

      if (filtered) {
        meters = meters.filter((m) => {
          if (this.state.selected_building === null) return true;
          if (this.state.selected_building.name === m.building) return true;
          return false;
        });
      }

      return meters;
    };

    const getAllZones = (tenant, filtered = false) => {
      let zones = [];
      tenant.buildings.forEach((b) => {
        if (b.zones.length) {
          zones = [
            ...zones,
            ...b.zones.map((el) => {
              return {
                ...el,
                building: b.name,
              };
            }),
          ];
        }
      });

      if (filtered) {
        zones = zones.filter((z) => {
          if (this.state.selected_building === null) return true;
          if (this.state.selected_building.name === z.building) return true;
          return false;
        });
      }

      return zones;
    };

    const isBuildingSelected = (b) =>
      this.state.selected_building &&
      b.building_id === this.state.selected_building.building_id;

    const getBuilding = (building) => {
      return (
        <div
          key={building.building_id}
          className={`building ${
            isBuildingSelected(building) ? "selected" : ""
          }`}
          onClick={(e) => this.selectBuilding(e, building)}
        >
          <div className="top">
            <div className="title">{building.name}</div>
          </div>
          <div className="bottom">
            <div className="sqm">{building.tenant_area} sqm</div>
            <div className="buttons">
              <div
                className="edit-size"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    modal_type: "update_building",
                    modal_object: building,
                  });
                }}
              >
                <ion-icon name="create" />
              </div>
              <div
                className="delete-building"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    modal_type: "delete_building",
                    modal_object: building,
                  });
                }}
              >
                <ion-icon name="trash" />
              </div>
            </div>
          </div>
        </div>
      );
    };

    const getZone = (zone) => {
      return (
        <div className="zone" key={zone.zone_id}>
          <div className="top">
            <div className="title">{zone.name}</div>
            <div className="description">{zone.description}</div>
          </div>
          <div className="bottom">
            <div className="zone-building">{zone.building}</div>
            <div className="buttons">
              <div className="edit-size"></div>
              <div
                className="delete-building"
                onClick={() =>
                  this.setState({
                    modal_type: "delete_zone",
                    modal_object: zone,
                  })
                }
              >
                <ion-icon name="trash" />
              </div>
            </div>
          </div>
        </div>
      );
    };

    const getMeter = (meter) => {
      let icon = <ion-icon name="flash" />;
      if (meter.type === "GAS_METER" || meter.type === "MAIN_GAS_METER")
        icon = <ion-icon name="flame" />;
      if (meter.type === "WATER_METER" || meter.type === "MAIN_WATER_METER")
        icon = <ion-icon name="water" />;
      if (meter.type === "HEAT_METER" || meter.type === "MAIN_HEAT_METER")
        icon = <ion-icon name="thermometer" />;
      if (meter.type.includes("NEWABLE")) icon = <ion-icon name="sunny" />;

      return (
        <div className="meter" key={meter.meter_id}>
          <div className="top">
            <div className="title">{meter.name}</div>
            <div className="description">{meter.description}</div>
            <div className="icon">{icon}</div>
          </div>
          <div className="bottom">
            <div className="meter-building">{meter.building}</div>
            <div className="buttons">
              <div className="edit-size"></div>
              <div
                className="delete-building"
                onClick={() =>
                  this.setState({
                    modal_type: "delete_meter",
                    modal_object: meter,
                  })
                }
              >
                <ion-icon name="trash" />
              </div>
            </div>
          </div>
        </div>
      );
    };

    const getTotalSpace = () => {
      let totalSpace = 0;
      tenant.buildings.forEach((b) => {
        totalSpace += b.tenant_area;
      });
      return totalSpace;
    };

    return (
      <div
        key={tenant.tenant_id}
        className={`tenant-card ${isSelected ? "selected" : ""}`}
        onClick={(e) => this.selectTenant(e, tenant)}
      >
        <div className="tenant-card-header">
          <div className="tenant-header-left">
            <div>
              <div className="tenant-name">{tenant.name}</div>
              <div className="tenant-description">{tenant.description}</div>
            </div>
          </div>
          <div className="tenant-header-right">
            <div className="stats-wrapper">
              <div className="tenant-stats">Space: {getTotalSpace()} sqm</div>
              <div className="tenant-stats">
                Buildings: {tenant.buildings.length}
              </div>
              <div className="tenant-stats">
                Zones: {getAllZones(tenant).length}
              </div>
              <div className="tenant-stats">
                Meters: {getAllMeters(tenant).length}
              </div>
            </div>
            <div className="buttons-wrapper">
              <button
                className="edit-button btn btn-outline-info"
                onClick={() => this.setState({ modal_type: "edit_tenant" })}
              >
                Edit
              </button>
              <button
                className="delete-button btn btn-outline-danger"
                onClick={() => this.setState({ modal_type: "delete_tenant" })}
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        {/* Expanded Card */}

        {isSelected && (
          <div className="expanded-sections">
            {/* BUILDINGS */}

            <div className="section tenant-buildings">
              <div
                className="section-header"
                onClick={(e) => this.selectSection(e, "buildings")}
              >
                <ion-icon name={buildings_expanded ? "remove" : "add"} />
                <div className="section-title">Buildings</div>
              </div>
              {buildings_expanded && (
                <div className="section-body">
                  <div
                    className="add-building"
                    onClick={() =>
                      this.setState({ modal_type: "link_building" })
                    }
                  >
                    <ion-icon name="add-circle-outline" />
                  </div>
                  {tenant.buildings.map((b) => getBuilding(b))}
                </div>
              )}
            </div>
            {/* ZONES */}

            {hasBuildings && (
              <>
                <div className="section tenant-zones">
                  <div
                    className="section-header"
                    onClick={(e) => this.selectSection(e, "zones")}
                  >
                    <ion-icon name={zones_expanded ? "remove" : "add"} />
                    <div className="section-title">
                      Zones{" "}
                      {this.state.selected_building && (
                        <span className="filter-note">
                          (filtered for {this.state.selected_building.name})
                        </span>
                      )}
                    </div>
                  </div>
                  {zones_expanded && (
                    <div className="section-body">
                      <div
                        className="add-zone"
                        onClick={() =>
                          this.setState({ modal_type: "link_zone" })
                        }
                      >
                        <ion-icon name="add-circle-outline" />
                      </div>
                      {getAllZones(tenant, true).map((z) => getZone(z))}
                    </div>
                  )}
                </div>
                {/* METERS */}

                <div className="section tenant-meters">
                  <div
                    className="section-header"
                    onClick={(e) => this.selectSection(e, "meters")}
                  >
                    <ion-icon name={meters_expanded ? "remove" : "add"} />
                    <div className="section-title">
                      Meters{" "}
                      {this.state.selected_building && (
                        <span className="filter-note">
                          (filtered for {this.state.selected_building.name})
                        </span>
                      )}
                    </div>
                  </div>
                  {meters_expanded && (
                    <div className="section-body">
                      <div
                        className="add-meter"
                        onClick={() =>
                          this.setState({ modal_type: "link_meter" })
                        }
                      >
                        <ion-icon name="add-circle-outline" />
                      </div>
                      {getAllMeters(tenant, true).map((m) => getMeter(m))}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }

  selectTenant(e, tenant) {
    e.stopPropagation();
    this.setState({ selected_tenant: tenant, selected_building: null });
  }

  selectBuilding(e, building) {
    e.stopPropagation();

    this.setState({
      selected_building:
        this.state.selected_building &&
        this.state.selected_building.building_id === building.building_id
          ? null
          : building,
    });
  }

  selectSection(e, section) {
    e.stopPropagation();
    let opened_sections = this.state.opened_sections;

    if (opened_sections.includes(section)) {
      opened_sections = opened_sections.filter((s) => s !== section);
    } else {
      opened_sections = [...this.state.opened_sections, section];
    }

    this.setState({ opened_sections: opened_sections });
  }

  getFilterRow() {
    return (
      <div className="section-filters">
        <div className="input-filter">
          <Filter
            placeholder="Filter by Name"
            height="38px"
            value={this.state.tenant_filter}
            setFilter={(query) =>
              this.setState({ tenant_filter: query.toLowerCase() })
            }
          />
        </div>
        <div className="building-filter">
          <SearchSelect
            options={this.state.building_filter_options}
            placeholder="Filter by Building"
            defaultValue={this.state.building_filter}
            actionOnSelectedOption={this.setBuildingFilter}
          />
        </div>
        <div className="checkmarks-filter">
          <div
            className="checkbox-wrapper"
            onClick={(e) => {
              e.preventDefault();
              this.setState((prevState) => ({
                has_zones_filter: !prevState.has_zones_filter,
              }));
            }}
          >
            <input
              type="checkbox"
              readOnly
              checked={this.state.has_zones_filter}
            />
            <label>Has Zones</label>
          </div>
          <div
            className="checkbox-wrapper"
            onClick={(e) => {
              e.preventDefault();
              this.setState((prevState) => ({
                has_meters_filter: !prevState.has_meters_filter,
              }));
            }}
          >
            <input
              type="checkbox"
              readOnly
              checked={this.state.has_meters_filter}
            />
            <label>Has Meters</label>
          </div>
        </div>
        <div className="create-tenant">
          {(this.state.tenant_filter ||
            this.state.building_filter ||
            this.state.has_meters_filter ||
            this.state.has_zones_filter) && (
            <button
              className="btn btn-outline-danger"
              onClick={() => this.clearFilters()}
            >
              Clear Filters
            </button>
          )}
          <button
            className="btn btn-outline-info"
            disabled={!this.state.selected_org}
            onClick={() => this.setState({ modal_type: "create_tenant" })}
          >
            Create Tenant
          </button>
        </div>
      </div>
    );
  }

  clearFilters() {
    this.setState({
      tenant_filter: "",
      building_filter: null,
      has_meters_filter: false,
      has_zones_filter: false,
    });
  }

  setBuildingFilter(building) {
    this.setState({ building_filter: building, selected_tenant: null });
  }

  getFilteredTenants() {
    const tenant_filter = this.state.tenant_filter;
    const building_filter = this.state.building_filter;
    const hasZones = this.state.has_zones_filter;
    const hasMeters = this.state.has_meters_filter;

    let tenants = [...this.state.tenants];

    if (hasZones) {
      tenants = tenants.filter((t) => {
        let has_zones = false;
        t.buildings.forEach((b) => {
          if (b.zones.length) has_zones = true;
        });
        return has_zones;
      });
    }

    if (hasMeters) {
      tenants = tenants.filter((t) => {
        let has_meters = false;
        t.buildings.forEach((b) => {
          if (b.meters.length) has_meters = true;
        });
        return has_meters;
      });
    }

    if (tenant_filter) {
      tenants = tenants.filter((t) => {
        return (
          t.name.toLowerCase().includes(tenant_filter) ||
          t.description.toLowerCase().includes(tenant_filter)
        );
      });
    }

    if (building_filter) {
      tenants = tenants.filter((t) => {
        return t.buildings
          .map((b) => b.building_id)
          .find((id) => id === building_filter.building_id);
      });
    }

    return tenants;
  }

  getTenantSummary() {
    if (!this.state.selected_tenant) {
      return (
        <div className="tenant-summary-wrapper">
          <div className="placeholder">
            Please select the tenant to populate the summary.
          </div>
        </div>
      );
    }

    const getTotalArea = () => {
      let total_area = 0;
      this.state.selected_tenant.buildings.forEach((b) => {
        total_area += b.tenant_area;
      });

      return total_area;
    };

    return (
      <div className="tenant-summary-wrapper">
        <div className="name">{`${this.state.selected_tenant.name} (id: ${this.state.selected_tenant.tenant_id})`}</div>
        <div className="description">
          {this.state.selected_tenant.description}
        </div>
        <div className="total-size">Total space: {getTotalArea()} sqm</div>
        <div className="building-tree">
          {this.state.selected_tenant.buildings.map((b) => {
            return (
              <div key={b.building_id} className="building">
                <div className="name">
                  {b.name}
                  <span className="sqm">{` - (${b.tenant_area}/${b.total_area} sqm)`}</span>
                </div>
                <div className="section">
                  {b.zones.length ? "Zones:" : "No Zones"}
                  {b.zones.map((z) => {
                    return (
                      <div key={z.name} className="entry">
                        - {z.name}
                      </div>
                    );
                  })}
                </div>
                <div className="section">
                  {b.meters.length ? "Meters:" : "No Meters"}
                  {b.meters.map((m) => {
                    return (
                      <div key={m.name} className="entry">
                        - {m.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  getModal() {
    let element;
    const modal_type = this.state.modal_type;
    if (modal_type === null) return null;
    // Tenant Modals
    if (modal_type === "create_tenant") element = this.getCreateTenantModal();
    if (modal_type === "edit_tenant") element = this.getUpdateTenantModal();
    if (modal_type === "delete_tenant") element = this.getDeleteTenantModal();
    // Assign Building Modals
    if (modal_type === "link_building") element = this.getLinkBuildingModal();
    if (modal_type === "update_building")
      element = this.getUpdateLinkedBuildingModal();
    if (modal_type === "delete_building")
      element = this.getDeleteLinkedBuilding();
    // Assign Zone Modals
    if (modal_type === "link_zone") element = this.getLinkZoneModal();
    if (modal_type === "delete_zone") element = this.getDeleteLinkedZoneModal();
    // Assign Meter Modals
    if (modal_type === "link_meter") element = this.getLinkMeterModal();
    if (modal_type === "delete_meter")
      element = this.getDeleteLinkedMeterModal();

    return (
      <Modal
        hasExit
        backdropUnclickable
        style={{ minWidth: "40vw", overflow: "unset" }}
        toggleOpen={() => this.closeModal()}
        children={element}
      />
    );
  }

  closeModal() {
    this.setState({
      modal_type: null,
      modal_object: null,

      new_tenant_name: "",
      new_tenant_description: "",

      updated_tenant_name: null,
      updated_tenant_description: null,

      linked_building: null,
      linked_building_size: 0,

      updated_building_size: 0,

      available_zones: [],
      link_zone_building: null,
      link_zone_selected_zone: null,

      available_meters: [],
      link_meter_building: null,
      link_meter_selected_meter: null,
    });
  }

  // CREATE TENANT

  getCreateTenantModal() {
    const disabled_save =
      !this.state.new_tenant_description ||
      !this.state.new_tenant_name ||
      !this.state.selected_org.organisation_id;
    const org_name = this.state.selected_org
      ? this.state.selected_org.name
      : "Current Organisation";

    return (
      <div className="create-tenant-modal">
        <h5 className="title">Create New Tenant for {org_name}</h5>
        <div className="form-group">
          <label className="form-control-label">Tenant Name:</label>
          <input
            placeholder="Enter tenants name"
            type="text"
            className="form-control"
            value={this.state.new_tenant_name}
            onChange={(e) => this.setState({ new_tenant_name: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label className="form-control-label">Tenant Description:</label>
          <input
            placeholder="Enter tenants description"
            type="text"
            className="form-control"
            value={this.state.new_tenant_description}
            onChange={(e) =>
              this.setState({ new_tenant_description: e.target.value })
            }
          />
        </div>
        <div className="button-wrapper">
          <button
            className="btn btn-outline-danger"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-success"
            disabled={disabled_save}
            onClick={() => this.saveTenant()}
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  saveTenant() {
    TenantActions.createTenant({
      name: this.state.new_tenant_name,
      description: this.state.new_tenant_description,
      organisation_id: this.state.selected_org.organisation_id,
    });
  }

  // UPDATE TENANT

  getUpdateTenantModal() {
    const checkIfDisabled = () => {
      let name_unmodified = false;
      let description_unmodifed = false;

      if (
        this.state.updated_tenant_name == null ||
        this.state.updated_tenant_name === this.state.selected_tenant.name
      ) {
        name_unmodified = true;
      }

      if (
        this.state.updated_tenant_description == null ||
        this.state.updated_tenant_description ===
          this.state.selected_tenant.description
      ) {
        description_unmodifed = true;
      }

      return name_unmodified && description_unmodifed;
    };

    return (
      <div className="update-tenant-modal">
        <h5 className="title">
          Update Tenant - {this.state.selected_tenant.name}
        </h5>
        <div className="form-group">
          <label className="form-control-label">Tenant Name:</label>
          <input
            placeholder="Enter tenants name"
            type="text"
            className="form-control"
            value={
              this.state.updated_tenant_name === null
                ? this.state.selected_tenant.name
                : this.state.updated_tenant_name
            }
            onChange={(e) =>
              this.setState({ updated_tenant_name: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label className="form-control-label">Tenant Description:</label>
          <input
            placeholder="Enter tenants description"
            type="text"
            className="form-control"
            value={
              this.state.updated_tenant_description === null
                ? this.state.selected_tenant.description
                : this.state.updated_tenant_description
            }
            onChange={(e) =>
              this.setState({ updated_tenant_description: e.target.value })
            }
          />
        </div>
        <div className="button-wrapper">
          <button
            className="btn btn-outline-danger"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-success"
            disabled={checkIfDisabled()}
            onClick={() => this.udpateTenant()}
          >
            Update
          </button>
        </div>
      </div>
    );
  }

  udpateTenant() {
    const tenant_object = {
      name: this.state.updated_tenant_name || this.state.selected_tenant.name,
      description:
        this.state.updated_tenant_description ||
        this.state.selected_tenant.description,
      organisation_id: this.state.selected_org.organisation_id,
    };
    TenantActions.updateTenant(
      this.state.selected_tenant.tenant_id,
      tenant_object
    );
  }

  // DELETE TENANT

  getDeleteTenantModal() {
    return (
      <div className="delete-tenant-modal">
        <h5 className="title">
          Delete Tenant - {this.state.selected_tenant.name}
        </h5>
        Are you sure you wish to delete this tenant? All associations with
        buildings/zones/meters will be erased.
        <div className="button-wrapper">
          <button
            className="btn btn-outline-success"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-danger"
            onClick={() => this.deleteTenant()}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }

  deleteTenant(id) {
    TenantActions.deleteTenant(this.state.selected_tenant.tenant_id);
  }

  // LINK BUILDING

  getLinkBuildingModal() {
    const selected_tenant = this.state.selected_tenant
      ? this.state.selected_tenant.name
      : "";

    const unlinked_available_buildings = this.state.available_buildings.filter(
      (b) => {
        if (
          this.state.selected_tenant.buildings.find(
            (el) => el.building_id === b.building_id
          )
        ) {
          return false;
        }
        return true;
      }
    );

    return (
      <div className="link-building-modal">
        <h5 className="title">Link Building to {selected_tenant}</h5>
        <div className="form-group">
          <label className="form-control-label">Select the Building:</label>
          <SearchSelect
            options={unlinked_available_buildings}
            placeholder="Select the Building"
            defaultValue={this.state.linked_building}
            actionOnSelectedOption={(b) => {
              this.setState({ linked_building: b, linked_building_size: 0 });
            }}
          />
        </div>
        <div className="form-group">
          <label className="form-control-label">Assign the Space (sqm):</label>
          <input
            placeholder="Space (sqm)"
            type="number"
            className="form-control"
            disabled={!this.state.linked_building}
            value={this.state.linked_building_size || ""}
            onChange={(e) =>
              this.setState({
                linked_building_size:
                  e.target.value > this.state.linked_building.size
                    ? this.state.linked_building.size
                    : e.target.value,
              })
            }
          />
        </div>
        <div className="button-wrapper">
          <button
            className="btn btn-outline-danger"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-success"
            disabled={
              !this.state.linked_building || !this.state.linked_building_size
            }
            onClick={() => this.linkBuilding()}
          >
            Link
          </button>
        </div>
      </div>
    );
  }

  linkBuilding() {
    TenantActions.linkBuilding({
      building_id: this.state.linked_building.building_id,
      tenant_id: this.state.selected_tenant.tenant_id,
      area: this.state.linked_building_size,
    });
  }

  // UPDATE LINKED BUILDING

  getUpdateLinkedBuildingModal() {
    const selected_tenant = this.state.selected_tenant
      ? this.state.selected_tenant.name
      : "";

    return (
      <div className="update-building-modal">
        <h5 className="title">Update Building Area for {selected_tenant}</h5>
        <div className="form-group">
          <label className="form-control-label">Building Name</label>
          <SearchSelect
            options={this.state.available_buildings}
            isDisabled={true}
            placeholder="Select the Building"
            defaultValue={this.state.modal_object}
            actionOnSelectedOption={(b) => {
              this.setState({ linked_building: b, linked_building_size: 0 });
            }}
          />
        </div>
        <div className="form-group">
          <label className="form-control-label">Specify the Area (sqm):</label>
          <input
            placeholder="Size (sqm)"
            type="number"
            className="form-control"
            value={
              this.state.updated_building_size ||
              this.state.modal_object.tenant_area
            }
            onChange={(e) =>
              this.setState({
                updated_building_size:
                  e.target.value > this.state.modal_object.total_area
                    ? this.state.modal_object.total_area
                    : e.target.value,
              })
            }
          />
        </div>
        <div className="button-wrapper">
          <button
            className="btn btn-outline-danger"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-success"
            disabled={!this.state.updated_building_size}
            onClick={() => this.updateBuilding()}
          >
            Update
          </button>
        </div>
      </div>
    );
  }

  updateBuilding() {
    const tenant_id = this.state.selected_tenant.tenant_id;
    const building_id = this.state.modal_object.building_id;
    const new_area = this.state.updated_building_size;
    TenantActions.updateLinkedBuilding(tenant_id, building_id, {
      area: new_area,
    });
  }

  // DELETE BUILDING

  getDeleteLinkedBuilding() {
    return (
      <div className="delete-building-modal">
        <h5 className="title">
          {`Unlink ${this.state.modal_object.name} from ${this.state.selected_tenant.name}?`}
        </h5>
        Are you sure you wish to unlink this building from the tenant? All
        meters and zones associated with this building will be erased.
        <div className="button-wrapper">
          <button
            className="btn btn-outline-success"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-danger"
            onClick={() => this.unlinkBuilding()}
          >
            Unlink
          </button>
        </div>
      </div>
    );
  }

  unlinkBuilding() {
    TenantActions.deleteLinkedBuilding(
      this.state.selected_tenant.tenant_id,
      this.state.modal_object.building_id
    );
  }

  // LINK ZONE

  getLinkZoneModal() {
    const tenant_buildings = this.state.selected_tenant.buildings;
    const selected_tenant = this.state.selected_tenant
      ? this.state.selected_tenant.name
      : "";

    const getUnlinkedAvailableZones = () => {
      const assignedZonesId = [];
      this.state.tenants.forEach((t) => {
        t.buildings.forEach((b) => {
          b.zones.forEach((z) => {
            assignedZonesId.push(z.zone_id);
          });
        });
      });

      return this.state.available_zones.filter((z) => {
        return assignedZonesId.includes(z.zone_id) === false;
      });
    };

    return (
      <div className="link-zone-modal">
        <h5 className="title">Link Zone to {selected_tenant}</h5>
        <div className="form-group">
          <label className="form-control-label">Building Name</label>
          <SearchSelect
            options={tenant_buildings}
            placeholder={
              tenant_buildings.length
                ? "Select the Building"
                : "Please link the building first"
            }
            defaultValue={this.state.link_zone_building}
            actionOnSelectedOption={(b) => this.selectBuildingAndFetchZones(b)}
          />
        </div>
        <div className="form-group">
          <label className="form-control-label">Select the Zone to link:</label>
          <SearchSelect
            options={getUnlinkedAvailableZones()}
            placeholder="Select the Zone"
            isDisabled={!this.state.link_zone_building}
            defaultValue={this.state.link_zone_selected_zone}
            actionOnSelectedOption={(z) => {
              this.setState({ link_zone_selected_zone: z });
            }}
          />
        </div>
        <div className="button-wrapper">
          <button
            className="btn btn-outline-danger"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-success"
            disabled={
              !this.state.link_zone_building ||
              !this.state.link_zone_selected_zone
            }
            onClick={() => this.linkZone()}
          >
            Link
          </button>
        </div>
      </div>
    );
  }

  selectBuildingAndFetchZones(building) {
    this.setState(
      { link_zone_building: building },
      TenantActions.fetchLinkableZones(building.building_id)
    );
  }

  linkZone() {
    TenantActions.linkZone({
      ...this.state.link_zone_selected_zone,
      tenant_id: this.state.selected_tenant.tenant_id,
    });
  }

  // DELETE ZONE

  getDeleteLinkedZoneModal() {
    if (this.state.available_zones.length === 0) {
      const building_id = this.state.available_buildings.find(
        (b) => b.name === this.state.modal_object.building
      ).building_id;

      TenantActions.fetchLinkableZones(building_id);
    }

    return (
      <div className="delete-zone-modal">
        <h5 className="title">
          {`Unlink ${this.state.modal_object.name} @ ${this.state.modal_object.building} from ${this.state.selected_tenant.name}?`}
        </h5>
        Are you sure you wish to unlink this zone from the tenant?
        <div className="button-wrapper">
          <button
            className="btn btn-outline-success"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-danger"
            disabled={this.state.available_zones.length === 0}
            onClick={() => this.unlinkZone()}
          >
            Unlink
          </button>
        </div>
      </div>
    );
  }

  unlinkZone() {
    const deleted_zone = this.state.available_zones.find(
      (z) => z.zone_id === this.state.modal_object.zone_id
    );
    TenantActions.deleteLinkedZone({
      ...deleted_zone,
      tenant_id: null,
    });
  }

  // LINK METER

  getLinkMeterModal() {
    const tenant_buildings = this.state.selected_tenant.buildings;
    const selected_tenant = this.state.selected_tenant
      ? this.state.selected_tenant.name
      : "";

    const getUnlinkedAvailableMeters = () => {
      const assignedMetersId = [];
      this.state.tenants.forEach((t) => {
        t.buildings.forEach((b) => {
          b.meters.forEach((m) => {
            assignedMetersId.push(m.meter_id);
          });
        });
      });

      return this.state.available_meters.filter((m) => {
        return assignedMetersId.includes(m.meter_id) === false;
      });
    };

    return (
      <div className="link-zone-modal">
        <h5 className="title">Link Meter to {selected_tenant}</h5>
        <div className="form-group">
          <label className="form-control-label">Building Name:</label>
          <SearchSelect
            options={tenant_buildings}
            placeholder={
              tenant_buildings.length
                ? "Select the Building"
                : "Please link the building first"
            }
            defaultValue={this.state.link_meter_building}
            actionOnSelectedOption={(b) => this.selectBuildingAndFetchMeters(b)}
          />
        </div>
        <div className="form-group">
          <label className="form-control-label">
            Select the Meter to link:
          </label>
          <SearchSelect
            options={getUnlinkedAvailableMeters().map((m) => {
              return {
                name: m.description,
                ...m,
              };
            })}
            placeholder={
              this.state.link_meter_building
                ? "Select the Meter"
                : "Select the Building first"
            }
            isDisabled={!this.state.link_meter_building}
            defaultValue={this.state.link_meter_selected_meter}
            actionOnSelectedOption={(z) => {
              this.setState({ link_meter_selected_meter: z });
            }}
          />
        </div>
        <div className="button-wrapper">
          <button
            className="btn btn-outline-danger"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-success"
            disabled={
              !this.state.link_meter_building ||
              !this.state.link_meter_selected_meter
            }
            onClick={() => this.linkMeter()}
          >
            Link
          </button>
        </div>
      </div>
    );
  }

  selectBuildingAndFetchMeters(building) {
    this.setState(
      { link_meter_building: building },
      TenantActions.fetchLinkableMeters(building.building_id)
    );
  }

  linkMeter() {
    TenantActions.linkMeter({
      ...this.state.link_meter_selected_meter,
      tenant_id: this.state.selected_tenant.tenant_id,
    });
  }

  // DELETE METER

  getDeleteLinkedMeterModal() {
    if (this.state.available_meters.length === 0) {
      const building_id = this.state.available_buildings.find(
        (b) => b.name === this.state.modal_object.building
      ).building_id;

      TenantActions.fetchLinkableMeters(building_id);
    }

    return (
      <div className="delete-meter-modal">
        <h5 className="title">
          {`Unlink ${this.state.modal_object.name} @ ${this.state.modal_object.building} from ${this.state.selected_tenant.name}?`}
        </h5>
        Are you sure you wish to unlink this meter from the tenant?
        <div className="button-wrapper">
          <button
            className="btn btn-outline-success"
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-danger"
            onClick={() => this.unlinkMeter()}
          >
            Unlink
          </button>
        </div>
      </div>
    );
  }

  unlinkMeter() {
    const deleted_meter = this.state.available_meters.find(
      (m) => m.meter_id === this.state.modal_object.meter_id
    );

    TenantActions.deleteLinkedMeter({
      ...deleted_meter,
      tenant_id: null,
    });
  }

  render() {
    return (
      <div className="br-mainpanel pd-15" id="ManageTenants">
        <DocumentTitle title="Manage Tenants" />
        {this.getModal()}
        <div className="br-pagetitle mg-b-40">
          <div>
            <h4>Manage Tenants</h4>
            <p>
              Create new tenants, update existing ones and assign building
              space.
            </p>
            <div className="mg-t-10 d-flex">
              <div style={{ minWidth: "350px" }}>
                {this.getOrganisationSelector()}
              </div>
              <div className="position-relative">
                <Spinner
                  mini
                  trueCondition={this.state.loading}
                  style={{ left: "15px" }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.selected_org && (
          <div>
            <div className="tenant-list col-12 col-xl-9">
              <h5 className="section-title">Tenant List</h5>
              {this.getFilterRow()}
              <div className="list-wrapper">
                {this.getFilteredTenants().map((t) => {
                  return this.getTenantCard(t);
                })}
              </div>
            </div>
            <div className="tenant-preview col-12 col-xl-3 ">
              <h5 className="section-title">Tenant Summary</h5>
              {this.getTenantSummary()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ManageTenants;
