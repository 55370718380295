import React, { useState, useMemo } from 'react';
import styles from './BenchmarkQuestionnaire.module.scss';
import moment from 'moment';
import { Steps, Form, Input, Select, Button, DatePicker, InputNumber, Row, Col, Typography, Card, Radio, Space } from 'antd';

const { Text, Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

const BenchmarkQuestionnaire = ({ onSubmit }) => {
    const [current, setCurrent] = useState(0);
    const [occupationPeriod, setOccupationPeriod] = useState('year');
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        location: '',
        type: '',
        floor_area: 0,
        hours_of_occupation: 0,
        data_period_start: '',
        currency: 'EUR',
        electricity: {
            consumption: 0,
            cost: 0
        },
        thermal: {
            fuel_type: '',
            consumption: 0,
            cost: 0
        },
        roi: {
            payback_period: 3,
            inflation_rate: 2
        }
    });

    const handleInputChange = (name, value) => {
        setFormData(prevData => {
            const newData = { ...prevData };
            if (name.includes('.')) {
                const [key, subKey] = name.split('.');
                newData[key] = { ...newData[key], [subKey]: value };
            } else {
                newData[name] = value;
            }
            return newData;
        });
    };

    const handleHeatingFuelTypeChange = (value) => {
        handleInputChange('thermal.fuel_type', value);
    };

    const handleOccupationPeriodChange = (e) => {
        const newPeriod = e.target.value;
        setOccupationPeriod(newPeriod);
        // Reset hours of occupation when changing period
        handleInputChange('hours_of_occupation', 0);
    };

    const handleHoursOfOccupationChange = (value) => {
        if (occupationPeriod === 'week') {
            handleInputChange('hours_of_occupation', value * 52);
        } else {
            handleInputChange('hours_of_occupation', value);
        }
    };

    const displayHoursOfOccupation = () => {
        if (formData.hours_of_occupation === null) return null;
        return occupationPeriod === 'week'
            ? formData.hours_of_occupation / 52
            : formData.hours_of_occupation;
    };

    const handleLocationChange = (value) => {
        handleInputChange('location', value);
        let currency = 'EUR';
        if (value === 'GREAT_BRITAIN' || value === 'NORTHERN_IRELAND') {
            currency = 'GBP';
        }
        handleInputChange('currency', currency);
    };

    const handleDateChange = (date, dateString) => {
        handleInputChange('data_period_start', moment(dateString).format('YYYY-MM-DD'));
    };

    const validateStep = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return formData.name && formData.floor_area && formData.type && formData.location;
            case 1:
                return formData.currency && formData.data_period_start;
            case 2:
                return formData.electricity.consumption && formData.electricity.cost &&
                    formData.thermal.fuel_type &&
                    (formData.thermal.fuel_type === 'ELECTRICITY' ||
                        (formData.thermal.consumption && formData.thermal.cost));
            case 3:
                return formData.roi.payback_period && formData.roi.inflation_rate;
            default:
                return false;
        }
    };

    const validateAllSteps = () => {
        stepContents.forEach((_, index) => {
            if (!validateStep(index)) {
                return false;
            }
        });

        return true
    };

    const getStepStatus = (stepIndex) => {
        if (stepIndex === current) return 'process';
        if (stepIndex > current) return 'wait';
        return validateStep(stepIndex) ? 'finish' : 'error';
    };

    const onFinish = () => {
        if (validateAllSteps()) {
            const modifiedFormData = {
                ...formData,
            }
            if (formData.thermal.fuel_type === 'ELECTRICITY') {
                delete modifiedFormData.thermal;
            }
            onSubmit(modifiedFormData);
        } else {
            console.error('Please complete all required fields');
        }
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const handleInputFocus = (event) => {
        event.target.select();
    };

    const stepContents = useMemo(() => [
        // Building Information
        <Row gutter={16} key="building-info">
            <Col span={24} xl={12}>
                <Form
                    layout="vertical"
                    initialValues={{
                        name: formData.name,
                        description: formData.description,
                        floor_area: formData.floor_area,
                        type: formData.type,
                        location: formData.location,
                        hours_of_occupation: formData.hours_of_occupation,
                        occupation_period: formData.occupation_period
                    }}
                >
                    <Form.Item name="name" label="Building Name" rules={[{ required: true, message: 'Please input the building name!' }]}>
                        <Input style={{ width: '300px' }} onChange={(e) => handleInputChange('name', e.target.value)} value={formData.name} />
                    </Form.Item>
                    <Form.Item name="description" label="Building Description">
                        <Input
                            style={{ width: '300px' }}
                            onChange={(e) => handleInputChange('description', e.target.value)}
                            value={formData.description}
                        />
                    </Form.Item>
                    <Form.Item required label="Floor Area and Building Type" rules={{ required: true, message: 'Floor area and building type are required' }}>
                        <Space>
                            <Form.Item
                                name="floor_area"
                                noStyle
                                rules={[{ required: true, message: 'Floor area is required' }]}
                            >
                                <InputNumber
                                    style={{ width: 145 }}
                                    min={0}
                                    placeholder="Floor area"
                                    onChange={(value) => handleInputChange('floor_area', value)}
                                    value={formData.floor_area}
                                    addonAfter="m²"
                                    onFocus={handleInputFocus}
                                />
                            </Form.Item>
                            <Form.Item
                                name="type"
                                noStyle
                                rules={[{ required: true, message: 'Building type is required' }]}
                            >
                                <Select
                                    style={{ width: 145 }}
                                    placeholder="Select building type"
                                    onChange={(value) => handleInputChange('type', value)}
                                    value={formData.type}
                                >
                                    <Option value="GENERAL_OFFICE">Office</Option>
                                    <Option value="GENERAL_RETAIL">Retail</Option>
                                </Select>
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item name="location" label="Building Location" rules={[{ required: true, message: 'Please select the building location!' }]}>
                        <Select onChange={handleLocationChange} style={{ width: '300px' }} value={formData.location}>
                            <Option value="GREAT_BRITAIN">Great Britain</Option>
                            <Option value="IRELAND">Ireland</Option>
                            <Option value="NORTHERN_IRELAND">Northern Ireland</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Hours of Occupation">
                        <Space>
                            <InputNumber
                                min={0}
                                max={occupationPeriod === 'year' ? 8760 : 168}
                                style={{ width: '115px' }}
                                onChange={handleHoursOfOccupationChange}
                                value={displayHoursOfOccupation()}
                                addonAfter='hrs'
                                onFocus={handleInputFocus}
                            />
                            <Radio.Group onChange={handleOccupationPeriodChange} value={occupationPeriod}>
                                <Radio.Button value="year">Per Year</Radio.Button>
                                <Radio.Button value="week">Per Week</Radio.Button>
                            </Radio.Group>
                        </Space>
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24} xl={12}>
                <div className={styles.explanation}>
                    <p><strong>Building Name:</strong> Enter the name of the building.</p>
                    <p><strong>Building Description:</strong> Provide a brief description of the building (optional).</p>
                    <p><strong>Floor Area (m²):</strong> Input the total floor area of the building in square meters.</p>
                    <p><strong>Building Type:</strong> Choose the type of building from the available options.</p>
                    <p><strong>Building Location:</strong> Select the location of the building. This will automatically set the currency (GBP for Great Britain and Northern Ireland, EUR for Ireland) if it hasn't been set yet.</p>
                    <p><strong>Hours of Occupation:</strong> Select whether to enter hours per year or per week, then input the total number of hours the building is occupied.</p>
                </div>
            </Col>
        </Row>,

        // Financial Information
        <Row gutter={16} key="financial-info">
            <Col span={24} xl={12}>
                <Form
                    layout="vertical"
                    initialValues={{
                        currency: formData.currency,
                        data_period_start: formData.data_period_start ? moment(formData.data_period_start, 'YYYY-MM-DD') : null
                    }}
                >
                    <Form.Item name="currency" label="Currency" rules={[{ required: true, message: 'Please select the currency!' }]}>
                        <Select style={{ width: '300px' }} onChange={(value) => handleInputChange('currency', value)} value={formData.currency}>
                            <Option value="EUR">EUR</Option>
                            <Option value="GBP">GBP</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="data_period_start" label="12 Month Period Start" rules={[{ required: true, message: 'Please select the start date!' }]}>
                        <DatePicker
                            picker="month"
                            format="YYYY-MM"
                            style={{ width: '300px' }}
                            onChange={handleDateChange}
                            value={formData.data_period_start ? moment(formData.data_period_start, 'YYYY-MM') : null}
                            disabledDate={(current) => {
                                const yearAgo = moment().subtract(13, 'months');
                                return current && current > yearAgo;
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24} xl={12}>
                <div className={styles.explanation}>
                    <p><strong>Currency:</strong> Select the currency for financial calculations. This is initially set based on the selected building location but can be changed if needed.</p>
                    <p><strong>12 Month Period Start:</strong> Choose the start date for the 12-month financial period. You can select any month up to 12 months ago. The last 11 months are not selectable to ensure a full 12-month period for analysis.</p>
                </div>
            </Col>
        </Row>,

        // Energy Consumption
        <Row gutter={16} key="energy-consumption">
            <Col span={24} xl={12}>
                <Form
                    layout="vertical"
                    initialValues={{
                        electricity_consumption: formData.electricity.consumption,
                        electricity_cost: formData.electricity.cost,
                        thermal_fuel_type: formData.thermal.fuel_type,
                        thermal_consumption: formData.thermal.consumption,
                        thermal_cost: formData.thermal.cost
                    }}
                >
                    <Form.Item name="electricity_consumption" label="Electricity Consumption" rules={[{ required: true, message: 'Please input the electricity consumption!' }]}>
                        <InputNumber min={0} style={{ width: '300px' }} onChange={(value) => handleInputChange('electricity.consumption', value)} value={formData.electricity.consumption} addonAfter='kWh' onFocus={handleInputFocus} />
                    </Form.Item>
                    <Form.Item name="electricity_cost" label="Electricity Cost" rules={[{ required: true, message: 'Please input the electricity cost!' }]}>
                        <InputNumber min={0} style={{ width: '300px' }} onChange={(value) => handleInputChange('electricity.cost', value)} value={formData.electricity.cost} addonAfter={formData.currency} onFocus={handleInputFocus} />
                    </Form.Item>
                    <Form.Item name="thermal_fuel_type" label="What's the main source of heating?" rules={[{ required: true, message: 'Please select the main thermal energy fuel_type!' }]}>
                        <Select onChange={handleHeatingFuelTypeChange} style={{ width: '300px' }} value={formData.thermal.fuel_type}>
                            <Option value="ELECTRICITY">Electricity</Option>
                            <Option value="NATURAL_GAS">Natural Gas</Option>
                            <Option value="FUEL_OIL">Fuel Oil</Option>
                        </Select>
                    </Form.Item>
                    {formData.thermal.fuel_type === 'ELECTRICITY' && (
                        <Form.Item>
                            <Text type="secondary">
                                If electricity is selected, the Electricity Consumption and Cost inputs above cover both - Heating and Regular Usage.
                            </Text>
                        </Form.Item>
                    )}
                    {formData.thermal.fuel_type && formData.thermal.fuel_type !== 'ELECTRICITY' && (
                        <>
                            <Form.Item name="thermal_consumption" label="Thermal Energy Consumption" rules={[{ required: true, message: 'Please input the thermal energy consumption!' }]}>
                                <InputNumber min={0} style={{ width: '300px' }} onChange={(value) => handleInputChange('thermal.consumption', value)} value={formData.thermal.consumption} addonAfter='kWh' onFocus={handleInputFocus} />
                            </Form.Item>
                            <Form.Item name="thermal_cost" label="Thermal Energy Cost" rules={[{ required: true, message: 'Please input the thermal energy cost!' }]}>
                                <InputNumber min={0} style={{ width: '300px' }} onChange={(value) => handleInputChange('thermal.cost', value)} value={formData.thermal.cost} addonAfter={formData.currency} onFocus={handleInputFocus} />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Col>
            <Col span={24} xl={12}>
                <div className={styles.explanation}>
                    <p><strong>Electricity Consumption (kWh):</strong> Enter the total electricity consumption in kilowatt-hours.</p>
                    <p><strong>Electricity Cost:</strong> Input the total cost of electricity consumption.</p>
                    <p><strong>Main Thermal Energy Fuel Type:</strong> Select the primary source of thermal energy for the building.</p>
                    {formData.thermal.fuel_type === 'ELECTRICITY' && (
                        <p><strong>Note:</strong> If electricity is used for thermal energy, the "electricity" input covers both.</p>
                    )}
                    {formData.thermal.fuel_type && formData.thermal.fuel_type !== 'ELECTRICITY' && (
                        <>
                            <p><strong>Thermal Energy Consumption (kWh):</strong> Enter the total thermal energy consumption in kilowatt-hours.</p>
                            <p><strong>Thermal Energy Cost:</strong> Input the total cost of thermal energy consumption.</p>
                        </>
                    )}
                </div>
            </Col>
        </Row>,

        // Return on Investment
        <Row gutter={16} key="roi">
            <Col span={24} xl={12}>
                <Form
                    layout="vertical"
                    initialValues={{
                        payback_period: formData.roi.payback_period,
                        inflation_rate: formData.roi.inflation_rate
                    }}
                >
                    <Form.Item
                        name="payback_period"
                        label="Required Payback Period"
                        rules={[
                            { required: true, message: 'Please input the required payback period!' },
                            { type: 'number', min: 1, max: 20, message: 'Payback period must be between 1 and 20 years' }
                        ]}
                    >
                        <InputNumber min={1} max={20} style={{ width: '300px' }} onChange={(value) => handleInputChange('roi.payback_period', value)} value={formData.roi.payback_period} addonAfter='years' onFocus={handleInputFocus} />
                    </Form.Item>
                    <Form.Item
                        name="inflation_rate"
                        label="Inflation Rate"
                        rules={[{ required: true, message: 'Please input the inflation rate!' }]}
                    >
                        <InputNumber
                            min={0}
                            max={100}
                            step={0.1}
                            style={{ width: '300px' }}
                            onChange={(value) => handleInputChange('roi.inflation_rate', value)}
                            value={formData.roi.inflation_rate}
                            addonAfter='%'
                            onFocus={handleInputFocus}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24} xl={12}>
                <div className={styles.explanation}>
                    <p><strong>Required Payback Period (years):</strong> Enter the desired payback period in years (between 1 and 20).</p>
                    <p><strong>Inflation Rate (%):</strong> Input the expected inflation rate as a percentage. You can use decimals with a step of 0.1%.</p>
                </div>
            </Col>
        </Row>
        // eslint-disable-next-line
    ], [formData]);

    return (
        <>
            <Title level={3} >Building Benchmark</Title>
            <Card style={{ background: '#FDFDFB', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
                <Steps current={current} onChange={setCurrent} size='small'>
                    <Step title="Building Information" status={getStepStatus(0)} />
                    <Step title="Financial Information" status={getStepStatus(1)} />
                    <Step title="Energy Consumption" status={getStepStatus(2)} />
                    <Step title="Return on Investment" status={getStepStatus(3)} />
                </Steps>
            </Card>
            <Card style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>
                <div className={styles.stepsContent}>
                    <Row justify="center" style={{ minHeight: '400px' }}>
                        <Col span={20}>
                            {stepContents[current]}
                        </Col>
                    </Row>
                </div>
                <div className={styles.stepsAction}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            {current > 0 && (
                                <Button onClick={() => prev()}>
                                    Previous
                                </Button>
                            )}
                        </Col>
                        <Col>
                            {current < stepContents.length - 1 && (
                                <Button type="primary" onClick={() => next()} >
                                    Next
                                </Button>
                            )}
                            {current === stepContents.length - 1 && (
                                <Button type="primary" onClick={onFinish} disabled={!validateAllSteps()}>
                                    Submit
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
            </Card>
        </>
    );
};

export default BenchmarkQuestionnaire;
