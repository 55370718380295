import React from 'react';
import Constants from '../../../../constants/index';
import './AddSensorPanel.scss';

const AddSensorPanel = (props) => {

  const { sensor, device_types, changeSensorValues, deleteNewSensor, deleteSensor, index, isUpdated } = props;

  const newSensor = !sensor.sensor_id;
  const validated = sensor.name && sensor.description && sensor.pac_eui && sensor.sensor_type_id;

  const getBackgroundColor = () => {
    let backgroundColor = 'white';
    if (!validated) backgroundColor = Constants.RED_TRANSPARENT;
    if (newSensor && validated) backgroundColor = Constants.GREEN_TRANSPARENT;
    if (newSensor && !validated) backgroundColor = Constants.YELLOW_TRANSPARENT;
    if (!newSensor && isUpdated && validated) backgroundColor = Constants.BLUE_TRANSPARENT;
    return backgroundColor;
  };

  const isDisabled = (sensorTypeId) => {
    if (!newSensor) {
      return true;
    }
    const matchingDevice = device_types.find(device => device.sensor_type_id === Number(sensorTypeId));
    return matchingDevice ? matchingDevice.protocol_type !== 'SIGFOX' : false;
  };

  const getSensorTable = () => {
    const device_types_names = device_types.map(device => device.name);
    const duplicate_device_type_names = device_types_names.filter((item, index) => device_types_names.indexOf(item) !== index);

    return (
      <tr style={{ background: getBackgroundColor() }}>
        <td className="valign-middle">
          <input
            required
            placeholder="Name"
            type="text"
            className="inline-single-input"
            value={sensor.name}
            onChange={changeSensorValues.bind(this, sensor, 'name', index)}
          />
        </td>
        <td className="valign-middle">
          <input
            placeholder="Description"
            type="text"
            className="inline-single-input"
            value={sensor.description}
            onChange={changeSensorValues.bind(this, sensor, 'description', index)}
          />
        </td>
        <td className="valign-middle">
          <input
            placeholder="Sigfox Id"
            type="text"
            className="inline-single-input"
            value={sensor.device_id}
            onChange={changeSensorValues.bind(this, sensor, 'device_id', index)}
            disabled={!newSensor}
          />
        </td>
        <td className="valign-middle">
          <input
            placeholder="Sigfox PAC"
            type="text"
            className="inline-single-input"
            value={sensor.pac_eui}
            onChange={changeSensorValues.bind(this, sensor, 'pac_eui', index)}
            disabled={!newSensor}
          />
        </td>
        <td className="valign-middle">
          <select
            onChange={(e) => {
              changeSensorValues(sensor, 'sensor_type_id', index, e);
              changeSensorValues(sensor, 'external_registration', index, { target: { value: isDisabled(e.target.value) } });
            }}
            value={sensor.sensor_type_id}
            className="inline-single-input"
            disabled={!newSensor}
          >
            <option disabled value={0}>Select Device Type</option>
            {device_types.map((device, key) => {
              const name = duplicate_device_type_names.includes(device.name)
                ? `${device.name} (${device.protocol_type})`
                : device.name;
              return <option key={key} value={device.sensor_type_id}>{name}</option>;
            })}
          </select>
        </td>
        <td className="valign-middle tx-center">
          <input
            type="checkbox"
            checked={!!sensor.external_registration}
            onChange={changeSensorValues.bind(this, sensor, 'external_registration', index)}
            disabled={isDisabled(sensor.sensor_type_id)}
          />
        </td>
        <td className="valign-middle tx-center savestatus-column">
          {sensor.sensor_id ? (
            <span
              onClick={deleteSensor.bind(this, sensor)}
              style={{ cursor: 'pointer' }}
              className="btn btn-icon btn-danger tx-18 pd-l-5 pd-r-5 pd-t-5"
            >
              <ion-icon name="trash" />
            </span>
          ) : (
            <span
              onClick={deleteNewSensor.bind(this, index)}
              style={{ cursor: 'pointer' }}
              className="btn btn-icon btn-warning tx-18 pd-l-5 pd-r-5 pd-t-5"
            >
              <ion-icon name="close" />
            </span>
          )}
        </td>
      </tr>
    );
  };

  return getSensorTable();
};

export default AddSensorPanel;
