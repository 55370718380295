import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Actions related to fetching and managing reports. This class interfaces with the API
 * to retrieve, create, update, and delete report data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const reportActions = new ReportActions();
 * reportActions.getSignificantEnergyUsersDownload(15, '2020-01-01', '2020-01-31');
 */
class ReportActions {
  /**
   * Fetches a download link for significant energy users for a specific organisation within a time range.
   * This method only supports organisations with IDs 15 and 10.
   *
   * @param {string} orgId - The unique identifier of the organisation.
   * @param {string} tsStart - The start timestamp of the data range.
   * @param {string} tsEnd - The end timestamp of the data range.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getSignificantEnergyUsersDownload(orgId, tsStart, tsEnd) {
    if (Number.parseInt(orgId) === 15 || Number.parseInt(orgId) === 10) {
      return GeneralUtils.get({
        url: `energy/significant-users/${tsStart}/${tsEnd}?meter_ids=543&meter_ids=542&meter_ids=646&meter_ids=640&meter_ids=657&meter_ids=641&meter_ids=648&meter_ids=544&meter_ids=578&meter_ids=647&meter_ids=564&meter_ids=562&meter_ids=561&meter_ids=656&meter_ids=635&meter_ids=636&meter_ids=788`,
        actionType: ActionTypes.SIGNIFICANT_ENERGY_USERS_REPORT_FETCHED,
      });
    } else {
      toast(
        `Your organisation not setup for this report, please contact OPNBuildings for info.`,
        { type: toast.TYPE.WARNING, autoClose: 3000, preventDuplicated: true }
      );
    }
  }

  /**
   * Fetches the available reports for a specific organisation from the API and dispatches an action to store this data.
   *
   * @param {string} orgId - The unique identifier of the organisation.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAvailableReports(orgId) {
    return GeneralUtils.get({
      url: `report/organisation/${orgId}/available`,
      actionType: ActionTypes.AVAILABLE_REPORTS_FETCHED,
    });
  }

  /**
   * Saves a report for a specific organisation by sending the provided report object to the API and dispatches an action to store this data.
   *
   * @param {string} orgId - The unique identifier of the organisation.
   * @param {Object} report - The report object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveReport(orgId, report) {
    return GeneralUtils.post({
      url: `report/organisation/${orgId}`,
      object: report,
      actionType: ActionTypes.REPORT_SAVED,
      successToast: "Report changes saved successfully",
      failToast: "Unable to save changes to report, please try again",
    });
  }

  /**
   * Fetches an energy benchmark report for a specific building within a specified time range from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @param {string} tsStart - The start timestamp of the data range.
   * @param {string} tsEnd - The end timestamp of the data range.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getEnergyBenchmarkReport(buildingId, tsStart, tsEnd) {
    let prevStart = moment.unix(tsStart).subtract(7, "days").unix();
    let prevEnd = moment.unix(tsEnd).subtract(7, "days").unix();
    return GeneralUtils.get({
      url: `energy/benchmark/building/${buildingId}/${tsStart}/${tsEnd}/${prevStart}/${prevEnd}`,
      actionType: ActionTypes.ENERGY_BENCHMARK_REPORT_FETCHED,
    });
  }

  /**
   * Fetches an energy benchmark report using a token from the API and dispatches an action to store this data.
   *
   * @param {string} token - The token used to fetch the report.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getEnergyBenchmarkReportWithToken(token) {
    return GeneralUtils.get({
      url: `energy/benchmark/building/${token}`,
      actionType: ActionTypes.ENERGY_BENCHMARK_REPORT_FETCHED,
    });
  }

  /**
   * Sends an energy report via email for a specific building within a specified time range from the API
   * and dispatches an action to store this data.
   *
   * @param {string} email - The email address to send the report to.
   * @param {string} buildingId - The unique identifier of the building.
   * @param {string} tsStart - The start timestamp of the data range.
   * @param {string} tsEnd - The end timestamp of the data range.
   * @returns {Promise} - A promise that resolves with the response of the send operation.
   */
  sendEnergyReportEmail(email, buildingId, tsStart, tsEnd) {
    let prevStart = moment.unix(tsStart).subtract(7, "days").unix();
    let prevEnd = moment.unix(tsEnd).subtract(7, "days").unix();
    return GeneralUtils.get({
      url: `energy/benchmark/building/${buildingId}/${tsStart}/${tsEnd}/${prevStart}/${prevEnd}/share/${email}`,
      actionType: ActionTypes.ENERGY_BENCHMARK_REPORT_SHARED,
    });
  }
}

export default new ReportActions();
