import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching and managing zone wellness and utilization data. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const zoneActions = new ZoneDetailsActions();
 * zoneActions.getZoneWellness('zone123', 1577836800, 1580515200, 'DAY'); // Unix epoch timestamps
 * zoneActions.getZoneUtilization('zone123', 1577836800, 1580515200, 'DAY'); // Unix epoch timestamps
 */
class ZoneDetailsActions {
  /**
   * Fetches wellness data for a specific zone from the API and dispatches an action to store this data.
   *
   * @param {string} zoneId - The unique identifier of the zone for which data is being requested.
   * @param {number} tsStart - The start timestamp of the period for which data is requested, in Unix epoch format.
   * @param {number} tsEnd - The end timestamp of the period for which data is requested, in Unix epoch format.
   * @param {string} bucketType - The granularity of the data aggregation, e.g., 'DAY', 'HOUR', or 'FIFTEEN_MIN'.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getZoneWellness(zoneId, tsStart, tsEnd, bucketType) {
    return GeneralUtils.get({
      url: `wellness/zone/${zoneId}/${bucketType}/${tsStart}/${tsEnd}`,
      actionType: ActionTypes.ZONE_DETAILS_WELLNESS_FETCHED,
      failToast: "Error fetching wellness data",
    });
  }

  /**
   * Fetches utilization data for a specific zone from the API and dispatches an action to store this data.
   *
   * @param {string} zoneId - The unique identifier of the zone for which data is being requested.
   * @param {number} tsStart - The start timestamp of the period for which data is requested, in Unix epoch format.
   * @param {number} tsEnd - The end timestamp of the period for which data is requested, in Unix epoch format.
   * @param {string} bucketType - The granularity of the data aggregation, e.g., 'DAY', 'HOUR', or 'FIFTEEN_MIN'.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getZoneUtilization(zoneId, tsStart, tsEnd, bucketType) {
    return GeneralUtils.get({
      url: `utilization/zone/${zoneId}/${bucketType}/${tsStart}/${tsEnd}`,
      actionType: ActionTypes.ZONE_DETAILS_UTILIZATION_FETCHED,
      failToast: "Error fetching utilization data",
    });
  }
}

export default new ZoneDetailsActions();
