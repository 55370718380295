import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { AdminMenuItem } from "../../components/AdminMenuItem";
import { Icon } from "../../components/Icon";

import BuildingActions from "../../actions/buildingActions";
import BuildingStore from "../../stores/buildingStore";
import UserStore from "../../stores/userStore";
import UrlManager from "../../utils/UrlManager";

class Leftpanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: BuildingStore.getBuildings(),
      estates: BuildingStore.getEstates(),
    };
    this._onBuildingChange = this._onBuildingChange.bind(this);
  }
  _onBuildingChange() {
    this.setState({
      buildings: BuildingStore.getBuildings(),
      estates: BuildingStore.getEstates(),
    });
  }

  componentWillMount() {
    BuildingStore.addChangeListener(this._onBuildingChange);
  }

  componentWillUnmount() {
    BuildingStore.removeChangeListener(this._onBuildingChange);
  }

  componentDidMount() {
    BuildingActions.getBuildings();
  }

  getAdminLinks() {
    const adminLinks = {
      label: "Manage Mapping",
      items: [
        { label: "Manage Assets", link: "/manage-assets", icon: null },
        { label: "Manage Meters", link: "/manage-meters", icon: null },
        //{ label: "Manage Doorways", link: "/manage-doorways", icon: null },
        { label: "Manage Sensors", link: "/manage-sensors", icon: null },
        //{ label: "Manage Counters", link: "/manage-counters", icon: null },
        { label: "Manage Appliances", link: "/manage-appliances", icon: null },
        { label: "Manage Mappings", link: "/manage-mapping", icon: null },
      ],
    };
    const automationLinks = {
      label: "Automation",
      items: [
        {
          label: "Inventory Processing",
          link: "/inventory-processing",
          icon: "filing",
        },
        {
          label: "Mapping and Validation",
          link: "/mapping-and-validation",
          icon: "map",
        },
        {
          label: "Assessment Execution",
          link: "/assessment-execution",
          icon: "ribbon",
        },
      ],
    };

    const debugItems = {
      label: "Debug",
      items: [
        {
          label: "Appliance Log Debug",
          link: "/appliance-log-debug",
          icon: "bug",
        },
        {
          label: "Mapped Point Debug",
          link: "/mapped-point-debug",
          icon: "bug",
        },
      ],
    };

    if (UserStore.isAdmin() || UserStore.isSuper()) {
      return (
        <React.Fragment>
          <label className="sidebar-label pd-x-10 mg-t-20 op-3">
            ADMIN MANAGEMENT
          </label>
          <ul className="br-sideleft-menu">
            <NavLink to="/manage-notifications" className="br-menu-link">
              <ion-icon name="mail" size="small" />
              <span className="menu-item-label">Report Center</span>
            </NavLink>
            <NavLink to="/manage-users" className="br-menu-link">
              <ion-icon name="contacts" size="small" />
              <span className="menu-item-label">Manage Users</span>
            </NavLink>
            <NavLink to="/manage-buildings" className="br-menu-link">
              <ion-icon name="globe" size="small" />
              <span className="menu-item-label">Manage Buildings</span>
            </NavLink>
            <NavLink to="/manage-zones" className="br-menu-link">
              <ion-icon name="grid" size="small" />
              <span className="menu-item-label">Manage Zones</span>
            </NavLink>
            <NavLink to="/manage-tenants" className="br-menu-link">
              <ion-icon name="people" size="small" />
              <span className="menu-item-label">Manage Tenants</span>
            </NavLink>
            {/* Switched to SuperRoute from AdminRoute due to OPN-638 */}
            {UserStore.isSuper() ? (
              <NavLink to="/sensor-listing" className="br-menu-link">
                <ion-icon name="wifi" size="small" />
                <span className="menu-item-label">Sensor Inventory</span>
              </NavLink>
            ) : null}
            <NavLink to="/change-request" className="br-menu-link">
              <ion-icon name="clipboard" size="small" />
              <span className="menu-item-label">Change Request</span>
            </NavLink>
            <NavLink to="/health" className="br-menu-link">
              <ion-icon name="pulse" size="small" />
              <span className="menu-item-label">System Health</span>
            </NavLink>
            {UserStore.isSuper() ? (
              <NavLink to="/change-organisation" className="br-menu-link">
                <ion-icon name="key" size="small" />
                <span className="menu-item-label">Organisation Change</span>
              </NavLink>
            ) : null}
            {UserStore.isSuper() ? (
              <NavLink to="/meter-data-upload" className="br-menu-link">
                <ion-icon name="cloud-upload" size="small" />
                <span className="menu-item-label">Meter Data Upload</span>
              </NavLink>
            ) : null}
            {UserStore.isSuper() ? (
              <AdminMenuItem
                category={adminLinks}
                pathName={window.location.pathname}
              />
            ) : null}
            {UserStore.isSuper() ? (
              <AdminMenuItem
                category={automationLinks}
                pathName={window.location.pathname}
              />
            ) : null}
            {UserStore.isSuper() ? (
              <AdminMenuItem
                category={debugItems}
                pathName={window.location.pathname}
              />
            ) : null}
          </ul>
        </React.Fragment>
      );
    }
  }


  getIndoorConditions() {
    const link = "/indoor-conditions";
    return (
      <li className="br-menu-item">
        <NavLink to={link} className="br-menu-link">
          <Icon name="IndoorConditions" />
          <span className="menu-item-label">Indoor Conditions</span>
        </NavLink>
      </li>
    );
  }

  getMetersExplorer() {
    const link = "/meters-explorer";
    const userHasAccessToEnergy = !!this.state.buildings.find((building) =>
      building.available_features.includes("energy")
    );

    if (userHasAccessToEnergy === false) {
      return null;
    }

    return (
      <li className="br-menu-item">
        <NavLink to={link} className="br-menu-link">
          <Icon name="MeterExplorer" />
          <span className="menu-item-label">Meters Explorer</span>
        </NavLink>
      </li>
    );
  }

  getDataExplorer() {
    const link = "/data-explorer";

    return (
      <li className="br-menu-item">
        <NavLink to={link} className="br-menu-link">
          <Icon name="DataExplorer" />
          <span className="menu-item-label">Data Explorer {this.getNewTag()}</span>
        </NavLink>
      </li>
    );
  }

  getAnalysis() {
    const link = "/analysis";

    const allowed_ids = [348, 342, 358];
    const user_id = UserStore.getProfile().user_id;
    const user_email = UserStore.getProfile().mail;
    const allowed_domains = ["@opnbuildings.com", "@remotehvac.com"];

    const isAllowedDomain = allowed_domains.some(domain => user_email.toLowerCase().endsWith(domain));

    if (!UserStore.isSuper() && !allowed_ids.includes(user_id) && !isAllowedDomain) return null;

    return (
      <li className="br-menu-item">
        <NavLink to={link} className="br-menu-link">
          <Icon name="Analysis" />
          <span className="menu-item-label">Analysis</span>
        </NavLink>
      </li>
    );
  }

  getNewTag() {
    return <span className="new-tag">New</span>;
  }

  getZonesOverview() {
    const link = "/zones";

    return (
      <li className="br-menu-item">
        <NavLink to={link} className="br-menu-link">
          <Icon name="Zone" />
          <span className="menu-item-label">Zones</span>
        </NavLink>
      </li>
    );
  }

  getMetersOverview() {
    const link = "/meters";

    return (
      <li className="br-menu-item">
        <NavLink to={link} className="br-menu-link">
          <Icon name="Gas" />
          <span className="menu-item-label">Meters</span>
        </NavLink>
      </li>
    );
  }

  getHvacOverview() {
    const link = "/hvac";

    return (
      <li className="br-menu-item">
        <NavLink to={link} className="br-menu-link">
          <Icon name="Hvac" />
          <span className="menu-item-label">HVAC</span>
        </NavLink>
      </li>
    );
  }

  getAlarmsOverview() {
    const link = "/alarms";

    return (
      <li className="br-menu-item">
        <NavLink to={link} className="br-menu-link">
          <Icon name="Alarm" />
          <span className="menu-item-label">Alarms</span>
        </NavLink>
      </li>
    );
  }

  getDashboards() {
    const link = "/dashboards";

    function CustomIsActive(match, location) {
      // Extract the pathname from the location object
      const { pathname } = location;

      // Define the paths you want to check for
      const pathsToCheck = ["/home", "/dashboards"];

      // Check if the current pathname matches any of the paths
      return pathsToCheck.some((path) => pathname.includes(path));
    }

    return (
      <li className="br-menu-item">
        <NavLink to={link} isActive={CustomIsActive} className="br-menu-link">
          <Icon name="Dashboard" />
          <span className="menu-item-label">Dashboards</span>
        </NavLink>
      </li>
    );
  }

  render() {
    let logo_img = (
      <NavLink to="/home">
        <img
          alt="OPNBuildings Logo"
          src="/img/OPNLogoWhite.png"
          style={{ width: "100%" }}
        />
      </NavLink>
    );

    let logo = <div className="br-logo">{logo_img}</div>;
    const is_dev =
      UrlManager.getAPIUrl() === "https://dev-apis.opnbuildings.com/webapis/";
    const is_local = UrlManager.getAPIUrl() === "http://localhost:8080/";
    const is_demo = window.location.pathname === "/buildings";

    if (is_demo) {
      logo = <div className="br-logo">{logo_img}</div>;
    } else if (is_dev) {
      logo = (
        <div className="br-logo">
          {logo_img}
          <span className="dev-banner">DEV</span>
        </div>
      );
    } else if (is_local) {
      logo = (
        <div className="br-logo">
          {logo_img}
          <span className="local-banner">LOCAL</span>
        </div>
      );
    }

    return (
      <div>
        {logo}
        <div className="br-corner-rounder">
          <div className="inner"></div>
        </div>
        <div
          className="br-sideleft sideleft-scrollbar"
          style={{ overflowY: "scroll", paddingBottom: '10px' }}
        >
          <label className="sidebar-label pd-x-10">&nbsp;</label>
          <ul className="br-sideleft-menu">
            {this.getDashboards()}
            {this.getIndoorConditions()}
            {this.getMetersExplorer()}
            {this.getDataExplorer()}
            {this.getAnalysis()}
            {this.getZonesOverview()}
            {this.getMetersOverview()}
            {this.getHvacOverview()}
            {this.getAlarmsOverview()}
          </ul>
          {this.getAdminLinks()}
        </div>
      </div>
    );
  }
}

export default Leftpanel;
