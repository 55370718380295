import React from "react";
import _ from "lodash";
import "./DeleteAlarmModal.scss";

import AlarmsOverviewActions from "../../../actions/alarmsOverviewActions";
import { PageTitle } from "../../../components/PageTitle";
import GeneralUtils from "../../../utils/GeneralUtils";

class DeleteAlarmModal extends React.Component {
  constructor(props) {
    super(props);

    this.deleteAlarm = this.deleteAlarm.bind(this);
  }

  getAlarmPreview() {
    const alarm = this.props.alarm;

    const name = _.get(alarm, "alarm.name");
    const target_period = _.get(alarm, "alarm.target_period");
    const target_factor = _.get(alarm, "alarm.target_factor");
    const target_amount = _.get(alarm, "alarm.target_amount");
    const target_type = _.get(alarm, "alarm.target_type");
    const target_category = _.get(alarm, "alarm.target_category");
    const required_triggers = _.get(alarm, "alarm.required_triggers");

    if (
      !name ||
      !target_period ||
      !target_factor ||
      !target_amount ||
      !target_type ||
      !target_category ||
      !required_triggers
    ) {
      return "Preview unavailable...";
    }

    return GeneralUtils.getAlarmExplainerText({
      name: name,
      target_period: target_period,
      target_factor: target_factor,
      target_amount: target_amount,
      target_type: target_type,
      target_category: target_category,
      required_triggers: required_triggers,
    });
  }

  deleteAlarm() {
    const id = _.get(this.props, "alarm.alarm.id");
    if (id) AlarmsOverviewActions.deleteAlarm(this.props.alarm.alarm.id);
  }

  render() {
    const alarmPreview = this.getAlarmPreview();

    return (
      <div id="DeleteAlarmModal">
        <div className="row mg-b-20">
          <div className="col-12">
            <PageTitle title={`Delete Alarm`} />
          </div>
        </div>
        <hr></hr>
        {/* FORM */}
        <div className="row mg-b-20">
          <div className="col-12">
            <div className="label">
              {`Are you sure you want to delete the following alarm - ${this.props.alarm.alarm.name} @ ${this.props.alarm.building.name}?`}
            </div>
            <div className="preview">
              <div className="placeholder">{alarmPreview}</div>
            </div>
          </div>
        </div>
        {/* BUTTONS */}
        <div className="row">
          <div className="col">
            <div className="button-bottom-panel">
              <div className="buttons">
                <button
                  className={`btn btn-info view-button`}
                  onClick={this.deleteAlarm}
                >
                  Delete Alarm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteAlarmModal;
