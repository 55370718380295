import React, { useState, useEffect, useCallback } from 'react';
import styles from './DataExplorer.module.scss';
import DataExplorerActions from '../../actions/dataExplorerActions';
import UserStore from '../../stores/userStore';
import useUnsavedChanges from '../../utils/useUnsavedChanges';

import ChartUtils from '../../utils/ChartUtils';
import moment from 'moment';
import { v4 } from "uuid";
import _ from 'lodash';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import Constants from '../../constants';

import { Button, Input, Typography } from 'antd';
import { DataExplorerCard } from './DataExplorerCard';
import { LogoSpinner } from '../../components/LogoSpinner';
import { ShareButton } from '../../components/ShareButton';
import { DocumentTitle } from '../../components/DocumentTitle';
import { Icon } from '../../components/Icon';

const { Text, Link } = Typography;

const EXCLUDED_PROPERTY_IDS = [538, 539, 540, 551, 552, 553]
const INITIAL_CHART_CONFIG = {
  name: "New Chart",
  chart_id: 'initialchart',
  bucket_type: Constants.BUCKET_HOUR,
  timeframes: [
    {
      date_type: 'WEEK',
      absolute: {
        start: moment().startOf('isoWeek').format(Constants.DATA_EXPLORER_DATE_FORMAT),
        end: moment().startOf('isoWeek').add(6, 'days').format(Constants.DATA_EXPLORER_DATE_FORMAT)
      },
    }
  ],
  properties: []
}

function DataExplorer() {
  const { token } = useParams();
  const [isLoggedIn] = useState(UserStore.loggedIn());

  const [properties, setProperties] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [config, setConfig] = useState(null); // fetched config

  const [isEditing, setIsEditing] = useState(false); // State to toggle editing of config name
  const [initialConfigName, setInitialConfigName] = useState(''); // State to store the initial config name
  const [configName, setConfigName] = useState('New Collection'); // State for config name
  const [configCharts, setConfigCharts] = useState([INITIAL_CHART_CONFIG]); // State for config name
  const [chartCounter, setChartCounter] = useState(0); // State for counting charts for naming

  const [menuOpen, setMenuOpen] = useState(true);

  // Use the unsaved changes warning hook
  const { routerPrompt, onDirty, onPristine } = useUnsavedChanges();

  useEffect(() => {
    if (isLoggedIn) fetchConfigs();
  }, [isLoggedIn]);

  useEffect(() => {
    const fetchConfigViaToken = async () => {
      try {
        const config = await DataExplorerActions.getConfigByToken(token);
        injectConfig(config);
      } catch (error) {
        console.error('Failed to fetch configuration via token:', error);
      }
    }

    if (token) {
      fetchConfigViaToken()
    }
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('resize', ChartUtils.resizeCharts);
      ChartUtils.resizeCharts()

      return () => {
        window.removeEventListener('resize', ChartUtils.resizeCharts);
      }
    }, 100);
  }, [menuOpen]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        let data = await DataExplorerActions.getProperties();
        data = data.filter(item => !EXCLUDED_PROPERTY_IDS.includes(item.property.property_id));
        setProperties(data);
      } catch (error) {
        console.error('Failed to fetch properties:', error);
      }
    };

    if (properties.length === 0) fetchProperties();
  }, [properties]);

  const hasChanged = useCallback(() => {
    // Check if the current configuration has changed
    if (!config) {

      return (configName !== 'New Collection' || _.get(configCharts, '[0].properties.length') !== 0) || configCharts.some(chart => !chart.name.includes('New Chart'));
    } else {
      const matching_config = configurations.find(item => item.config_id === config.config_id);
      if (!matching_config) return false;

      const nameChanged = configName !== _.get(matching_config, 'name');
      const chartsChanged = !_.isEqual(
        configCharts.map(e => ({ ...e, chart_id: null, properties: _.sortBy(e.properties, 'property_id') })),
        matching_config.charts.map(e => ({ ...e, chart_id: null, properties: _.sortBy(e.properties, 'property_id') }))
      );

      return (nameChanged || chartsChanged)
    }
  }, [config, configCharts, configName, configurations]);

  useEffect(() => {
    // To trigger unsaved changes warning

    if (hasChanged()) {
      onDirty();
    } else {
      onPristine();
    }

  }, [config, configCharts, configName, onDirty, onPristine, configurations, hasChanged]);


  const handleKeyPressForInput = (event) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
    } else if (event.key === 'Escape') {
      setConfigName(initialConfigName);
      setIsEditing(false);
    }
  };

  const fetchConfigs = async () => {
    try {
      const configs = await DataExplorerActions.getConfigs();
      setConfigurations(configs);
    } catch (error) {
      console.error('Failed to fetch configurations:', error);
    }
  };

  const addChart = () => {
    if (canAddNewCharts()) {
      const newChart = {
        ...INITIAL_CHART_CONFIG,
        name: "New Chart " + (chartCounter + 1),
        chart_id: v4(),
      };
      setConfigCharts(prevCharts => [newChart, ...prevCharts]);
      setChartCounter(prev => prev + 1);
    } else {
      toast('Maximum limit of 20 charts reached.', {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });
    }
  };

  const duplicateChart = (index) => {
    if (canAddNewCharts()) {
      const newChart = {
        ...configCharts[index],
        name: configCharts[index].name + ' Copy',
        chart_id: v4(),
      };
      setConfigCharts(prevCharts => [newChart, ...prevCharts]);
    } else {
      toast('Maximum limit of 20 charts reached.', {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });
    }
  }

  const canAddNewCharts = () => {
    if (configCharts.length >= 20) {
      return false;
    }
    return true;
  }

  const updateChartConfig = (index, newConfig) => {
    setConfigCharts(prevCharts => {
      const updatedCharts = [...prevCharts];
      updatedCharts[index] = { ...updatedCharts[index], ...newConfig };
      return updatedCharts;
    });
  };

  const cleanUp = () => {
    if (!hasChanged()) {
      setConfig(null);
      setConfigName('New Collection');
      setConfigCharts([INITIAL_CHART_CONFIG]);
      setChartCounter(0);
    } else if (hasChanged() && window.confirm('Are you sure you want to start a new collection? Unsaved changes will be lost.')) {
      setConfig(null);
      setConfigName('New Collection');
      setConfigCharts([INITIAL_CHART_CONFIG]);
      setChartCounter(0);
    }
  }

  const removeChart = (id) => {
    setConfigCharts(configCharts.filter(chart => chart.chart_id !== id));
  };

  const getConfigHeader = () => {

    if (token) {
      return <div>
        <div className={styles.configHeader}>
          <div className={styles.configLeft}>
            <div className={styles.configName} onClick={() => { setIsEditing(true); setInitialConfigName(configName); }}>
              <span style={{ fontSize: '20px' }}>{configName}</span>
            </div>
          </div>
          <div className={styles.configRight}>
            <div>
            </div>
            <ShareButton
              condition
              object={{
                type: "EXPLORER",
                config_id: _.get(config, 'config_id')
              }}
              generateToken={DataExplorerActions.generateToken}
              urlPath="data-explorer/{newToken}"
            />
          </div>
        </div>
        <div>
          {isLoggedIn && (<div style={{ marginBottom: '7.5px', marginTop: '-7.5px' }}>
            <Text type='secondary'>
              This is a shared collection, to adjust parameters or create your own, {' '}
              <Link href="/data-explorer">click here</Link>.
            </Text>
          </div>)}
        </div>
      </div>

    }

    return <div className={styles.configHeader}>
      <div className={styles.configLeft}>
        {isEditing ? (
          <div className={styles.configName}>
            <Input
              value={configName}
              onChange={e => setConfigName(e.target.value)}
              style={{ width: 'auto' }}
              onBlur={() => setIsEditing(false)}
              autoFocus
              allowClear
              onKeyDown={handleKeyPressForInput}
            />
          </div>
        ) : (
          <div className={styles.configName} onClick={() => { setIsEditing(true); setInitialConfigName(configName); }}>
            <span style={{ fontSize: '20px' }}>{configName}</span>
            <Button
              icon={<Icon name="Edit" color={'#5e656e'} size={15} />}
              size="small"
              style={{ marginLeft: '20px', border: 'none', outline: 'none' }}
            />
          </div>
        )}

      </div>
      <div className={styles.configRight}>
        <div className={styles.addChart}>
          <Button
            className="button green"
            icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
            size="small"
            onClick={addChart}
            disabled={!canAddNewCharts()}
          >Add Chart</Button>
        </div>
        <ShareButton
          condition={_.get(config, 'config_id')}
          object={{
            type: "EXPLORER",
            config_id: _.get(config, 'config_id')
          }}
          generateToken={DataExplorerActions.generateToken}
          urlPath="data-explorer/{newToken}"
        />
      </div>
    </div>
  }

  const getContextualMenu = () => {

    if (token) return null;


    const isSelected = (configuration = false) => {
      return configuration.config_id === _.get(config, 'config_id');
    }

    const isButtonDisabled = () => {
      return configName === 'New Collection' && _.get(configCharts, '[0].properties.length') === 0;
    }

    const existsInCollections = () => {
      return configurations.some(item => item.config_id === _.get(config, 'config_id'))
    }

    return (
      <div className={`${styles.contextualMenu} ${menuOpen ? styles.open : styles.closed}`}>
        <div className={styles.label}>Collections</div>
        <div className={styles.configButtons}>
          <Button
            className="button light-blue"
            icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
            size="small"
            onClick={cleanUp}
            disabled={!existsInCollections() || isButtonDisabled()}
          >New</Button>
          <Button
            className="button green"
            icon={<Icon name="Save" color={'#fff'} size={18} />}
            size="small"
            onClick={existsInCollections() ? updateConfiguration : saveConfiguration}
            disabled={isButtonDisabled()}
          >Save</Button>
        </div>
        <div className={styles.configList}>
          {configurations.map((config) => (
            <div key={config.config_id} className={`${styles.configItem} ${isSelected(config) ? styles.highlighted : ''}`} onClick={() => injectConfig(config, true)}>
              <div className={styles.configName}>{config.name}</div>
              <div className={styles.configDelete}>
                <Button
                  icon={<Icon name="Delete" color={'#fff'} size={20} />}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteConfiguration(config.config_id);
                  }}
                />
              </div>

            </div>
          ))}
        </div>
        {!token && <Button
          className={`${styles.menuButton} ${menuOpen ? styles.open : styles.close}`}
          onClick={() => setMenuOpen(!menuOpen)}
          icon={<Icon name={menuOpen ? 'ArrowLeft' : 'ArrowRight'} size={30} />}
          size="small"
          style={{ border: 'none', outline: 'none' }}
        />}
      </div >
    );
  }

  const injectConfig = (config, checkForChanges = false) => {
    if (checkForChanges && hasChanged() && window.confirm('Are you sure you want to load this configuration? Unsaved changes will be lost.')) {
      setConfig(config);
      setConfigName(config.name);
      setConfigCharts(config.charts);
      setChartCounter(config.charts.length);
    } else if (!checkForChanges || !hasChanged()) {
      setConfig(config);
      setConfigName(config.name);
      setConfigCharts(config.charts);
      setChartCounter(config.charts.length);
    }
  };

  const generateUniqueName = (baseName) => {
    let newName = baseName;
    let counter = 1;
    const nameExists = (name) => configurations.some(config => config.name === name);

    while (nameExists(newName)) {
      newName = `${baseName} (${counter})`;
      counter++;
    }
    return newName;
  };

  const saveConfiguration = async () => {
    try {
      const uniqueName = generateUniqueName(configName);
      const configObject = {
        name: uniqueName,
        charts: configCharts.map((c, i) => {
          return {
            ...c,
            position: i + 1,
            chart_id: null
          }
        })
      }
      await DataExplorerActions.saveConfig(configObject).then(config => injectConfig(config)).then(() => fetchConfigs());
    } catch (error) {
      console.error('Failed to save configuration:', error);
    }
  };

  const updateConfiguration = async () => {
    let config_id = _.get(config, 'config_id');
    if (!config_id) {
      config_id = configurations.find(item => item.name === configName).config_id;
    }

    try {
      const configObject = {
        name: configName,
        charts: configCharts.map((c, i) => {
          return {
            ...c,
            position: i + 1,
            chart_id: null
          }
        })
      }
      await DataExplorerActions.updateConfig(configObject, config_id).then(config => injectConfig(config)).then(() => fetchConfigs());
    } catch (error) {
      console.error('Failed to update configuration:', error);
    }
  }

  const deleteConfiguration = (configId) => {
    try {
      if (window.confirm('Are you sure you want to delete this configuration?')) {
        DataExplorerActions.deleteConfig(configId).then(() => fetchConfigs());;
        if (config && config.config_id === configId) cleanUp();
      }
    } catch (error) {
      console.error('Failed to delete configuration:', error);
    }
  }

  const findCorrespondingObjects = (selectedProperties, properties) => {
    if (selectedProperties.length === 0 && properties.length === 0) return [];

    if (selectedProperties.length && token && properties.length === 0) {
      return selectedProperties.map(item => {
        return {
          entity: {
            entity_type_id: item.entity_type_id,
          },
          property: {
            property_id: item.property_id,
          }
        }
      })
    }

    const props = selectedProperties.map(id => {
      return properties.find(item =>
        item.entity.entity_type_id === id.entity_type_id &&
        item.property.property_id === id.property_id
      );
    }).filter(item => item !== undefined);

    return props;
  };

  const moveChartUp = (index) => {
    if (index > 0) {
      setConfigCharts(prevCharts => {
        const updatedCharts = [...prevCharts];
        [updatedCharts[index - 1], updatedCharts[index]] = [updatedCharts[index], updatedCharts[index - 1]];
        return updatedCharts;
      });
    }
  };

  const moveChartDown = (index) => {
    if (index < configCharts.length - 1) {
      setConfigCharts(prevCharts => {
        const updatedCharts = [...prevCharts];
        [updatedCharts[index + 1], updatedCharts[index]] = [updatedCharts[index], updatedCharts[index + 1]];
        return updatedCharts;
      });
    }
  };

  const getChartPosition = (arr, index) => {
    if (arr.length === 1) return 'only';
    if (index === 0) return 'first';
    if (index === arr.length - 1) return 'last';
    return 'middle';
  }

  return (
    <div className={`${styles.dataExplorer} ${isLoggedIn ? '' : styles.outOfLogin} br-mainpanel br-profile-page floorplan-background`}>
      <DocumentTitle title="Data Explorer" />
      <LogoSpinner loading={false} />
      {routerPrompt}
      {getContextualMenu()}
      <div className={`${styles.chartsArea} ${menuOpen && !token ? '' : styles.menuClosed}`}>
        <div className={styles.chartsContainer}>
          {getConfigHeader()}
          {configCharts.map((chart, index, arr) => {
            return <DataExplorerCard
              key={chart.chart_id}
              properties={properties}
              gatherConfig={(newConfig) => {
                updateChartConfig(index, newConfig);
              }}
              config={{
                ...chart,
                properties: findCorrespondingObjects(chart.properties, properties)
              }}
              removeChart={() => removeChart(chart.chart_id)}
              duplicateChart={() => duplicateChart(index)}
              isShared={!!token}
              moveChartUp={() => moveChartUp(index)}
              moveChartDown={() => moveChartDown(index)}
              position={getChartPosition(arr, index)}
              index={index}
            />
          })}
        </div>
      </div>
    </div>
  );
}

export default DataExplorer;
