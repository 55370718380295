import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";

const CHANGE = "ZONE_CHANGE";
const MAPPING_CHANGE = "MAPPING_CHANGE";
const ZONE_OPERATION = "ZONE_OPERATION";

let _zones = [];

class ZonesStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.ZONES_FETCHED:
        this._storeZones(action.payload);
        break;
      case ActionTypes.ZONE_SAVED:
        this._saveZone(action.payload);
        break;
      case ActionTypes.ZONE_UPDATED:
        this._updateZone(action.payload);
        break;
      case ActionTypes.ZONE_DELETED:
        this._deleteZone(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }
  addZoneOperationListener(callback) {
    this.on(ZONE_OPERATION, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }
  removeZoneOperationListener(callback) {
    this.removeListener(ZONE_OPERATION, callback);
  }

  addMappingChangeListener(callback) {
    this.on(MAPPING_CHANGE, callback);
  }

  removeMappingChangeListener(callback) {
    this.removeListener(MAPPING_CHANGE, callback);
  }

  clear() {
    _zones = [];
  }

  _storeZones(json) {
    _zones = json;
    this.emit(CHANGE);
  }

  _saveZone() {
    this.emit(ZONE_OPERATION);
  }

  _updateZone(json) {
    if (json.zone_id) {
      for (let i = 0; i < _zones.length; i++) {
        if (_zones[i].zone_id === json.zone_id) {
          _zones[i] = json;
          break;
        }
      }
    }
    this.emit(ZONE_OPERATION);
  }
  _deleteZone(json) {
    this.emit(ZONE_OPERATION);
  }

  // Getters

  getZones() {
    return _zones;
  }
}

export default new ZonesStore();
