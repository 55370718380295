import React, { Component } from 'react';
import './AssessmentExecution.scss';
import moment from "moment";
import Constants from '../../constants';

// components/utils
import GeneralUtils from '../../utils/GeneralUtils';
import { DocumentTitle } from '../../components/DocumentTitle';
import { Spinner } from '../../components/Spinner';
import { SearchSelect } from '../../components/SearchSelect';
import { DateRangeNavigation } from '../../components/DateRangeNavigation';

//stores/action
import AutomatedAssessmentsActions from '../../actions/automatedAssessmentsActions';
import AutomatedAssessmentsStore from '../../stores/automatedAssessmentsStore';
import GeneralStore from '../../stores/generalStore';
import OrganisationActions from '../../actions/organisationActions';
import OrganisationStore from '../../stores/organisationStore';
import UserStore from '../../stores/userStore';

class AssessmentExecution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      available_overrides: Constants.ASSESSMENT_OVERRIDES,
      // By Default Enable all categories
      overrides: Constants.ASSESSMENT_OVERRIDES.filter(el => el.category === 'category').map(el => el.code),
      selected_building: null,
      email_recipients: [],
      email_string: '',
      current_heating_kwh: 0,
      current_hot_water_kwh: 0,
      current_ventilation_kwh: 0,
      current_cooling_kwh: 0,
      timeframe_start: GeneralStore.getStartDate(),
      timeframe_end: GeneralStore.getEndDate(),
      timeframe_changed: false,
      buildings: OrganisationStore.getBuildings(),
      description: '',
      loading: false,
    }

    this.setEmails = this.setEmails.bind(this)
    this.setBuilding = this.setBuilding.bind(this)
    this.setDate = this.setDate.bind(this)
    this.setCurrentConsumptionAmount = this.setCurrentConsumptionAmount.bind(this);
    this._onOrganisationChange = this._onOrganisationChange.bind(this)
    this._onAssessmentStart = this._onAssessmentStart.bind(this)
  }

  componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
    AutomatedAssessmentsStore.addAssessmentStartedListener(this._onAssessmentStart)
    AutomatedAssessmentsStore.addAssessmentStartFailedListener(this._onAssessmentStartFail)
  }
  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
  }
  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
    AutomatedAssessmentsStore.removeAssessmentStartedListener(this._onAssessmentStart)
    AutomatedAssessmentsStore.removeAssessmentStartFailedListener(this._onAssessmentStartFail)
  }

  _onAssessmentStart() {
    this.setState({
      selected_building: null,
      email_recipients: [],
      email_string: '',
      timeframe_start: GeneralStore.getStartDate(),
      timeframe_end: GeneralStore.getEndDate(),
      timeframe_changed: false,
      buildings: OrganisationStore.getBuildings(),
      overrides: this.state.available_overrides.filter(el => el.category === 'category').map(el => el.code),
      description: '',
      loading: false,
    })
  }
  _onAssessmentStartFail() {
    this.setState({
      loading: false,
    })
  }

  _onOrganisationChange() {
    this.setState({
      buildings: OrganisationStore.getBuildings(),
    })
  }

  setCurrentConsumptionAmount(value, target) {
    if (target === 'heating') {
      this.setState({ current_heating_kwh: value })
    } else if (target === 'cooling') {
      this.setState({ current_cooling_kwh: value })
    } else if (target === 'ventilation') {
      this.setState({ current_ventilation_kwh: value })
    } else if (target === 'hot_water') {
      this.setState({ current_hot_water_kwh: value })
    }
  }

  setEmails(string) {
    let emails = string
      .split(',')
      .map(el => el.trim())
      .filter(el => el)
      .filter(el => GeneralUtils.emailIsValid(el));

    this.setState({
      email_recipients: emails,
      email_string: string
    })
  }

  setDate(start, end) {
    this.setState({
      timeframe_start: start,
      timeframe_end: end,
      timeframe_changed: true,
    })
  }

  getBuildings() {
    let options = this.state.buildings.map(building => {
      return { name: building.name, value: building.building_id }
    })
    return options;
  }

  setBuilding(building_object) {
    if (building_object.annual_gas_consumption && building_object.annual_elec_consumption) {
      let heating_amount = building_object.annual_gas_consumption * 0.9;
      let hot_water_amount = building_object.annual_gas_consumption * 0.1;
      let cooling_amount = building_object.annual_elec_consumption * 0.2;
      let ventilation_amount = building_object.annual_elec_consumption * 0.2;
      this.setState({ selected_building: building_object, current_cooling_kwh: cooling_amount, current_heating_kwh: heating_amount, current_hot_water_kwh: hot_water_amount, current_ventilation_kwh: ventilation_amount })
    } else {
      this.setState({ selected_building: building_object })
    }
  }

  runExecution(validation) {
    if (validation === false) return;
    if (validation === true) {
      const id = this.state.selected_building.value;
      const start = this.state.timeframe_start;
      const end = this.state.timeframe_end;
      const emails = this.state.email_recipients;
      const overrides = this.state.overrides;
      AutomatedAssessmentsActions.executeAutomaticAssessment(id, start, end, emails, overrides, this.state.current_heating_kwh, this.state.current_cooling_kwh, this.state.current_ventilation_kwh, this.state.current_hot_water_kwh)
      this.setState({ loading: true })
    }
  }

  getPreview() {
    const formattedStartDate = moment.unix(this.state.timeframe_start).format('DD MMM, YYYY');
    const formattedEndDate = moment.unix(this.state.timeframe_end).format('DD MMM, YYYY');

    const categories = this.state.available_overrides.filter(el => (el.category === 'category' && this.state.overrides.includes(el.code)));
    const overrides = this.state.available_overrides.filter(el => (el.category !== 'category' && this.state.overrides.includes(el.code)));

    return <div className="preview">
      {/* Building */}
      {this.state.selected_building && <div className='line'><span className="label">Building: </span>{this.state.selected_building.name}</div>}
      {/* Timeframe */}
      {(this.state.timeframe_start && this.state.timeframe_end && this.state.timeframe_changed) && <div><span className="label">Timeframe: </span> {formattedStartDate} - {formattedEndDate}</div>}
      {/* Emails */}
      {this.state.email_recipients.length > 0 && <div className='line'><span className="label">Email Recipients:</span></div>}
      {this.state.email_recipients.map((email, index) => <div className="list-item" key={email + index}>- {email}</div>)}
      {/* Categories */}
      {(categories.length > 0) && <div className='line'><span className="label">Categories:</span></div>}
      {categories.map((cat, index) => <div className="list-item" key={cat.name + index}>- {cat.name}</div>)}
      {/*Spend Amounts */}
      {(this.state.current_heating_kwh > 0 || this.state.current_cooling_kwh > 0 || this.state.current_ventilation_kwh > 0 || this.state.current_hot_water_kwh > 0) && <div className='line'><span className="label">Energy Consumption Breakdown:</span></div>}
      {this.state.current_heating_kwh > 0 && <div className='list-item'> - Heating System : {GeneralUtils.getFormattedNumberWithUnit(this.state.current_heating_kwh, ' kWh')}</div>}
      {this.state.current_cooling_kwh > 0 && <div className='list-item'> -  Cooling System : {GeneralUtils.getFormattedNumberWithUnit(this.state.current_cooling_kwh, ' kWh')}</div>}
      {this.state.current_ventilation_kwh > 0 && <div className='list-item'> - Ventilation System : {GeneralUtils.getFormattedNumberWithUnit(this.state.current_ventilation_kwh, ' kWh')}</div>}
      {this.state.current_hot_water_kwh > 0 && <div className='list-item'> -  Hot Water System : {GeneralUtils.getFormattedNumberWithUnit(this.state.current_hot_water_kwh, ' kWh')}</div>}

      {/* Overrides */}
      {(overrides.length > 0) && <div className='line'><span className="label">Overrides:</span></div>}
      {overrides.map((ovr, index) => <div className="list-item" key={ovr.name + index}>- {ovr.name}</div>)}
    </div>
  }

  changeOverride(code, isCategory = false) {
    if (this.state.overrides.includes(code)) {
      let modified_overrides = this.state.overrides.filter(el => el !== code);
      if (isCategory) {
        const dependant_overrides = this.state.available_overrides.filter(el => el.category.includes(code));
        dependant_overrides.forEach(ovr => {
          modified_overrides = modified_overrides.filter(el => el !== ovr.code);
        })
      }
      this.setState({ overrides: modified_overrides })
    } else {
      const modified_overrides = [...this.state.overrides, code]
      this.setState({ overrides: modified_overrides })
    }
  }

  getCategories() {
    const categories = this.state.available_overrides.filter(el => el.category === 'category');

    return <div className="col-4 categories">
      <div className="label">Categories: </div>
      <div className="item-wrapper">
        {categories.map(cat => {
          return <div key={cat.code} className="checkbox-row" onMouseEnter={e => this.setState({ description: cat.description })} onMouseLeave={e => this.setState({ description: '' })}>
            <label><input
              type="checkbox"
              checked={this.state.overrides.includes(cat.code)}
              onChange={e => this.changeOverride(cat.code, true)}
              value=""
            />{cat.name}</label>
          </div>
        })}
      </div>
    </div>
  }
  getOverrides() {

    const getOverridesList = () => {
      let overridesList = this.state.available_overrides
      //filter out category overrides
      .filter(el => el.category !== 'category')
      //check if existing override codes overlap with overrides category
      .filter(el => this.state.overrides.some(item => el.category.includes(item)))
      return overridesList
    }

    return <div className="col-4 overrides">
      <div className="label">Overrides: </div>
      <div className="item-wrapper">
        {getOverridesList().map(ovr => {
          return <div key={ovr.code} className="checkbox-row" onMouseEnter={e => this.setState({ description: ovr.description })} onMouseLeave={e => this.setState({ description: '' })}>
            <label>
              <input
                type="checkbox"
                checked={this.state.overrides.includes(ovr.code)}
                onChange={e => this.changeOverride(ovr.code)}
              />
              {ovr.name}
            </label>
          </div>
        })}
      </div>
    </div>
  }
  getDescription() {
    return <div className="col-4 description">
      <div className="label">Description: </div>
      <div>{this.state.description}</div>
    </div>
  }


  render() {

    let selectedBuilding = null;
    if (this.state.selected_building) {
      selectedBuilding = this.state.buildings.filter(building => building.building_id === this.state.selected_building.value)[0];
    }

    let runExecutionButtonEnabled = (
      this.state.selected_building
      && this.state.timeframe_end
      && this.state.timeframe_start
      && this.state.email_recipients.length > 0
      && this.state.overrides.length > 0);

    if (this.state.overrides.includes("ENB_HEA") && this.state.current_heating_kwh < 1) {
      runExecutionButtonEnabled = false;
    }
    if (this.state.overrides.includes("ENB_COO") && this.state.current_cooling_kwh < 1) {
      runExecutionButtonEnabled = false;
    }
    if (this.state.overrides.includes("ENB_HWT") && this.state.current_hot_water_kwh < 1) {
      runExecutionButtonEnabled = false;
    }
    if (this.state.overrides.includes("ENB_VEN") && this.state.current_ventilation_kwh < 1) {
      runExecutionButtonEnabled = false;
    }

    const isLoading = this.state.loading;

    return (<div className="br-mainpanel br-profile-page pd-15" id={'AssessmentExecution'}>
      <DocumentTitle title='Automatic Assessment Execution' />
      <div className="br-pagetitle mg-b-0">
        <div>
          <h4>Automatic Assessment Execution</h4>
          <p className="mg-b-0">
            Execute the Energy Assessment for the building and send it out as a report.
          </p>
        </div>
      </div>
      <div>
        <div className="col-12 ">
          <div className="card pd-30 shadow-base bd-0 mg-t-20">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label">Select target building: <span className="tx-danger">*</span></label>
                      <SearchSelect limit={9999} extraHeight={true} options={this.getBuildings()} placeholder={'Select the building'} defaultValue={selectedBuilding} actionOnSelectedOption={this.setBuilding} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label">Select timeframe: <span className="tx-danger">*</span></label>
                      <DateRangeNavigation dateChangeCallback={(start, end) => this.setDate(start, end)} ts_start={this.state.timeframe_start} ts_end={this.state.timeframe_end} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-control-label">Select Email Recipients: </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter email recipients (separate them with comma)"
                        value={this.state.email_string}
                        onChange={e => this.setEmails(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label">Annual Heating kWh: </label>
                      <input
                        type="number"
                        disabled={this.state.overrides.includes("ENB_HEA") ? false: true}
                        className="form-control"
                        placeholder="Enter estimated amount consumed by heating systems"
                        value={this.state.current_heating_kwh}
                        onChange={e => this.setCurrentConsumptionAmount(e.target.value, 'heating')} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label">Annual Cooling kWh: </label>
                      <input
                        type="number"
                        disabled={this.state.overrides.includes("ENB_COO") ? false: true}
                        className="form-control"
                        placeholder="Enter estimated amount consumed by cooling systems"
                        value={this.state.current_cooling_kwh}
                        onChange={e => this.setCurrentConsumptionAmount(e.target.value, 'cooling')} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label">Annual Hot Water kWh: </label>
                      <input
                        type="number"
                        disabled={this.state.overrides.includes("ENB_HWT") ? false: true}
                        className="form-control"
                        placeholder="Enter estimated amount consumed byhot water systems"
                        value={this.state.current_hot_water_kwh}
                        onChange={e => this.setCurrentConsumptionAmount(e.target.value, 'hot_water')} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label">Annual Ventilation kWh: </label>
                      <input
                        type="number"
                        disabled={this.state.overrides.includes("ENB_VEN") ? false: true}
                        className="form-control"
                        placeholder="Enter estimated amount consumed by ventilation systems"
                        value={this.state.current_ventilation_kwh}
                        onChange={e => this.setCurrentConsumptionAmount(e.target.value, 'ventilation')} />
                    </div>
                  </div>
                </div>
                <div className="row options-row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-control-label">Adjust Assessment Options: </label>
                      <div className='options-wrapper row'>
                        {this.getCategories()}
                        {this.getOverrides()}
                        {this.getDescription()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group ht-100p mg-b-0 d-flex flex-column">
                  <label className="form-control-label">Summary:</label>
                  <div className="preview-box">
                    {this.getPreview()}
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <div
                  className={`btn btn-info tx-14 tx-uppercase tx-mont tx-semibold wd-100p ${runExecutionButtonEnabled || isLoading ? '' : 'disabled'}`}
                  style={{ cursor: runExecutionButtonEnabled ? "pointer" : 'not-allowed' }}
                  onClick={() => this.runExecution(runExecutionButtonEnabled)}>
                  <span style={{ position: 'relative' }}>
                    {isLoading && <Spinner mini trueCondition style={{ position: 'absolute', borderColor: 'white white transparent', top: '-7px', left: '-40px' }} />}{isLoading ? 'Loading...' : 'Run Execution'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default AssessmentExecution;
