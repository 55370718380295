import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching and managing meter consumption data. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state via a Dispatcher.
 *
 * @class
 * @example
 * const meterDetailsActions = new MeterDetailsActions();
 * meterDetailsActions.getMeterConsumption('meter123', '2020-01-01', '2020-01-31', 'DAY');
 */
class MeterDetailsActions {
  /**
   * Fetches consumption data for a specific meter from the API and dispatches an action to store this data
   * in the application's state management system upon successful retrieval.
   *
   * @param {string} meterId - The unique identifier of the meter for which data is being requested.
   * @param {string} tsStart - The start date of the period for which data is requested, formatted as YYYY-MM-DD.
   * @param {string} tsEnd - The end date of the period for which data is requested, formatted as YYYY-MM-DD.
   * @param {string} bucketType - The granularity of the data aggregation, e.g., 'DAY', 'HOUR', or 'FIFTEEN_MIN'.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getMeterConsumption(meterId, tsStart, tsEnd, bucketType) {
    return GeneralUtils.get({
      url: `v2/meters/${meterId}/data?from=${tsStart}&to=${tsEnd}&bucket=${bucketType}`,
      actionType: ActionTypes.METER_DETAILS_CONSUMPTION_FETCHED,
      modifyResponse: (response) => {
        return {
          data: response,
          bucketType: bucketType
        };
      },
      failToast: "Error fetching meter consumption data",
    });
  }
}

export default new MeterDetailsActions();
