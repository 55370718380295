import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to exploring meters. This class interfaces with the API
 * to retrieve buildings, meters, and meter chart data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const metersExplorerActions = new MetersExplorerActions();
 * metersExplorerActions.getBuildings();
 */
class MetersExplorerActions {
  /**
   * Fetches the list of allowed buildings from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildings() {
    return GeneralUtils.get({
      url: "building/allowed",
      actionType: ActionTypes.METERS_EXPLORER_BUILDINGS_FETCHED,
    });
  }

  /**
   * Fetches the list of meters from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getMeters() {
    return GeneralUtils.get({
      url: "meter/",
      actionType: ActionTypes.METERS_EXPLORER_METERS_FETCHED,
    });
  }

  /**
   * Fetches chart data for a specific meter within a specified time range from the API and dispatches an action to store this data.
   * This method supports fetching data for meter comparisons.
   *
   * @param {string} meterId - The unique identifier of the meter.
   * @param {string} tsStart - The start timestamp of the data range.
   * @param {string} tsEnd - The end timestamp of the data range.
   * @param {string} bucketType - The granularity of the data aggregation, e.g., 'DAY', 'HOUR', or 'FIFTEEN_MIN'.
   * @param {string[]} meterIds - The list of meter IDs for comparison.
   * @param {boolean} [isComparison=false] - Whether the data is for comparison.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getMeterChartData(meterId, tsStart, tsEnd, bucketType, meterIds, isComparison = false) {
    let params = meterIds.map((id, index) => `${index === 0 ? "?" : "&"}compareMeters=${id}`).join("");
    let url = `energy/meter/${meterId}/compare/${bucketType}/${tsStart}/${tsEnd}${params}`;

    return GeneralUtils.get({
      url: url,
      actionType: isComparison
        ? ActionTypes.METERS_EXPLORER_COMPARISON_CHART_DATA_FETCHED
        : ActionTypes.METERS_EXPLORER_CHART_DATA_FETCHED,
      modifyResponse: (response) => {
        return { ...response, isComparison: isComparison };
      }
    });
  }
}

export default new MetersExplorerActions();
