import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";

const CHANGE = "MAPPING_CHANGE";
const MAPPING_GENERATED = "MAPPING_GENERATED";
const MAPPING_OPERATION = "MAPPING_OPERATION";

let _generated_mapping = [];
let _mapping_by_data_source = [];
let _mapping_by_entity = [];
let _saved_bulk = [];

class MappingStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.MAPPING_SAVED:
        this._mappingSaved(action.payload);
        break;
      case ActionTypes.MAPPING_BULK_SAVED:
        this._mappingBulkSaved(action.payload);
        break;
      case ActionTypes.MAPPING_DELETED:
        this._mappingDeleted(action.payload);
        break;
      case ActionTypes.MAPPING_GENERATED:
        this._mappingGenerated(action.payload);
        break;
      case ActionTypes.MAPPING_BY_DATA_SOURCE_FETCHED:
        this._mappingByDataSourceFetched(action.payload);
        break;
      case ActionTypes.MAPPING_BY_ENTITY_FETCHED:
        this._mappingByEntityFetched(action.payload);
        break;

      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }

  addMappingListener(callback) {
    this.on(MAPPING_GENERATED, callback);
  }

  removeMappingListener(callback) {
    this.removeListener(MAPPING_GENERATED, callback);
  }

  addMappingOperationListener(callback) {
    this.on(MAPPING_OPERATION, callback);
  }

  removeMappingOperationListener(callback) {
    this.removeListener(MAPPING_OPERATION, callback);
  }

  clear() {
    _generated_mapping = [];
    _mapping_by_data_source = [];
    _mapping_by_entity = [];
    _saved_bulk = [];
  }

  _mappingSaved() {
    this.emit(MAPPING_OPERATION);
  }

  _mappingBulkSaved(json) {
    _saved_bulk = json;
    this.emit(MAPPING_OPERATION);
  }

  _mappingDeleted() {
    this.emit(MAPPING_OPERATION);
  }

  _mappingGenerated(json) {
    _generated_mapping = json;
    this.emit(MAPPING_GENERATED);
  }

  _mappingByDataSourceFetched(json) {
    _mapping_by_data_source = json;
    this.emit(CHANGE);
  }

  _mappingByEntityFetched(json) {
    _mapping_by_entity = json;
    this.emit(CHANGE);
  }

  // Getters

  getGeneratedMapping() {
    return _generated_mapping;
  }

  getMappingByDataSource() {
    return _mapping_by_data_source;
  }

  getMappingByEntity() {
    return _mapping_by_entity;
  }

  getSavedBulk() {
    return _saved_bulk;
  }
}

export default new MappingStore();
