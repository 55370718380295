import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';

const CHANGE = 'ENTITY_TYPE_CHANGE';

let _entity_types = [];

class EntityTypeStore extends EventEmitter {

    constructor() {
        super();
        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
            case ActionTypes.ENTITY_TYPES_FETCHED:
                this._storeEntityTypes(action.payload);
                break;
            default:
                break;
        }
    }

    // Hooks a React component's callback to the CHANGED event.
    addChangeListener(callback) {
        this.on(CHANGE, callback);
    }

    // Removes the listener from the CHANGED event.
    removeChangeListener(callback) {
        this.removeListener(CHANGE, callback);
    }

    clear() {
        _entity_types = [];
    }

    _storeEntityTypes(json) {
        _entity_types = json;
        this.emit(CHANGE);
    }

    // Getters

    getEntityTypes() {
        return _entity_types;
    }

}

export default new EntityTypeStore();