import React from "react";
import GeneralUtils from "../../../../utils/GeneralUtils";
import UrlManager from "../../../../utils/UrlManager";
import _ from "lodash";

import "./EnergyConsumptionTrackingConfig.scss";
import DashboardsActions from "../../../../actions/dashboardsActions";

import { MeterTree } from "../../../../components/MeterTree";
import { PageTitle } from "../../../../components/PageTitle";

class EnergyConsumptionTrackingConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      left_header: "",
      right_header: "",
      selected_meters: [],
      token: null,
      tokenStale: true,
    };

    this.toggleMeter = this.toggleMeter.bind(this);
    this.copyUrl = this.copyUrl.bind(this);
    this.fetchToken = this.fetchToken.bind(this);
    this.viewDashboard = this.viewDashboard.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const inputsChanged =
      prevState.left_header !== this.state.left_header ||
      prevState.right_header !== this.state.right_header ||
      JSON.stringify(prevState.selected_meters) !==
        JSON.stringify(this.state.selected_meters);

    if (inputsChanged) {
      this.setState({ tokenStale: true });
    }
  }

  toggleMeter(newMeterObject) {
    this.setState((prevState) => {
      const { selected_meters } = prevState;
      const index = selected_meters.findIndex(
        (meter) => meter.meter_id === newMeterObject.meter_id
      );

      if (index === -1) {
        return { selected_meters: [...selected_meters, newMeterObject] };
      } else {
        return {
          selected_meters: selected_meters.filter(
            (meter) => meter.meter_id !== newMeterObject.meter_id
          ),
        };
      }
    });
  }

  async fetchToken() {
    const left_header = _.get(this.state, "left_header");
    const right_header = _.get(this.state, "right_header");
    const meter_ids = this.state.selected_meters.map((m) => m.meter_id);

    if (left_header && meter_ids.length > 0) {
      const object = {
        type: "METER_USAGE_DASHBOARD",
        meter_ids: meter_ids,
        custom: {
          header_left: left_header,
          header_right: right_header,
          stacked_chart: false,
        },
      };

      try {
        const token = await DashboardsActions.generateToken(object);
        this.setState({ token: token, tokenStale: false });
        return token;
      } catch (error) {
        console.error("Error generating token");
        throw error;
      }
    }
  }

  async copyUrl() {
    const token =
      this.state.tokenStale || !this.state.token
        ? await this.fetchToken()
        : this.state.token;
    const url = UrlManager.getUIUrl() + `dashboards/meter-usage/${token}`;
    GeneralUtils.copyToClipboard(url);
  }

  async viewDashboard() {
    const token =
      this.state.tokenStale || !this.state.token
        ? await this.fetchToken()
        : this.state.token;
    const url = `dashboards/meter-usage/${token}`;
    window.open(url, "_blank");
  }

  render() {
    const generationDisabled =
      !this.state.left_header || !this.state.selected_meters.length;
    return (
      <div id="EnergyConsumptionTrackingConfig">
        <div className="row mg-b-20">
          <div className="col-12">
            <PageTitle
              title={"Energy Consumption Tracking - Dashboard Configuration"}
            />
          </div>
        </div>
        <hr></hr>
        <div className="row mg-b-20">
          <div className="col-12 col-lg-6">
            <div className="top-left-panel">
              <div className="headers">
                <div className="left-header">
                  <div className="label">
                    Title <span className="field-status">(required)</span>
                  </div>
                  <input
                    className="left-input"
                    type="text"
                    value={this.state.left_header}
                    onChange={(e) =>
                      this.setState({ left_header: e.target.value })
                    }
                  />
                </div>
                <div className="right-header">
                  <div className="label">
                    Sub Title <span className="field-status">(optional)</span>
                  </div>
                  <input
                    className="right-input"
                    type="text"
                    value={this.state.right_header}
                    onChange={(e) =>
                      this.setState({ right_header: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="meters-section">
                <div className="label">
                  Meters Selected{" "}
                  <span className="field-status">(required)</span>
                </div>
                <div className="meters">
                  {this.state.selected_meters.map((m) => {
                    return (
                      <div
                        key={m.meter_id}
                        className="meter-item"
                        onClick={() => this.toggleMeter(m)}
                      >
                        {m.description} <ion-icon name="trash" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="bottom-left-panel">
              <div className="label">Preview</div>
              <div className="placeholder">
                <div className="image">
                  <img
                    alt="dashboard"
                    src="/img/dashboardsThumbnails/energy-consumption-tracking.png"
                  />
                  <div className="select-left"></div>
                  <div className="select-right"></div>
                  <div className="select-meters"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="label">
              Target Meters <span className="field-status">(required)</span>
            </div>
            <div className="meter-tree-wrapper">
              <MeterTree
                height={"487px"}
                selected_meters={this.state.selected_meters}
                toggleHandler={this.toggleMeter}
                clearAllMetersHandler
                filterMeterTypes={["wm"]}
              />
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col">
            <div className="button-bottom-panel">
              <div className="buttons">
                <button
                  className={`btn btn-info copy-button ${
                    generationDisabled ? "disabled" : ""
                  }`}
                  disabled={generationDisabled}
                  onClick={this.copyUrl}
                >
                  Copy Link
                </button>
                <button
                  className={`btn btn-info view-button ${
                    generationDisabled ? "disabled" : ""
                  }`}
                  disabled={generationDisabled}
                  onClick={this.viewDashboard}
                >
                  View Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EnergyConsumptionTrackingConfig;
