import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to managing tenants, buildings, zones, and meters. This class interfaces with the API
 * to retrieve, create, update, and delete data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const tenantActions = new TenantActions();
 * tenantActions.getOrganisationTenants('org123');
 */
class TenantActions {
  // TENANT ACTIONS

  /**
   * Fetches the tenants for a specific organisation from the API and dispatches an action to store this data.
   *
   * @param {string} orgId - The unique identifier of the organisation.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getOrganisationTenants(orgId) {
    return GeneralUtils.get({
      url: `tenants/organisation/${orgId}`,
      actionType: ActionTypes.TENANTS_FETCHED,
    });
  }

  /**
   * Creates a new tenant by sending the provided tenant object to the API and dispatches an action to store this data.
   *
   * @param {Object} tenantObject - The tenant object to be created.
   * @returns {Promise} - A promise that resolves with the response of the create operation.
   */
  createTenant(tenantObject) {
    return GeneralUtils.post({
      url: "tenants",
      object: tenantObject,
      actionType: ActionTypes.TENANT_SAVED,
      successToast: "Tenant saved successfully",
      failToast: "Unable to save tenant, please try again",
    });
  }

  /**
   * Updates an existing tenant by sending the provided tenant object to the API and dispatches an action to store this data.
   *
   * @param {string} tenantId - The unique identifier of the tenant.
   * @param {Object} tenantObject - The tenant object to be updated.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateTenant(tenantId, tenantObject) {
    return GeneralUtils.put({
      url: `tenants/${tenantId}`,
      object: tenantObject,
      actionType: ActionTypes.TENANT_UPDATED,
      successToast: "Tenant updated successfully",
      failToast: "Unable to update tenant, please try again",
    });
  }

  /**
   * Deletes a specific tenant by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} tenantId - The unique identifier of the tenant to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteTenant(tenantId) {
    return GeneralUtils.delete({
      url: `tenants/${tenantId}`,
      actionType: ActionTypes.TENANT_DELETED,
      successToast: "Tenant deleted successfully",
      failToast: "Unable to delete tenant, please try again",
    });
  }

  // BUILDING ACTIONS

  /**
   * Links a building to a tenant by sending the provided building-tenant area object to the API and dispatches an action to store this data.
   *
   * @param {Object} buildingTenantAreaObject - The building-tenant area object to be created.
   * @returns {Promise} - A promise that resolves with the response of the create operation.
   */
  linkBuilding(buildingTenantAreaObject) {
    return GeneralUtils.post({
      url: "tenants/building",
      object: buildingTenantAreaObject,
      actionType: ActionTypes.BUILDING_LINKED,
      successToast: "Building linked successfully",
      failToast: "Unable to link this building, please try again",
    });
  }

  /**
   * Updates the area of a linked building by sending the provided new area object to the API and dispatches an action to store this data.
   *
   * @param {string} tenantId - The unique identifier of the tenant.
   * @param {string} buildingId - The unique identifier of the building.
   * @param {Object} newAreaObject - The new area object to be updated.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateLinkedBuilding(tenantId, buildingId, newAreaObject) {
    return GeneralUtils.put({
      url: `tenants/${tenantId}/building/${buildingId}`,
      object: newAreaObject,
      actionType: ActionTypes.LINKED_BUILDING_UPDATED,
      successToast: "Building's area updated successfully",
      failToast: "Unable to update building's area, please try again",
    });
  }

  /**
   * Deletes a linked building by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} tenantId - The unique identifier of the tenant.
   * @param {string} buildingId - The unique identifier of the building to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteLinkedBuilding(tenantId, buildingId) {
    return GeneralUtils.delete({
      url: `tenants/${tenantId}/building/${buildingId}`,
      actionType: ActionTypes.LINKED_BUILDING_DELETED,
      successToast: "Building unlinked successfully",
      failToast: "Unable to unlink building from tenant, please try again",
    });
  }

  // ZONE ACTIONS

  /**
   * Fetches the zones that can be linked to a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  fetchLinkableZones(buildingId) {
    return GeneralUtils.get({
      url: `zone/building/${buildingId}`,
      actionType: ActionTypes.LINKABLE_ZONES_FETCHED,
    });
  }

  /**
   * Links a zone to a building by sending the provided updated zone object to the API and dispatches an action to store this data.
   *
   * @param {Object} updatedZoneObject - The updated zone object to be linked.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  linkZone(updatedZoneObject) {
    return GeneralUtils.put({
      url: "zone/",
      object: updatedZoneObject,
      actionType: ActionTypes.LINKED_BUILDING_UPDATED,
      successToast: "Zone linked successfully",
      failToast: "Unable to link the zone, please try again",
    });
  }

  /**
   * Deletes a linked zone by sending the provided updated zone object to the API and dispatches an action to remove this data from the state.
   *
   * @param {Object} updatedZoneObject - The updated zone object to be unlinked.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteLinkedZone(updatedZoneObject) {
    return GeneralUtils.put({
      url: "zone/",
      object: updatedZoneObject,
      actionType: ActionTypes.LINKED_ZONE_DELETED,
      successToast: "Zone unlinked successfully",
      failToast: "Unable to unlink the zone, please try again",
    });
  }

  // METER ACTIONS

  /**
   * Fetches the meters that can be linked to a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  fetchLinkableMeters(buildingId) {
    return GeneralUtils.get({
      url: `meter/building/${buildingId}`,
      actionType: ActionTypes.LINKABLE_METERS_FETCHED,
    });
  }

  /**
   * Links a meter to a building by sending the provided updated meter object to the API and dispatches an action to store this data.
   *
   * @param {Object} updatedMeterObject - The updated meter object to be linked.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  linkMeter(updatedMeterObject) {
    return GeneralUtils.put({
      url: "meter/",
      object: updatedMeterObject,
      actionType: ActionTypes.METER_LINKED,
      successToast: "Meter linked successfully",
      failToast: "Unable to link the meter, please try again",
    });
  }

  /**
   * Deletes a linked meter by sending the provided updated meter object to the API and dispatches an action to remove this data from the state.
   *
   * @param {Object} updatedMeterObject - The updated meter object to be unlinked.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteLinkedMeter(updatedMeterObject) {
    return GeneralUtils.put({
      url: "meter/",
      object: updatedMeterObject,
      actionType: ActionTypes.LINKED_METER_DELETED,
      successToast: "Meter unlinked successfully",
      failToast: "Unable to unlink the meter, please try again",
    });
  }
}

export default new TenantActions();
