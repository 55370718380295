import ActionTypes from '../constants';
import GeneralUtils from '../utils/GeneralUtils';
import _ from 'lodash';

class BuildingBenchmarkActions {

  getBuildingBenchmarkReport(questionnaireData) {
    return GeneralUtils.post({
      url: "benchmark/building",
      object: questionnaireData,
      actionType: ActionTypes.BUILDING_BENCHMARK_REPORT_FETCHED,
      failToast: "Unable to send your information, please try again or contact support",
    });
  }

  getReports() {
    return GeneralUtils.get({
      url: "benchmark/building",
      actionType: ActionTypes.BUILDING_BENCHMARK_REPORTS_FETCHED,
      failToast: "Unable to fetch reports, please try again or contact support",
    });
  }

  getReport(ref) {
    return GeneralUtils.get({
      url: "benchmark/building/ref/" + ref,
      actionType: ActionTypes.BUILDING_BENCHMARK_REPORT_FETCHED,
      failToast: "Unable to fetch report, please try again or contact support",
    });
  }

  getReportViaToken(token) {
    return GeneralUtils.get({
      url: `tokens/process?token=${token}`,
      authorizationNeeded: false,
      actionType: ActionTypes.BUILDING_BENCHMARK_REPORT_FETCHED_VIA_TOKEN,
      failToast: "Unable to fetch report, please try again or contact support",
    });
  }

  deleteReport(ref) {
    return GeneralUtils.delete({
      url: `benchmark/building/ref/${ref}`,
      actionType: ActionTypes.BUILDING_BENCHMARK_REPORT_DELETED,
      successToast: "Report deleted successfully",
      failToast: "Unable to delete report, please try again or contact support",
    });
  }

  generateToken(tokenObject) {
    return GeneralUtils.post({
      url: "tokens/generate",
      object: tokenObject,
      actionType: ActionTypes.BUILDING_BENCHMARK_TOKEN_GENERATED,
      modifyResponse: (response) => _.get(response, "token"),
      failToast: "Error creating sharing token.",
    });
  }
}

export default new BuildingBenchmarkActions();


