import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";

const METERS_OVERVIEW_FETCH = Constants.METERS_OVERVIEW_FETCH;

let _meters = [];

class MetersOverviewStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case METERS_OVERVIEW_FETCH:
        this._storeData(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addMetersOverviewFetchListener(callback) {
    this.on(METERS_OVERVIEW_FETCH, callback);
  }
  removeMetersOverviewFetchListener(callback) {
    this.removeListener(METERS_OVERVIEW_FETCH, callback);
  }

  clear() {
    _meters = [];
  }

  _storeData(json) {
    _meters = json;

    this.emit(METERS_OVERVIEW_FETCH);
  }

  getMetersOverview() {
    return _meters;
  }
}

export default new MetersOverviewStore();
