import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching HVAC overview data. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const hvacOverviewActions = new HvacOverviewActions();
 * hvacOverviewActions.getHvacOverview();
 */
class HvacOverviewActions {
  /**
   * Fetches the HVAC overview data from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getHvacOverview() {
    return GeneralUtils.get({
      url: "v2/assets",
      actionType: ActionTypes.HVAC_OVERVIEW_FETCH,
      failToast: "Unable to fetch HVAC overview, please try again",
    });
  }
}

export default new HvacOverviewActions();
