import React, { Component } from 'react';
import './ManageSensors.scss';

import OrganisationActions from '../../actions/organisationActions';
import OrganisationStore from '../../stores/organisationStore';
import UserStore from '../../stores/userStore';

import { RegisterDevices } from './RegisterDevices'
import { SearchSelect } from '../../components/SearchSelect'
import { DocumentTitle } from '../../components/DocumentTitle'

class ManageSensors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: OrganisationStore.getBuildings(),
      selected_building: null
      }

    // listener events
    this._onOrganisationChange = this._onOrganisationChange.bind(this);

    // // filter setters
    this._setTargetBuilding = this._setTargetBuilding.bind(this);
  }

  componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
  }

  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
  }

  _onOrganisationChange() {
    this.setState({
      buildings: OrganisationStore.getBuildings(),
    })
  }

  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
  }

  _setTargetBuilding(selected_building) {

    this.setState({
      selected_building: selected_building,
     });
  }

  render() {
    let buildingsFormattedArray = this.state.buildings.map(building => {
      return {...building, label: building.name, name: building.name + ` (building id: ${building.building_id})`}
    })


    return (
      <div className="br-mainpanel pd-15">
        <DocumentTitle title='Manage Sensors' />
        <div className="br-pagetitle mg-b-0">
          <div>
            <h4>Update Sensors Details</h4>
            <p className="mg-b-0">Update and create new Sensors. Select the building to continue.
            </p>
          </div>
        </div>
        <div>
          <div className="col-12">
          <div className="br-pagetitle pd-x-0 pd-b-0" style={{ width: '100%' }}>
              <span className='col-8 col-md-5 col-lg-4 pd-l-0'>
                <SearchSelect limit={100} options={buildingsFormattedArray} placeholder={"Select Building"} defaultValue={this.state.selected_building} actionOnSelectedOption={this._setTargetBuilding} />
              </span>              
          </div>
          {this.state.selected_building && <RegisterDevices building_id={this.state.selected_building.building_id} building_name={this.state.selected_building.label}/>}
          </div>
        </div>
      </div>
    );
  }
}

export default ManageSensors;