import React, { Component } from "react";
import styles from "./ZoneSelect.module.scss";
import _ from "lodash";
import PropTypes from "prop-types";
import { SearchSelect } from "../SearchSelect";
import ZonesOverviewActions from "../../actions/zonesOverviewActions";

class ZoneSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: [],
    };
  }

  componentDidMount() {
    this.fetchZones();
  }

  async fetchZones() {
    const ts_start = _.get(this.props, "ts_start");
    const ts_end = _.get(this.props, "ts_end");

    try {
      let zones = await ZonesOverviewActions.getZonesOverview(ts_start, ts_end);

      zones = zones.map((z) => {
        return {
          building_name: z.building.name,
          building_id: z.building.id,
          entity: z.entity,
          wellness: z.wellness,
          tenant: z.tenant,
          building: z.building,
          ...z.zone,
          name: `${z.zone.name} - ${z.building.name}`,
        };
      });
      this.setState({ zones: zones });
    } catch (err) {
      throw new Error(err);
    }
  }

  render() {
    const filteredZones = this.props.filter
      ? this.state.zones.filter(this.props.filter)
      : this.state.zones;

    return (
      <div
        className={
          this.props.white ? styles["ZoneSelectWhite"] : styles["ZoneSelect"]
        }
      >
        <SearchSelect
          options={filteredZones}
          placeholder={this.props.placeholder || "Select the Zone..."}
          defaultValue={this.props.selectedZone}
          actionOnSelectedOption={this.props.onZoneSelect}
        />
      </div>
    );
  }
}

export default ZoneSelect;

ZoneSelect.propTypes = {
  selectedZone: PropTypes.object,
  onZoneSelect: PropTypes.func,
  placeholder: PropTypes.string,
  filter: PropTypes.func,
  white: PropTypes.bool,
};
