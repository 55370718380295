import React, { Component } from "react";
import "./PasswordReset.scss";
import UserActions from "../../actions/userActions";
import { DocumentTitle } from "../../components/DocumentTitle";

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: "",
    };
    this.updateMail = this.updateMail.bind(this);
    this.sendReset = this.sendReset.bind(this);
  }
  sendReset(event) {
    event.preventDefault();
    UserActions.sendPasswordReset(this.state.mail);
  }
  updateMail(event) {
    this.setState({ mail: event.target.value });
  }
  render() {
    return (
      <div
        className="row no-gutters flex-row-reverse ht-100v"
        id="PasswordReset"
      >
        <DocumentTitle title="Password Reset" />
        <div className="right-panel col-md-6 bg-gray-200 d-flex align-items-center justify-content-center">
          <form
            onSubmit={this.sendReset}
            className="login-wrapper wd-250 wd-xl-350 mg-y-30"
          >
            <h4 className="tx-inverse tx-center tx-white-8">
              Forgotten Your Login?
            </h4>
            <p className="tx-center mg-b-20 tx-white-6">
              Request a password reset using your email below.
            </p>
            <div className="form-group">
              <input
                type="email"
                defaultValue={this.state.mail}
                className="form-control"
                placeholder="Enter your email"
                onChange={this.updateMail}
              />
            </div>
            <button
              type="submit"
              className="btn btn-info btn-block"
              onSubmit={this.sendReset}
              onClick={this.sendReset}
            >
              Send Request
            </button>
          </form>
        </div>
        <div className="col-md-6 left-panel d-flex align-items-center justify-content-center">
          <div className="floorplan-illustration"></div>
          <div className="wd-250 wd-xl-450 mg-y-30 text-wrapper">
            <div className="signin-logo tx-28 tx-bold tx-br-primary">
              <img
                className="logo-image"
                alt="logo"
                src="/img/OPNLogo.png"
                style={{ width: "100%" }}
              />
            </div>
            <div className="tx-br-primary mg-b-60 tx-center">
              Sustainable Building Performance
            </div>

            <h5 className="tx-br-primary tx-archivo-semibold">
              Why OPNBuildings?
            </h5>
            <p className="tx-br-primary">
              OPNBuildings takes away the strain of building management and
              allows you to achieve full control and visibility into your
              portfolio.
            </p>
            <p className="tx-br-primary mg-b-60">
              We give you new levels of insights as well as accurate, pinpointed
              and real-time information on issues that are happening in your
              buildings.
            </p>
            <a
              href="https://www.opnbuildings.com"
              className="btn bd bd-br-primary bd-1 tx-br-primary pd-x-25 tx-uppercase tx-12 tx-spacing-2 tx-medium visit-website-button"
            >
              Visit Website
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordReset;
