import React, { Component } from "react";
import "./HealthCheck.scss";
import AdminStore from "../../../stores/adminStore";
import AdminActions from "../../../actions/adminActions";
import moment from "moment";
import _ from "lodash";
import { Filter } from "../../../components/Filter";
import { DocumentTitle } from "../../../components/DocumentTitle";

class HealthCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      health_checks: AdminStore.getHealthChecks(),
      appliance_stats: AdminStore.getApplianceStats(),
      counter_stats: AdminStore.getCounterStats(),
      sensor_stats: AdminStore.getSensorStats(),
      loading: true,
      last_refresh: moment(),
      filter: "",
    };
    this._onStatusChange = this._onStatusChange.bind(this);
  }
  _onStatusChange() {
    this.setState({
      health_checks: AdminStore.getHealthChecks(),
      appliance_stats: AdminStore.getApplianceStats(),
      counter_stats: AdminStore.getCounterStats(),
      sensor_stats: AdminStore.getSensorStats(),
      loading: false,
      last_refresh: moment(),
    });
  }
  componentWillMount() {
    AdminStore.addChangeListener(this._onStatusChange);
  }
  componentDidMount() {
    clearInterval(this.timer);
    this.timer = setInterval(
      () => AdminActions.getHealthChecks(false),
      1000 * 60
    );
    AdminActions.getHealthChecks(false);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    AdminStore.removeChangeListener(this._onStatusChange);
  }
  getInputFilter() {
    return (
      <div className="col-12 col-md-4 my-4 pd-0">
        <Filter setFilter={(value) => this.setState({ filter: value })} />
      </div>
    );
  }

  getHealthChecks() {
    let health_checks = this.state.health_checks;

    const services = [
      "Fault Detection rules run",
      "Receiver server online",
      "Engine server online",
      "Report server online",
    ];
    let services_bundled = {
      name: "Services Available",
      comment: "Not Passed",
      status: "CRITICAL",
      ts_last_check: new Date(),
      ts_last_success: new Date(),
    };

    const data_processing = [
      "Asset entities processed",
      "Doorway entities processed",
      "Meter entities processed",
    ];
    let data_processing_bundled = {
      name: "Data Processing",
      comment: "Not Passed",
      status: "CRITICAL",
      ts_last_check: new Date(),
      ts_last_success: new Date(),
    };

    health_checks = health_checks.map((check) => {
      if (check.name === "Database Accessible") {
        if (check.comment !== "Passed")
          check.comment = "Issues with database accessibility detected";
        if (check.comment === "Passed")
          check.comment = "Database is accessible and online";
        check.ts_last_success = check.ts_last_check;
        return check;
      } else if (services.includes(check.name)) {
        if (check.status === "WARNING")
          services_bundled = { ...services_bundled, status: "WARNING" };
        if (check.status === "CRITICAL")
          services_bundled = { ...services_bundled, status: "CRITICAL" };
        if (check.comment !== "Passed")
          services_bundled = {
            ...services_bundled,
            comment: "Issues with services detected",
            status: "WARNING",
          };
        if (check.comment === "Passed")
          services_bundled = {
            ...services_bundled,
            comment: "Services are available and online",
            status: "OK",
          };
        if (
          moment(check.ts_last_check).isBefore(services_bundled.ts_last_check)
        )
          services_bundled = {
            ...services_bundled,
            ts_last_check: check.ts_last_check,
          };
        if (
          moment(check.ts_last_success).isBefore(
            services_bundled.ts_last_success
          )
        )
          services_bundled = {
            ...services_bundled,
            ts_last_success: check.ts_last_success,
          };
      } else if (data_processing.includes(check.name)) {
        if (check.status === "WARNING")
          data_processing_bundled = {
            ...data_processing_bundled,
            status: "WARNING",
          };
        if (check.status === "CRITICAL")
          data_processing_bundled = {
            ...data_processing_bundled,
            status: "CRITICAL",
          };
        if (check.comment !== "Passed")
          data_processing_bundled = {
            ...data_processing_bundled,
            comment: "Issues with data processing detected",
            status: "WARNING",
          };
        if (check.comment === "Passed")
          data_processing_bundled = {
            ...data_processing_bundled,
            comment: "Data processing is functional and online",
            status: "OK",
          };
        if (
          moment(check.ts_last_check).isBefore(
            data_processing_bundled.ts_last_check
          )
        )
          data_processing_bundled = {
            ...data_processing_bundled,
            ts_last_check: check.ts_last_check,
          };
        if (
          moment(check.ts_last_success).isBefore(
            data_processing_bundled.ts_last_success
          )
        )
          data_processing_bundled = {
            ...data_processing_bundled,
            ts_last_success: check.ts_last_success,
          };
      }
      return null;
    });

    health_checks = [
      ...health_checks,
      services_bundled,
      data_processing_bundled,
    ].filter((_) => _);

    return (
      <div className="row widget-1">
        {health_checks.map((health, key) => {
          let progressClass =
            "progress-bar bg-success progress-bar-striped progress-bar-animated progress-bar-xs wd-100p";
          let txClass = "tx-success";
          if (health.status === "WARNING") {
            progressClass =
              "progress-bar bg-warning progress-bar-striped progress-bar-animated progress-bar-xs wd-100p";
            txClass = "tx-warning";
          }
          if (health.status === "CRITICAL") {
            progressClass =
              "progress-bar bg-danger progress-bar-striped progress-bar-animated progress-bar-xs wd-100p";
            txClass = "tx-danger";
          }

          let ts_last_success_str =
            moment(health.ts_last_success).format("Do MMM, YYYY - HH:mm") ===
            "Invalid date"
              ? ""
              : moment(health.ts_last_success).format("Do MMM - HH:mm");

          return (
            <div
              key={health.name + key}
              className="col-12 col-sm-6 col-md-4 mg-b-20"
            >
              <div className="card shadow-base">
                <div className="card-header" style={{ padding: "10px" }}>
                  <h6 className="card-title">{health.name}</h6>
                  <h6
                    className={txClass}
                    style={{
                      fontSize: "12px",
                      float: "right",
                      textAlign: "right",
                      marginBottom: "0px",
                    }}
                  >
                    {ts_last_success_str}
                  </h6>
                </div>
                <div className="card-body" style={{ padding: "0px 15px 10px" }}>
                  <div className="progress" style={{ width: "50%" }}>
                    <div
                      className={progressClass}
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="100"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span
                    className={txClass}
                    style={{ fontSize: "22px", fontWeight: "500" }}
                  >
                    {health.status}
                  </span>
                </div>
                <div className="card-footer" style={{ padding: "5px 10px" }}>
                  <div>
                    <h6
                      className="tx-inverse"
                      style={{
                        fontSize: "11px",
                        margin: "5px",
                        fontWeight: "500",
                      }}
                    >
                      {health.comment}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  getApplianceStats() {
    let criticalCount = 0;
    let warningCount = 0;
    let onlineCount = 0;
    let applianceCountCritical;
    let applianceCountWarning;
    let applianceCountOnline;

    for (let i = 0; i < this.state.appliance_stats.length; i++) {
      if (this.state.appliance_stats[i].status === "CRITICAL") {
        criticalCount++;
      } else if (this.state.appliance_stats[i].status === "WARNING") {
        warningCount++;
      } else {
        onlineCount++;
      }
    }
    applianceCountWarning = (
      <span
        className="btn btn-warning btn-icon"
        style={{ float: "right", marginLeft: "10px", marginTop: "-5px" }}
      >
        <div style={{ height: "20px", width: "120px" }}>
          {warningCount} Warning
        </div>
      </span>
    );
    applianceCountCritical = (
      <span
        className="btn btn-danger btn-icon"
        style={{ float: "right", marginLeft: "10px", marginTop: "-5px" }}
      >
        <div style={{ height: "20px", width: "120px" }}>
          {criticalCount} Offline
        </div>
      </span>
    );
    applianceCountOnline = (
      <span
        className="btn btn-success btn-icon"
        style={{ float: "right", marginLeft: "10px", marginTop: "-5px" }}
      >
        <div style={{ height: "20px", width: "120px" }}>
          {onlineCount} Online
        </div>
      </span>
    );

    return (
      <div className="row row-sm mg-t-0">
        <div className="col-12 mg-t-20 mg-lg-t-0">
          <div className="card shadow-base bd-0">
            <div className="card-header pd-x-20 bg-transparent">
              <h6 className="card-title tx-uppercase tx-12 mg-b-0 pd-t-5">
                BMS Data Collectors
                {applianceCountCritical}
                {applianceCountWarning}
                {applianceCountOnline}
              </h6>
            </div>
            <div className="card-body" style={{ paddingTop: "0px" }}>
              <table className="table mg-b-0 tx-12">
                <thead>
                  <tr className="tx-10">
                    <th className="wd-30p pd-y-5">Appliance Name</th>
                    <th className="wd-30p pd-y-5 hidden-xs-down">Issue</th>
                    <th className="wd-25p pd-y-5 hidden-xs-down">
                      Last Online
                    </th>
                    <th className="wd-15p pd-y-5">Status</th>
                  </tr>
                </thead>
                {this.getDataSourceTableBody(this.state.appliance_stats)}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  getCounterStats() {
    let criticalCount = 0;
    let warningCount = 0;
    let onlineCount = 0;
    let counterCountCritical;
    let counterCountWarning;
    let counterCountOnline;

    for (let i = 0; i < this.state.counter_stats.length; i++) {
      if (this.state.counter_stats[i].status === "CRITICAL") {
        criticalCount++;
      } else if (this.state.counter_stats[i].status === "WARNING") {
        warningCount++;
      } else {
        onlineCount++;
      }
    }
    counterCountWarning = (
      <span
        className="btn btn-warning btn-icon"
        style={{ float: "right", marginLeft: "10px", marginTop: "-5px" }}
      >
        <div style={{ height: "20px", width: "120px" }}>
          {warningCount} Warning
        </div>
      </span>
    );
    counterCountCritical = (
      <span
        className="btn btn-danger btn-icon"
        style={{ float: "right", marginLeft: "10px", marginTop: "-5px" }}
      >
        <div style={{ height: "20px", width: "120px" }}>
          {criticalCount} Offline
        </div>
      </span>
    );
    counterCountOnline = (
      <span
        className="btn btn-success btn-icon"
        style={{ float: "right", marginLeft: "10px", marginTop: "-5px" }}
      >
        <div style={{ height: "20px", width: "120px" }}>
          {onlineCount} Online
        </div>
      </span>
    );

    return (
      <div className="row row-sm mg-t-20">
        <div className="col-12 mg-t-20 mg-lg-t-0">
          <div className="card shadow-base bd-0">
            <div className="card-header pd-x-20 bg-transparent">
              <h6 className="card-title tx-uppercase tx-12 mg-b-0 pd-t-5">
                People Counters
                {counterCountCritical}
                {counterCountWarning}
                {counterCountOnline}
              </h6>
            </div>
            <div className="card-body" style={{ paddingTop: "0px" }}>
              <table className="table mg-b-0 tx-12">
                <thead>
                  <tr className="tx-10">
                    <th className="wd-30p pd-y-5">Counter Name</th>
                    <th className="wd-30p pd-y-5 hidden-xs-down">Issue</th>
                    <th className="wd-25p pd-y-5 hidden-xs-down">
                      Last Online
                    </th>
                    <th className="wd-15p pd-y-5">Status</th>
                  </tr>
                </thead>
                {this.getDataSourceTableBody(this.state.counter_stats)}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  getSensorStats() {
    let criticalCount = 0;
    let warningCount = 0;
    let onlineCount = 0;
    let sensorCountCritical;
    let sensorCountWarning;
    let sensorCountOnline;

    for (let i = 0; i < this.state.sensor_stats.length; i++) {
      if (this.state.sensor_stats[i].status === "CRITICAL") {
        criticalCount++;
      } else if (this.state.sensor_stats[i].status === "WARNING") {
        warningCount++;
      } else {
        onlineCount++;
      }
    }
    sensorCountWarning = (
      <span
        className="btn btn-warning btn-icon"
        style={{ float: "right", marginLeft: "10px", marginTop: "-5px" }}
      >
        <div style={{ height: "20px", width: "120px" }}>
          {warningCount} Warning
        </div>
      </span>
    );
    sensorCountCritical = (
      <span
        className="btn btn-danger btn-icon"
        style={{ float: "right", marginLeft: "10px", marginTop: "-5px" }}
      >
        <div style={{ height: "20px", width: "120px" }}>
          {criticalCount} Offline
        </div>
      </span>
    );
    sensorCountOnline = (
      <span
        className="btn btn-success btn-icon"
        style={{ float: "right", marginLeft: "10px", marginTop: "-5px" }}
      >
        <div style={{ height: "20px", width: "120px" }}>
          {onlineCount} Online
        </div>
      </span>
    );

    return (
      <div className="row row-sm mg-t-20">
        <div className="col-12 mg-t-20 mg-lg-t-0">
          <div className="card shadow-base bd-0">
            <div className="card-header pd-x-20 bg-transparent">
              <h6 className="card-title tx-uppercase tx-12 mg-b-0 pd-t-5">
                Sensors
                {sensorCountCritical}
                {sensorCountWarning}
                {sensorCountOnline}
              </h6>
            </div>
            <div className="card-body" style={{ paddingTop: "0px" }}>
              <table className="table mg-b-0 tx-12">
                <thead>
                  <tr className="tx-10">
                    <th className="wd-30p pd-y-5">Sensor Location</th>
                    <th className="wd-30p pd-y-5">Model &amp; ID</th>
                    <th className="wd-25p pd-y-5 hidden-xs-down">
                      Last Online
                    </th>
                    <th className="wd-15p pd-y-5">Status</th>
                  </tr>
                </thead>
                {this.getSensorTableBody(this.state.sensor_stats)}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  getSensorTableBody(devices) {
    const getFilterMatchStatus = (name) => {
      const filter = this.state.filter.toLowerCase().trim();
      if (filter !== "") {
        if (name.toLowerCase().includes(filter)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };

    //filter for status and filter
    let critical_status_devices = devices.filter(
      (device) =>
        device.status === "CRITICAL" && getFilterMatchStatus(device.name)
    );
    //sort it for uptime and then name
    critical_status_devices = _.sortBy(critical_status_devices, [
      "uptime",
      "name",
    ]);

    critical_status_devices = critical_status_devices.map((device, key) => {
      let ts_last_data =
        moment(device.ts_last_data).format("Do MMM, YYYY - HH:mm") ===
        "Invalid date"
          ? "Unknown"
          : moment(device.ts_last_data).format("Do MMM, YYYY - HH:mm");

      let iconClass = "square-8 bg-danger mg-r-5 rounded-circle";
      let txClass = "tx-danger";
      return (
        <tr key={device.name + key}>
          <td className="valign-middle" title={device.uuid}>
            <span className="tx-inverse" title={device.uuid}>
              {device.location}
            </span>
            <span className="tx-8 d-block">{device.building_name}</span>
          </td>
          <td className="valign-middle" title={device.uuid}>
            <span className="tx-inverse" title={device.uuid}>
              {device.name}
            </span>
          </td>
          <td className="valign-middle hidden-xs-down">{ts_last_data}</td>
          <td className="valign-middle">
            <span className={iconClass}></span>{" "}
            <span className={txClass}>
              <i className="icon ion-android-arrow-up mg-r-5"></i>
              {device.status}
            </span>
          </td>
        </tr>
      );
    });

    //filter for status and filter
    let warning_status_devices = devices.filter(
      (device) =>
        device.status === "WARNING" && getFilterMatchStatus(device.name)
    );
    //sort it for uptime and then name
    warning_status_devices = _.sortBy(warning_status_devices, [
      "uptime",
      "name",
    ]);

    warning_status_devices = warning_status_devices.map((device, key) => {
      let ts_last_data =
        moment(device.ts_last_data).format("Do MMM, YYYY - HH:mm") ===
        "Invalid date"
          ? "Unknown"
          : moment(device.ts_last_data).format("Do MMM, YYYY - HH:mm");

      let txClass = "tx-warning";
      return (
        <tr key={device.name + key}>
          <td className="valign-middle" title={device.uuid}>
            <span className="tx-inverse" title={device.uuid}>
              {device.location}
            </span>
            <span className="tx-8 d-block">{device.building_name}</span>
          </td>
          <td className="valign-middle" title={device.uuid}>
            <span className="tx-inverse" title={device.uuid}>
              {device.name}
            </span>
          </td>
          <td className="valign-middle hidden-xs-down">{ts_last_data}</td>
          <td className="valign-middle">
            <span className={txClass}>
              <i className="icon ion-android-arrow-up mg-r-5"></i>
              {device.status}
            </span>
          </td>
        </tr>
      );
    });

    const noEntities = (
      <tr>
        <td colSpan={6} className="valign-middle">
          No Entities Found
        </td>
      </tr>
    );

    let healthy_status_devices = devices.filter(
      (device) => device.status !== "WARNING" && device.status !== "CRITICAL"
    );

    const lists = (
      <>
        {critical_status_devices}
        {warning_status_devices}
        <tr>
          <td colSpan={6} className="valign-middle tx-success tx-12">
            {healthy_status_devices.length} others online and healthy
          </td>
        </tr>
      </>
    );

    const hasResults =
      [
        ...critical_status_devices,
        ...warning_status_devices,
        ...healthy_status_devices,
      ].length > 0;

    return <tbody>{hasResults ? lists : noEntities}</tbody>;
  }
  getDataSourceTableBody(devices) {
    const getFilterMatchStatus = (name) => {
      const filter = this.state.filter.toLowerCase().trim();
      if (filter !== "") {
        if (name.toLowerCase().includes(filter)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };

    //filter for status and filter
    let critical_status_devices = devices.filter(
      (device) =>
        device.status === "CRITICAL" && getFilterMatchStatus(device.name)
    );
    //sort it for uptime and then name
    critical_status_devices = _.sortBy(critical_status_devices, [
      "uptime",
      "name",
    ]);

    critical_status_devices = critical_status_devices.map((device, key) => {
      let ts_last_data =
        moment(device.ts_last_data).format("Do MMM, YYYY - HH:mm") ===
        "Invalid date"
          ? "Unknown"
          : moment(device.ts_last_data).format("Do MMM, YYYY - HH:mm");

      let iconClass = "square-8 bg-danger mg-r-5 rounded-circle";
      let txClass = "tx-danger";
      return (
        <tr key={device.name + key}>
          <td className="valign-middle" title={device.uuid}>
            <span className="tx-inverse" title={device.uuid}>
              {device.name}
            </span>
            <span className="tx-8 d-block">{device.building_name}</span>
          </td>
          <td className="valign-middle hidden-xs-down">
            <span className="tx-inverse">{device.comment}</span>
          </td>
          <td className="valign-middle hidden-xs-down">{ts_last_data}</td>
          <td className="valign-middle">
            <span className={iconClass}></span>{" "}
            <span className={txClass}>
              <i className="icon ion-android-arrow-up mg-r-5"></i>
              {device.status}
            </span>
          </td>
        </tr>
      );
    });

    //filter for status and filter
    let warning_status_devices = devices.filter(
      (device) =>
        device.status === "WARNING" && getFilterMatchStatus(device.name)
    );
    //sort it for uptime and then name
    warning_status_devices = _.sortBy(warning_status_devices, [
      "uptime",
      "name",
    ]);

    warning_status_devices = warning_status_devices.map((device, key) => {
      let ts_last_data =
        moment(device.ts_last_data).format("Do MMM, YYYY - HH:mm") ===
        "Invalid date"
          ? "Unknown"
          : moment(device.ts_last_data).format("Do MMM, YYYY - HH:mm");

      let iconClass = "square-8 bg-warning mg-r-5 rounded-circle";
      let txClass = "tx-warning";
      return (
        <tr key={device.name + key}>
          <td className="valign-middle">
            <span className="tx-inverse">{device.name}</span>
            <span className="tx-8 d-block">{device.uuid}</span>
          </td>
          <td className="valign-middle hidden-xs-down">
            <span className="tx-inverse">{device.comment}</span>
          </td>
          <td className="valign-middle hidden-xs-down">{ts_last_data}</td>
          <td className="valign-middle">
            <span className={iconClass}></span>{" "}
            <span className={txClass}>
              <i className="icon ion-android-arrow-up mg-r-5"></i>
              {device.status}
            </span>
          </td>
        </tr>
      );
    });
    const noEntities = (
      <tr>
        <td colSpan={6} className="valign-middle">
          No Entities Found
        </td>
      </tr>
    );

    let healthy_status_devices = devices.filter(
      (device) => device.status !== "WARNING" && device.status !== "CRITICAL"
    );

    const lists = (
      <>
        {critical_status_devices}
        {warning_status_devices}
        <tr>
          <td colSpan={6} className="valign-middle tx-success tx-12">
            {healthy_status_devices.length} others online and healthy
          </td>
        </tr>
      </>
    );

    const hasResults =
      [
        ...critical_status_devices,
        ...warning_status_devices,
        ...healthy_status_devices,
      ].length > 0;

    return <tbody>{hasResults ? lists : noEntities}</tbody>;
  }
  render() {
    return (
      <div className="br-mainpanel">
        <DocumentTitle title={"System Health"} />
        <div style={{ padding: "20px" }}>
          {this.getHealthChecks()}
          {this.getInputFilter()}
          {this.getApplianceStats()}
          {this.getCounterStats()}
          {this.getSensorStats()}
        </div>
      </div>
    );
  }
}

export default HealthCheck;
