import React, { Component } from "react";
import "./ManageUsers.scss";
import UserActions from "../../actions/userActions";
import UserStore from "../../stores/userStore";
import GeneralUtils from "../../utils/GeneralUtils";
import { InviteUserPanel } from "./InviteUserPanel";
import _ from "lodash";
import { DocumentTitle } from "../../components/DocumentTitle";
import { Modal } from "../../components/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from 'antd';
import { Icon } from '../../components/Icon';

const NEW_USER = {
  fk_organisation_id: 0,
  fk_role_id: 0,
  mail: "",
  mobile_phone: "",
  name: "",
  title: "",
  status: "new",
  allowed_buildings: [],
  allowed_apps: [],
};

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_user: NEW_USER,
      buildings: UserStore.getOrganisationBuildings(),
      roles: UserStore.getOrganisationRoles(),
      users: UserStore.getUsers(),
      current_user: UserStore.getUser(),
      user_toggle: false,
      loading: true,
      new_user_modal: false,
      modal_object: null,
    };
    this._onUserChange = this._onUserChange.bind(this);
    this.toggleUser = this.toggleUser.bind(this);
    this.getModal = this.getModal.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.resendInvitation = this.resendInvitation.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.changeUserValues = this.changeUserValues.bind(this);
    this.changeNewUserValues = this.changeNewUserValues.bind(this);
    this.validateUserInput = this.validateUserInput.bind(this);
  }
  _onUserChange() {
    this.setState({
      new_user: NEW_USER,
      users: UserStore.getUsers(),
      current_user: UserStore.getUser(),
      buildings: UserStore.getOrganisationBuildings(),
      roles: UserStore.getOrganisationRoles(),
      user_toggle: false,
      loading: false,
      new_user_modal: false,
      modal_object: null,
    });
  }
  componentWillMount() {
    UserStore.addChangeListener(this._onUserChange);
  }
  componentDidMount() {
    let current_user = this.state.current_user;
    if (current_user && current_user.fk_organisation_id) {
      UserActions.getOrganisationUsers(current_user.fk_organisation_id);
    }
  }
  componentWillUnmount() {
    UserStore.removeChangeListener(this._onUserChange);
  }
  noRowsAvailable(numRows) {
    let rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(<td key={i} className="valign-middle"></td>);
    }
    return (
      <tr>
        <td className="valign-middle">
          <div className="d-flex align-items-center">
            <div>
              <div className="tx-inverse">No users available.</div>
            </div>
          </div>
        </td>
        {rows}
      </tr>
    );
  }
  toggleUser() {
    let new_user = NEW_USER;
    new_user.fk_organisation_id = this.state.current_user.fk_organisation_id;

    if (this.state.user_toggle) {
      this.setState({ user_toggle: false, new_user: new_user });
    } else {
      this.setState({ user_toggle: true });
    }
  }
  changeUserValues(user, target_property, event) {
    let users = this.state.users;
    if (
      target_property === "allowed_buildings" ||
      target_property === "allowed_apps"
    ) {
      user[target_property] = event;
      for (let i = 0; i < users.length; i++) {
        if (users[i].user_id === user.user_id) {
          users[i][target_property] = event;
          break;
        }
      }
    } else {
      user[target_property] = event.target.value;
      for (let i = 0; i < users.length; i++) {
        if (users[i].user_id === user.user_id) {
          users[i][target_property] = event.target.value;
          break;
        }
      }
    }

    this.setState({ users: users });
  }
  getModal() {
    if (this.state.new_user_modal === false) return null;

    return (
      <Modal
        hasExit
        backdropUnclickable
        style={{ minWidth: "600px", maxWidth: "600px", overflow: "unset" }}
        toggleOpen={() => this.closeModal()}
      >
        <div className="modal-wrapper">
          <h5 className="modal-title">
            Where would you like to direct {this.state.modal_object.name}?
          </h5>
          <p className="modal-body">
            Please select the destination to which the user will be directed
            upon clicking the invitation link.
          </p>
          <p className="modal-body">
            Choose between new Management Portal (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https:/mgmt-ui.opnbuildings.com"}
            >
              mgmt-ui.opnbuildings.com
            </a>
            ) <br /> and Engineering Portal (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://ui.opnbuildings.com"}
            >
              ui.opnbuildings.com
            </a>
            ).
          </p>
          <div className="modal-buttons">
            <button
              className="btn btn-danger"
              onClick={() => this.closeModal()}
            >
              Cancel
            </button>
            <button
              onClick={() =>
                UserActions.saveUser("MANAGEMENT_UI", this.state.modal_object)
              }
              className="btn btn-success"
            >
              Management Portal
            </button>
            <button
              onClick={() =>
                UserActions.saveUser("ENGINEERING_UI", this.state.modal_object)
              }
              className="btn btn-success"
            >
              Engineering Portal
            </button>
          </div>
        </div>
      </Modal>
    );
  }
  closeModal() {
    this.setState({ new_user_modal: false, modal_object: null });
  }
  changeNewUserValues(user, target_property, event) {
    if (
      target_property === "allowed_buildings" ||
      target_property === "allowed_apps"
    ) {
      user[target_property] = event;
    } else {
      user[target_property] = event.target.value;
    }
    this.setState({ new_user: user });
  }
  validateUserInput(user) {
    let validRole = parseInt(user.fk_role_id) > 0;
    let validOrg = parseInt(user.fk_organisation_id) > 0;
    let validEmail =
      GeneralUtils.stringNotEmpty(user.mail) &&
      GeneralUtils.emailIsValid(user.mail);
    let validName = GeneralUtils.stringNotEmpty(user.name);
    let validApps = user.allowed_apps.length;
    return validRole && validOrg && validEmail && validName && validApps;
  }
  saveUser(user) {
    if (this.validateUserInput(user)) {
      if (user.user_id && user.user_id > 0) {
        UserActions.updateUser(user, true);
      } else if (
        user.allowed_apps.includes("MANAGEMENT_UI") &&
        user.allowed_apps.includes("ENGINEERING_UI")
      ) {
        this.setState({ new_user_modal: true, modal_object: user });
      } else {
        UserActions.saveUser(user.allowed_apps[0], user);
      }
    } else {
      toast("Invalid Input, Please check fields and try again", {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });
    }
  }
  deleteUser(user) {
    if (user.user_id && user.user_id > 0) {
      if (window.confirm("Are you sure you wish to delete this user?")) {
        UserActions.deleteUser(user);
      }
    }
  }

  resendInvitation(user) {
    const userId = user.user_id;
    if (window.confirm("Are you sure you wish to resend the invitation?")) {
      UserActions.resendInvitation(userId);
    }
  }

  getUserTable() {
    let userRows;
    let newUserRow;
    if (this.state.user_toggle) {
      newUserRow = (
        <InviteUserPanel
          buildings={this.state.buildings}
          roles={this.state.roles}
          changeUserValues={this.changeNewUserValues}
          saveUser={this.saveUser}
          deleteUser={this.deleteUser}
          toggleUserCallback={this.toggleUser}
          user={this.state.new_user}
        />
      );
    } else {
      userRows = this.noRowsAvailable(5);
    }
    let users = _.orderBy(this.state.users, "name", "asc");

    if (users.length > 0) {
      userRows = users.map((user, key) => {
        if (user.user_id && user.user_id !== this.state.current_user.user_id) {
          return (
            <InviteUserPanel
              key={user.user_id}
              buildings={this.state.buildings}
              roles={this.state.roles}
              changeUserValues={this.changeUserValues}
              saveUser={this.saveUser}
              deleteUser={this.deleteUser}
              toggleUserCallback={this.toggleUser}
              resendInvitation={this.resendInvitation}
              user={user}
            />
          );
        } else {
          return null;
        }
      });
    }
    let button = (
      <Button
        className="button green"
        icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
        size="small"
        onClick={this.toggleUser}
      >Add User</Button>
    );
    if (this.state.user_toggle) {
      button = (
        <Button
          className="button red"
          icon={<Icon name="Close" color={'#fff'} size={18} />}
          size="small"
          onClick={this.toggleUser}
        >Cancel</Button>
      );
    }

    return (
      <div>
        <div className="br-pagetitle pd-l-0 pd-b-0 mg-b-10">
          {button}
        </div>
        <div className="card bd-0 shadow-base mg-t-5">
          <table className="table mg-b-0 table-contact">
            <thead>
              <tr>
                <th className="wd-15p tx-mont tx-medium">Name</th>
                <th className="wd-20p tx-10-force tx-mont tx-medium">Email</th>
                <th className="wd-10p tx-10-force tx-mont tx-medium">Role</th>
                <th className="wd-15p tx-10-force tx-mont tx-medium">
                  Accessible Apps
                </th>
                <th className="wd-15p tx-10-force tx-mont tx-medium">
                  Accessible Buildings
                </th>
                <th className="wd-15p tx-10-force tx-mont tx-medium">
                  Last Online
                </th>
                <th className="wd-10p tx-10-force tx-mont tx-medium tx-center">
                  Save/Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {newUserRow}
              {userRows}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="br-mainpanel pd-15">
        <DocumentTitle title="Manage Users" />
        {this.getModal()}
        <div className="br-pagetitle mg-b-0">
          <div>
            <h4>Manage Users</h4>
            <p className="mg-b-0">
              Invite New Users, Edit User Access and Remove Existing Users.
            </p>
          </div>
        </div>

        <div>
          <div className="col-12">{this.getUserTable()}</div>
        </div>
      </div>
    );
  }
}

export default ManageUsers;
