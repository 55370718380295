import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./MetersUsageDashboard.scss";
import moment from "moment";
import _ from "lodash";
import GeneralUtils from "../../../utils/GeneralUtils";
import Constants from "../../../constants/index";

// stores and actions
import DashboardsStore from "../../../stores/dashboardsStore";
import DashboardsActions from "../../../actions/dashboardsActions";

// charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";

// components
import { LogoSpinner } from "../../../components/LogoSpinner";
import { TickingTime } from "../../../components/TickingTime";
import { RefreshProgressBar } from "../../../components/RefreshProgressBar";

const REFRESH_SECONDS = 60;

class MetersUsageDashboard extends Component {
  interval = 0;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this._onDataFetch = this._onDataFetch.bind(this);
    this._dataFetch = this._dataFetch.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentWillMount() {
    highchartsMore(Highcharts);
    DashboardsStore.addDashboardDataFetchedListener(this._onDataFetch);
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    DashboardsStore.removeDashboardDataFetchedListener(this._onDataFetch);
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.interval);
  }

  handleResize() {
    window.location.reload();
  }

  componentDidMount() {
    const token = _.get(this.props, "match.params.token");

    if (token) {
      this._dataFetch(token);
      if (this.interval === 0) {
        this.interval = setInterval(
          () => this._dataFetch(token),
          REFRESH_SECONDS * 1000
        );
      }
    }
  }

  _dataFetch(token) {
    DashboardsActions.getDataWithToken(token);
  }

  _onDataFetch() {
    const dashboard_data = DashboardsStore.getMeterUsageDashboardData();
    this.setState({
      ...dashboard_data,
      loading: false,
      timestamp: moment().format("h:mm:ss A"),
    });
  }

  getHeaders() {
    const primary_title = _.get(this.state, "header_left");
    const secondary_title = _.get(this.state, "header_right");

    return (
      <div id="Headers">
        <div className="top-headers">
          <div className="title">{primary_title || "Meter Dashboard"}</div>
          <div className="description">{secondary_title}</div>
        </div>

        <div className="last-updated">
          Last Updated: {this.state.timestamp}
          <RefreshProgressBar
            countdownSeconds={REFRESH_SECONDS}
            animated
            striped
            height={"8px"}
          />
        </div>
      </div>
    );
  }

  getTrendVsLastWeek() {
    const change = _.get(this.state, "usage_difference_percentage");
    const difference = _.get(this.state, "usage_difference");
    let unit = _.get(this.state, "unit");
    if (unit === "kwh") {
      unit = " kWh";
    } else {
      unit = ` ${unit}`;
    }

    const getDifference = () => {
      if (difference === null || difference === undefined) return null;
      const formatted_change = GeneralUtils.getFormattedNumberWithUnit(
        difference,
        unit,
        0
      );
      return formatted_change;
    };

    const getMessage = () => {
      if (change === null || change === undefined) return null;
      const formatted_change = GeneralUtils.getFormattedNumberWithUnit(
        Math.abs(change),
        "%",
        1
      );
      if (change > 0) return formatted_change + " More than previous week.";
      if (change < 0) return formatted_change + " Less than previous week.";
      if (change === 0) return "Is the same as last week.";
    };

    const getIcon = () => {
      if (difference > 0) {
        return (
          <div className="icon-wrapper red">
            <ion-icon className="icon" name="trending-up" />
          </div>
        );
      }
      if (difference < 0) {
        return (
          <div className="icon-wrapper green">
            <ion-icon className="icon" name="trending-down" />
          </div>
        );
      }
    };

    return (
      <div id="TrendVsLastWeek">
        <div className="header">
          <div className="title mg-l-30">Change From Previous Week</div>
        </div>
        <div className="body">
          <div className="text-panel">
            <div className="value">{getDifference()}</div>
            <div className="value-subtext">{getMessage()}</div>
          </div>
          <div className="icon-panel">{getIcon()}</div>
        </div>
      </div>
    );
  }

  getComparisonChart() {
    const selectedDay = moment().format("dddd");
    const usage = _.get(this.state, "usage");
    const predictions = _.get(this.state, "predictions");
    const last_week = _.get(this.state, "last_week");

    const getChart = () => {
      const screenWidth = window.innerWidth;

      const getFontSizes = (screenWidth) => {
        let legendItem = "12px";
        let categoryItem = "16px";
        let title = "12px";
        let yAxisLabel = "12px";
        let itemDistance = 15;
        let symbolPadding = 5;
        let lineWidth = 3;

        if (screenWidth < 1920) {
          categoryItem = "12px";
        }

        if (screenWidth > 1920) {
          legendItem = "18px";
          categoryItem = "20px";
          title = "18px";
          yAxisLabel = "15px";
          itemDistance = 15;
          lineWidth = 5;
        }

        if (screenWidth > 2560) {
          legendItem = "28px";
          categoryItem = "30px";
          title = "30px";
          yAxisLabel = "24px";
          itemDistance = 20;
          symbolPadding = 10;
          lineWidth = 7;
        }

        return {
          legendItem,
          categoryItem,
          title,
          yAxisLabel,
          itemDistance,
          symbolPadding,
          lineWidth,
        };
      };

      const options = {
        credits: false,
        title: {
          text: null,
        },
        plotOptions: {
          column: {
            grouping: false,
          },
        },
        yAxis: {
          labels: {
            style: {
              fontSize: getFontSizes(screenWidth).yAxisLabel,
            },
            formatter: function () {
              return Math.abs(this.value) + " kWh";
            },
          },
          title: {
            enabled: true,
            text: this.state.meter_unit,
            margin: 10,
            style: {
              fontSize: getFontSizes(screenWidth).title,
            },
          },
        },
        xAxis: {
          categories: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
          labels: {
            style: {
              fontSize: getFontSizes(screenWidth).categoryItem,
            },
          },
        },
        legend: {
          floating: true,
          align: "right",
          verticalAlign: "top",
          y: -17,
          itemDistance: getFontSizes(screenWidth).itemDistance,
          symbolPadding: getFontSizes(screenWidth).symbolPadding,
          backgroundColor: "white",
          itemStyle: {
            fontSize: getFontSizes(screenWidth).legendItem,
          },
        },
        tooltip: {
          valueDecimals: 0,
          shared: true,
          crosshairs: true,
          valueSuffix: " kWh",
        },
        series: [
          {
            type: "column",
            name: "Usage",
            data: usage,
            color: "#5f83b7",
          },
          {
            type: "column",
            name: "Prediction",
            data: predictions,
            color: "#dedede",
          },
          {
            type: "spline",
            name: "Last Week",
            color: Constants.PASTEL_BROWN,
            lineWidth: getFontSizes(screenWidth).lineWidth,
            data: last_week,
            marker: {
              lineColor: Highcharts.getOptions().colors[3],
              fillColor: Constants.PASTEL_BROWN,
            },
          },
        ],
      };

      return (
        <HighchartsReact
          containerProps={{ className: "chart-container" }}
          highcharts={Highcharts}
          options={options}
        />
      );
    };

    return (
      <div id="UsageChart">
        <div className="header mg-b-10 mg-l-30">
          <div className="title">Usage - Week So Far</div>
        </div>
        <div className={`body ${selectedDay}`}>{getChart()}</div>
      </div>
    );
  }

  getHeader() {
    return (
      <div className="dashboard-title">
        <div className="building-name">
          {this.state.building_id > 0 ? this.state.building_name : ""}
        </div>
        <div className="title">
          {this.state.meter_id > 0 ? this.state.meter_name : ""}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="MetersUsageDashboard" className="floorplan-background">
        <div className="main-wrapper">
          <LogoSpinner loading={this.state.loading} />;
          <div className="headers tile">
            <div className="content-wrapper">{this.getHeaders()}</div>
          </div>
          <div className="trend-vs-last-week tile">
            <div className="content-wrapper">{this.getTrendVsLastWeek()}</div>
          </div>
          <div className="chart-usage-vs-last-week tile">
            <div className="content-wrapper">{this.getComparisonChart()}</div>
          </div>
        </div>
        <div className="dashboard-footer">
          <div className="clock-wrapper">
            <TickingTime format="MMMM Do YYYY, h:mm:ss A" />
          </div>
          <div className="powered-by">
            <h3>Powered by</h3>
            <Link
              to={{ pathname: "https://www.opnbuildings.com" }}
              target="_blank"
            >
              <span>
                <img alt="OPNBuildings Logo" src="/img/OPNLogo.png" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default MetersUsageDashboard;
