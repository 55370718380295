import React, { useState, useMemo, useCallback } from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import styles from './TimeframePickerMini.module.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
    weekStart: 1,
});

const { RangePicker } = DatePicker;
const DATE_FORMAT = 'YYYY-MM-DD';

const configTheme = {
    token: {
        colorPrimary: '#1ca289',
    }
}

const TimeframePickerMini = ({
    onChange,
    start,
    end,
    granularity = 'custom',
    unix = false,
    disabled = false,
    spacing = '5px',
    format,
    placement,
}) => {
    const [open, setOpen] = useState(false);
    const [pickerGranularity] = useState(granularity);


    const pickerFormat = useMemo(() => ({
        custom: "DD MMM YY",
        day: 'DD MMM YY',
        week: `DD MMM YY [- ${moment(unix ? start * 1000 : start).endOf('isoWeek').format('DD MMM YYYY')}]`,
        month: 'MMM YY',
        quarter: '[Q]Q-YY',
        year: 'YYYY',
    }), [start, unix]);

    const dateAdapter = useMemo(() => (date) => unix ? moment(date * 1000).format(DATE_FORMAT) : moment(date).format(DATE_FORMAT), [unix]);


    const handleDateChange = (date, dateString, selectedGranularity = null) => {
        let newStart, newEnd;
        const granularity = selectedGranularity || pickerGranularity;

        setOpen(false)

        const formatDates = (rawStart, rawEnd, granularity) => {
            if (unix) {
                newStart = moment(rawStart).unix();
                newEnd = moment(rawEnd).endOf('day').unix();
            } else {
                newStart = moment(rawStart).format(DATE_FORMAT);
                newEnd = moment(rawEnd).format(DATE_FORMAT);
            }
        };

        if (granularity === 'custom' && date) {
            const rawStart = _.get(date[0], '$d');
            const rawEnd = _.get(date[1], '$d');
            formatDates(rawStart, rawEnd, granularity);
        } else if (granularity === 'week') {
            const rawDate = _.get(date, '$d');
            newStart = moment(rawDate).startOf('isoWeek').format(DATE_FORMAT);
            newEnd = moment(rawDate).endOf('isoWeek').format(DATE_FORMAT);
            if (unix) {
                newStart = moment(newStart).unix();
                newEnd = moment(newEnd).endOf('day').unix();
            }
        } else {
            const rawDate = _.get(date, '$d');
            newStart = moment(rawDate).startOf(granularity).format(DATE_FORMAT);
            newEnd = moment(rawDate).endOf(granularity).format(DATE_FORMAT);
            if (unix) {
                newStart = moment(newStart).unix();
                newEnd = moment(newEnd).endOf('day').unix();
            }
        }

        onChange(newStart, newEnd, granularity);
    };

    const handleOpenChange = useCallback((status) => {
        setOpen(status);

        // Centering calendar
        const centerPopup = () => {
            const containerPrefix = styles.popupStyle;
            const inputPrefix = styles.timeframePickerMini;

            const getPopupElement = () => {
                let popup = document.querySelector(`.${containerPrefix}.ant-picker-dropdown-range`);
                if (!popup) {
                    popup = document.querySelector(`.${containerPrefix}.ant-picker-dropdown`);
                }
                return popup;
            };

            const getInputElement = (granularity) => {
                return granularity === 'custom'
                    ? document.querySelector(`.${inputPrefix} .ant-picker-range`)
                    : document.querySelector(`.${inputPrefix} .ant-picker-input`);
            };

            const setPopupPosition = (popup, inputRect, granularity) => {
                const viewportWidth = window.innerWidth;
                const popupWidth = popup.getBoundingClientRect().width || (granularity === 'custom' ? 576 : 288);
                let offsetLeft = inputRect.left + inputRect.width / 2 - popupWidth / 2;

                offsetLeft = Math.max(0, Math.min(offsetLeft, viewportWidth - popupWidth));

                popup.style.left = `${offsetLeft}px`;
                popup.style.top = `${inputRect.bottom + 6}px`;
            };

            const popup = getPopupElement();
            if (popup) {
                const granularity = popup.classList.contains('ant-picker-dropdown-range') ? 'custom' : 'other';
                const input = getInputElement(granularity);

                if (input) {
                    const inputRect = input.getBoundingClientRect();
                    const arrow = popup.querySelector(`.${containerPrefix} .ant-picker-range-arrow`);
                    if (arrow) {
                        arrow.style.display = 'none';
                    }
                    setPopupPosition(popup, inputRect, granularity);
                }
            }
        };

        // Function to handle date change
        const dateChangeHandler = (cell) => {
            const listener = () => {
                const date = cell.getAttribute('title');
                let granularity = '';
                if (date.length === 10) {
                    granularity = 'day';
                } else if (date.length === 7) {
                    granularity = 'month';
                } else if (date.length === 4) {
                    granularity = 'year';
                }
                handleDateChange(dayjs(date), [date, date], granularity);
            };
            cell.addEventListener('click', listener);
            cell.listenerRef = listener; // Store the reference for later removal
        };

        if (status) {
            setTimeout(() => {
                if (!placement) centerPopup();
                // Add event listeners when popup opens
                const containerPrefix = styles.popupStyle;
                const selectedCells = document.querySelectorAll(`.${containerPrefix} .ant-picker-cell-selected`);
                selectedCells.forEach((c) => dateChangeHandler(c));
            }, 0);
        } else {
            // Remove event listeners when popup closes
            const containerPrefix = styles.popupStyle;
            const selectedCells = document.querySelectorAll(`.${containerPrefix} .ant-picker-cell-selected`);
            selectedCells.forEach(cell => {
                const listener = cell.listenerRef;
                if (listener) {
                    cell.removeEventListener('click', listener);
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    const disableFutureDates = useMemo(() => (current) => current && current > dayjs().endOf('day'), []);

    const getPresets = () => {
        return [
            {
                label: 'Today', value: [
                    dayjs().startOf("day"),
                    dayjs()]
            },
            {
                label: 'Yesterday', value: [
                    dayjs().subtract(1, "day").startOf("day"),
                    dayjs().subtract(1, "day").endOf("day")]
            },
            {
                label: 'This Week', value: [
                    dayjs().startOf('week').day(1),
                    dayjs()]
            },
            {
                label: 'Last Week', value: [
                    dayjs().subtract(1, 'week').startOf('week').day(1),
                    dayjs().subtract(1, 'week').endOf('week').day(0)]
            },
            {
                label: 'This Month', value: [
                    dayjs().startOf('month'),
                    dayjs()]
            },
            {
                label: 'Last Month', value: [
                    dayjs().subtract(1, 'month').startOf('month'),
                    dayjs().subtract(1, 'month').endOf('month')]
            },
            {
                label: 'Year Ago', value: [
                    dayjs().subtract(1, 'year').startOf('day'),
                    dayjs().subtract(1, 'year')]
            },
            {
                label: 'Year to Date', value: [
                    dayjs().startOf('year'),
                    dayjs()]
            },
        ];
    };


    const renderPicker = () => {
        const displayStart = dateAdapter(start);
        const displayEnd = dateAdapter(end);

        if (pickerGranularity === 'custom') {
            return (
                <RangePicker
                    onChange={handleDateChange}
                    value={start && end ? [dayjs(displayStart), dayjs(displayEnd)] : [null, null]}
                    size="small"
                    variant="borderless"
                    className={styles.picker}
                    onOpenChange={handleOpenChange}
                    open={open}
                    popupClassName={open ? styles.popupStyle : styles.popupStyleHidden}
                    disabled={disabled}
                    separator={'-'}
                    disabledDate={disableFutureDates}
                    format={format || pickerFormat[pickerGranularity]}
                    allowClear={false}
                    suffixIcon={null}
                    placement={placement}
                    presets={getPresets()}
                />
            );
        } else {
            return (
                <DatePicker
                    value={start ? dayjs(displayStart) : null}
                    picker={pickerGranularity}
                    onChange={handleDateChange}
                    size="small"
                    variant="borderless"
                    className={styles.picker}
                    onOpenChange={handleOpenChange}
                    open={open}
                    popupClassName={open ? styles.popupStyle : styles.popupStyleHidden}
                    disabled={disabled}
                    disabledDate={disableFutureDates}
                    format={format || pickerFormat[pickerGranularity]
                    }
                    allowClear={false}
                    suffixIcon={null}
                    placement={placement}
                />
            );
        }
    };

    return (
        <ConfigProvider theme={configTheme}>
            <div className={styles.timeframePickerMini} style={{
                '--spacing': spacing
            }}>
                <div className={styles.pickerWrapper}>
                    <div className={styles.pickerElement}>{renderPicker()}</div>
                </div>
            </div>
        </ConfigProvider>
    );
};

TimeframePickerMini.propTypes = {
    onChange: PropTypes.func.isRequired,
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unix: PropTypes.bool,
    spacing: PropTypes.string,
    granularity: PropTypes.oneOf(['custom', 'day', 'week', 'month', 'quarter', 'year']),
    format: PropTypes.string,
    placement: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight'])
};

export default TimeframePickerMini;
