import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";
import "react-toastify/dist/ReactToastify.css";

/**
 * Actions related to fetching entity types for buildings. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const entityTypeActions = new EntityTypeActions();
 * entityTypeActions.getBuildingEntityTypes('building123');
 */
class EntityTypeActions {
  /**
   * Fetches the entity types for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} building_id - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildingEntityTypes(building_id) {
    return GeneralUtils.get({
      url: `entity-type/building/${building_id}`,
      actionType: ActionTypes.ENTITY_TYPES_FETCHED,
    });
  }
}

export default new EntityTypeActions();
