import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';
import moment from 'moment';

const CHANGE = 'DATE_CHANGE';
let _ts_start = moment().startOf("day").unix();
let _ts_end = moment().unix();
let _granularity = 'day'


class GeneralStore extends EventEmitter {

    constructor() {
        super();
        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
            case ActionTypes.SET_DATES:
                this._setDates(action.payload);
                break;
            default:
                break;
        }
    }

    _setDates(payload) {
        _ts_start = payload.ts_start;
        _ts_end = payload.ts_end
        _granularity = payload.granularity
        this.emit(CHANGE);
    }

    getStartDate() {
        return _ts_start;
    }

    getEndDate() {
        return _ts_end;
    }

    getGranularity() {
        return _granularity;
    }

    // Hooks a React component's callback to the CHANGED event.
    addChangeListener(callback) {
        this.on(CHANGE, callback);
    }

    // Removes the listener from the CHANGED event.
    removeChangeListener(callback) {
        this.removeListener(CHANGE, callback);
    }

}

export default new GeneralStore();