import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./ButtonCell.scss";
import { Icon } from "../../../../components/Icon";

class ButtonCell extends PureComponent {
  render() {
    return (
      <td className="ButtonCell tx-14">
        <div className="buttons-wrapper d-flex">
          {this.props.buttons.map((b) => {
            return (
              <button
                key={b.label}
                title={b.label}
                onClick={(e) => {
                  e.stopPropagation();
                  b.clickHandler();
                }}
                className="button"
                style={{
                  "--buttonColor": b.buttonColor,
                }}
              >
                <Icon name={b.icon} color = {b.iconColor}/>
              </button>
            );
          })}
        </div>
      </td>
    );
  }
}

ButtonCell.propTypes = {
  firstRow: PropTypes.any,
  secondRow: PropTypes.any,
};

export default ButtonCell;
