import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching wellness data. This class interfaces with the API
 * to retrieve the latest conditions in zones and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const wellnessActions = new WellnessActions();
 * wellnessActions.getLatestConditionsInZones('building123', 'some-token');
 */
class WellnessActions {
  /**
   * Fetches the latest conditions in zones for a specific building from the API and dispatches an action to store this data.
   * Optionally includes a token in the request URL if provided.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @param {string} [token] - An optional token for the request.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getLatestConditionsInZones(buildingId, token) {
    let url = `wellness/building/${buildingId}/latest`;
    if (token && token.length > 0) {
      url += `?token=${token}`;
    }
    return GeneralUtils.get({
      url: url,
      actionType: ActionTypes.ZONES_FETCHED,
      failToast: "Unable to fetch latest conditions in zones, please try again",
    });
  }
}

export default new WellnessActions();
