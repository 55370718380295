import React from "react";
import styles from "./Hvac.module.scss";
import Constants from "../../../constants";
import _ from "lodash";

//components
import { DocumentTitle } from "../../../components/DocumentTitle";
import { LogoSpinner } from "../../../components/LogoSpinner";
import HvacOverviewStore from "../../../stores/hvacOverviewStore";
import HvacOverviewActions from "../../../actions/hvacOverviewActions";
import { AntTable } from "../../../components/AntTable";

class Hvac extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };

    this.onHvacFetch = this.onHvacFetch.bind(this);
    this.rowClickHandler = this.rowClickHandler.bind(this);
  }

  componentWillMount() {
    HvacOverviewStore.addHvacOverviewFetchListener(this.onHvacFetch);
  }

  componentWillUnmount() {
    HvacOverviewStore.removeHvacOverviewFetchListener(this.onHvacFetch);
  }

  componentDidMount() {
    this.setState({ data: HvacOverviewStore.getHvacOverview() });
    this.fetchData();
  }

  fetchData() {
    this.setState(
      {
        loading: true,
      },
      () => HvacOverviewActions.getHvacOverview()
    );
  }

  onHvacFetch() {
    const data = HvacOverviewStore.getHvacOverview();
    this.setState({ data: data, loading: false });
  }

  rowClickHandler(entry) {
    const building_id = _.get(entry, "building.id");
    const entity_id = _.get(entry, "entity.id");

    if (building_id && entity_id) {
      sessionStorage.setItem("zoneLastLoc", this.props.location.pathname);
      this.props.history.push("/hvac/" + entity_id);
    }
  }

  getTable() {
    const columns = [
      {
        title: 'Building Name',
        dataIndex: ['building', 'name'],
        key: 'buildingName',
        defaultSortOrder: 'ascend',
        width: '25%',
        filterable: true,
      },
      {
        title: 'Asset Name',
        dataIndex: ['asset', 'name'],
        key: 'assetName',
        width: '25%',
        filterable: true,
      },
      {
        title: 'Description',
        dataIndex: ['asset', 'description'],
        key: 'zoneDescription',
        width: '30%',
        filterable: true,
      },
      {
        title: 'Type',
        dataIndex: ['asset', 'type'],
        key: 'assetType',
        render: (text) => _.get(Constants, text, null),
        width: '20%',
      }
    ];

    const tags = [
      {
        label: "All",
        condition: function (record) {
          return true
        },
        default: true,
      },
      {
        label: "Cooling",
        condition: function (record) {
          return record && record.asset && Constants.COOLING_ASSETS_FULL.includes(record.asset.type)
        },
      },
      {
        label: "Heating",
        condition: function (record) {
          return record && record.asset && Constants.HEATING_ASSETS_FULL.includes(record.asset.type)
        },
      },
      {
        label: "Air Handling",
        condition: function (record) {
          return record && record.asset && Constants.AIR_HANDLING_ASSETS_FULL.includes(record.asset.type)
        },
      },
      {
        label: "Terminal",
        condition: function (record) {
          return record && record.asset && Constants.TERMINAL_ASSETS_FULL.includes(record.asset.type)
        },
      },
    ]

    return <AntTable
      tableId="Hvac Assets"
      columns={columns}
      dataSource={this.state.data}
      hasInputFilter={true}
      virtual
      scroll={{ x: 1000 }}
      rowKey={(record) => `${record.asset.id}`}
      onRow={(record) => ({
        onClick: () => this.rowClickHandler(record),
      })}
      tags={tags}
    />
  }

  render() {
    return (
      <div
        id="Hvac"
        className="br-mainpanel br-profile-page floorplan-background"
        style={{ scrollY: "scroll" }}
      >
        <DocumentTitle title="HVAC Overview" />
        <LogoSpinner loading={this.state.loading} />
        <div className="br-container">
          <div className="row">
            <div className={`col mg-t-30 d-flex ${styles.tableWrapper}`}>
              {this.getTable()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hvac;
