import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";
import _ from "lodash";
import moment from "moment";
import GeneralUtils from "../utils/GeneralUtils";
import ChartUtils from "../utils/ChartUtils";

const METER_DETAILS_CONSUMPTION_FETCHED = Constants.METER_DETAILS_CONSUMPTION_FETCHED;

const DATA_PROPERTIES = {
  consumption: ['energy kwh', 'volume liters'],
  kwConsumption: ['energy kwh'],
  kw: ['power kw', 'energy kwh'],
  kvarh: ['energy kvarh'],
  rollingAverage: ['rolling average']
};

const INITIAL_STATE = {
  entity: {},
  profiles: {
    consumption: {
      current: {},
      rollingAverage: {}
    },
    kw: {},
    kvarh: {}
  },
  unit: ""
};

let _state = {...INITIAL_STATE};

/**
 * MeterDetailsStore manages state and behavior related to meter details data within a React application,
 * handling operations such as fetching, storing, and updating meter-related data. This store integrates
 * with an event-driven architecture using an EventEmitter to facilitate reactive updates across components
 * that depend on meter data.
 *
 * Responsibilities:
 * - Fetching meter consumption data and storing it in a local state.
 * - Emitting changes to meter data to any subscribed components.
 * - Providing getter methods for accessing specific data slices.
 *
 * @extends EventEmitter
 * @uses Dispatcher to register and handle actions
 * @uses GeneralUtils and ChartUtils for data manipulation and formatting
 */
class MeterDetailsStore extends EventEmitter {
  constructor() {
    super();
    Dispatcher.register(this._registerToActions.bind(this));
  }

  /**
   * Registers the actions this store will respond to with the Dispatcher.
   * Specifically listens for the METER_DETAILS_CONSUMPTION_FETCHED action to update the store's state.
   * 
   * @param {Object} action - The action dispatched from somewhere within the application
   */
  _registerToActions(action) {
    if (action.actionType === METER_DETAILS_CONSUMPTION_FETCHED) {
      this._storeMeterData(action.payload);
    }
  }

 /**
   * Subscribes a callback function to the METER_DETAILS_CONSUMPTION_FETCHED event.
   *
   * @param {function} callback - The callback to execute when data is fetched
   */
  addMeterDetailsFetchListener(callback) {
    this.on(METER_DETAILS_CONSUMPTION_FETCHED, callback);
  }

  /**
   * Unsubscribes a callback function from the METER_DETAILS_CONSUMPTION_FETCHED event.
   *
   * @param {function} callback - The callback to remove
   */
  removeMeterDetailsFetchListener(callback) {
    this.removeListener(METER_DETAILS_CONSUMPTION_FETCHED, callback);
  }
  
  /**
   * Resets the store's state to its initial state.
   */
  _clear() {
    _state = {...INITIAL_STATE};
  }

  /**
   * Initializes the store's state using data from the fetched JSON payload.
   * It sets up the initial state of entity details and consumption profiles based on the provided data.
   *
   * @param {Object} json - The JSON payload containing meter data
   */
  _initializeState(json) {
    this._clear();

    _state.entity = _.get(json.data, '[0].entity_type');

    _state.profiles.consumption.current = ChartUtils.getLineConsumptionSeries(
      "Current",
      "#4caf50",
      Constants.SOLID,
      2,
      true,
      true
    );

    _state.profiles.consumption.rollingAverage = ChartUtils.getLineConsumptionSeries(
      "Average",
      "#01CB99",
      Constants.DOT,
      1,
      true,
      true
    );

    _state.profiles.kw = ChartUtils.getLineConsumptionSeries(
      "Kilowatts",
      Constants.BLUE,
      Constants.SOLID,
      2,
      true,
      true
    );

    _state.profiles.kvarh = ChartUtils.getLineConsumptionSeries(
      "Kilovolt-ampere hour",
      Constants.BLUE,
      Constants.SOLID,
      2,
      true,
      true
    );
  }

  /**
   * Calculates the conversion rate for kilowatts based on the bucket type.
   *
   * @param {string} bucketType - The bucket type (e.g., 'FIFTEEN_MIN', 'HOUR', 'DAY')
   * @returns {number} The conversion rate for the given bucket type
   */  
  _getKwConversionRate(bucketType) {
    return bucketType === Constants.BUCKET_FIFTEEN_MIN ? 0.25 : (bucketType === Constants.BUCKET_HOUR ? 1 : 24);
  }

  /**
   * Stores meter data fetched from the API into the store's state and emits a change event.
   * Processes each data item based on its property type and calculates additional derived data.
   *
   * @param {Object} json - The JSON payload containing meter data
   */
  _storeMeterData(json) {
    if (_.isEmpty(json)) return;

    this._initializeState(json);

    const kwConversionRate = this._getKwConversionRate(json.bucketType);
    let calculatedKwData = [];

    json.data
      .filter((item) => item.property && item.data)
      .forEach((item, i) => {
        
        let data = item.data.map(({ timestamp, value }) => ({
          x: moment(timestamp).valueOf(),
          y: GeneralUtils.roundNumber(value, 2),
        }));

        const propertyName = _.get(item, 'property.name', '').toLowerCase();

        // Consumption
        if (DATA_PROPERTIES.consumption.includes(propertyName)) {
          _state.profiles.consumption.current.data = data;
          _state.unit = item.property.data_type === "kwh" ? " kWh" : " Liters";

          // Kilowatts Calculated - Calculate if no actual kw data available
          if (DATA_PROPERTIES.kwConsumption.includes(propertyName)) {
            calculatedKwData = data.map(({ x, y }) => ({
              x: x,
              y: GeneralUtils.roundNumber(y / kwConversionRate, 2)
            }));
          }
        }

        // Kilowatts Mapped - Use actual kw data if available
        if (DATA_PROPERTIES.kw.includes(propertyName)) {
          if (!_state.profiles.kw.data.length) {
            _state.profiles.kw.data = calculatedKwData
          } else {
            _state.profiles.kw.data = data
          }
        }

        // Consumption Rolling Average
        if (propertyName.includes(DATA_PROPERTIES.rollingAverage)) {
          _state.profiles.consumption.rollingAverage.data = data;
        }

        // Kvarh
        if (DATA_PROPERTIES.kvarh.includes(propertyName)) {
          _state.profiles.kvarh.data = data;
        }
      });

    this.emit(METER_DETAILS_CONSUMPTION_FETCHED);
  }



  /**
   * Retrieves the entity details for the currently focused meter.
   *
   * @returns {Object} The entity object containing meter details.
   */
  getEntity() {
    return _state.entity;
  }

  /**
   * Retrieves the consumption data for the meter, including current consumption and rolling averages.
   *
   * @returns {Array<Object>} An array containing current and rolling average consumption data series.
   */
  getConsumptionData() {
    const { current, rollingAverage } = _state.profiles.consumption;
    return current.data.length && rollingAverage.data.length ? [current, rollingAverage] : [];
  }

  /**
   * Retrieves the unit of measure for the consumption data, such as kWh or liters, depending on the meter's configuration.
   *
   * @returns {string} The unit of consumption, indicating how consumption data should be interpreted.
   */
  getConsumptionUnit() {
    return _state.unit;
  }

  /**
   * Retrieves the data series for kilowatt (kW) consumption.
   *
   * @returns {Array<Object>|[]} An array containing the kilowatt data series if available, otherwise an empty array.
 */
  getKwData() {
    const { kw } = _state.profiles;
    return kw.data.length ? [kw] : [];
  }

  /**
   * Retrieves the data series for kilovolt-ampere reactive hour (kVarh) consumption.
   *
   * @returns {Array<Object>|[]} An array containing the kVarh data series if available, otherwise an empty array.
   */
  getKvarhData() {
    const { kvarh } = _state.profiles;
    return kvarh.data.length ? [kvarh] : [];
  }
}

export default new MeterDetailsStore();
