import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';

const CHANGE = 'ORGANISATION_CHANGE';
let _organisations = [];
let _estates = [];
let _buildings = [];
let _organisation = {
    name: '',
    organisation_id: 0,
    description: ''
}

class OrganisationStore extends EventEmitter {

    constructor() {
        super();
        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
            case ActionTypes.ORGANISATION_FETCHED:
                this._storeOrganisation(action.payload);
                break;
            case ActionTypes.ORGANISATIONS_FETCHED:
                this._storeOrganisations(action.payload);
                break;
            case ActionTypes.BUILDING_DELETED:
                this._removeBuilding(action.payload);
                break;
            case ActionTypes.BUILDING_SAVED:
                this._saveBuilding(action.payload);
                break;
            default:
                break;
        }
    }

    // Hooks a React component's callback to the CHANGED event.
    addChangeListener(callback) {
        this.on(CHANGE, callback);
    }

    // Removes the listener from the CHANGED event.
    removeChangeListener(callback) {
        this.removeListener(CHANGE, callback);
    }

    _removeBuilding(response) {
        if (response.deleted_building_id) {
            let buildings = [];
            for (let i = 0; i < _buildings.length; i++) {
                if (_buildings[i].building_id !== response.deleted_building_id) {
                    buildings.push(_buildings[i]);
                }
            }
            _buildings = buildings;
            this.emit(CHANGE);
        }
    }

    _saveBuilding(response) {
        _buildings.push(response)
        this.emit(CHANGE);
    }

    _storeOrganisations(json) {
        _organisations = json;
        if (json.length) {
            let combined_estates = [];
            json.forEach(org => {
                combined_estates = [...combined_estates, ...org.estates]
            })
            this.setEstates(combined_estates)
        }

        this.emit(CHANGE);
    }

    _storeOrganisation(json) {
        _organisation = json;
        if (json.estates) {
            this.setEstates(json.estates)
        }

        this.emit(CHANGE);
    }

    setEstates(estates) {
        _estates = estates;
        this.setBuildings(estates)
    }

    setBuildings(estates) {
        _buildings = [];
        for (var i = 0; i < estates.length; i++) {
            if (estates[i].buildings) {
                for (var x = 0; x < estates[i].buildings.length; x++) {
                    if(estates[i].buildings[x].status !=='disabled'){
                        _buildings.push(estates[i].buildings[x]);
                    }
                }
            }
        }
    }

    getOrganisations() {
        return _organisations;
    }

    getOrganisation() {
        return _organisation;
    }

    getEstates() {
        return _estates;
    }

    getBuildings() {
        return _buildings;
    }

}

export default new OrganisationStore();