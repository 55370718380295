import React, { Component } from 'react';
import './ManageBuildings.scss';
import OrganisationActions from '../../actions/organisationActions';
import OrganisationStore from '../../stores/organisationStore';
import BuildingActions from '../../actions/buildingActions';
import UserStore from '../../stores/userStore';
import { DocumentTitle } from '../../components/DocumentTitle';
import { Filter } from '../../components/Filter';
import Constants from '../../constants';
import { Spinner } from '../../components/Spinner';
import { Button } from 'antd';
import { Icon } from '../../components/Icon';


class ManageBuildings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisation: OrganisationStore.getOrganisation(),
      estates: OrganisationStore.getEstates(),
      buildings: OrganisationStore.getBuildings(),
      loading: true,
      filter: ''
    };
    this._onOrganisationChange = this._onOrganisationChange.bind(this);
    this._deleteBuilding = this._deleteBuilding.bind(this);
    this._buildingClick = this._buildingClick.bind(this);
    this._addBuilding = this._addBuilding.bind(this);
  }
  _onOrganisationChange() {
    this.setState({
      organisation: OrganisationStore.getOrganisation(),
      estates: OrganisationStore.getEstates(),
      buildings: OrganisationStore.getBuildings(),
      loading: false
    })
  }
  componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
  }
  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
  }
  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
  }
  _deleteBuilding(building) {
    if (building.building_id && building.building_id > 0) {
      if (window.confirm("Are you sure you wish to delete this building? All underlying data will be permanently deleted!")) {
        BuildingActions.deleteBuilding(building.building_id);
      }
    }
  }
  _buildingClick(event, building) {
    let route = "/manage-buildings/" + building.building_id;
    this.props.history.push(route);
  }
  _addBuilding() {
    let route = "/manage-buildings/add";
    this.props.history.push(route);
  }
  getBuildingGrid() {
    const buildings = this.state.buildings;
    return <div className="row mg-b-25 pd-xs-l-10 pd-xs-r-10 mg-t-20">
      {buildings
        .filter(building => {
          return building.name.toLowerCase().includes(this.state.filter.toLowerCase()) ||
            building.description.toLowerCase().includes(this.state.filter.toLowerCase()) ||
            this.state.filter.toLowerCase().includes(building.status)
        })
        .map(building => {
          let statusColor = Constants.GREEN;
          if (building.status.toLowerCase() !== 'active') {
            statusColor = Constants.RED;
          }
          let mondayHours = "";
          let tuesdayHours = "";
          let wednesdayHours = "";
          let thursdayHours = "";
          let fridayHours = "";
          let saturdayHours = "";
          let sundayHours = "";

          Object.keys(building.building_hours).forEach(day_of_week => {
            let hours_array = building.building_hours[day_of_week];
            if (Number.parseInt(day_of_week) === 1) {
              for (let i = 0; i < hours_array.length; i++) {
                if (i === (hours_array.length - 1)) {
                  mondayHours = mondayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":")));
                } else {
                  mondayHours = mondayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":"))) + ",";
                }
              }
            } else if (Number.parseInt(day_of_week) === 2) {
              for (let i = 0; i < hours_array.length; i++) {
                if (i === (hours_array.length - 1)) {
                  tuesdayHours = tuesdayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":")));
                } else {
                  tuesdayHours = tuesdayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":"))) + ",";
                }
              }
            } else if (Number.parseInt(day_of_week) === 3) {
              for (let i = 0; i < hours_array.length; i++) {
                if (i === (hours_array.length - 1)) {
                  wednesdayHours = wednesdayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":")));
                } else {
                  wednesdayHours = wednesdayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":"))) + ",";
                }
              }
            } else if (Number.parseInt(day_of_week) === 4) {
              for (let i = 0; i < hours_array.length; i++) {
                if (i === (hours_array.length - 1)) {
                  thursdayHours = thursdayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":")));
                } else {
                  thursdayHours = thursdayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":"))) + ",";
                }
              }
            } else if (Number.parseInt(day_of_week) === 5) {
              for (let i = 0; i < hours_array.length; i++) {
                if (i === (hours_array.length - 1)) {
                  fridayHours = fridayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":")));
                } else {
                  fridayHours = fridayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":"))) + ",";
                }
              }
            } else if (Number.parseInt(day_of_week) === 6) {
              for (let i = 0; i < hours_array.length; i++) {
                if (i === (hours_array.length - 1)) {
                  saturdayHours = saturdayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":")));
                } else {
                  saturdayHours = saturdayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":"))) + ",";
                }
              }
            } else {
              for (let i = 0; i < hours_array.length; i++) {
                if (i === (hours_array.length - 1)) {
                  sundayHours = sundayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":")));
                } else {
                  sundayHours = sundayHours + (hours_array[i].start.slice(0, hours_array[i].start.lastIndexOf(":")) + " - " + hours_array[i].end.slice(0, hours_array[i].end.lastIndexOf(":"))) + ",";
                }
              }
            }
          });
          return (
            <div key={building.building_id} className={'col-12 col-md-6 col-xl-4 mg-b-15'} >
              <div onClick={(event) => this._buildingClick(event, building)} className='card building-card shadow-base col-12 pd-0'>
                <div className='card-header'>
                  <h5 style={{ marginBottom: '0px' }}>{building.name}</h5>
                  <p className="truncate" style={{ marginBottom: '0px', width: '85%' }}>{building.description}</p>
                  <span style={{ position: 'absolute', top: '20px', right: '15px', textTransform: 'uppercase', color: statusColor, fontWeight: 500 }}>{building.status}</span>
                </div>
                <div className='card-body table-wrapper'>
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <div className="row">
                        <div className="col-12">
                          <p className="truncate" style={{ marginBottom: '6px' }}><span style={{ fontWeight: 600 }}>Address: </span>
                            <br />
                            {building.address ? building.address.address_line : "Not Available"}
                          </p>
                        </div>
                        <div className="col-12">
                          <p style={{ marginBottom: '6px' }}><span style={{ fontWeight: 600 }}>Size (sq/m):</span>
                            <br />
                            {building.size}
                          </p>
                        </div>
                        <div className="col-12">
                          <p style={{ marginBottom: '6px' }}><span style={{ fontWeight: 600 }}>Category:</span>
                            <br />
                            {building.category}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-5">
                      <div className="row" style={{ paddingRight: '20px' }}>
                        <div className="col-12" style={{ border: '1px dashed #878da2', fontSize: '12px' }}>
                          <span style={{ fontWeight: 600 }}>Operational Hours</span>
                          <br />
                          Mon: {mondayHours.length > 0 ? mondayHours : 'Closed'}
                          <br />
                          Tues: {tuesdayHours.length > 0 ? tuesdayHours : 'Closed'}
                          <br />
                          Wed: {wednesdayHours.length > 0 ? wednesdayHours : 'Closed'}
                          <br />
                          Thur: {thursdayHours.length > 0 ? thursdayHours : 'Closed'}
                          <br />
                          Fri: {fridayHours.length > 0 ? fridayHours : 'Closed'}
                          <br />
                          Sat: {saturdayHours.length > 0 ? saturdayHours : 'Closed'}
                          <br />
                          Sun: {sundayHours.length > 0 ? sundayHours : 'Closed'}
                          <br />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div onClick={(event) => this._buildingClick(event, building)} className='card-footer'>
                  <p><ion-icon name="create" style={{ marginRight: '5px' }} />Modify Building</p>
                </div>
              </div>
            </div>
          )
        })
      }
    </div >
  }
  render() {
    if (this.state.loading) {
      return (
        <div className="br-mainpanel pd-15">
          <DocumentTitle title='Manage Buildings' />
          <div className="br-pagetitle mg-b-0">
            <div>
              <h4>Manage Buildings</h4>
              <p className="mg-b-0">Add New Buildings, Edit Building Details, Modify Operation Hours and Energy Rates.</p>
            </div>
          </div>
          <Spinner />
        </div>
      );
    } else {

      return (
        <div className="br-mainpanel pd-15">
          <DocumentTitle title='Manage Buildings' />
          <div className="br-pagetitle mg-b-0">
            <div>
              <h4>Manage Buildings</h4>
              <p className="mg-b-0">Add New Buildings, Edit Building Details, Modify Operation Hours and Energy Rates.</p>
            </div>
          </div>

          <div className="row pd-xs-l-10 pd-xs-r-10 mg-t-20">
            <div className="col-12 col-md-6 col-xl-4">
              <Filter value={this.state.filter} placeholder='Filter Names' setFilter={(query) => this.setState({ filter: query })} />
            </div>
            <div className="col-12 col-sm d-flex justify-end">
              <Button
                className="button green"
                icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
                size="small"
                onClick={this._addBuilding}
              >Add Building</Button>
            </div>
          </div>
          {this.getBuildingGrid()}
        </div>
      );
    }
  }
}

export default ManageBuildings;
