import React, { Component } from "react";
import "./MetersExplorer.scss";

// stores and actions
import GeneralStore from "../../stores/generalStore";
import GeneralActions from "../../actions/generalActions";
import MetersExplorerStore from "../../stores/metersExplorerStore";
import MetersExplorerActions from "../../actions/metersExplorerActions";

// components
import { Spinner } from "../../components/Spinner";
import { TimeframePickerMini } from "../../components/TimeframePickerMini";
import { Filter } from "../../components/Filter";
import { DocumentTitle } from "../../components/DocumentTitle";

// utilities
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import HC_more from "highcharts/highcharts-more";

import GeneralUtils from "../../utils/GeneralUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import _ from "lodash";

class MetersExplorer extends Component {
  interval = 0;
  constructor(props) {
    super(props);
    this.chartAreaRef = React.createRef();
    this.chart = React.createRef();
    this.comparison_chart = React.createRef();
    this.state = {
      ts_start: GeneralStore.getStartDate(),
      ts_end: GeneralStore.getEndDate(),
      comparison_ts_start: null,
      comparison_ts_end: null,
      catalogue: [],
      meters: [],

      filter: "",
      meter_types: [
        { label: "All", accessor: "" },
        { label: "Electric", accessor: "em" },
        { label: "Gas", accessor: "gm" },
        { label: "Water", accessor: "wm" },
        { label: "Heat", accessor: "hm" },
      ],
      selected_type: { label: "All", accessor: "" },
      time_buckets: [
        { label: "Mins", accessor: "15" },
        { label: "Hour", accessor: "hour" },
        { label: "Day", accessor: "day" },
        { label: "Week", accessor: "week" },
      ],
      selected_bucket: { label: "Hour", accessor: "hour" },

      selected_meters: [],
      chart_data: [],
      comparison_chart_data: [],
      chart_mode: "normal",

      chart_container_height: 0,
      chart_area_expanded: false,
      legend_hover_meter_id: null,

      comparison_array: [],
      no_data_ids: [],
      fetching: false,
      drawer_open: true,

      // UI Store

      sortBy: "",
      sortDirection: "",
    };
    this.onCatalogueFetched = this.onCatalogueFetched.bind(this);
    this.getMeterChartData = this.getMeterChartData.bind(this);
    this.onChartDataFetched = this.onChartDataFetched.bind(this);
    this.onScreenResize = this.onScreenResize.bind(this);
    this.onComparisonChartDataFetched =
      this.onComparisonChartDataFetched.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.changeSortBy = this.changeSortBy.bind(this);
    this.selectComparisonDate = this.selectComparisonDate.bind(this);
    this.expandChartArea = this.expandChartArea.bind(this);
  }

  componentDidMount() {
    if (this.state.catalogue.length === 0) {
      MetersExplorerActions.getMeters();
      MetersExplorerActions.getBuildings();
    }

    if (this.state.selected_meters.length > 0) {
      this.getMeterChartData();
    }
  }

  expandChartArea() {
    this.setState({ chart_area_expanded: !this.state.chart_area_expanded });
    this.onScreenResize();
  }

  componentDidUpdate(_, prevState) {
    const selected_meters_changed =
      this.state.selected_meters.length !== prevState.selected_meters.length;
    const selected_meters_not_empty = this.state.selected_meters.length > 0;
    const selected_date_changed =
      this.state.ts_start !== prevState.ts_start ||
      this.state.ts_end !== prevState.ts_end;
    const selected_comparison_date_changed =
      this.state.comparison_ts_start !== prevState.comparison_ts_start ||
      this.state.comparison_ts_end !== prevState.comparison_ts_end;
    const selected_bucket_changed =
      this.state.selected_bucket.label !== prevState.selected_bucket.label;

    if (
      (selected_meters_changed ||
        selected_date_changed ||
        selected_bucket_changed ||
        selected_comparison_date_changed) &&
      selected_meters_not_empty
    ) {
      this.getMeterChartData();
    }
  }

  getMeterChartData() {
    const meter_id = this.state.selected_meters[0].meter_id;
    const ts_start = this.state.ts_start;
    const ts_end = this.state.ts_end;
    const comparison_ts_start = this.state.comparison_ts_start;
    const comparison_ts_end = this.state.comparison_ts_end;
    const bucket_type = this.state.selected_bucket.accessor;
    const meter_ids = this.state.selected_meters.map((meter) => meter.meter_id);

    this.setState({ comparison_array: meter_ids, fetching: true });
    MetersExplorerActions.getMeterChartData(
      meter_id,
      ts_start,
      ts_end,
      bucket_type,
      meter_ids
    );
    // Comparison Call if the date has been changed
    if (comparison_ts_start !== null && comparison_ts_end !== null) {
      MetersExplorerActions.getMeterChartData(
        meter_id,
        comparison_ts_start,
        comparison_ts_end,
        bucket_type,
        meter_ids,
        true
      );
    }
  }

  componentWillMount() {
    MetersExplorerStore.addCatalogueCreatedListener(this.onCatalogueFetched);
    MetersExplorerStore.addChartDataListener(this.onChartDataFetched);
    MetersExplorerStore.addComparisonChartDataListener(
      this.onComparisonChartDataFetched
    );
    window.addEventListener("resize", this.onScreenResize);
  }

  componentWillUnmount() {
    MetersExplorerStore.removeCatalogueCreatedListener(this.onCatalogueFetched);
    MetersExplorerStore.removeChartDataListener(this.onChartDataFetched);
    MetersExplorerStore.removeComparisonChartDataListener(
      this.onComparisonChartDataFetched
    );

    window.removeEventListener("resize", this.onScreenResize);
    MetersExplorerStore.clear();
  }

  onScreenResize(delay = 10) {
    setTimeout(() => {
      if (this.chartAreaRef.current) {
        this.setState({
          chart_container_height: this.chartAreaRef.current.clientHeight - 10,
        });
      }
    }, delay);
  }

  onCatalogueFetched() {
    const catalogue = MetersExplorerStore.getCatalogue();
    const meters = MetersExplorerStore.getRawMeters();

    this.setState({ catalogue: catalogue, meters: meters });
  }

  onChartDataFetched() {
    let chart_data = MetersExplorerStore.getChartData();
    const no_data_ids = this.state.comparison_array.filter(
      (s) => chart_data.map((e) => e.meter_id).includes(s) === false
    );

    // assigning colors
    chart_data = chart_data.map((e, i) => {
      return { ...e, color: Highcharts.getOptions().colors[i] };
    });

    chart_data = chart_data.map((d) => {
      const match = this.state.chart_data.find(
        (el) => el.meter_id === d.meter_id
      );

      if (match) {
        return {
          ...d,
          color: match.color,
        };
      } else {
        const array_of_colors = this.state.chart_data
          .map((el) => el.color)
          .filter((e) => e);
        // const array_of_possible_colors = [
        //   Highcharts.getOptions().colors[0],
        //   Highcharts.getOptions().colors[1],
        //   Highcharts.getOptions().colors[2],
        //   Highcharts.getOptions().colors[3],
        //   Highcharts.getOptions().colors[4],
        //   Highcharts.getOptions().colors[5],
        // ];
        const array_of_possible_colors = [
          "#007BFF",
          "#E53935",
          "#43A047",
          "#8E24AA",
          "#FB8C00",
          "#F48FB1",
        ];

        const unused_colors = _.difference(
          array_of_possible_colors,
          array_of_colors
        );

        return {
          ...d,
          color: unused_colors[0],
        };
      }
    });

    this.onScreenResize(10);

    this.setState({
      chart_data: chart_data,
      fetching: false,
      no_data_ids: no_data_ids,
    });
  }

  onComparisonChartDataFetched() {
    let comparison_chart_data = MetersExplorerStore.getComparisonChartData();

    comparison_chart_data = comparison_chart_data.map((d) => {
      const match = this.state.chart_data.find(
        (el) => el.meter_id === d.meter_id
      );

      if (match) {
        return {
          ...d,
          color: match.color,
        };
      } else {
        const array_of_colors = this.state.chart_data
          .map((el) => el.color)
          .filter((e) => e);
        const array_of_possible_colors = [
          Highcharts.getOptions().colors[0],
          Highcharts.getOptions().colors[1],
          Highcharts.getOptions().colors[2],
          Highcharts.getOptions().colors[3],
          Highcharts.getOptions().colors[4],
          Highcharts.getOptions().colors[5],
        ];

        const unused_colors = _.difference(
          array_of_possible_colors,
          array_of_colors
        );

        return {
          ...d,
          color: unused_colors[0],
        };
      }
    });

    this.setState({
      comparison_chart_data: comparison_chart_data,
      fetching: false,
    });
  }

  selectDate(ts_start, ts_end) {
    GeneralActions.setDates(ts_start, ts_end);
    this.setState({
      ts_start: ts_start,
      ts_end: ts_end,
    });
  }

  selectComparisonDate(ts_start, ts_end) {
    this.setState({
      comparison_ts_start: ts_start,
      comparison_ts_end: ts_end,
    });
  }

  toggleDrawer() {
    this.setState({ drawer_open: !this.state.drawer_open });
    this.reflowCharts();
  }

  reflowCharts() {
    setTimeout(() => {
      this.chart.current && this.chart.current.chart.reflow();
      this.comparison_chart.current &&
        this.comparison_chart.current.chart.reflow();
    }, 500);
  }

  getContextualMenu() {
    return (
      <div
        className={`contextual-menu ${this.state.drawer_open ? "" : "closed"}`}
      >
        {this.getTimeframeSelector()}
        {this.getMeterTypeAndBucketSelector()}
        {this.getMeterTree()}
        <div className="collapse-button" onClick={() => this.toggleDrawer()}>
          {this.state.drawer_open ? (
            <ion-icon name="arrow-dropleft" />
          ) : (
            <ion-icon name="arrow-dropright" />
          )}
        </div>
      </div>
    );
  }

  getTimeframeSelector() {
    const comparison_date_selected =
      this.state.comparison_ts_start !== null &&
      this.state.comparison_ts_end !== null;

    return (
      <div className="timeframe-selector">
        <div className="timeframes-wrapper">
          <div className="timeframe">
            <div className="label">Timeframe</div>
            <div className="selector">
              <TimeframePickerMini
                start={this.state.ts_start}
                end={this.state.ts_end}
                granularity='custom'
                onChange={this.selectDate}
                spacing="2px"
                placement="bottomLeft"
                unix
              />
            </div>
          </div>
          <div className="comparison-timeframe">
            <div className="label">Compare To</div>
            <div className="selector">
              <TimeframePickerMini
                start={this.state.comparison_ts_start}
                end={this.state.comparison_ts_end}
                granularity='custom'
                onChange={this.selectComparisonDate}
                spacing="2px"
                placement="bottomLeft"
                unix
              />

              {comparison_date_selected && (
                <button
                  className="clear-button"
                  onClick={() =>
                    this.setState({
                      comparison_ts_start: null,
                      comparison_ts_end: null,
                      comparison_chart_data: [],
                    })
                  }
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="separator"></div>
      </div>
    );
  }

  selectMeterType(type) {
    const water_meters_selected = this.state.selected_meters.find((meter) =>
      meter.type.includes("wm")
    );

    const water_or_all = type.label === "Water" || type.label === "All";

    if (water_meters_selected && !water_or_all) {
      toast("Water Meters can only be charted with other water meters.", {
        type: toast.TYPE.WARNING,
        autoClose: 4000,
        preventDuplicated: true,
      });
      return;
    } else if (
      !water_meters_selected &&
      type.label === "Water" &&
      this.state.selected_meters.length
    ) {
      toast("Water Meters can only be charted with other water meters.", {
        type: toast.TYPE.WARNING,
        autoClose: 4000,
        preventDuplicated: true,
      });
      return;
    } else {
      this.setState({ selected_type: type });
    }
  }

  selectTimeBucket(bucket) {
    this.setState({ selected_bucket: bucket });
  }

  getMeterTypeAndBucketSelector() {
    return (
      <div className="meter-type-time-bucket-section">
        <div className="meter-type-selector">
          <div className="label">Meter Type</div>
          <div className="tags">
            {this.state.meter_types.map((type) => {
              return (
                <div
                  className={`tag-wrapper`}
                  onClick={() => this.selectMeterType(type)}
                  key={type.label}
                >
                  <div
                    className={`tag ${type.label === this.state.selected_type.label
                      ? "active"
                      : ""
                      }`}
                  >
                    {type.label}
                  </div>
                </div>
              );
            })}
            <div className="tag-wrapper"></div>
            <div className="tag-wrapper"></div>
          </div>
        </div>
        <div className="meter-type-selector">
          <div className="label">Time Bucket</div>
          <div className="tags">
            {this.state.time_buckets.map((bucket) => {
              return (
                <div
                  className={`tag-wrapper`}
                  onClick={() => this.selectTimeBucket(bucket)}
                  key={bucket.label}
                >
                  <div
                    className={`tag ${bucket.label === this.state.selected_bucket.label
                      ? "active"
                      : ""
                      }`}
                  >
                    {bucket.label}
                  </div>
                </div>
              );
            })}
            <div className="tag-wrapper"></div>
            <div className="tag-wrapper"></div>
          </div>
        </div>
      </div>
    );
  }

  toggleMeter(meter_id, building_id) {
    const water_meters_selected = this.state.selected_meters.find((meter) =>
      meter.type.includes("wm")
    );
    let modified_catalogue = _.cloneDeep(this.state.catalogue);
    let meters = modified_catalogue.find(
      (e) => e.building_id === building_id
    ).meters;

    const findIdAndSelect = (id, arr) => {
      arr.forEach((el) => {
        if (el.meter_id === id) {
          let modified_selected_meters = _.cloneDeep(
            this.state.selected_meters
          );

          if (modified_selected_meters.find((m) => m.meter_id === meter_id)) {
            this.setState({
              selected_meters: modified_selected_meters.filter(
                (m) => m.meter_id !== meter_id
              ),
            });
            if (this.state.legend_hover_meter_id === meter_id)
              this.setState({ legend_hover_meter_id: null });
          } else {
            // 6 existing points
            if (this.state.selected_meters.length === 6) {
              toast("You can only chart a maximum of 6 data points.", {
                type: toast.TYPE.ERROR,
                autoClose: 4000,
                preventDuplicated: true,
              });
              // no selections and selected meter is water meter
            } else if (this.state.selected_meters.length === 0) {
              this.setState({
                selected_meters: [...modified_selected_meters, el],
              });
            } else {
              if (el.type.includes("wm") && !water_meters_selected) {
                toast(
                  "Water Meters can only be charted with other water meters.",
                  {
                    type: toast.TYPE.WARNING,
                    autoClose: 4000,
                    preventDuplicated: true,
                  }
                );
                return;
              }
              this.setState({
                selected_meters: [...modified_selected_meters, el],
              });
            }
          }
          return;
        } else {
          findIdAndSelect(id, el.submeters);
        }
      });
    };

    findIdAndSelect(meter_id, meters);

    this.setState({ catalogue: modified_catalogue });
  }

  clearAllMeters() {
    this.setState({
      selected_meters: [],
      selected_type: { label: "All", accessor: "" },
      chart_data: [],
      comparison_chart_data: [],
      comparison_ts_start: null,
      comparison_ts_end: null,
    });
  }

  toggleMeterExpansion(meter_id, building_id) {
    let modified_catalogue = _.cloneDeep(this.state.catalogue);
    let meters = modified_catalogue.find(
      (e) => e.building_id === building_id
    ).meters;

    const findIdAndToggle = (id, arr) => {
      arr.forEach((el) => {
        if (el.meter_id === id) {
          el.expanded = !el.expanded;
          return;
        } else {
          findIdAndToggle(id, el.submeters);
        }
      });
    };

    findIdAndToggle(meter_id, meters);
    this.setState({ catalogue: modified_catalogue });
  }

  toggleBuildingExpansion(building_id) {
    const modified_catalogue = _.cloneDeep(this.state.catalogue);
    let building = modified_catalogue.find(
      (b) => b.building_id === building_id
    );
    building.expanded = !building.expanded;
    this.setState({ catalogue: modified_catalogue });
  }

  getMeterTree() {
    const filter = this.state.filter.toLowerCase();

    const filterTree = (array, fn) => {
      return array.reduce((r, o) => {
        let submeters = filterTree(o.submeters || [], fn);
        if (fn(o) || submeters.length) {
          r.push(Object.assign({}, o, submeters.length && { submeters }));
        }
        return r;
      }, []);
    };

    const getSubMeters = (parent_meter, count, isDisabled = false) => {
      if (
        (parent_meter.expanded || this.state.filter.length > 0) &&
        parent_meter.submeters.length > 0
      ) {
        let indent = count * 15;
        count++;
        return parent_meter.submeters.map((meter, key) => {
          return (
            <div key={key} style={{ opacity: isDisabled ? 0.2 : 1 }}>
              {getRow(meter, indent, isDisabled)}
              {getSubMeters(meter, count, isDisabled)}
            </div>
          );
        });
      }
    };

    const getRow = (meter, indent, isDisabled = false) => {
      const meterSelected = this.state.selected_meters.find(
        (m) => m.meter_id === meter.meter_id
      );
      const virtualTag = meter.virtual ? (
        <div
          className="label-vr"
          data-rh="Virtually Calculated Meter"
          title="Virtually Calculated Meter"
        >
          VR
        </div>
      ) : undefined;

      let expandIcon = <div style={{ width: "18px" }}></div>;
      if (meter.submeters.length > 0)
        expandIcon = (
          <ion-icon
            onClick={this.toggleMeterExpansion.bind(
              this,
              meter.meter_id,
              meter.building_id
            )}
            name={
              meter.expanded ? "remove-circle-outline" : "add-circle-outline"
            }
          ></ion-icon>
        );
      if (this.state.filter !== "")
        expandIcon = <ion-icon name="return-right"></ion-icon>;

      return (
        <div
          className="row-wrapper"
          style={{ marginLeft: indent + "px", opacity: isDisabled ? 0.2 : 1 }}
        >
          <div className="row-icon">{expandIcon}</div>
          <div
            className="row-label"
            onClick={() =>
              isDisabled === false &&
              this.toggleMeter(meter.meter_id, meter.building_id)
            }
            title={meter.description}
          >
            {virtualTag}
            {meter.description}
          </div>
          <div
            className="row-checkbox"
            onClick={() =>
              isDisabled === false &&
              this.toggleMeter(meter.meter_id, meter.building_id)
            }
          >
            <div className="checkbox-outline">
              {meterSelected && <div className="checkbox-fill"></div>}
            </div>
          </div>
        </div>
      );
    };

    const getMeterTree = (meters, building_id) => {
      if (filter) {
        const list_result = this.state.meters
          .filter((meter) => meter.building_id === building_id)
          .filter((meter) => meter.description.toLowerCase().includes(filter));

        return list_result.map((meter, key) => {
          return (
            <div key={key} style={{ marginLeft: "20px", position: "relative" }}>
              {getRow(meter, 0)}
            </div>
          );
        });
      }

      let result = filterTree(meters, ({ description }) =>
        description.toLowerCase().includes(filter)
      );

      return result.map((meter, key) => {
        let disabled = false;
        // if type is selected, grey out every other type
        if (meter.type.includes(this.state.selected_type.accessor) === false) {
          disabled = true;
          // if type is not selected but there's a selected meter with unit, grey out other units
        } else if (
          this.state.selected_meters.length &&
          meter.unit !== this.state.selected_meters[0].unit
        ) {
          disabled = true;
        }

        return (
          <div
            key={key}
            style={{
              marginLeft: "20px",
              position: "relative",
            }}
          >
            {getRow(meter, 0, disabled)}
            {getSubMeters(meter, 1, disabled)}
          </div>
        );
      });
    };

    const getBuildingMeters = (building) => {
      const buildingHasSelectedType = building.meters.find((meter) =>
        meter.type.includes(this.state.selected_type.accessor)
      );
      const buildingFulfillsFilterCriteria =
        filterTree(building.meters, ({ description }) =>
          description.toLowerCase().includes(filter)
        ).length > 0;
      const building_is_disabled = building.status === "disabled";

      let expandIcon = (
        <ion-icon
          name={
            building.expanded ? "remove-circle-outline" : "add-circle-outline"
          }
        ></ion-icon>
      );

      if (this.state.filter !== "")
        expandIcon = <ion-icon name="business"></ion-icon>;

      if (
        buildingHasSelectedType === undefined ||
        buildingFulfillsFilterCriteria === false ||
        building_is_disabled
      )
        return null;

      return (
        <div key={building.building_id} className="building-tree">
          <div
            className="building-wrapper"
            onClick={this.toggleBuildingExpansion.bind(
              this,
              building.building_id
            )}
          >
            {expandIcon} <span className="building-label">{building.name}</span>
          </div>
          {(building.expanded || this.state.filter !== "") &&
            getMeterTree(building.meters, building.building_id)}
        </div>
      );
    };

    return (
      <div className="meter-tree">
        <div className="label">Meter Tree</div>
        <div className="filter">
          <div className="filter-wrapper">
            <Filter
              value={this.state.filter}
              placeholder={"Filter by Meter Name"}
              setFilter={(query) => this.setState({ filter: query })}
            />
          </div>
          <div className="clear-all-wrapper">
            <button
              className="clear-all-button"
              onClick={() => this.clearAllMeters()}
            >
              Clear
            </button>
          </div>
        </div>
        <div className="tree-selector">
          <div className="inner-selector">
            {this.state.catalogue.map((building) =>
              getBuildingMeters(building)
            )}
          </div>
        </div>
      </div>
    );
  }

  getChartsArea() {
    if (this.state.selected_meters.length === 0) {
      return (
        <div
          className={`charts-area ${this.state.drawer_open ? "" : "expanded"}`}
        >
          {this.getChartsPlaceholder()}
        </div>
      );
    }

    return (
      <div
        className={`charts-area ${this.state.drawer_open ? "" : "expanded"}`}
      >
        <div className="charts-wrapper">
          {this.getChartsBody()}
          {this.getDataTable()}
        </div>
      </div>
    );
  }

  getChartsPlaceholder() {
    return (
      <div className="charts-placeholder">
        <h3>Select the meter to chart its consumption.</h3>
        <h3>You may select multiple meters to compare them.</h3>
        <h3>
          Adding comparison timeframe will add another chart for selected
          period.
        </h3>
      </div>
    );
  }

  getChartsBody() {
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    HC_more(Highcharts);

    const unit_suffix = ` ${this.state.selected_meters[0] &&
      this.state.selected_meters[0].unit === "liters"
      ? "Liters"
      : "kWh"
      }`;
    const comparison_dates_available =
      this.state.comparison_ts_start !== null &&
      this.state.comparison_ts_end !== null;
    const number_of_charts = comparison_dates_available ? 2 : 1;
    let chart_height = this.state.chart_container_height
      ? this.state.chart_container_height / number_of_charts
      : this.chartAreaRef.current &&
      this.chartAreaRef.current.clientHeight / number_of_charts - 5;

    chart_height = chart_height - 10 + "px";

    let chart_data = this.state.chart_data.map((c) => {
      return {
        ...c,
        type: "spline",
        visible:
          c.meter_id === this.state.legend_hover_meter_id ||
          this.state.legend_hover_meter_id === null,
      };
    });
    let comparison_chart_data = this.state.comparison_chart_data.map((c) => {
      return {
        ...c,
        type: "spline",
        visible:
          c.meter_id === this.state.legend_hover_meter_id ||
          this.state.legend_hover_meter_id === null,
      };
    });

    let xAxis_min = null;
    let xAxis_max = null;
    let yAxis_max = null;

    if (
      this.state.chart_mode === "aligned" &&
      comparison_dates_available &&
      chart_data.length &&
      comparison_chart_data.length
    ) {
      let modified_chart_data =
        chart_data[0].data.length < comparison_chart_data[0].data.length
          ? chart_data
          : comparison_chart_data;
      let longer_chart_data =
        chart_data[0].data.length > comparison_chart_data[0].data.length
          ? chart_data
          : comparison_chart_data;

      const chart_data_values = chart_data.map((e) => e.data.map((b) => b[1]));
      const comparison_chart_data_values = comparison_chart_data.map((e) =>
        e.data.map((b) => b[1])
      );

      const max_value = _.max(
        _.flatten([...chart_data_values, ...comparison_chart_data_values])
      );

      if (max_value / 10000 > 1) {
        yAxis_max = Math.ceil((max_value / 10000) * 10000);
      } else if (max_value / 1000 > 1) {
        yAxis_max = Math.ceil((max_value / 1000) * 1000);
      } else if (max_value / 100 > 1) {
        yAxis_max = Math.ceil((max_value / 100) * 100);
      } else if (max_value / 10 > 1) {
        yAxis_max = Math.ceil((max_value / 10) * 10);
      }

      // create arrays of timestamps
      const timestamps_of_chart_dataset = chart_data[0].data.map((el) => el[0]);
      const timestamps_of_comparison_chart_dataset =
        comparison_chart_data[0].data.map((el) => el[0]);

      // check for at least one common timestamp
      const timestamp_overlap_exists = timestamps_of_chart_dataset.some(
        (ts) => {
          return timestamps_of_comparison_chart_dataset.includes(ts);
        }
      );

      if (timestamp_overlap_exists) {
        // find out whats the earliest timestamp
        const earliest_timestamp = _.min([
          timestamps_of_chart_dataset[0],
          timestamps_of_comparison_chart_dataset[0],
        ]);
        const latest_timestamp = _.max([
          timestamps_of_chart_dataset[timestamps_of_chart_dataset.length - 1],
          timestamps_of_comparison_chart_dataset[
          timestamps_of_comparison_chart_dataset.length - 1
          ],
        ]);
        // use it as xAxis min in both (?) charts?
        xAxis_min = earliest_timestamp;
        xAxis_max = latest_timestamp;
      } else {
        // overlap does not exist align via first weekday match
        const formatted_timestamps = timestamps_of_chart_dataset.map((e) =>
          moment(e).format("dddd hh:mm")
        );
        const formatted_comparison_timestamps =
          timestamps_of_comparison_chart_dataset.map((e) =>
            moment(e).format("dddd hh:mm")
          );

        let longer_timestamp_array =
          formatted_timestamps.length > formatted_comparison_timestamps.length
            ? formatted_timestamps
            : formatted_comparison_timestamps;
        let shorter_timestamp_array =
          formatted_timestamps.length < formatted_comparison_timestamps.length
            ? formatted_timestamps
            : formatted_comparison_timestamps;
        let empty_data_pre = 0;

        longer_timestamp_array.forEach((ts, index) => {
          if (shorter_timestamp_array[0] === ts && empty_data_pre === 0) {
            empty_data_pre = index;
          }
        });

        modified_chart_data = modified_chart_data.map((serie) => {
          let difference_between_timestamps = 604800000;
          if (this.state.selected_bucket.label === "Day")
            difference_between_timestamps = 86400000;
          if (this.state.selected_bucket.label === "Hour")
            difference_between_timestamps = 3600000;
          if (this.state.selected_bucket.label === "Mins")
            difference_between_timestamps = 900000;

          const pre_entries = longer_chart_data[0].data
            .slice(0, empty_data_pre)
            .map((el, ind) => [
              serie.data[0][0] - difference_between_timestamps * (1 + ind),
              null,
            ])
            .reverse();

          const post_entries = longer_chart_data[0].data
            .slice(
              empty_data_pre + modified_chart_data[0].data.length,
              longer_chart_data[0].data.length
            )
            .map((el, ind) => [
              serie.data[serie.data.length - 1][0] +
              difference_between_timestamps * (1 + ind),
              null,
            ]);

          return {
            ...serie,
            data: [...pre_entries, ...serie.data, ...post_entries],
          };
        });

        if (
          chart_data[0].data.length === comparison_chart_data[0].data.length
        ) {
          chart_data = this.state.chart_data.map((c) => {
            return {
              ...c,
              visible:
                c.meter_id === this.state.legend_hover_meter_id ||
                this.state.legend_hover_meter_id === null,
            };
          });
          comparison_chart_data = this.state.comparison_chart_data.map((c) => {
            return {
              ...c,
              visible:
                c.meter_id === this.state.legend_hover_meter_id ||
                this.state.legend_hover_meter_id === null,
            };
          });
        } else if (
          chart_data[0].data.length < comparison_chart_data[0].data.length
        ) {
          chart_data = modified_chart_data;
        } else {
          comparison_chart_data = modified_chart_data;
        }
      }
    } else if (this.state.chart_mode === "normal") {
      chart_data = this.state.chart_data.map((c) => {
        return {
          ...c,
          visible:
            c.meter_id === this.state.legend_hover_meter_id ||
            this.state.legend_hover_meter_id === null,
          data: c.data.filter((el) => el[1] !== null),
          type: "spline",
        };
      });
      comparison_chart_data = this.state.comparison_chart_data.map((c) => {
        return {
          ...c,
          visible:
            c.meter_id === this.state.legend_hover_meter_id ||
            this.state.legend_hover_meter_id === null,
          data: c.data.filter((el) => el[1] !== null),
          type: "spline",
        };
      });
    }

    const buttonSpacing = 10;
    const buttonPadding = 7;

    const baseExportingConfig = {
      enabled: true,
      filename: `OPNBuildings - Meter Explorer - data export`,
      csv: {
        dateFormat: "%Y-%m-%dT%H:%M:%S.%LZ",
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        exportButton: {
          text: "<span style='color:white;'>Export</span>",
          y: -8,
          buttonSpacing: buttonSpacing,
          theme: {
            fill: "#8699A6",
            padding: buttonPadding,
            r: 8,
            states: {
              hover: {
                fill: "#6C7B88",
              },
            },
          },
          menuItems: [
            "downloadCSV",
            "downloadPNG",
            {
              text: "Download PNG image with data",
              onclick: function () {
                const start_date = moment(
                  this.series[0].processedXData[0]
                ).format("DD MMM YYYY, HH:MM");
                const end_date = moment(
                  this.series[0].processedXData[
                  this.series[0].processedXData.length - 1
                  ]
                ).format("DD MMM YYYY, HH:MM");
                let subtitleText = `${start_date} - ${end_date}`;
                let suffix = unit_suffix || "";
                this.series.forEach((e, index) => {
                  if (index > 5) return;
                  let total_value = e.processedYData.reduce(
                    (acc, val) => acc + val,
                    0
                  );
                  let formattedValue = GeneralUtils.getFormattedNumberWithUnit(
                    total_value,
                    suffix,
                    suffix.includes("ppm") ||
                      suffix.includes("°C") ||
                      suffix.includes("%")
                      ? 0
                      : 1
                  );
                  subtitleText += `<br/><div style="text-align: center; color: ${e.color}; font-size: 12px; margin: auto; width: 100%">${e.name} - ${formattedValue}</div>`;
                });
                this.exportChart(null, {
                  title: { text: document.title },
                  subtitle: { text: subtitleText },
                  chart: { height: "auto" },
                  legend: { enabled: true },
                });
              },
            },
            "downloadPDF",
          ],
        },
      },
    };

    const exporting_config = {
      exporting: {
        ...baseExportingConfig,
        buttons: {
          ...baseExportingConfig.buttons,
          alignButton: {
            buttonSpacing: buttonSpacing,
            text:
              this.state.chart_mode === "aligned"
                ? "<span style='color:white;'>Unalign</span>"
                : "<span style='color:white;'>Allign</span>",
            y: -8,
            theme: {
              fill: "#748291",
              padding: buttonPadding,
              r: 8,
              states: {
                hover: {
                  fill: "#5A656E",
                },
              },
            },
            onclick: () =>
              this.setState({
                chart_mode:
                  this.state.chart_mode === "aligned" ? "normal" : "aligned",
              }),
            enabled:
              this.state.ts_end - this.state.ts_start <
              this.state.comparison_ts_end - this.state.comparison_ts_start,
          },
          expandButton: {
            buttonSpacing: buttonSpacing,
            text: this.state.chart_area_expanded
              ? "<span style='color:white;'>Collapse</span>"
              : "<span style='color:white;'>Expand</span>",
            onclick: this.expandChartArea,
            enabled: true,
            y: -8,
            theme: {
              fill: "#5E6977",
              padding: buttonPadding,
              r: 8,
              states: {
                hover: {
                  fill: "#47545D",
                },
              },
            },
          },
        },
      },
    };

    const comparison_exporting_config = {
      exporting: {
        ...baseExportingConfig,
        buttons: {
          ...baseExportingConfig.buttons,
          alignButton: {
            buttonSpacing: buttonSpacing,
            text:
              this.state.chart_mode === "aligned"
                ? "<span style='color:white;'>Unalign</span>"
                : "<span style='color:white;'>Allign</span>",
            y: -8,
            theme: {
              fill: "#748291",
              padding: buttonPadding,
              r: 8,
              states: {
                hover: {
                  fill: "#5A656E",
                },
              },
            },
            onclick: () =>
              this.setState({
                chart_mode:
                  this.state.chart_mode === "aligned" ? "normal" : "aligned",
              }),
            enabled:
              this.state.ts_end - this.state.ts_start >
              this.state.comparison_ts_end - this.state.comparison_ts_start,
          },
        },
      },
    };

    const baseChartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        zoomType: "x",
        animation: {
          duration: 200,
        },
        spacing: [20, 30, 10, 30],
        height: chart_height,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        gridLineWidth: 0,
        type: "datetime",
        min: xAxis_min,
        max: xAxis_max,
      },
      yAxis: {
        gridLineWidth: 0,
        title: "Consumption",
        max: yAxis_max,
        labels: {
          formatter: function () {
            return this.value + " " + unit_suffix;
          },
        },
      },
      tooltip: {
        shared: true,
        crosshairs: true,
        valueDecimals: 1,
        valueSuffix: " " + unit_suffix,
        xDateFormat: "%a, %b %e : %H:%M",
      },
      legend: {
        enabled: false,
      },
    };

    const chart_options = {
      ...baseChartOptions,
      title: {
        text: `${moment(this.state.ts_start * 1000).format(
          "DD MMM, YYYY"
        )} - ${moment(this.state.ts_end * 1000).format("DD MMM, YYYY")}`,
        align: "left",
        style: {
          fontFamily: "Fira Sans",
          fontSize: "16px",
          fontWeight: 500,
        },
      },
      series: chart_data,
      ...exporting_config,
    };

    const comparison_chart_options = {
      ...baseChartOptions,
      title: {
        text: `${moment(this.state.comparison_ts_start * 1000).format(
          "DD MMM, YYYY"
        )} - ${moment(this.state.comparison_ts_end * 1000).format(
          "DD MMM, YYYY"
        )}`,
        align: "left",
        style: {
          fontFamily: "Fira Sans",
          fontSize: "16px",
          fontWeight: 500,
        },
      },
      series: comparison_chart_data,
      ...comparison_exporting_config,
    };

    return (
      <div
        className="charts-body"
        ref={this.chartAreaRef}
        style={{
          minHeight: this.state.chart_area_expanded
            ? "calc(100vh - 110px)"
            : "",
        }}
      >
        <div className="charts-card">
          <HighchartsReact
            ref={this.chart}
            highcharts={Highcharts}
            options={chart_options}
          />
        </div>
        {comparison_dates_available && (
          <div className="charts-card">
            <HighchartsReact
              ref={this.comparison_chart}
              highcharts={Highcharts}
              options={comparison_chart_options}
            />
          </div>
        )}
      </div>
    );
  }

  changeSortBy(sortBy) {
    if (this.state.sortBy !== sortBy) {
      this.setState({ sortBy: sortBy, sortDirection: "asc" });
    } else {
      if (this.state.sortDirection === "asc") {
        this.setState({ sortDirection: "desc" });
      } else {
        this.setState({ sortDirection: "asc" });
      }
    }
  }

  getDataTable() {
    const getRowsData = (chart_data) => {
      return chart_data.map((serie) => {
        const data_values = serie.data.map((element) => element[1]);

        return {
          name: serie.name,
          color: serie.color,
          meter_id: serie.meter_id,
          min: _.min(data_values),
          avg: _.mean(data_values),
          max: _.max(data_values),
          total: _.sum(data_values),
        };
      });
    };

    const unit_suffix = ` ${this.state.selected_meters[0] &&
      this.state.selected_meters[0].unit === "liters"
      ? "Liters"
      : "kWh"
      }`;
    const series = getRowsData(this.state.chart_data);
    const comparison_series = getRowsData(this.state.comparison_chart_data);
    const comparison_available = comparison_series.length > 0;
    const timestamp =
      moment(this.state.ts_start * 1000).format("DD MMM, YYYY") +
      " - " +
      moment(this.state.ts_end * 1000).format("DD MMM, YYYY");
    const comparison_timestamp =
      moment(this.state.comparison_ts_start * 1000).format("DD MMM, YYYY") +
      " - " +
      moment(this.state.comparison_ts_end * 1000).format("DD MMM, YYYY");

    let combined_series = series.map((meter) => {
      return {
        ...meter,
        meter_id: meter.meter_id,
        comp_min:
          comparison_series.find((c_meter) => c_meter.name === meter.name) &&
          comparison_series.find((c_meter) => c_meter.name === meter.name).min,
        comp_max:
          comparison_series.find((c_meter) => c_meter.name === meter.name) &&
          comparison_series.find((c_meter) => c_meter.name === meter.name).max,
        comp_avg:
          comparison_series.find((c_meter) => c_meter.name === meter.name) &&
          comparison_series.find((c_meter) => c_meter.name === meter.name).avg,
        comp_total:
          comparison_series.find((c_meter) => c_meter.name === meter.name) &&
          comparison_series.find((c_meter) => c_meter.name === meter.name)
            .total,
      };
    });

    combined_series = _.orderBy(
      combined_series,
      this.state.sortBy,
      this.state.sortDirection
    );

    let meter_sort_icon;
    let min_sort_icon;
    let avg_sort_icon;
    let max_sort_icon;
    let total_sort_icon;
    let comp_min_sort_icon;
    let comp_avg_sort_icon;
    let comp_max_sort_icon;
    let comp_total_sort_icon;

    let icon;
    if (this.state.sortDirection === "desc")
      icon = <ion-icon name="arrow-dropdown" size="small" />;
    if (this.state.sortDirection === "asc")
      icon = <ion-icon name="arrow-dropup" size="small" />;

    if (this.state.sortBy === "name") meter_sort_icon = icon;
    if (this.state.sortBy === "min") min_sort_icon = icon;
    if (this.state.sortBy === "avg") avg_sort_icon = icon;
    if (this.state.sortBy === "max") max_sort_icon = icon;
    if (this.state.sortBy === "total") total_sort_icon = icon;
    if (this.state.sortBy === "comp_min") comp_min_sort_icon = icon;
    if (this.state.sortBy === "comp_avg") comp_avg_sort_icon = icon;
    if (this.state.sortBy === "comp_max") comp_max_sort_icon = icon;
    if (this.state.sortBy === "comp_total") comp_total_sort_icon = icon;

    const vr_tag = <div className="tag-vr">VR</div>;

    return (
      <div className="table-wrapper">
        <div className="row">
          <div className="col-12 col-sm-12">
            <table className="table mg-b-0">
              <thead>
                <tr>
                  <th className="tx-center"></th>
                  <th colSpan={4} className="tx-center">
                    {timestamp}
                  </th>
                  {comparison_available && (
                    <th colSpan={4} className="tx-center timeframe comparison">
                      {comparison_timestamp}
                    </th>
                  )}
                </tr>
              </thead>
              <thead>
                <tr>
                  <th
                    onClick={() => this.changeSortBy("name")}
                    className="meter"
                  >
                    Meter{meter_sort_icon}
                  </th>
                  <th
                    onClick={() => this.changeSortBy("min")}
                    className="tx-center"
                  >
                    Min{min_sort_icon}
                  </th>
                  <th
                    onClick={() => this.changeSortBy("avg")}
                    className="tx-center"
                  >
                    Avg{avg_sort_icon}
                  </th>
                  <th
                    onClick={() => this.changeSortBy("max")}
                    className="tx-center"
                  >
                    Max{max_sort_icon}
                  </th>
                  <th
                    onClick={() => this.changeSortBy("total")}
                    className="tx-center"
                  >
                    Total{total_sort_icon}
                  </th>
                  {comparison_available && (
                    <th
                      onClick={() => this.changeSortBy("comp_total")}
                      className="tx-center comparison"
                    >
                      Total{comp_total_sort_icon}
                    </th>
                  )}
                  {comparison_available && (
                    <th
                      onClick={() => this.changeSortBy("comp_min")}
                      className="tx-center comparison"
                    >
                      Min{comp_min_sort_icon}
                    </th>
                  )}
                  {comparison_available && (
                    <th
                      onClick={() => this.changeSortBy("comp_avg")}
                      className="tx-center comparison"
                    >
                      Avg{comp_avg_sort_icon}
                    </th>
                  )}
                  {comparison_available && (
                    <th
                      onClick={() => this.changeSortBy("comp_max")}
                      className="tx-center comparison"
                    >
                      Max{comp_max_sort_icon}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {combined_series.map((meter, index) => {
                  const is_virtual =
                    this.state.selected_meters.find(
                      (m) => m.meter_id === meter.meter_id
                    ) &&
                    this.state.selected_meters.find(
                      (m) => m.meter_id === meter.meter_id
                    ).virtual;
                  const building_id =
                    this.state.selected_meters.find(
                      (m) => m.meter_id === meter.meter_id
                    ) &&
                    this.state.selected_meters.find(
                      (m) => m.meter_id === meter.meter_id
                    ).building_id;

                  return (
                    <tr
                      key={meter.name + index}
                      onMouseEnter={() =>
                        this.setState({ legend_hover_meter_id: meter.meter_id })
                      }
                      onMouseLeave={() =>
                        this.setState({ legend_hover_meter_id: null })
                      }
                    >
                      <td
                        className="meter"
                        style={{ borderLeft: `5px solid ${meter.color}` }}
                      >
                        <div
                          className="tag-delete"
                          onClick={() =>
                            this.toggleMeter(meter.meter_id, building_id)
                          }
                        >
                          <ion-icon name="close" />
                        </div>
                        {meter.name}
                        {is_virtual && vr_tag}
                      </td>
                      <td className="tx-center">
                        {GeneralUtils.getFormattedNumberWithUnit(
                          meter.min,
                          unit_suffix,
                          1
                        )}
                      </td>
                      <td className="tx-center">
                        {GeneralUtils.getFormattedNumberWithUnit(
                          meter.avg,
                          unit_suffix,
                          1
                        )}
                      </td>
                      <td className="tx-center">
                        {GeneralUtils.getFormattedNumberWithUnit(
                          meter.max,
                          unit_suffix,
                          1
                        )}
                      </td>
                      <td className="tx-center tx-bold">
                        {GeneralUtils.getFormattedNumberWithUnit(
                          meter.total,
                          unit_suffix,
                          1
                        )}
                      </td>
                      {comparison_available && (
                        <td className="tx-center tx-bold comparison start">
                          {GeneralUtils.getFormattedNumberWithUnit(
                            meter.comp_total,
                            unit_suffix,
                            1
                          )}
                        </td>
                      )}
                      {comparison_available && (
                        <td className="tx-center comparison ">
                          {GeneralUtils.getFormattedNumberWithUnit(
                            meter.comp_min,
                            unit_suffix,
                            1
                          )}
                        </td>
                      )}
                      {comparison_available && (
                        <td className="tx-center comparison">
                          {GeneralUtils.getFormattedNumberWithUnit(
                            meter.comp_avg,
                            unit_suffix,
                            1
                          )}
                        </td>
                      )}
                      {comparison_available && (
                        <td className="tx-center comparison end">
                          {GeneralUtils.getFormattedNumberWithUnit(
                            meter.comp_max,
                            unit_suffix,
                            1
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className="br-mainpanel br-profile-page"
          style={{ scrollY: "scroll" }}
        >
          <div
            id="MetersExplorer"
            style={{
              marginTop: "0px",
              paddingTop: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Spinner />
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="MetersExplorer"
          className="br-mainpanel br-profile-page"
          style={{ scrollY: "scroll" }}
        >
          <DocumentTitle title="Meters Explorer" />
          {this.getContextualMenu()}
          {this.getChartsArea()}
        </div>
      );
    }
  }
}

export default MetersExplorer;
