import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching QR tokens and zone KPIs. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const qrActions = new QrActions();
 * qrActions.getToken('zone123');
 */
class QrActions {
  /**
   * Fetches a QR token for a specific zone and type from the API and dispatches an action to store this data.
   *
   * @param {string} zoneId - The unique identifier of the zone.
   * @param {string} [type="zone_kpi"] - The type of token to fetch.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getToken(zoneId, type = "zone_kpi") {
    return GeneralUtils.get({
      url: `zone/${zoneId}/type/${type}/token`,
      actionType: ActionTypes.QR_TOKEN_FETCHED,
      failToast: "Unable to fetch QR token, please try again",
    });
  }

  /**
   * Fetches QR tokens in bulk for an array of zones and dispatches an action to store this data.
   *
   * @param {Object[]} array - The array of zones.
   * @param {string} [type="zone_kpi"] - The type of tokens to fetch.
   */
  getTokensInBulk(array, type = "zone_kpi") {
    let modifiedZones = [];
    array.forEach((zone) => {
      let modifiedZone = { ...zone };
      GeneralUtils.get({
        url: `zone/${zone.zone_id}/type/${type}/token`,
        failToast: "Unable to fetch QR tokens, please try again",
      }).then((response) => {
        if (response) {
          modifiedZone.token = response;
          modifiedZones = [...modifiedZones, modifiedZone];
          if (array.length === modifiedZones.length) {
            Dispatcher.dispatch({
              actionType: ActionTypes.QR_TOKENS_BULK_FETCHED,
              payload: modifiedZones,
            });
          }
        }
      });
    });
  }

  /**
   * Fetches zone KPI data using a token from the API and dispatches an action to store this data.
   *
   * @param {string} token - The token used to fetch zone KPI data.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getZoneKpiWithToken(token) {
    return GeneralUtils.get({
      url: `zone/kpi/token/${token}`,
      actionType: ActionTypes.QR_ZONE_KPI_FETCHED,
      failToast: "Unable to fetch zone KPI with token, please try again",
    });
  }
}

export default new QrActions();
