import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Login.scss";
import UserActions from "../../actions/userActions";
import UserStore from "../../stores/userStore";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: UserStore.getUser(),
      loginStatus: UserStore.getLoginStatus(),
      loading: false,
    };
    this._onChange = this._onChange.bind(this);
    this.attemptLogin = this.attemptLogin.bind(this);
    this.updateMail = this.updateMail.bind(this);
    this.updatePassword = this.updatePassword.bind(this);

    this.mailRef = React.createRef();
    this.passwordRef = React.createRef();
  }
  attemptLogin(event) {
    event.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        UserActions.login({
          mail: this.mailRef.current.value,
          password: this.passwordRef.current.value,
        });
      }
    );
  }
  _onChange() {
    if (UserStore.getLoginStatus().success === true) {
      if (UserStore.loggedIn()) {
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.referrer
        ) {
          this.props.history.push(this.props.location.state.referrer);
        } else {
          this.props.history.push("/home");
        }
      }
    } else {
      this.setState({
        user: UserStore.getUser(),
        loginStatus: UserStore.getLoginStatus(),
        loading: false,
      });
    }
  }
  componentWillMount() {
    UserStore.addChangeListener(this._onChange);
    UserStore.logout();
  }
  componentWillUnmount() {
    UserStore.removeChangeListener(this._onChange);
  }
  updateMail(event) {
    UserActions.updateMail(event.target.value);
  }
  updatePassword(event) {
    UserActions.updatePassword(event.target.value);
  }
  render() {
    return (
      <div className="row no-gutters flex-row-reverse ht-100v" id="Login">
        <div className="col-md-6 right-panel d-flex align-items-center justify-content-center">
          <div className="floorplan-illustration"></div>
          <form
            onSubmit={this.attemptLogin}
            className="login-wrapper wd-250 wd-xl-350 mg-y-30"
          >
            <h4 className="tx-white-8 tx-center">Sign In</h4>
            <p className="tx-center mg-b-40 tx-white-6">
              Welcome back! Please sign in.
            </p>
            <div className="form-group">
              <input
                ref={this.mailRef}
                name="login"
                type="email"
                defaultValue={this.state.user.mail}
                className="form-control"
                placeholder="Enter your user email"
                onChange={this.updateMail}
              />
            </div>
            <div className="form-group">
              <input
                name="pass"
                ref={this.passwordRef}
                type="password"
                defaultValue={this.state.user.password}
                className="form-control"
                placeholder="Enter your password"
                onChange={this.updatePassword}
              />
              <Link
                to="/reset-password"
                className="tx-info tx-12 d-block mg-t-10"
              >
                Forgot password?
              </Link>
            </div>
            <button
              type="submit"
              className="btn btn-info btn-block"
              onClick={this.attemptLogin}
            >
              {this.state.loading ? "Signing In..." : "Sign In"}
            </button>
            <div className="mg-t-60 tx-center tx-white-6">
              Not yet a member?{" "}
              <a href="mailto:info@opnbuildings.com" className="tx-info">
                Contact Us
              </a>
            </div>
          </form>
        </div>
        <div className="col-md-6 left-panel d-flex align-items-center justify-content-center">
          <div className="floorplan-illustration"></div>
          <div className="wd-250 wd-xl-450 mg-y-30 text-wrapper">
            <div className="signin-logo tx-28 tx-bold tx-br-primary">
              <img
                className="logo-image"
                alt="logo"
                src="/img/OPNLogo.png"
                style={{ width: "100%" }}
              />
            </div>
            <div className="tx-br-primary mg-b-60 tx-center">
              Sustainable Building Performance
            </div>

            <h5 className="tx-br-primary tx-archivo-semibold">
              Why OPNBuildings?
            </h5>
            <p className="tx-br-primary">
              OPNBuildings takes away the strain of building management and
              allows you to achieve full control and visibility into your
              portfolio.
            </p>
            <p className="tx-br-primary mg-b-60">
              We give you new levels of insights as well as accurate, pinpointed
              and real-time information on issues that are happening in your
              buildings.
            </p>
            <a
              href="https://www.opnbuildings.com"
              className="btn bd bd-br-primary bd-1 tx-br-primary pd-x-25 tx-uppercase tx-12 tx-spacing-2 tx-medium visit-website-button"
            >
              Visit Website
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
