import React, { Component } from "react";
import "./InviteActivationLogin.scss";
import UserActions from "../../actions/userActions";
import UserStore from "../../stores/userStore";

import { PasswordChecker } from "../../components/PasswordChecker";
import { DocumentTitle } from "../../components/DocumentTitle";

class InviteActivationLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      loginStatus: UserStore.getLoginStatus(),
      passwordValidated: false,
    };
    this._onChange = this._onChange.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.setPasswordValidation = this.setPasswordValidation.bind(this);
  }
  _onChange() {
    if (UserStore.getLoginStatus().success === true) {
      if (UserStore.loggedIn()) {
        this.props.history.push("/home");
      }
    } else {
      this.setState({
        password: "",
        confirmPassword: "",
        loginStatus: UserStore.getLoginStatus(),
      });
    }
  }
  componentWillMount() {
    UserStore.addChangeListener(this._onChange);
    UserStore.logout();
  }
  componentWillUnmount() {
    UserStore.removeChangeListener(this._onChange);
  }
  updatePassword(event) {
    event.preventDefault();
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.user_id &&
      this.props.match.params.token &&
      this.state.passwordValidated
    ) {
      UserActions.loginWithInviteToken(
        this.props.match.params.user_id,
        this.props.match.params.token,
        this.state.password
      );
    }
  }
  onChangePassword(event) {
    this.setState({ password: event.target.value });
  }
  onChangeConfirmPassword(event) {
    this.setState({ confirmPassword: event.target.value });
  }
  setPasswordValidation(boolean) {
    this.setState({ passwordValidated: boolean });
  }
  render() {
    return (
      <div
        className="row no-gutters flex-row-reverse ht-100v"
        id="InviteActivationLogin"
      >
        <DocumentTitle title="Welcome!" />
        <div className="col-md-6 right-panel bg-gray-200 d-flex align-items-center justify-content-center">
          <form
            onSubmit={this.updatePassword}
            className="login-wrapper wd-250 wd-xl-350 mg-y-30"
          >
            <h4 className="tx-inverse tx-center tx-white-8">
              Invite Activation
            </h4>
            <p className="tx-center mg-b-20 tx-white-6">
              Your account is now activated, please enter a password for your
              account below.
            </p>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                value={this.state.password}
                placeholder="Enter your new password"
                onChange={this.onChangePassword}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                value={this.state.confirmPassword}
                placeholder="Confirm your new password"
                onChange={this.onChangeConfirmPassword}
              />
              <PasswordChecker
                password={this.state.password}
                confirmPassword={this.state.confirmPassword}
                setPasswordValidation={this.setPasswordValidation}
              />
            </div>
            <button
              type="submit"
              className="btn btn-info btn-block"
              disabled={this.state.passwordValidated === false}
              onSubmit={this.updatePassword}
              onClick={this.updatePassword}
            >
              Confirm and Login
            </button>
          </form>
        </div>
        <div className="col-md-6 left-panel d-flex align-items-center justify-content-center">
          <div className="floorplan-illustration"></div>
          <div className="wd-250 wd-xl-450 mg-y-30 text-wrapper">
            <div className="signin-logo tx-28 tx-bold tx-br-primary">
              <img
                className="logo-image"
                alt="logo"
                src="/img/OPNLogo.png"
                style={{ width: "100%" }}
              />
            </div>
            <div className="tx-br-primary mg-b-60 tx-center">
              Sustainable Building Performance
            </div>

            <h5 className="tx-br-primary tx-archivo-semibold">
              Why OPNBuildings?
            </h5>
            <p className="tx-br-primary">
              OPNBuildings takes away the strain of building management and
              allows you to achieve full control and visibility into your
              portfolio.
            </p>
            <p className="tx-br-primary mg-b-60">
              We give you new levels of insights as well as accurate, pinpointed
              and real-time information on issues that are happening in your
              buildings.
            </p>
            <a
              href="https://www.opnbuildings.com"
              className="btn bd bd-br-primary bd-1 tx-br-primary pd-x-25 tx-uppercase tx-12 tx-spacing-2 tx-medium visit-website-button"
            >
              Visit Website
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default InviteActivationLogin;
