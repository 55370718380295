import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import Constants from '../constants';
const CHANGE = 'SENSOR_CHANGE';
const TRANSACTION_STATUS_CHANGE = 'TRANSACTION_STATUS_CHANGE';
const SENSOR_DATA_CHANGE = "SENSOR_DATA_CHANGE";

let _sensors = [];
let _device_types = [];
let _successfull_transactions = [];
let _failed_transactions = [];

class SensorStore extends EventEmitter {

    constructor() {
        super();
        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
            case Constants.BUILDING_SIGFOX_SENSORS:
                this._storeSensors(action.payload);
                break;
            case Constants.SIGFOX_DEVICE_TYPES:
                this._storeDeviceTypes(action.payload);
                break;
            case Constants.SENSOR_SAVED:
                this._saveSensor(action.payload);
                break;
            case Constants.SENSOR_DELETED:
                this._deleteSensor(action.payload);
                break;
            case Constants.SENSOR_UPDATED:
                this._updateSensor(action.payload);
                break;
            case Constants.SENSOR_TRANSACTION_FAILED:
                this._failedTransaction(action.payload);
                break;
            default:
                break;
        }
    }

    // Hooks a React component's callback to the CHANGED event.
    addChangeListener(callback) {
        this.on(CHANGE, callback);
    }

    addTransactionStatusChangeListener(callback) {
        this.on(TRANSACTION_STATUS_CHANGE, callback);
    }

    addSensorDataChangeListener(callback) {
        this.on(SENSOR_DATA_CHANGE, callback);
    }

    // Removes the listener from the CHANGED event.
    removeChangeListener(callback) {
        this.removeListener(CHANGE, callback);
    }

    removeTransactionStatusChangeListener(callback) {
        this.removeListener(TRANSACTION_STATUS_CHANGE, callback);
    }

    removeSensorDataChangeListener(callback) {
        this.removeListener(SENSOR_DATA_CHANGE, callback);
    }

    clear() {
        _sensors = [];
        _device_types = [];
        _failed_transactions = [];
        _successfull_transactions = [];
    }
    clearTransactionData() {
        _failed_transactions = [];
        _successfull_transactions = [];
    }

    _storeDeviceTypes(json) {
        _device_types = json;
        this.emit(CHANGE);
    }

    _storeSensors(json) {
        _sensors = json;
        this.emit(CHANGE);
    }

    _deleteSensor(json) {
        _sensors = _sensors.filter(sensor => sensor.device_id !== json.device_id)
        this.emit(TRANSACTION_STATUS_CHANGE);
    }

    // Most likely change emissions wont be necessary for updates and posts

    _saveSensor(json) {
        const added_sensor = {
            building_id: json.building_id,
            name: json.name,
            device_id: json.device_id,
            pac_eui: json.pac_eui,
            description: json.description,
            external_registration: json.external_registration,
            sensor_id: json.response.sensor_id,
            sensor_type_id: json.response.sensor_type_id,
        }

        _sensors = [added_sensor, ..._sensors]
        _successfull_transactions = [..._successfull_transactions, json]
        this.emit(TRANSACTION_STATUS_CHANGE)
    }

    _updateSensor(json) {
        _successfull_transactions = [..._successfull_transactions, json]
        this.emit(TRANSACTION_STATUS_CHANGE)
    }

    _failedTransaction(json) {
        _failed_transactions = [..._failed_transactions, json]
        this.emit(TRANSACTION_STATUS_CHANGE)
    }

    getDeviceTypes() {
        return _device_types;
    }

    getSensors() {
        return _sensors;
    }

    getSuccessfullTransactions() {
        return _successfull_transactions
    }

    getFailedTransactions() {
        return _failed_transactions;
    }

}

export default new SensorStore();