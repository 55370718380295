import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";

let _raw_meters = [];
let _meters = [];
let _buildings = [];
let _meter_catalogue = [];
let _meter_catalogue_no_water = [];
let _list = null;
let _lists = [];
let _review_meter = null;
let _review_meters = [];
let _last_added_meter = null;
let _report = null;

const ENERGY_REVIEW_METERS_FETCHED = ActionTypes.ENERGY_REVIEW_METERS_FETCHED;
const ENERGY_REVIEW_BUILDINGS_FETCHED =
  ActionTypes.ENERGY_REVIEW_BUILDINGS_FETCHED;
const ENERGY_REVIEW_CATALOGUE_CREATED =
  ActionTypes.ENERGY_REVIEW_CATALOGUE_CREATED;
const ENERGY_REVIEW_LIST_UPDATED = ActionTypes.ENERGY_REVIEW_LIST_UPDATED;
const ENERGY_REVIEW_LISTS_FETCHED = ActionTypes.ENERGY_REVIEW_LISTS_FETCHED;
const ENERGY_REVIEW_LIST_FETCHED = ActionTypes.ENERGY_REVIEW_LIST_FETCHED;
const ENERGY_REVIEW_REVIEW_METERS_FETCHED =
  ActionTypes.ENERGY_REVIEW_REVIEW_METERS_FETCHED;
const ENERGY_REVIEW_REVIEW_METER_FETCHED =
  ActionTypes.ENERGY_REVIEW_REVIEW_METER_FETCHED;
const ENERGY_REVIEW_LIST_CREATED = ActionTypes.ENERGY_REVIEW_LIST_CREATED;
const ENERGY_REVIEW_LIST_DELETED = ActionTypes.ENERGY_REVIEW_LIST_DELETED;
const ENERGY_REVIEW_REVIEW_METER_CREATED =
  ActionTypes.ENERGY_REVIEW_REVIEW_METER_CREATED;
const ENERGY_REVIEW_REVIEW_METER_DELETED =
  ActionTypes.ENERGY_REVIEW_REVIEW_METER_DELETED;
const ENERGY_REVIEW_REVIEW_METER_UPDATED =
  ActionTypes.ENERGY_REVIEW_REVIEW_METER_UPDATED;
const ENERGY_REVIEW_REPORT_FETCHED = ActionTypes.ENERGY_REVIEW_REPORT_FETCHED;

class EnergyReviewStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  clear() {
    _raw_meters = [];
    _meters = [];
    _buildings = [];
    _meter_catalogue = [];
    _meter_catalogue_no_water = [];
    _lists = [];
    _list = null;
    _review_meter = null;
    _review_meters = [];
    _last_added_meter = null;
    _report = null;
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      // Config
      case ENERGY_REVIEW_METERS_FETCHED:
        this.storeMeters(action.payload);
        break;
      case ENERGY_REVIEW_BUILDINGS_FETCHED:
        this.storeBuildings(action.payload);
        break;
      case ENERGY_REVIEW_LISTS_FETCHED:
        this.storeLists(action.payload);
        break;
      case ENERGY_REVIEW_LIST_FETCHED:
        this.storeList(action.payload);
        break;
      case ENERGY_REVIEW_REVIEW_METERS_FETCHED:
        this.storeReviewMeters(action.payload);
        break;
      case ENERGY_REVIEW_REVIEW_METER_FETCHED:
        this.storeReviewMeter(action.payload);
        break;
      case ENERGY_REVIEW_LIST_CREATED:
        this.createList(action.payload);
        break;
      case ENERGY_REVIEW_LIST_UPDATED:
        this.updateList(action.payload);
        break;
      case ENERGY_REVIEW_LIST_DELETED:
        this.deleteList(action.payload);
        break;
      case ENERGY_REVIEW_REVIEW_METER_CREATED:
        this.createMeter(action.payload);
        break;
      case ENERGY_REVIEW_REVIEW_METER_DELETED:
        this.deleteMeter(action.payload);
        break;
      case ENERGY_REVIEW_REVIEW_METER_UPDATED:
        this.updateMeter(action.payload);
        break;
      // Report
      case ENERGY_REVIEW_REPORT_FETCHED:
        this.storeReport(action.payload);
        break;
      default:
        break;
    }
  }

  // Fetch Report

  addReportFetchedListener(callback) {
    this.on(ENERGY_REVIEW_REPORT_FETCHED, callback);
  }

  removeReportFetchedListener(callback) {
    this.removeListener(ENERGY_REVIEW_REPORT_FETCHED, callback);
  }

  storeReport(json) {
    _report = json;
    this.emit(ENERGY_REVIEW_REPORT_FETCHED);
  }

  getReport() {
    return _report;
  }
  // fetch single review meter

  addReviewMeterFetchedListener(callback) {
    this.on(ENERGY_REVIEW_REVIEW_METER_FETCHED, callback);
  }

  removeReviewMeterFetchedListener(callback) {
    this.removeListener(ENERGY_REVIEW_REVIEW_METER_FETCHED, callback);
  }

  storeReviewMeter(json) {
    _review_meter = json;
    this.emit(ENERGY_REVIEW_REVIEW_METER_FETCHED);
  }

  getReviewMeter() {
    return _review_meter;
  }

  // fetch single list

  addListFetchedListener(callback) {
    this.on(ENERGY_REVIEW_LIST_FETCHED, callback);
  }

  removeListFetchedListener(callback) {
    this.removeListener(ENERGY_REVIEW_LIST_FETCHED, callback);
  }

  storeList(json) {
    _list = json;
    this.emit(ENERGY_REVIEW_LIST_FETCHED);
  }

  getList() {
    return _list;
  }

  // Update Meter
  addReviewMeterUpdatedListener(callback) {
    this.on(ENERGY_REVIEW_REVIEW_METER_UPDATED, callback);
  }

  removeReviewMeterUpdatedListener(callback) {
    this.removeListener(ENERGY_REVIEW_REVIEW_METER_UPDATED, callback);
  }

  updateMeter(json) {
    this.emit(ENERGY_REVIEW_REVIEW_METER_UPDATED);
  }

  // Delete Meter
  addReviewMeterDeletedListener(callback) {
    this.on(ENERGY_REVIEW_REVIEW_METER_DELETED, callback);
  }

  removeReviewMeterDeletedListener(callback) {
    this.removeListener(ENERGY_REVIEW_REVIEW_METER_DELETED, callback);
  }

  deleteMeter(json) {
    this.emit(ENERGY_REVIEW_REVIEW_METER_DELETED);
  }

  // Create Review Meter
  addReviewMeterCreatedListener(callback) {
    this.on(ENERGY_REVIEW_REVIEW_METER_CREATED, callback);
  }

  removeReviewMeterCreatedListener(callback) {
    this.removeListener(ENERGY_REVIEW_REVIEW_METER_CREATED, callback);
  }

  createMeter(json) {
    _last_added_meter = null;
    if (json.selectMeter) {
      _last_added_meter = json;
    }
    this.emit(ENERGY_REVIEW_REVIEW_METER_CREATED);
  }

  getLastAddedMeter() {
    return _last_added_meter;
  }
  // Delete List
  addListDeletedListener(callback) {
    this.on(ENERGY_REVIEW_LIST_DELETED, callback);
  }

  removeListDeletedListener(callback) {
    this.removeListener(ENERGY_REVIEW_LIST_DELETED, callback);
  }

  deleteList(json) {
    this.emit(ENERGY_REVIEW_LIST_DELETED);
  }
  // Update List
  addListUpdatedListener(callback) {
    this.on(ENERGY_REVIEW_LIST_UPDATED, callback);
  }

  removeListUpdatedListener(callback) {
    this.removeListener(ENERGY_REVIEW_LIST_UPDATED, callback);
  }

  updateList(json) {
    this.emit(ENERGY_REVIEW_LIST_UPDATED);
  }

  // Create List
  addListCreatedListener(callback) {
    this.on(ENERGY_REVIEW_LIST_CREATED, callback);
  }

  removeListCreatedListener(callback) {
    this.removeListener(ENERGY_REVIEW_LIST_CREATED, callback);
  }

  createList(json) {
    this.emit(ENERGY_REVIEW_LIST_CREATED);
  }

  // Review meters fetch listener

  addReviewMetersFetchedListener(callback) {
    this.on(ENERGY_REVIEW_REVIEW_METERS_FETCHED, callback);
  }

  removeReviewMetersFetchedListener(callback) {
    this.removeListener(ENERGY_REVIEW_REVIEW_METERS_FETCHED, callback);
  }

  storeReviewMeters(json) {
    _review_meters = json;
    this.emit(ENERGY_REVIEW_REVIEW_METERS_FETCHED);
  }

  getReviewMeters() {
    return _review_meters;
  }

  // Lists fetch listener

  addListsFetchedListener(callback) {
    this.on(ENERGY_REVIEW_LISTS_FETCHED, callback);
  }

  removeListsFetchedListener(callback) {
    this.removeListener(ENERGY_REVIEW_LISTS_FETCHED, callback);
  }

  storeLists(json) {
    _lists = json;
    this.emit(ENERGY_REVIEW_LISTS_FETCHED);
  }

  getLists() {
    return _lists;
  }

  // Fetching all meters for the user.
  addMetersFetchedListener(callback) {
    this.on(ENERGY_REVIEW_METERS_FETCHED, callback);
  }

  removeMetersFetchedListener(callback) {
    this.removeListener(ENERGY_REVIEW_METERS_FETCHED, callback);
  }

  storeMeters(json) {
    _meters = [];
    _raw_meters = [];

    if (json) {
      let allMeters = json;
      allMeters.forEach(function (meter) {
        if (meter.description.toLowerCase().indexOf("hide") === -1) {
          meter.submeters = [];
          meter.expanded = false;
          json.forEach(function (m) {
            if (m.parent_id === meter.meter_id) {
              if (m.description.toLowerCase().indexOf("hide") === -1) {
                meter.submeters.push(m);
                _raw_meters.push(m);
              }
            }
          });
          if (meter.parent_id == null || meter.parent_id === 0) {
            _meters.push(meter);
            _raw_meters.push(meter);
          }
        }
      });
    }

    this.createCatalogue();
    this.emit(ENERGY_REVIEW_METERS_FETCHED);
  }

  getMeters() {
    return _meters;
  }
  getRawMeters() {
    return _raw_meters;
  }

  // Fetching all buildings for the user.
  addBuildingsFetchedListener(callback) {
    this.on(ENERGY_REVIEW_BUILDINGS_FETCHED, callback);
  }

  removeBuildingsFetchedListener(callback) {
    this.removeListener(ENERGY_REVIEW_BUILDINGS_FETCHED, callback);
  }

  storeBuildings(json) {
    _buildings = json;
    this.createCatalogue();
    this.emit(ENERGY_REVIEW_BUILDINGS_FETCHED);
  }

  getBuildings() {
    return _buildings;
  }

  // Create a catalogue
  addCatalogueCreatedListener(callback) {
    this.on(ENERGY_REVIEW_CATALOGUE_CREATED, callback);
  }

  removeCatalogueCreatedListener(callback) {
    this.removeListener(ENERGY_REVIEW_CATALOGUE_CREATED, callback);
  }

  createCatalogue() {
    // All meters included
    let meter_catalogue = _buildings
      .map((b) => {
        return {
          ...b,
          expanded: false,
          meters: _meters.filter((m) => m.building_id === b.building_id),
        };
      })
      .filter((b) => b.meters.length > 0);
    _meter_catalogue = meter_catalogue;

    // Water meters excluded
    let catalogue_no_water = _buildings
      .map((b) => {
        return {
          ...b,
          expanded: false,
          meters: _meters.filter(
            (m) =>
              m.building_id === b.building_id && m.type.includes("wm") === false
          ),
        };
      })
      .filter((b) => b.meters.length > 0);

    _meter_catalogue_no_water = catalogue_no_water;
    this.emit(ENERGY_REVIEW_CATALOGUE_CREATED);
  }

  getCatalogue() {
    return _meter_catalogue;
  }

  getCatalogueNoWater() {
    return _meter_catalogue_no_water;
  }
}

export default new EnergyReviewStore();
