import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import COLOR_PALETTE from './ColorPalette';

import GeneralUtils from '../../../../../utils/GeneralUtils';

const { Title } = Typography;

const SavingsCard = ({ data }) => {
  const current = _.get(data, 'current')
  const improvements = _.get(data, 'improvements', []);
  const noImprovements = improvements.length === 0;

  if (noImprovements) {
    return <Card bordered={false} style={{ height: '500px' }} styles={{ body: { height: '100%' } }}>
      <Title level={5}>Potential Savings</Title>
      <Row gutter={[20, 20]} style={{ height: '100%' }}>
        <Col span={24} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Title level={5}>No Upgrades found</Title>
        </Col>
      </Row>
    </Card>
  }

  const allAImprovement = improvements.find(imp => imp.electricity.grade === 'A' && imp.thermal.grade === 'A');

  const currentTotalConsumption = current.electricity.consumption + current.thermal.consumption;
  const currentTotalEmissions = current.electricity.emissions + current.thermal.emissions;
  const currentTotalCost = current.electricity.cost + current.thermal.cost;

  const improvedTotalConsumption = allAImprovement.electricity.consumption + allAImprovement.thermal.consumption;
  const improvedTotalEmissions = allAImprovement.electricity.emissions + allAImprovement.thermal.emissions;
  const improvedTotalCost = allAImprovement.electricity.cost + allAImprovement.thermal.cost;

  const consumptionSavings = currentTotalConsumption - improvedTotalConsumption;
  const emissionsSavings = currentTotalEmissions - improvedTotalEmissions;
  const costSavings = currentTotalCost - improvedTotalCost;

  const getSavingsChartOptions = (type, savings, remaining) => {
    const unit = type === 'Energy' ? 'kWh' : 'kgCO2e';

    return {
      chart: {
        type: 'pie',
        height: 180,
      },
      title: {
        text: `${type}`,
        style: {
          color: 'black',
          fontSize: '14px'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.0f} ' + unit + '</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: 10,
            connectorPadding: 5,
            connectorWidth: 1,
            format: '{point.percentage:.0f}%',
            style: {
              fontSize: '11px',
              fontWeight: 'normal',
              color: 'black',
              textOutline: 'none'
            },
            filter: {
              property: 'name',
              operator: '===',
              value: 'Savings'
            }
          },
          showInLegend: true,
          startAngle: 0,
          endAngle: 360,
          center: ['50%', '50%'],
          size: '80%'
        }
      },
      legend: {
        enabled: false
      },
      series: [{
        name: type,
        innerSize: '50%',
        data: [
          {
            name: 'Savings',
            y: savings,
            color: COLOR_PALETTE.improvements.savings
          },
          {
            name: 'Remaining',
            y: remaining,
            color: COLOR_PALETTE.improvements.remaining
          }
        ]
      }],
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    };
  };

  const getCurrencySymbol = (currency) => {
    switch (currency.toUpperCase()) {
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      default:
        return currency;
    }
  };

  return (
    <Card bordered={false} style={{ height: '100%' }}>
      <Title level={5}>Potential Savings</Title>
      <Row>
        <Col span={24} md={12} lg={24}>
          <HighchartsReact
            highcharts={Highcharts}
            options={getSavingsChartOptions('Energy', consumptionSavings, improvedTotalConsumption)}
          />
        </Col>
        <Col span={24} md={12} lg={24}>
          <HighchartsReact
            highcharts={Highcharts}
            options={getSavingsChartOptions('Emissions', emissionsSavings, improvedTotalEmissions)}
          />
        </Col>
      </Row>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Title level={5}>
          Annual Cost Savings:
        </Title>
        <Title level={3} style={{ margin: 0 }}>
          <div style={{ color: COLOR_PALETTE.improvements.savings }}>
            {getCurrencySymbol(data.user_data.currency)}
            {GeneralUtils.getFormattedNumberWithUnit(costSavings, '')}
          </div>
        </Title>
      </div>
    </Card>
  );
};

export default SavingsCard;