import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";
import _ from "lodash";
import moment from "moment";
import GeneralUtils from "../utils/GeneralUtils";
import ChartUtils from "../utils/ChartUtils";

const ZONE_DETAILS_WELLNESS_FETCHED = Constants.ZONE_DETAILS_WELLNESS_FETCHED;
const ZONE_DETAILS_UTILIZATION_FETCHED =
  Constants.ZONE_DETAILS_UTILIZATION_FETCHED;

let _zone = [];
let _tempData = [];
let _co2Data = [];
let _humidityData = [];
let _luxData = [];
let _pm25Data = [];
let _utilizationRateData = [];
let _utilizationCountData = [];
let _utilizationPirData = [];

class ZoneDetailsStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ZONE_DETAILS_WELLNESS_FETCHED:
        this._storeWellnessData(action.payload);
        break;
      case ZONE_DETAILS_UTILIZATION_FETCHED:
        this._storeUtilizationData(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addZoneDetailsFetchListener(callback) {
    this.on(ZONE_DETAILS_WELLNESS_FETCHED, callback);
  }

  removeZoneDetailsFetchListener(callback) {
    this.removeListener(ZONE_DETAILS_WELLNESS_FETCHED, callback);
  }

  addZoneUtilizationFetchListener(callback) {
    this.on(ZONE_DETAILS_UTILIZATION_FETCHED, callback);
  }

  removeZoneUtilizationFetchListener(callback) {
    this.removeListener(ZONE_DETAILS_UTILIZATION_FETCHED, callback);
  }

  clear() {
    _zone = [];
    _tempData = [];
    _co2Data = [];
    _humidityData = [];
    _luxData = [];
    _pm25Data = [];
    _utilizationRateData = [];
    _utilizationCountData = [];
    _utilizationPirData = [];
  }

  _storeWellnessData(json) {
    _zone = _.get(json, "zone");

    _tempData = [];
    _co2Data = [];
    _humidityData = [];
    _luxData = [];
    _pm25Data = [];

    let tempProfile = ChartUtils.getLineConsumptionSeries(
      "Zone Temperature",
      Constants.YELLOW,
      Constants.SOLID,
      2,
      true,
      true
    );
    let oatProfile = ChartUtils.getLineConsumptionSeries(
      "Outside Temperature",
      Constants.BLUE_TRANSPARENT,
      Constants.SOLID,
      1,
      true,
      true
    );
    let tempSPUpperProfile = ChartUtils.getLineConsumptionSeries(
      "Upper Target",
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );
    let tempSPLowerProfile = ChartUtils.getLineConsumptionSeries(
      "Lower Target",
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );

    let co2Profile = ChartUtils.getLineConsumptionSeries(
      "CO2 Level",
      Constants.YELLOW,
      Constants.SOLID,
      2,
      true,
      true
    );
    let co2SPUpperProfile = ChartUtils.getLineConsumptionSeries(
      "Upper Target",
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );
    let co2SPLowerProfile = ChartUtils.getLineConsumptionSeries(
      "Lower Target",
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );

    let humidityProfile = ChartUtils.getLineConsumptionSeries(
      "Humidity Level",
      Constants.BLUE,
      Constants.SOLID,
      2,
      true,
      true
    );
    let humiditySPUpperProfile = ChartUtils.getLineConsumptionSeries(
      "Upper Target",
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );
    let humiditySPLowerProfile = ChartUtils.getLineConsumptionSeries(
      "Lower Target",
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );

    let pm25Profile = ChartUtils.getLineConsumptionSeries(
      "PM2.5",
      Constants.RED,
      Constants.SOLID,
      2,
      true,
      true
    );
    let pm25SPUpperProfile = ChartUtils.getLineConsumptionSeries(
      "Upper Target",
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );
    let pm25SPLowerProfile = ChartUtils.getLineConsumptionSeries(
      "Lower Target",
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );

    let luxProfile = ChartUtils.getLineConsumptionSeries(
      "Lux",
      Constants.RED,
      Constants.SOLID,
      2,
      true,
      true
    );
    let luxSPUpperProfile = ChartUtils.getLineConsumptionSeries(
      "Upper Target",
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );
    let luxSPLowerProfile = ChartUtils.getLineConsumptionSeries(
      "Lower Target",
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );

    const wellness = _.get(json, "zone.wellness");

    if (wellness.length) {
      wellness.forEach((e) => {
        const timestamp = moment(e.timestamp).valueOf();

        // Temperature
        if (_.get(e, "temperature_val") !== null) {
          tempProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(e, "temperature_val"), 2),
          ]);

          tempSPUpperProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(json, "zone.temp_target_upper"), 2),
          ]);

          tempSPLowerProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(json, "zone.temp_target_lower"), 2),
          ]);
        }

        // CO2
        if (_.get(e, "co2_val") !== null) {
          co2Profile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(e, "co2_val"), 2),
          ]);
          co2SPUpperProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(json, "zone.co2_target_upper"), 2),
          ]);
          co2SPLowerProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(json, "zone.co2_target_lower"), 2),
          ]);
        }

        // Humidity
        if (_.get(e, "humidity_val") !== null) {
          humidityProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(e, "humidity_val"), 2),
          ]);
          humiditySPUpperProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(json, "zone.humd_target_upper"), 2),
          ]);
          humiditySPLowerProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(json, "zone.humd_target_lower"), 2),
          ]);
        }

        // PM2.5
        if (_.get(e, "pm25_val") !== null) {
          pm25Profile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(e, "pm25_val"), 2),
          ]);
          pm25SPUpperProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(json, "zone.pm25_target_upper"), 2),
          ]);
          pm25SPLowerProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(json, "zone.pm25_target_lower"), 2),
          ]);
        }

        // Lux
        if (_.get(e, "lux_val") !== null) {
          luxProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(e, "lux_val"), 2),
          ]);
          luxSPUpperProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(json, "zone.lux_target_upper"), 2),
          ]);
          luxSPLowerProfile.data.push([
            timestamp,
            GeneralUtils.roundNumber(_.get(json, "zone.lux_target_lower"), 2),
          ]);
        }
      });
    }

    const outside_weather = _.get(json, "zone.outside_weather_data");

    if (outside_weather.length) {
      outside_weather.forEach((e) => {
        const timestamp = moment(e.timestamp).valueOf();

        oatProfile.data.push([
          timestamp,
          GeneralUtils.roundNumber(_.get(e, "temperature"), 1),
        ]);
      });
    }

    if (tempProfile.data.length) {
      _tempData.push(
        tempProfile,
        tempSPUpperProfile,
        tempSPLowerProfile,
        oatProfile
      );
    }

    if (co2Profile.data.length) {
      _co2Data.push(co2Profile, co2SPUpperProfile, co2SPLowerProfile);
    }

    if (humidityProfile.data.length) {
      _humidityData.push(
        humidityProfile,
        humiditySPUpperProfile,
        humiditySPLowerProfile
      );
    }

    if (pm25Profile.data.length) {
      _pm25Data.push(pm25Profile, pm25SPLowerProfile, pm25SPUpperProfile);
    }

    if (luxProfile.data.length) {
      _luxData.push(luxProfile, luxSPLowerProfile, luxSPUpperProfile);
    }

    this.emit(ZONE_DETAILS_WELLNESS_FETCHED);
  }

  _storeUtilizationData(json) {
    const utilization_data = _.get(json, "chart_data");
    const pir_data = _.get(json, "pir_data");
    _utilizationRateData = [];
    _utilizationCountData = [];
    _utilizationPirData = [];

    if (utilization_data && utilization_data.length) {
      let averageRate = {
        name: "Average",
        type: "column",
        color: "#4caf50",
        data: [],
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
      };

      let peakRate = {
        name: "Peak",
        type: "line",
        lineWidth: 1,
        marker: {
          enabled: false,
        },
        color: "#01CB99",
        data: [],
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
      };

      let averageCount = {
        name: "Average",
        type: "column",
        color: "#4caf50",
        data: [],
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
      };

      let peakCount = {
        name: "Peak",
        type: "line",
        lineWidth: 1,
        marker: {
          enabled: false,
        },
        color: "#01CB99",
        data: [],
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
      };

      utilization_data.forEach((e) => {
        const timestamp = moment(e.timestamp).valueOf();

        averageRate.data.push([timestamp, e.avg_percent]);
        peakRate.data.push([timestamp, e.peak_percent]);
        averageCount.data.push([timestamp, e.avg_number]);
        peakCount.data.push([timestamp, e.peak_number]);
      });

      _utilizationRateData.push(averageRate, peakRate);
      _utilizationCountData.push(averageCount, peakCount);
    }

    if (pir_data && pir_data.data.length) {
      let pirData = {
        name:
          _.get(pir_data, "mapping.description") ||
          _.get(pir_data, "data_source.name"),
        type: "column",
        color: "#4caf50",
        data: [],
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
      };

      pir_data.data.forEach((e) => {
        const timestamp = moment(e.timestamp).valueOf();

        pirData.data.push([timestamp, e.value > 0 ? 100 : 0]);
      });

      _utilizationPirData.push(pirData);
    }

    this.emit(ZONE_DETAILS_UTILIZATION_FETCHED);
  }

  getZoneDetails() {
    return _zone;
  }

  getTemperatureData() {
    return _tempData;
  }

  getCo2Data() {
    return _co2Data;
  }

  getHumidityData() {
    return _humidityData;
  }

  getPm25Data() {
    return _pm25Data;
  }

  getLuxData() {
    return _luxData;
  }
  getUtilizationRateData() {
    return _utilizationRateData;
  }
  getUtilizationCountData() {
    return _utilizationCountData;
  }

  getUtilizationPirData() {
    return _utilizationPirData;
  }
}

export default new ZoneDetailsStore();
