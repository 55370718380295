import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching and managing counters. This class interfaces with the API
 * to retrieve, create, update, and delete counter data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const counterActions = new CounterActions();
 * counterActions.getBuildingCounters('building123');
 */
class CounterActions {
  /**
   * Fetches counters for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildingCounters(buildingId) {
    return GeneralUtils.get({
      url: `counter/building/${buildingId}`,
      actionType: ActionTypes.COUNTERS_FETCHED,
      failToast: "Unable to get building counters, please try again",
    });
  }

  /**
   * Saves a new counter to the API and dispatches an action to store the new counter data.
   *
   * @param {Object} counter - The counter object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveCounter(counter) {
    return GeneralUtils.post({
      url: "counter/",
      object: counter,
      actionType: ActionTypes.COUNTER_SAVED,
      successToast: `Counter ${counter.name} saved successfully`,
      failToast: `Unable to save counter ${counter.name}, please try again`,
    });
  }

  /**
   * Updates an existing counter in the API and dispatches an action to store the updated data.
   *
   * @param {Object} counter - The counter object to be updated.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateCounter(counter) {
    return GeneralUtils.put({
      url: "counter/",
      object: counter,
      actionType: ActionTypes.COUNTER_UPDATED,
      successToast: `Counter ${counter.name} updated successfully`,
      failToast: `Unable to update counter ${counter.name}, please try again`,
    });
  }

  /**
   * Deletes a specific counter by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} deviceId - The unique identifier of the counter to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteCounter(deviceId) {
    return GeneralUtils.delete({
      url: `counter/${deviceId}`,
      actionType: ActionTypes.COUNTER_DELETED,
      successToast: `Counter ${deviceId} deleted successfully`,
      failToast: "Unable to delete counter, please try again",
    });
  }
}

export default new CounterActions();
