import React, { Component } from "react";
import { Link } from "react-router-dom";
import { TickingTime } from "../../../components/TickingTime";
import { DialChart } from "../../../components/DialChart";
import "./MeterUsageDashboard.scss";
import moment from "moment";
import GeneralUtils from "../../../utils/GeneralUtils";
import Constants from "../../../constants/index";

// stores and actions
import CarbonEmissionsStore from "../../../stores/carbonEmissionsStore";
import CarbonEmissionsActions from "../../../actions/carbonEmissionsActions";

//charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import solidGauge from "highcharts/modules/solid-gauge.js";
import highchartsMore from "highcharts/highcharts-more.js";
import { Spinner } from "../../../components/Spinner";

class MeterUsageDashboard extends Component {
  interval = 0;
  constructor(props) {
    super(props);
    this.state = {
      building_name: null,
      building_id: 0,
      meter_name: null,
      meter_id: 0,
      meter_type: null,
      meter_unit: null,
      trend_data: [],
      today_so_far: 0,
      total_usage: 0,
      total_usage_last_week: 0,
      week_so_far: 0,
      previous_week: 0,
      change_percentage: 0,
      daily_total_average: 0,
    };

    this._onDataFetch = this._onDataFetch.bind(this);
    this._dataFetch = this._dataFetch.bind(this);
  }

  // lifecycle methods and datafetch

  componentWillMount() {
    highchartsMore(Highcharts);
    solidGauge(Highcharts);
    CarbonEmissionsStore.addMeterUsageDataFetchListener(this._onDataFetch);
  }

  componentWillUnmount() {
    CarbonEmissionsStore.removeMeterUsageDataFetchListener(this._onDataFetch);
    clearInterval(this.interval);
  }

  componentDidMount() {
    if (this.props.meter_id) {
      let meter_id = Number.parseInt(this.props.meter_id);
      this._dataFetch(meter_id);
      if (this.interval === 0) {
        this.interval = setInterval(() => this._dataFetch(meter_id), 300000);
      }
    }
  }

  _dataFetch(meter_id) {
    CarbonEmissionsActions.getMeterUsageData(meter_id);
  }

  _onDataFetch() {
    const data = CarbonEmissionsStore.getMeterUsageData();

    this.setState({
      building_name: data.building_name,
      building_id: data.building_id,
      meter_name: data.meter_name,
      meter_type: data.meter_type,
      meter_unit: data.meter_unit,
      meter_id: data.meter_id,
      trend_data: data.trend_data,
      week_so_far: data.week_so_far,
      previous_week: data.previous_week,
      change_percentage: data.change_percentage,
      today_so_far: data.today_so_far,
      daily_total_average: data.daily_total_average,
    });
  }

  // getters for Components

  getTrendVsLastWeek() {
    let thisWeek = 0;
    let lastWeek = 0;
    let change = "0%";
    let difference = 0;

    if (this.state.building_id > 0) {
      thisWeek = this.state.week_so_far;
      lastWeek = this.state.previous_week;
      change = GeneralUtils.getFormattedNumberWithUnit(
        Math.abs(this.state.change_percentage),
        "%",
        1
      );
      difference = GeneralUtils.getFormattedNumberWithUnit(
        thisWeek - lastWeek,
        " " + this.state.meter_unit,
        0
      );
    } else {
      return <Spinner height={"100%"} />;
    }

    const getMessage = () => {
      if (thisWeek > lastWeek) return change + " More than previous week.";
      if (thisWeek < lastWeek) return change + " Less than previous week.";
      return "Is the same as last week.";
    };

    const getIcon = () => {
      if (thisWeek > lastWeek) {
        return (
          <div className="icon-wrapper red">
            <ion-icon className="icon" name="trending-up" />
          </div>
        );
      }
      if (thisWeek < lastWeek) {
        return (
          <div className="icon-wrapper green">
            <ion-icon className="icon" name="trending-down" />
          </div>
        );
      }
    };

    return (
      <div id="TrendVsLastWeek">
        <div className="header">
          <div className="title">Change From Previous Week</div>
        </div>
        <div className="body">
          <div className="text-panel">
            <div className="value">{difference}</div>
            <div className="value-subtext">{getMessage()}</div>
          </div>
          <div className="icon-panel">{getIcon()}</div>
        </div>
      </div>
    );
  }

  getTriviaTile() {
    if (this.state.building_id === 0) return <Spinner height="100%" />;

    const total_usage = this.state.building_id ? this.state.week_so_far : 0;
    const emissionsFactor = 0.233;
    const total_carbon = emissionsFactor * total_usage;

    const totalKM = () => {
      const kmPerEmissions = Math.round(total_carbon / 0.12);

      return (
        <div className="trivia">
          <div className="header">
            <div className="title">Did You Know?</div>
          </div>
          <div className="body">
            <div className="text">
              <div className="value">
                {GeneralUtils.getFormattedNumberWithUnit(
                  kmPerEmissions,
                  " km",
                  0
                )}
              </div>
              <div className="label">
                The distance a passenger car would need to travel to match this
                weeks' emissions...
              </div>
            </div>
            <div className="image">
              <img className="car-image" alt="car" src="/img/car.png" />
            </div>
          </div>
        </div>
      );
    };
    const windTurbine = () => {
      const KWHPerDay = 105;

      const getFootprint = (totalKWH) => {
        let count = 0;

        if (totalKWH > 0) {
          count = Math.round((totalKWH / KWHPerDay) * 100) / 100;
        }
        let message = `${count} Days`;

        return {
          days: count,
          message: message,
        };
      };

      const results = getFootprint(total_usage);

      return (
        <div className="trivia">
          <div className="header">
            <div className="title">Did you know?</div>
            <div className="subtitle"></div>
          </div>
          <div className="body">
            <div className="image">
              <img className="wind-image" alt="wind" src="/img/wind.png" />
            </div>
            <div className="text">
              <div className="smaller-value">{results.message}</div>
              <div className="label">
                The consumption so far this week would take this many days of an
                average wind turbine in operation.
              </div>
            </div>
          </div>
        </div>
      );
    };

    const barrelsOfOil = () => {
      const barrelsOfOilKWH = 900;

      const getFootprint = (totalKWH) => {
        let count = 0;

        if (totalKWH > 0) {
          count = Math.round((totalKWH / barrelsOfOilKWH) * 100) / 100;
        }
        let message = `${count} Barrels`;

        return {
          barrels: count,
          message: message,
        };
      };

      const results = getFootprint(total_usage);

      return (
        <div className="trivia">
          <div className="header">
            <div className="title">Did you know?</div>
            <div className="subtitle"></div>
          </div>
          <div className="body">
            <div className="image">
              <img className="barrels-image" alt="oil" src="/img/oil.png" />
            </div>
            <div className="text">
              <div className="smaller-value">{results.message}</div>
              <div className="label">
                The consumption so far this week would equate to this many
                barrels of oil being burnt.
              </div>
            </div>
          </div>
        </div>
      );
    };

    const treesNeeded = () => {
      const treesNeededForYear = Math.floor(total_carbon / 19); // per https://www.eea.europa.eu/articles/forests-health-and-climate-change/key-facts/trees-help-tackle-climate-change
      const treesNeededForWeek = treesNeededForYear * 52;

      return (
        <div className="trivia">
          <div className="header">
            <div className="title">Did you know?</div>
            <div className="subtitle"></div>
          </div>
          <div className="body">
            <div className="text">
              <div className="value">
                {GeneralUtils.getFormattedNumberWithUnit(
                  treesNeededForWeek,
                  " trees",
                  0
                )}
              </div>
              <div className="label">
                would need to be planted to offset the emissions produced this
                this week so far.
              </div>
            </div>
            <div className="image">
              <img
                className="tree-image"
                style={{ width: "100%" }}
                alt="tree"
                src="/img/tree.jpg"
              />
            </div>
          </div>
        </div>
      );
    };
    const savingTip1 = () => {
      return (
        <div className="trivia">
          <div className="header">
            <div className="title">Did you know?</div>
            <div className="subtitle"></div>
          </div>
          <div className="body">
            <div className="text">
              <div className="label">
                Look out for any opportunities to save water - do not assume
                someone else has identified the opportunity.
              </div>
            </div>
            <div className="image">
              <img
                className="tree-image"
                style={{ width: "100%" }}
                alt="tree"
                src="/img/question.png"
              />
            </div>
          </div>
        </div>
      );
    };
    const savingTip2 = () => {
      return (
        <div className="trivia">
          <div className="header">
            <div className="title">Did you know?</div>
            <div className="subtitle"></div>
          </div>
          <div className="body">
            <div className="text">
              <div className="label">
                A dripping tap that leaks one drop per second can add up to 750
                litres a month. Lets report it and get it fixed.
              </div>
            </div>
            <div className="image">
              <img
                className="tree-image"
                style={{ width: "100%" }}
                alt="tree"
                src="/img/question.png"
              />
            </div>
          </div>
        </div>
      );
    };
    const washingMachineLoads = (total_usage) => {
      const amountPerWash = 72;
      const washesForWeek = total_usage / amountPerWash;

      return (
        <div className="trivia">
          <div className="header">
            <div className="title">Did you know?</div>
            <div className="subtitle"></div>
          </div>
          <div className="body">
            <div className="text">
              <div className="value">
                {GeneralUtils.getFormattedNumberWithUnit(
                  washesForWeek,
                  " washes",
                  0
                )}
              </div>
              <div className="label">
                Consumption so far this week equates to this many washes using
                an average household washing machine.
              </div>
            </div>
            <div className="image">
              <img
                className="tree-image"
                style={{ width: "100%" }}
                alt="tree"
                src="/img/washing.png"
              />
            </div>
          </div>
        </div>
      );
    };

    let isWaterMeter = false;
    if (this.state.meter_type != null) {
      if (this.state.meter_type.indexOf("wm") > -1) {
        isWaterMeter = true;
      }
    }

    if (isWaterMeter) {
      return (
        <div id="TriviaTile">
          <div
            id="trivia-carousel"
            className="carousel ride"
            data-ride="carousel"
            data-interval="7000"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#trivia-carousel"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#trivia-carousel" data-slide-to="1"></li>
              <li data-target="#trivia-carousel" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">{savingTip1()}</div>
              <div className="carousel-item ">
                {washingMachineLoads(total_usage)}
              </div>
              <div className="carousel-item ">{savingTip2()}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="TriviaTile">
          <div
            id="trivia-carousel"
            className="carousel ride"
            data-ride="carousel"
            data-interval="7000"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#trivia-carousel"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#trivia-carousel" data-slide-to="1"></li>
              <li data-target="#trivia-carousel" data-slide-to="2"></li>
              <li data-target="#trivia-carousel" data-slide-to="3"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">{totalKM()}</div>
              <div className="carousel-item ">{windTurbine()}</div>
              <div className="carousel-item ">{barrelsOfOil()}</div>
              <div className="carousel-item ">{treesNeeded()}</div>
              <div className="carousel-item ">{treesNeeded()}</div>
              <div className="carousel-item ">{treesNeeded()}</div>
            </div>
          </div>
        </div>
      );
    }
  }

  getComparisonChart() {
    if (this.state.meter_id === 0) return <Spinner height="100%" />;

    const selectedDay = moment().format("dddd");
    let usage = [];
    let predictions = [];
    let last_week = [];

    if (this.state.trend_data) {
      const trend = this.state.trend_data;
      usage = trend.map((entry) => entry.total);
      predictions = trend.map((entry) => entry.predicted_total);
      last_week = trend.map((entry) => entry.prev_total);
    }

    usage = [...usage.fill(null, moment().day())];
    predictions = [...predictions.fill(null, 0, moment().day())];
    const getChart = () => {
      const screenWidth = window.innerWidth;

      const getFontSizes = (screenWidth) => {
        let legendItem = "12px";
        let categoryItem = "16px";
        let title = "12px";
        let yAxisLabel = "12px";
        let itemDistance = 15;
        let symbolPadding = 5;
        let lineWidth = 3;

        if (screenWidth < 1920) {
          categoryItem = "12px";
        }

        if (screenWidth > 1920) {
          legendItem = "18px";
          categoryItem = "20px";
          title = "18px";
          yAxisLabel = "15px";
          itemDistance = 15;
          lineWidth = 5;
        }

        if (screenWidth > 2560) {
          legendItem = "28px";
          categoryItem = "30px";
          title = "30px";
          yAxisLabel = "24px";
          itemDistance = 20;
          symbolPadding = 10;
          lineWidth = 7;
        }

        return {
          legendItem,
          categoryItem,
          title,
          yAxisLabel,
          itemDistance,
          symbolPadding,
          lineWidth,
        };
      };

      const options = {
        credits: false,
        title: {
          text: null,
        },
        plotOptions: {
          column: {
            grouping: false,
          },
        },
        yAxis: {
          labels: {
            style: {
              fontSize: getFontSizes(screenWidth).yAxisLabel,
            },
          },
          title: {
            enabled: true,
            text: this.state.meter_unit,
            margin: 10,
            style: {
              fontSize: getFontSizes(screenWidth).title,
            },
          },
        },
        xAxis: {
          categories: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
          labels: {
            style: {
              fontSize: getFontSizes(screenWidth).categoryItem,
            },
          },
        },
        legend: {
          floating: true,
          align: "right",
          verticalAlign: "top",
          y: -17,
          itemDistance: getFontSizes(screenWidth).itemDistance,
          symbolPadding: getFontSizes(screenWidth).symbolPadding,
          backgroundColor: "white",
          itemStyle: {
            fontSize: getFontSizes(screenWidth).legendItem,
          },
        },
        series: [
          {
            type: "column",
            name: "Usage",
            data: usage,
            color: "#5f83b7",
          },
          {
            type: "column",
            name: "Prediction",
            data: predictions,
            color: "#dedede",
          },
          {
            type: "spline",
            name: "Last Week",
            color: Constants.PASTEL_BROWN,
            lineWidth: getFontSizes(screenWidth).lineWidth,
            data: last_week,
            marker: {
              lineColor: Highcharts.getOptions().colors[3],
              fillColor: Constants.PASTEL_BROWN,
            },
          },
        ],
      };

      return (
        <HighchartsReact
          containerProps={{ className: "chart-container" }}
          highcharts={Highcharts}
          options={options}
        />
      );
    };

    let type = "";
    if (this.state.meter_type != null) {
      if (this.state.meter_type.indexOf("em") > -1) {
        type = "Electrical Usage";
      } else if (this.state.meter_type.indexOf("gm") > -1) {
        type = "Gas Usage";
      } else if (this.state.meter_type.indexOf("wm") > -1) {
        type = "Water Consumption";
      }
    }

    return (
      <div id="UsageChart">
        <div className="header mg-b-10 mg-l-30">
          <div className="title">{type} - Week So Far</div>
        </div>
        <div className={`body ${selectedDay}`}>{getChart()}</div>
      </div>
    );
  }

  getTodaySoFarChart() {
    const totalUsage = this.state.meter_id > 0 ? this.state.today_so_far : 0;
    const percentage =
      this.state.meter_id > 0
        ? Math.round(
            (this.state.today_so_far / this.state.daily_total_average) * 100
          )
        : 0;

    let type = "";
    if (this.state.meter_type != null) {
      if (this.state.meter_type.indexOf("em") > -1) {
        type = "Electrical Usage";
      } else if (this.state.meter_type.indexOf("gm") > -1) {
        type = "Gas Usage";
      } else if (this.state.meter_type.indexOf("wm") > -1) {
        type = "Water Consumption";
      }
    }

    return (
      <div id="TodaySoFar">
        <div className="header">
          <div className="title">{type} - So Far Today</div>
        </div>
        <div className="body">
          <DialChart
            currentValue={totalUsage}
            goalValue={this.state.daily_total_average}
            color="#77dd77"
            milestoneLength="15%"
          />
          <div className="labels">
            <div className="current">
              <div className="label">Usage Today</div>
              <div className="unit">
                <div className="number">
                  {GeneralUtils.getFormattedNumberWithUnit(totalUsage, "", 0)}
                </div>
                <div className="suffix">
                  <div className="kwh">kwh</div>
                </div>
              </div>
            </div>
            <div className="percentage">
              <div className="unit">
                <div className="number">{percentage}%</div>
              </div>
            </div>
            <div className="goal">
              <div className="label">Daily Average</div>
              <div className="unit">
                <div className="number">
                  {GeneralUtils.getFormattedNumberWithUnit(
                    this.state.daily_total_average,
                    "",
                    0
                  )}
                </div>
                <div className="suffix">
                  <div className="kwh">kwh</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getHeader() {
    return (
      <div className="dashboard-title">
        <div className="building-name">
          {this.state.building_id > 0 ? this.state.building_name : ""}
        </div>
        <div className="title">
          {this.state.meter_id > 0 ? this.state.meter_name : ""}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="MeterUsageDashboard">
        <div className="background">
          <img
            className="background-image"
            alt="background"
            src="/img/OPNHero.png"
          />
        </div>
        {this.getHeader()}
        <div className="trivia-tile tile">
          <div className="content-wrapper">{this.getTriviaTile()}</div>
        </div>
        <div className="trend-vs-last-week tile">
          <div className="content-wrapper">{this.getTrendVsLastWeek()}</div>
        </div>
        <div className="today-so-far tile">
          <div className="content-wrapper">{this.getTodaySoFarChart()}</div>
        </div>
        <div className="chart-usage-vs-last-week tile">
          <div className="content-wrapper">{this.getComparisonChart()}</div>
        </div>
        <div className="trend-vs-last-week tile">
          <div className="content-wrapper">{this.getTrendVsLastWeek()}</div>
        </div>
        <div className="dashboard-footer">
          <div className="clock-wrapper">
            <TickingTime format="MMMM Do YYYY, h:mm:ss a" />
          </div>
          <div className="powered-by">
            <h3>
              Powered by{" "}
              <Link
                to={{ pathname: "https://www.opnbuildings.com" }}
                target="_blank"
              >
                <span>
                  <img
                    className="logo-image"
                    alt="logo"
                    src="/img/OPNLogoWhite.png"
                  />
                </span>
              </Link>
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default MeterUsageDashboard;
