import React from "react";
import "./Alarms.scss";
import _ from "lodash";
import moment from "moment";

//components
import { Modal } from "../../components/Modal";
import { Table } from "../../components/Table";
import { DocumentTitle } from "../../components/DocumentTitle";
import { LogoSpinner } from "../../components/LogoSpinner";
import DateCell from "../../components/Table/TableCells/DateCell";
import GeneralStore from "../../stores/generalStore";
import GeneralActions from "../../actions/generalActions";
import AlarmsOverviewStore from "../../stores/alarmsOverviewStore";
import AlarmsOverviewActions from "../../actions/alarmsOverviewActions";
import DoubleRowCell from "../../components/Table/TableCells/DoubleRowCell";
import ButtonCell from "../../components/Table/TableCells/ButtonCell/ButtonCell";

// modals
import { AddAlarmModal } from "./AddAlarmModal";
import { EditAlarmModal } from "./EditAlarmModal";
import { DeleteAlarmModal } from "./DeleteAlarmModal";

class Alarms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ts_start: GeneralStore.getStartDate(),
      ts_end: GeneralStore.getEndDate(),
      loading: true,
      selected_modal: null,
      selected_alarm: null,
    };

    this.onAlarmsFetch = this.onAlarmsFetch.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onAlarmsAction = this.onAlarmsAction.bind(this);
    this.rowClickHandler = this.rowClickHandler.bind(this);
    this.selectModal = this.selectModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getButtonPanelCell = this.getButtonPanelCell.bind(this);
  }

  componentWillMount() {
    AlarmsOverviewStore.addAlarmsOverviewFetchListener(this.onAlarmsFetch);
    AlarmsOverviewStore.addAlarmsOverviewActionListener(this.onAlarmsAction);
    GeneralStore.addChangeListener(this.onDateChange);
  }

  componentWillUnmount() {
    AlarmsOverviewStore.removeAlarmsOverviewFetchListener(this.onAlarmsFetch);
    AlarmsOverviewStore.removeAlarmsOverviewActionListener(this.onAlarmsAction);
    GeneralStore.removeChangeListener(this.onDateChange);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState(
      {
        loading: true,
      },
      () => AlarmsOverviewActions.getAlarmsOverview()
    );
  }

  onAlarmsFetch() {
    const data = AlarmsOverviewStore.getAlarmsOverview();
    this.setState({ data: data, loading: false });
  }

  onAlarmsAction() {
    this.closeModal();
  }

  onDateChange() {
    this.setState(
      {
        ts_start: GeneralStore.getStartDate(),
        ts_end: GeneralStore.getEndDate(),
      },
      () => this.fetchData()
    );
  }

  // Custom cells

  getAlarmCell(key, entry) {
    const name = _.get(entry, "alarm.name");
    const description = _.get(entry, "alarm.description");
    return <DoubleRowCell key={key} firstRow={name} secondRow={description} />;
  }

  getDateCell(key, entry) {
    const timestamp = _.get(entry, "alarm.ts_last_triggered");
    return <DateCell key={key} timestamp={timestamp} />;
  }

  getTypeCell(key, entry) {
    const type = _.get(entry, "alarm.target_factor");
    let label = "";

    if (type === "wellness temperature") label = "Temperature";
    if (type === "wellness CO2") label = "CO2";
    if (type === "wellness humidity") label = "Humidity";
    if (type === "utilization percent") label = "Occupancy";
    if (type === "energy consumption") label = "Energy";
    if (type === "energy reactive power") label = "Energy";
    if (type === "energy active power") label = "Energy";

    if (label === "" || type === null) {
      return (
        <td
          key={key}
          className="tx-14 not-available"
          style={{ color: "#c9c9c9" }}
        >
          N/A
        </td>
      );
    }

    return (
      <td key={key} className="tx-14">
        {label}
      </td>
    );
  }

  getButtonPanelCell(key, entry) {
    const status = _.get(entry, "alarm.status");
    const isActive = status === "active";

    return (
      <ButtonCell
        key={key}
        buttons={[
          {
            label: "Enable/Disable Alarm",
            icon: isActive ? "ToggleOn" : "ToggleOff",
            iconColor: isActive ? "#3e8e7e" : "#d3d3d3",
            clickHandler: () => this.toggleAlarmStatus(entry),
          },
          {
            label: "Edit Alarm",
            icon: "Edit",
            iconColor: "#cc7832",
            clickHandler: () => this.selectModal("edit", entry),
          },
          {
            label: "Delete Alarm",
            icon: "Cross",
            iconColor: "#d04648",
            clickHandler: () => this.selectModal("delete", entry),
          },
        ]}
      />
    );
  }

  rowClickHandler(entry) {
    const entityType = _.get(entry, "entity.type");
    const entityId = _.get(entry, "entity.id");
    const alarmTimestamp = _.get(entry, "alarm.ts_last_triggered");
    const startOfDayUnix = moment(alarmTimestamp).startOf("day").unix();
    const endOfDayUnix = moment(alarmTimestamp).endOf("day").unix();

    if (entityType === "ZONE") {
      if (alarmTimestamp) GeneralActions.setDates(startOfDayUnix, endOfDayUnix);
      sessionStorage.setItem("zoneLastLoc", this.props.location.pathname);
      this.props.history.push("/zones/" + entityId);
    } else if (entityType === "METER") {
      if (alarmTimestamp) GeneralActions.setDates(startOfDayUnix, endOfDayUnix);
      sessionStorage.setItem("meterLastLoc", this.props.location.pathname);
      this.props.history.push("/meters/" + entityId);
    }
  }

  toggleAlarmStatus(entry) {
    const id = _.get(entry, "alarm.id");
    const obj = {
      status: _.get(entry, "alarm.status") === "active" ? "disabled" : "active",
    };
    if (id && obj) {
      AlarmsOverviewActions.toggleAlarmStatus(id, obj);
    }
  }

  getModals() {
    const modal = this.state.selected_modal;
    if (modal === null) return null;
    return (
      <Modal
        style={{ minWidth: "50vw" }}
        hasExit
        toggleOpen={() => this.selectModal(null, null)}
      >
        {modal === "add" && this.getCreateAlarmModal()}
        {modal === "edit" && this.getEditAlarmModal()}
        {modal === "delete" && this.getDeleteAlarmModal()}
      </Modal>
    );
  }

  closeModal() {
    this.setState(
      {
        selected_modal: null,
      },
      () => this.fetchData()
    );
  }

  getCreateAlarmModal() {
    return <AddAlarmModal />;
  }

  getEditAlarmModal() {
    return <EditAlarmModal alarm={this.state.selected_alarm} />;
  }

  getDeleteAlarmModal() {
    return <DeleteAlarmModal alarm={this.state.selected_alarm} />;
  }

  selectModal(modal, alarm) {
    const selected_modal = this.state.selected_modal;
    this.setState({
      selected_modal: selected_modal === modal ? null : modal,
      selected_alarm: selected_modal === modal ? null : alarm,
    });
  }

  render() {
    return (
      <div
        id="Alarms"
        className="br-mainpanel br-profile-page floorplan-background"
        style={{ scrollY: "scroll" }}
      >
        <DocumentTitle title="Alarms Overview" />
        <LogoSpinner loading={this.state.loading} />
        {this.getModals()}
        <div className="br-container">
          <div className="row">
            <div className="col mg-t-30 d-flex justify-content-center">
              {(!this.state.loading || !!this.state.data.length) && (
                <Table
                  id="alarms"
                  sortBy="alarm.ts_last_triggered"
                  sortDirection="desc"
                  rowClickHandler={this.rowClickHandler}
                  addNewButton={{
                    label: "Add New Alarm",
                    clickHandler: () => this.selectModal("add", null),
                  }}
                  headers={[
                    {
                      label: "Building Name",
                      accessor: "building.name",
                      filterable: true,
                      width: 20,
                    },
                    {
                      label: "Alarm",
                      accessor: "alarm.name",
                      filterable: true,
                      width: 30,
                      customCell: this.getAlarmCell,
                    },
                    {
                      label: "Entity",
                      accessor: "entity.name",
                      filterable: true,
                      width: 20,
                    },
                    {
                      label: "Type",
                      accessor: "alarm.target_factor",
                      filterable: false,
                      width: 15,
                      customCell: this.getTypeCell,
                    },
                    {
                      label: "Last Triggered",
                      accessor: "alarm.ts_last_triggered",
                      filterable: false,
                      width: 15,
                      customCell: this.getDateCell,
                    },
                    {
                      label: "Actions",
                      accessor: "alarm.status",
                      filterable: false,
                      unsortable: false,
                      width: 5,
                      customCell: this.getButtonPanelCell,
                    },
                  ]}
                  data={this.state.data}
                  inputFilter
                  tagFilters={[
                    {
                      label: "All",
                      condition: function (obj) {
                        return obj.alarm.target_factor;
                      },
                      default: true,
                    },
                    {
                      label: "Temperature",
                      condition: function (obj) {
                        return (
                          obj.alarm.target_factor &&
                          obj.alarm.target_factor.includes(
                            "wellness temperature"
                          )
                        );
                      },
                    },
                    {
                      label: "CO2",
                      condition: function (obj) {
                        return (
                          obj.alarm.target_factor &&
                          obj.alarm.target_factor.includes("wellness CO2")
                        );
                      },
                    },
                    {
                      label: "Humidity",
                      condition: function (obj) {
                        return (
                          obj.alarm.target_factor &&
                          obj.alarm.target_factor.includes("wellness humidity")
                        );
                      },
                    },
                    {
                      label: "Energy",
                      condition: function (obj) {
                        return (
                          obj.alarm.target_factor &&
                          obj.alarm.target_factor.includes("energy consumption")
                        );
                      },
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Alarms;
