import React, { useState } from 'react';

import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import GeneralUtils from '../../../../../utils/GeneralUtils';

import COLOR_PALETTE from './ColorPalette';
import { Card, Row, Col, Button, Typography } from 'antd';
const { Text, Paragraph } = Typography;

const ImprovementCard = ({ data }) => {
  const [selectedElectricityGrade, setSelectedElectricityGrade] = useState('A');
  const [selectedThermalGrade, setSelectedThermalGrade] = useState('A');

  const gradeColors = COLOR_PALETTE.grades;
  const grades = ['A', 'B', 'C', 'D'];
  const current = _.get(data, 'current');

  if (!data || !data.improvements || !data.current || !data.user_data) {
    return null;
  }

  const currentElectricityGrade = _.get(current, 'electricity.grade');
  const currentThermalGrade = _.get(current, 'thermal.grade');
  const isCurrentGrade = selectedElectricityGrade === currentElectricityGrade && selectedThermalGrade === currentThermalGrade;

  const improvement = data.improvements.find(imp => imp.electricity.grade === selectedElectricityGrade && imp.thermal.grade === selectedThermalGrade);

  const isGradeDisabled = (grade, currentGrade) => {
    const gradeIndex = grades.indexOf(grade);
    const currentGradeIndex = grades.indexOf(currentGrade);
    return gradeIndex > currentGradeIndex;
  };

  const GradeButton = ({ grade, isSelected, onClick, currentGrade }) => {
    const disabled = isGradeDisabled(grade, currentGrade);
    return (
      <Button
        onClick={onClick}
        disabled={disabled}
        style={{
          margin: '0 5px',
          width: '40px',
          fontSize: '16px',
          fontWeight: 'bold',
          backgroundColor: isSelected ? gradeColors[grade] : 'white',
          borderColor: disabled ? COLOR_PALETTE.gray : gradeColors[grade],
          borderWidth: '2px',
          color: isSelected ? 'white' : disabled ? COLOR_PALETTE.gray : gradeColors[grade],
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        {grade}
      </Button>
    );
  };

  const overallGrade = isCurrentGrade
    ? _.get(current, 'grade')
    : improvement
      ? improvement.grade
      : null;

  const getSavingsChartOptions = (type) => {
    const currentValue = type === 'Energy'
      ? current.electricity.consumption + current.thermal.consumption
      : current.electricity.emissions + current.thermal.emissions;
    const improvedValue = type === 'Energy'
      ? improvement.electricity.consumption + improvement.thermal.consumption
      : improvement.electricity.emissions + improvement.thermal.emissions;
    const savings = currentValue - improvedValue;

    const unit = type === 'Energy' ? 'kWh' : 'kgCO2e';

    return {
      chart: {
        type: 'pie',
        height: 250,
      },
      title: {
        text: `${type} Savings`,
        align: 'center',
        verticalAlign: 'top',
        y: 20
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.0f} ' + unit + '</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: 10,
            connectorPadding: 5,
            connectorWidth: 1,
            format: '{point.percentage:.0f}%',
            style: {
              fontSize: '11px',
              fontWeight: 'normal',
              color: 'black',
              textOutline: 'none'
            },
            filter: {
              property: 'name',
              operator: '===',
              value: 'Savings'
            }
          },
          showInLegend: true,
          startAngle: 0,
          endAngle: 360,
          center: ['50%', '50%'],
          size: '80%'
        }
      },
      legend: {
        enabled: false,
      },
      series: [{
        name: type,
        innerSize: '50%',
        data: [
          {
            name: 'Savings',
            y: savings,
            color: COLOR_PALETTE.improvements.savings,
            // sliced: true,
            selected: true
          },
          {
            name: 'Remaining',
            y: improvedValue,
            color: COLOR_PALETTE.improvements.remaining
          }
        ]
      }],
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    };
  };

  if (!improvement) {
    return <Card style={{ marginBottom: '20px', minHeight: '415px' }}>
      <Row gutter={[20, 20]}>
        <Col span={24} lg={8}>
          <div style={{ textAlign: 'center', marginBottom: '30px', marginTop: '10px' }}>
            <Text strong>Electricity Grade</Text>
            <div style={{ marginTop: '10px' }}>
              {grades.map(grade => (
                <GradeButton
                  key={`electricity-${grade}`}
                  grade={grade}
                  isSelected={grade === selectedElectricityGrade}
                  onClick={() => setSelectedElectricityGrade(grade)}
                  currentGrade={currentElectricityGrade}
                />
              ))}
            </div>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <Text strong>Thermal Grade</Text>
            <div style={{ marginTop: '10px' }}>
              {grades.map(grade => (
                <GradeButton
                  key={`thermal-${grade}`}
                  grade={grade}
                  isSelected={grade === selectedThermalGrade}
                  onClick={() => setSelectedThermalGrade(grade)}
                  currentGrade={currentThermalGrade}
                />
              ))}
            </div>
          </div>
          {overallGrade && (
            <div style={{ textAlign: 'center' }}>
              <Text strong>Overall Grade</Text>
              <div style={{
                width: '60px',
                height: '60px',
                backgroundColor: gradeColors[overallGrade],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '40px',
                fontWeight: 'bold',
                color: '#fff',
                borderRadius: '10px',
                margin: '10px auto'
              }}>
                {overallGrade}
              </div>
            </div>
          )}
        </Col>
        <Col span={24} lg={16} style={{ textAlign: 'center', height: '360px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Paragraph >
            The selected grades match your current building performance.
          </Paragraph>
          <Paragraph>
            To see potential improvements, please select higher grades for either Electricity or Thermal energy use.
          </Paragraph>
        </Col>
      </Row>
    </Card>
  }


  const totalCost = improvement.electricity.cost + improvement.thermal.cost;
  const totalMaxInvestment = improvement.roi.electricity_max_investment + improvement.roi.thermal_max_investment;
  const currentTotalCost = current.electricity.cost + current.thermal.cost;


  // Check if user_data contains all required properties
  if (!data.user_data.currency || !data.user_data.roi || !data.user_data.roi.inflation_rate || !data.user_data.roi.payback_period) {
    return null;
  }

  const costSavings = currentTotalCost - totalCost;
  const costSavingsPercentage = (costSavings / currentTotalCost) * 100;

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      default:
        return currency;
    }
  };

  const formatCurrency = (value, currency) => {
    const symbol = getCurrencySymbol(currency);
    return `${symbol}${GeneralUtils.getFormattedNumberWithUnit(value)}`;
  };

  const EmphasizedValue = ({ children }) => (
    <Text strong style={{ fontSize: '14px', color: 'black' }}>
      {children}
    </Text>
  );

  const savingsSummary = (
    <>
      <Paragraph style={{ textAlign: 'center' }}>
        This plan could save up to <EmphasizedValue>{formatCurrency(costSavings, data.user_data.currency)}</EmphasizedValue> per year (<EmphasizedValue>{Math.round(costSavingsPercentage)}%</EmphasizedValue>) in energy costs.
      </Paragraph>
      <Paragraph style={{ textAlign: 'center' }}>
        The maximum recommended investment is <EmphasizedValue>{formatCurrency(totalMaxInvestment, data.user_data.currency)}</EmphasizedValue>, based on <EmphasizedValue>{data.user_data.roi.inflation_rate}%</EmphasizedValue> inflation over <EmphasizedValue>{data.user_data.roi.payback_period}</EmphasizedValue> years.
      </Paragraph>
    </>
  );

  return (
    <Card style={{ marginBottom: '20px', minHeight: '415px' }}>
      <Row gutter={[20, 20]}>
        <Col span={24} lg={8}>
          <div style={{ textAlign: 'center', marginBottom: '30px', marginTop: '10px' }}>
            <Text strong>Electricity Grade</Text>
            <div style={{ marginTop: '10px' }}>
              {grades.map(grade => (
                <GradeButton
                  key={`electricity-${grade}`}
                  grade={grade}
                  isSelected={grade === selectedElectricityGrade}
                  onClick={() => setSelectedElectricityGrade(grade)}
                  currentGrade={currentElectricityGrade}
                />
              ))}
            </div>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <Text strong>Thermal Grade</Text>
            <div style={{ marginTop: '10px' }}>
              {grades.map(grade => (
                <GradeButton
                  key={`thermal-${grade}`}
                  grade={grade}
                  isSelected={grade === selectedThermalGrade}
                  onClick={() => setSelectedThermalGrade(grade)}
                  currentGrade={currentThermalGrade}
                />
              ))}
            </div>
          </div>
          {overallGrade && (
            <div style={{ textAlign: 'center' }}>
              <Text strong>Overall Grade</Text>
              <div style={{
                width: '60px',
                height: '60px',
                backgroundColor: gradeColors[overallGrade],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '40px',
                fontWeight: 'bold',
                color: '#fff',
                borderRadius: '10px',
                margin: '10px auto'
              }}>
                {overallGrade}
              </div>
            </div>
          )}
        </Col>
        <Col span={24} lg={16}>
          <>
            <Row gutter={[20, 20]}>
              <Col span={24} lg={12}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getSavingsChartOptions('Energy')}
                />
              </Col>
              <Col span={24} lg={12}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getSavingsChartOptions('Emissions')}
                />
              </Col>
            </Row>
            <div style={{ marginTop: '20px' }}>
              {savingsSummary}
            </div>
          </>
        </Col>
      </Row>
    </Card>
  );
};

export default ImprovementCard;