import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";

const HVAC_OVERVIEW_FETCH = Constants.HVAC_OVERVIEW_FETCH;

let _hvac = [];

class HvacOverviewStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case HVAC_OVERVIEW_FETCH:
        this._storeData(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addHvacOverviewFetchListener(callback) {
    this.on(HVAC_OVERVIEW_FETCH, callback);
  }
  removeHvacOverviewFetchListener(callback) {
    this.removeListener(HVAC_OVERVIEW_FETCH, callback);
  }

  clear() {
    _hvac = [];
  }

  _storeData(json) {
    _hvac = json;

    this.emit(HVAC_OVERVIEW_FETCH);
  }

  getHvacOverview() {
    return _hvac;
  }
}

export default new HvacOverviewStore();
