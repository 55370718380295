import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import decode from "jwt-decode";

const CHANGE = "USER_CHANGE";
let _user = {
  mail: "",
  password: "",
};

let _login_Status = {
  status: "",
  success: false,
  fail: false,
};

let _users = [];
let _organisation_buildings = [];
let _organisation_roles = [];

class UserStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.SET_USER:
        this._setUser();
        break;
      case ActionTypes.LOGIN:
        this._loginUser(action.payload);
        break;
      case ActionTypes.ORGANISATION_USERS_FETCHED:
        this._setUsers(action.payload);
        break;
      case ActionTypes.LOGIN_FAIL:
        this._loginFail(action.payload);
        break;
      case ActionTypes.UPDATE_MAIL_VALUE:
        this._updateMailValue(action.payload);
        break;
      case ActionTypes.UPDATE_PASSWORD_VALUE:
        this._updatePasswordValue(action.payload);
        break;
      case ActionTypes.USER_DELETED:
        this._removeUser(action.payload);
        break;
      case ActionTypes.USER_SAVED:
        this._saveUser(action.payload);
        break;
      case ActionTypes.USER_ORGANISATION_UPDATED:
        this.emit(CHANGE);
        break;
      default:
        break;
    }
  }

  _removeUser(response) {
    if (response.deleted_user_id) {
      let users = [];
      for (let i = 0; i < _users.length; i++) {
        if (_users[i].user_id !== response.deleted_user_id) {
          users.push(_users[i]);
        }
      }
      _users = users;
      toast("User Removed Successfully", {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
        preventDuplicated: true,
      });
      this.emit(CHANGE);
    }
  }

  _saveUser(response) {
    _users.push(response);
    this.emit(CHANGE);
  }

  _loginUser(response) {
    if (response.message) {
      this.setToken(response.message);
      _login_Status.fail = false;
      _login_Status.success = true;
      _login_Status.status = "";
      _user = this.getProfile();
      this.emit(CHANGE);
    }
  }

  _loginFail(response) {
    _login_Status.fail = true;
    _login_Status.status = "Failure";
    toast(
      "Login attempt failed, please ensure valid email and password is used",
      { type: toast.TYPE.ERROR, autoClose: 3000, preventDuplicated: true }
    );
    this.emit(CHANGE);
  }

  _updateMailValue(mail) {
    _user = this.getUser();
    _user.mail = mail;
    this.emit(CHANGE);
  }

  _updatePasswordValue(password) {
    _user = this.getUser();
    _user.password = password;
    this.emit(CHANGE);
  }

  _setUsers(response) {
    if (response.organisation_users) {
      _users = response.organisation_users;
    }
    if (response.organisation_buildings) {
      _organisation_buildings = [];
      for (let i = 0; i < response.organisation_buildings.length; i++) {
        if (response.organisation_buildings[i].status !== "disabled") {
          _organisation_buildings.push(response.organisation_buildings[i]);
        }
      }
    }
    if (response.organisation_roles) {
      _organisation_roles = response.organisation_roles;
    }
    this.emit(CHANGE);
  }

  getAuthToken() {
    return _login_Status.auth_Token;
  }

  getLoginStatus() {
    return _login_Status;
  }

  getUser() {
    if (_user.user_id) {
      return _user;
    }
    _user = this.getProfile();
    return _user;
  }

  getUsers() {
    return _users;
  }

  getOrganisationRoles() {
    return _organisation_roles;
  }

  getOrganisationBuildings() {
    return _organisation_buildings;
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }

  getProfile() {
    let token = this.getToken();
    // Using jwt-decode npm package to decode the token
    if (token) {
      return JSON.parse(decode(token).user);
    } else {
      return _user;
    }
  }
  isSuper() {
    let auth = this.getToken();
    if (auth && auth.length > 0) {
      let decoded_token = decode(auth);
      if (
        decoded_token.role &&
        decoded_token.role.length > 0 &&
        decoded_token.role === "super"
      ) {
        return true;
      }
    }
    return false;
  }

  isAdmin() {
    let auth = this.getToken();
    if (auth && auth.length > 0) {
      let decoded_token = decode(auth);
      if (
        decoded_token.role &&
        decoded_token.role.length > 0 &&
        decoded_token.role === "admin"
      ) {
        return true;
      }
    }
    return false;
  }

  getSubscriptionStatus() {
    let auth = this.getToken();
    if (auth && auth.length > 0) {
      let decoded_token = decode(auth);
      if (
        decoded_token.registration_status &&
        decoded_token.registration_status.length > 0
      ) {
        return decoded_token.registration_status;
      }
    }
    return null;
  }

  removeToken() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("id_token");
    // Clear session storage
  }

  clearSessionStorage() {
    sessionStorage.clear();
  }

  logout() {
    this.removeToken();
    this.clearSessionStorage();

    _user = {
      mail: "",
      password: "",
    };

    _login_Status = {
      status: "",
      success: false,
      fail: false,
    };

    this.emit(CHANGE);
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage
    let tokenExits = !!token;
    let tokenExpired = this.isTokenExpired(token);

    return tokenExits && !tokenExpired;
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("id_token");
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem("id_token", idToken);
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      }
    } catch (err) {
      return false;
    }
    return false;
  }
}

export default new UserStore();
