import React, { Component } from "react";
import "./MappingAndValidation.scss";

// Utils
import GeneralUtils from "../../utils/GeneralUtils";
import _ from "lodash";

import { DocumentTitle } from "../../components/DocumentTitle";
import { SearchSelect } from "../../components/SearchSelect";
import { Spinner } from "../../components/Spinner";
import { Filter } from "../../components/Filter";
import { Modal } from "../../components/Modal";
import { PointDebugComponent } from "../../components/PointDebugComponent";

// stores/actions
import OrganisationActions from "../../actions/organisationActions";
import OrganisationStore from "../../stores/organisationStore";
import AutomatedAssessmentsActions from "../../actions/automatedAssessmentsActions";
import AutomatedAssessmentsStore from "../../stores/automatedAssessmentsStore";
import UserStore from "../../stores/userStore";
import GeneralStore from "../../stores/generalStore";

class MappingAndValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // buildings
      buildings: OrganisationStore.getBuildings(),
      selected_building: null,

      //mappings
      mappings: [],
      modified_property_ids: [],

      //points
      points: [],

      // units
      selected_unit_filter: null,

      // modal, loading, filter
      modal_open: false,
      modal_type: null,
      modal_object: null,
      keep_modal_open: true,
      loading: false,
      filter: "",
      before_initial_fetch: true,
      overwritten_mapping: false,
      isDesktopView: window.innerWidth > 1000,
    };

    this.setBuilding = this.setBuilding.bind(this);
    this.setUnitTypeFilter = this.setUnitTypeFilter.bind(this);
    this.setSelectedPoint = this.setSelectedPoint.bind(this);
    this.getUnits = this.getUnits.bind(this);
    this.getCard = this.getCard.bind(this);
    this.getPlaceholderCard = this.getPlaceholderCard.bind(this);
    this.getModal = this.getModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveMappings = this.saveMappings.bind(this);
    this.cancelMapping = this.cancelMapping.bind(this);

    // Listener callbacks
    this._onResize = this._onResize.bind(this);
    this._onMappingFetch = this._onMappingFetch.bind(this);
    this._onMappingsSave = this._onMappingsSave.bind(this);
    this._onMappingsSaveFail = this._onMappingsSaveFail.bind(this);
    this._onPointsFetch = this._onPointsFetch.bind(this);
    this._onOrganisationChange = this._onOrganisationChange.bind(this);
  }

  // Lifecycle methods

  componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
    AutomatedAssessmentsStore.addMappingDataFetchedListener(
      this._onMappingFetch
    );
    AutomatedAssessmentsStore.addAppliancesDataFetchedListener(
      this._onPointsFetch
    );
    AutomatedAssessmentsStore.addSavedMappingListener(this._onMappingsSave);
    AutomatedAssessmentsStore.addFailedSavedMappingListener(
      this._onMappingsSaveFail
    );
    window.addEventListener("resize", this._onResize);
  }

  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
    AutomatedAssessmentsStore.removeMappingDataFetchedListener(
      this._onMappingFetch
    );
    AutomatedAssessmentsStore.removeAppliancesDataFetchedListener(
      this._onPointsFetch
    );
    AutomatedAssessmentsStore.removeSavedMappingListener(this._onMappingsSave);
    AutomatedAssessmentsStore.removeFailedSavedMappingListener(
      this._onMappingsSaveFail
    );
    window.removeEventListener("resize", this._onResize);
  }

  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
    this.setState({ loading: true });
  }

  // Listener callbacks

  _onResize() {
    this.setState({ isDesktopView: window.innerWidth > 1000 });
  }

  _onMappingFetch() {
    const mapping_data = AutomatedAssessmentsStore.getMappingData();

    this.setState({
      ...this.state,
      loading: false,
      mappings: mapping_data,
      before_initial_fetch: false,
      overwritten_mapping: false,
      filter: "",
      selected_unit_filter: null,
      modified_property_ids: [],
    });
  }

  _onPointsFetch() {
    const points = AutomatedAssessmentsStore.getAppliancesData()
    this.setState({
      points: points
    });
  }

  _onOrganisationChange() {
    this.setState({
      buildings: OrganisationStore.getBuildings(),
      loading: false,
    });
  }

  _onMappingsSave() {
    this.setState({
      modal_open: false,
      modal_type: null,
      modal_object: null,
      modified_property_ids: [],
    });
    this.setBuilding(this.state.selected_building);
  }

  _onMappingsSaveFail() {
    this.setState({
      modal_open: false,
      modal_type: null,
      modal_object: null,
    });
  }

  // Setters/Getters

  setBuilding(building) {
    this.setState({ selected_building: building, loading: true });
    AutomatedAssessmentsActions.getBuildingMapping(building.building_id);
    AutomatedAssessmentsActions.getBuildingUniquePoints(building.building_id);
  }

  setUnitTypeFilter(type) {
    this.setState({ selected_unit_filter: type });
  }

  deletePoint(point) {
    let mappings = this.state.mappings;
    let modified_property_ids = this.state.modified_property_ids;

    // finding a unit
    mappings.entities.forEach((unit) => {
      if (
        unit.entity_type_id === point.unit.entity_type_id &&
        unit.name === point.unit.name
      ) {
        unit.properties.forEach((property) => {
          // finding a property
          if (property.property_id === point.property.property_id) {
            // updating it with current mapping
            property.updated_mappings = [];
            property.modified = true;
            modified_property_ids = [
              ...modified_property_ids,
              property.property_id + unit.name,
            ];

            modified_property_ids = [...new Set(modified_property_ids)];
          }
        });
      }
    });

    this.setState({
      mappings: mappings,
      modified_property_ids: modified_property_ids,
      overwritten_mapping: true,
    });
  }

  revertPoint(point) {
    let mappings = this.state.mappings;
    let modified_property_ids = this.state.modified_property_ids;

    // finding a unit
    mappings.entities.forEach((unit) => {
      if (
        unit.entity_type_id === point.unit.entity_type_id &&
        unit.name === point.unit.name
      ) {
        unit.properties.forEach((property) => {
          // finding a property
          if (property.property_id === point.property.property_id) {
            // updating it with current mapping
            property.updated_mappings = _.cloneDeep(property.current_mappings);
            property.modified = false;
            modified_property_ids = modified_property_ids.filter(
              (e) => e !== property.property_id + unit.name
            );

            modified_property_ids = [...new Set(modified_property_ids)];
          }
        });
      }
    });

    this.setState({
      mappings: mappings,
      modified_property_ids: modified_property_ids,
    });
  }

  setSelectedPoint(point, modal_object) {
    let mappings = _.cloneDeep(this.state.mappings);
    let updated_mappings = [];
    let overwritten_mapping = false;

    // fnding an entity
    let entity = mappings.entities.find((unit) => {
      return unit.entity_type_id === modal_object.unit.entity_type_id && unit.name === modal_object.unit.name;
    })

    if (entity) {
      // finding property
      const property = entity.properties.find((property) => {
        return modal_object.property.property_id === property.property_id
      })

      // setting correct mappings instance
      if (property) {
        updated_mappings = property.updated_mappings;
      }
    }

    const new_item = {
      data_source_id: point.fk_data_source_id,
      instance_number: point.instance_number,
      name: point.name,
      description: point.description,
      unit: point.unit,
    };


    if (updated_mappings.find(i => i.name === new_item.name && i.data_source_id === new_item.data_source_id && i.instance_number === new_item.instance_number)) {
      updated_mappings = updated_mappings.filter(mapping => {
        return mapping.data_source_id !== new_item.data_source_id ||
          mapping.instance_number !== new_item.instance_number ||
          mapping.name !== new_item.name
      });
    } else {
      updated_mappings.push(new_item);
    }

    let modified_property_ids = this.state.modified_property_ids;

    // finding a unit
    mappings.entities.forEach((unit) => {
      if (
        unit.name === modal_object.unit.name
      ) {
        unit.properties.forEach((property) => {
          // finding a property
          if (property.property_id === modal_object.property.property_id) {

            // updating it with prepared updated mapping
            property.updated_mappings = updated_mappings;
            property.modified = true;

            modified_property_ids = [
              ...modified_property_ids,
              property.property_id + unit.name,
            ];
            modified_property_ids = [...new Set(modified_property_ids)];

            if (property.current_mappings.length) overwritten_mapping = true;
          }
        });
      }
    });

    if (this.state.keep_modal_open) {
      this.setState({
        mappings: mappings,
        overwritten_mapping: overwritten_mapping,
        modified_property_ids: modified_property_ids,
      });
    } else {
      this.setState({
        modal_open: false,
        modal_type: null,
        modal_object: null,
        mappings: mappings,
        overwritten_mapping: overwritten_mapping,
        modified_property_ids: modified_property_ids,
      });
    }
  }

  getBuildings() {
    let options = this.state.buildings;
    return _.sortBy(options, e => e.name.toLowerCase());
  }

  openModal(type, object) {
    this.setState({
      modal_open: true,
      modal_type: type,
      modal_object: object,
    });
  }

  closeModal() {
    this.setState({
      modal_open: false,
      modal_type: null,
      modal_object: null,
    });
  }

  // Rendered Elements

  getModal() {
    if (this.state.modal_open === false) return null;

    if (this.state.modal_type === "point-select") {
      let element = (
        <PointDebugComponent
          ts_start={GeneralStore.getStartDate()}
          ts_end={GeneralStore.getEndDate()}
          building={this.state.selected_building}
          selectedPropertyCallback={this.setSelectedPoint}
          points={this.state.points}
          modalOpenedCallback={this.modalOpenedCallback}
          modalObject={this.state.modal_object}
          modifiedPropertyIds={[...new Set(this.state.modified_property_ids)]}
          modifiedEntity={this.state.mappings.entities.find(ent => ent.entity_type_id === this.state.modal_object.unit.entity_type_id && ent.name === this.state.modal_object.unit.name)}
          keepModalOpen={this.state.keep_modal_open}
          toggleModalOpen={() =>
            this.setState({ keep_modal_open: !this.state.keep_modal_open })
          }
        />
      );

      return (
        <Modal
          hasExit
          style={{ padding: 0, height: "90vh", width: "80vw" }}
          toggleOpen={this.closeModal}
          children={element}
        />
      );
    }

    if (this.state.modal_type === "mappings-save") {
      let element = <Spinner height={"100%"} />;

      return <Modal children={element} />;
    }

    return null;
  }

  getControls() {

    const getClasses = (type) => {
      let classNames = "btn btn-outline-info";
      if (this.state.selected_unit_filter === type) {
        classNames += " active";
      }
      return classNames;
    };

    const getNumberOfUnits = (unit) => {
      const mappings = _.get(this.state, 'mappings.entities');
      if (mappings) {
        const filtered_mappings = mappings.filter((e) => e.type === unit);
        if (filtered_mappings.length) return `(${filtered_mappings.length})`;
      }
      return null;
    };

    return (
      <div className="controls">
        <div className="filter-wrapper">
          <Filter
            placeholder={"Filter Units"}
            value={this.state.filter}
            setFilter={(e) => this.setState({ filter: e })}
          />
        </div>
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            onClick={() => this.setUnitTypeFilter(null)}
            type="button"
            style={{ zIndex: 0 }}
            className={getClasses(null)}
          >
            All
          </button>
          <button
            onClick={() => this.setUnitTypeFilter('ASSET')}
            type="button"
            style={{ zIndex: 0 }}
            className={getClasses('ASSET')}
          >
            {this.getLabel('ASSET')} {getNumberOfUnits('ASSET')}
          </button>
          <button
            onClick={() => this.setUnitTypeFilter('METER')}
            type="button"
            style={{ zIndex: 0 }}
            className={getClasses('METER')}
          >
            {this.getLabel('METER')} {getNumberOfUnits('METER')}
          </button>
          <button
            onClick={() => this.setUnitTypeFilter('ZONE')}
            type="button"
            style={{ zIndex: 0 }}
            className={getClasses('ZONE')}
          >
            {this.getLabel('ZONE')} {getNumberOfUnits('ZONE')}
          </button>
          <button
            onClick={() => this.setUnitTypeFilter('BUILDING')}
            type="button"
            style={{ zIndex: 0 }}
            className={getClasses('BUILDING')}
          >
            {this.getLabel('BUILDING')} {getNumberOfUnits('BUILDING')}
          </button>
          <button
            onClick={() => this.setUnitTypeFilter('DOORWAY')}
            type="button"
            style={{ zIndex: 0 }}
            className={getClasses('DOORWAY')}
          >
            {this.getLabel('DOORWAY')} {getNumberOfUnits('DOORWAY')}
          </button>

        </div>
      </div>
    );
  }

  getCard(unit, asset_type) {

    let properties = unit.properties;
    properties = _.sortBy(properties, (e) => e.name.toLowerCase());

    return (
      <div className="card unit-card" key={unit.name}>
        <div className="d-flex justify-content-between mb-2">
          <div className="unit-title">{unit.name}</div>
        </div>
        <div className="table-wrapper card">
          <table className="table">
            <thead>{this.getHeader()}</thead>
            <tbody>{properties.map((prop) => this.getRow(prop, unit, asset_type))}</tbody>
          </table>
        </div>
      </div>
    );
  }

  getHeader() {
    return (
      <tr>
        <th className="wd-20p tx-left pd-y-5">Property</th>
        <th className="wd-30p tx-center pd-y-5">Point Name</th>
        <th className="wd-20p tx-center pd-y-5">Controller</th>
        <th className="wd-10p tx-center pd-y-5">Confidence</th>
        <th className="wd-20p tx-center pd-y-5"></th>
      </tr>
    );
  };

  getRow(property, unit, asset_type) {

    const getSelectedPoints = () => {
      let points = []; // Initialize an empty array to hold point objects

      const source = property.updated_mappings;

      // No points selected
      if (!source.length) {
        points.push({
          name: '-',
          controller: '-',
          probability: '-'
        })
      } else {
        points = source.map(mapping => {
          // Defaults - sourced from mapping itself
          let name = mapping.name;
          let controller = '-';
          let probability = '-';
          let type = mapping.type;
          let point_not_found = true;

          // Trying to populate info from points list
          const associated_point = this.state.points.find(pt => pt.fk_data_source_id === mapping.data_source_id && pt.name === mapping.name);
          if (associated_point) {
            name = associated_point.name;
            controller = associated_point.controller_name;
            probability = GeneralUtils.getFormattedNumberWithUnit(associated_point.suggested_probability, "%", 1);
            point_not_found = false;
          }

          return {
            name,
            controller,
            probability,
            type,
            point_not_found
          }
        })
      }

      return points;
    };

    const modal_object = {
      property,
      unit,
      asset_type
    };

    const getBackgroundColor = (property) => {
      if (property.modified) return "#00ff0029";
    };

    const getCalculatedTag = () => {
      return <span className="calculated-tag">Calculated</span>;
    };


    return (
      <tr
        key={property.name}
        style={{ backgroundColor: getBackgroundColor(property) }}
      >
        <td className="tx-left valign-middle tx-inverse name">
          {property.name}
        </td>
        <td className="tx-center valign-middle tx-inverse selected-point">
          {getSelectedPoints().map((p, i) => <div style={{ color: p.point_not_found ? '#c4c4c4' : '' }} key={p.name + i}>{p.name} {p.type === 'CALCULATED' && getCalculatedTag()}</div>)}
        </td>
        <td className="tx-center valign-middle tx-inverse selected-point">
          {getSelectedPoints().map((p, i) => <div key={p.name + i}>{p.controller}</div>)}
        </td>
        <td className="tx-center valign-middle tx-inverse selected-point">
          {getSelectedPoints().map((p, i) => <div key={p.name + i}>{p.probability}</div>)}
        </td>
        <td className="tx-right valign-middle tx-inverse row-buttons">
          <button
            title="Revert Change"
            onClick={() => this.revertPoint(modal_object)}
            disabled={_.isEqual(
              property.current_mappings,
              property.updated_mappings
            )}
            className="btn btn-warning revert-button"
          >
            <ion-icon name="undo" />
          </button>
          <button
            title="Delete Point"
            onClick={() => this.deletePoint(modal_object)}
            disabled={property.current_mappings.length === 0}
            className="btn btn-danger delete-button"
          >
            <ion-icon name="trash" />
          </button>
          {this.state.isDesktopView && (
            <button
              onClick={() => this.openModal("point-select", modal_object)}
              className="btn btn-info point-button"
            >
              {property.current_mappings || property.updated_mappings
                ? "Change Point"
                : "Select Point"}
            </button>
          )}
          {this.state.isDesktopView === false && (
            <button
              title="Change Point"
              onClick={() => this.openModal("point-select", modal_object)}
              className="btn btn-info point-button-small"
            >
              <ion-icon name={"add"} />
            </button>
          )}
        </td>
      </tr>
    );
  };

  getLabel(type) {
    let label = "";

    switch (type) {
      case "ASSET":
        label = "Assets";
        break;
      case "METER":
        label = "Meters";
        break;
      case "ZONE":
        label = "Zones";
        break;
      case "BUILDING":
        label = "Buildings";
        break;
      case "DOORWAY":
        label = "Doorways";
        break;
      default:
        break;
    }

    return label;
  }

  getUnits(type) {
    let label = this.getLabel(type)

    let unitsArray = _.get(this.state, 'mappings.entities');
    if (!unitsArray) return null;
    unitsArray = unitsArray.filter((e) => e.type === type);
    unitsArray = _.sortBy(unitsArray, (e) => e.name.toLowerCase());

    // When there are no associated units, display nothing
    if (unitsArray.length === 0) return null;
    // When there are no mappings at all - display nothing
    if (this.state.mappings.length === 0) return null;
    // When there is a selected unit filter but it's not a respective unit type - display nothing
    if (
      this.state.selected_unit_filter &&
      this.state.selected_unit_filter !== type
    )
      return null;


    const unitsCards = unitsArray
      .filter((u) =>
        u.name.toLowerCase().includes(this.state.filter.toLowerCase())
      )
      .map((u) => this.getCard(u, type));

    // When there are no filtered Units
    if (unitsCards.length === 0) return null;

    return (
      <div className="unit-section row">
        <div className="col-12">
          <h5 className="section-title">{label}</h5>
          <div className="col-12 section-card">{unitsCards}</div>
        </div>
      </div>
    );
  }

  getPlaceholderCard() {
    // Before anything is fetched
    if (this.state.before_initial_fetch) {
      return (
        <div className="unit-section row before-fetch">
          <div className="col-12">
            <div className="col-12 section-card card">
              <div className="placeholder-wrapper">
                <div className="placeholder">
                  Please select the building to fetch available entities.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let allUnits = _.get(this.state, 'mappings.entities');

    const allUnitsFiltered = allUnits.filter((unit) =>
      unit.name.toLowerCase().includes(this.state.filter.toLowerCase())
    );

    const getMessage = () => {
      let message = "No Units Available";
      if (this.state.selected_unit_filter)
        message = `No ${this.getLabel(this.state.selected_unit_filter)} Available.`;
      if (allUnits.length === 0 && allUnitsFiltered.length === 0)
        return message;
      if (this.state.filter)
        message += " Please make sure selected filters are set correctly.";
      return message;
    };

    if (
      this.state.mappings.entities.length === 0 ||
      allUnitsFiltered.length === 0
    ) {
      return (
        <div className="unit-section row unit-filters">
          <div className="col-12">
            <div className="col-12 section-card card">
              <div className="placeholder-wrapper">
                <div className="placeholder">{getMessage()}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  getButtons() {
    const mappingsUnchanged = this.state.modified_property_ids.length === 0;

    if (mappingsUnchanged) return null;

    return (
      <div className="buttons-wrapper">
        <button
          onClick={this.cancelMapping}
          disabled={mappingsUnchanged}
          className="btn btn-danger"
        >
          Cancel/Revert
        </button>
        <button
          onClick={this.saveMappings}
          disabled={mappingsUnchanged}
          className="btn btn-success"
        >
          Save Mappings
        </button>
      </div>
    );
  }

  saveMappings() {
    const posted_object = this.state.mappings;

    if (this.state.overwritten_mapping) {
      if (
        window.confirm(
          "Overwriting a mapping will destroy associated historical data. Are you sure you wish to continue?"
        )
      ) {
        AutomatedAssessmentsActions.saveMapping(posted_object);
        this.openModal("mappings-save", null);
      }
    } else {
      AutomatedAssessmentsActions.saveMapping(posted_object);
    }
  }

  cancelMapping() {
    if (
      window.confirm(
        "This will revert all the changes. Are you sure you wish to continue?"
      )
    ) {
      this.setState({
        mappings: AutomatedAssessmentsStore.getMappingData(),
        modified_property_ids: [],
      });
    }
  }

  render() {
    return (
      <div className="br-mainpanel br-profile-page pd-15" id={"MappingAndValidation"}>
        <DocumentTitle title="Mapping and Validation Tool" />
        {this.getModal()}
        <div className="br-pagetitle mg-b-0">
          <div className="row wd-100p d-flex justify-between">
            <div className="col-12 col-md-6">
              <h4>
                Mapping and Validation Tool{" "}
                {this.state.selected_building
                  ? `- ${this.state.selected_building.name}`
                  : ""}
              </h4>
              <p className="mg-b-0">
                Match the properties with selected points.
              </p>
            </div>
            <div className="col-12 col-md-6 col-xl-3 ml-xl-auto mg-t-20 mt-md-0 building-selection">
              <div style={{ maxWidth: "400px" }}>
                <Spinner mini trueCondition={this.state.loading} />
                <SearchSelect
                  limit={9999}
                  extraHeight={true}
                  options={this.getBuildings()}
                  placeholder={
                    this.state.loading ? "Loading..." : "Select the building"
                  }
                  defaultValue={this.state.selected_building}
                  actionOnSelectedOption={this.setBuilding}
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="col-12 units">
            {this.getControls()}
            {this.getUnits("ASSET")}
            {this.getUnits("METER")}
            {this.getUnits("ZONE")}
            {this.getUnits("BUILDING")}
            {this.getUnits("DOORWAY")}
            {this.getPlaceholderCard()}
            {this.getButtons()}
          </div>
        </div>
      </div>
    );
  }
}

export default MappingAndValidation;
