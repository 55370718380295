import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";
import ActionTypes from "../constants";

import ChartUtils from "../utils/ChartUtils";
import moment from "moment";
import GeneralUtils from "../utils/GeneralUtils";

const CHANGE = "ASSETS_CHANGE";
const ASSET_OPERATION = "ASSET_OPERATION";

let _assets = [];
let _zone_assets = [];
let _heating_assets = [];
let _cooling_assets = [];
let _heating_circ_assets = [];
let _cooling_circ_assets = [];
let _air_handling_assets = [];

let _asset_types = [];

let _controlData = [];
let _runningCostData = [];
let _runningStatusData = [];
let _efficiencyData = [];

class AssetsStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.ASSETS_FETCHED:
        this._storeAssets(action.payload);
        break;
      case ActionTypes.ASSET_TYPES_FETCHED:
        this._storeAssetsType(action.payload);
        break;
      case ActionTypes.ASSET_SAVED:
        this._saveAsset(action.payload);
        break;
      case ActionTypes.ASSET_UPDATED:
        this._updateAsset(action.payload);
        break;
      case ActionTypes.ASSET_DELETED:
        this._deleteAsset(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }
  addAssetOperationListener(callback) {
    this.on(ASSET_OPERATION, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }
  removeAssetOperationListener(callback) {
    this.removeListener(ASSET_OPERATION, callback);
  }

  clear() {
    _assets = [];
    _zone_assets = [];
    _heating_assets = [];
    _cooling_assets = [];
    _air_handling_assets = [];
    _heating_circ_assets = [];
    _cooling_circ_assets = [];

    _controlData = [];
    _runningCostData = [];
    _runningStatusData = [];
    _efficiencyData = [];
  }

  _storeAssets(json) {
    _assets = json;

    if (json.assets) {
      let all_assets = json.assets;
      _zone_assets = [];
      _heating_assets = [];
      _cooling_assets = [];
      _air_handling_assets = [];
      _heating_circ_assets = [];
      _cooling_circ_assets = [];

      for (let i = 0; i < all_assets.length; i++) {
        if (all_assets[i].agg_control_score) {
          all_assets[i].cost = GeneralUtils.roundNumber(
            all_assets[i].agg_control_score.running_cost,
            2
          );
          all_assets[i].control_score = GeneralUtils.roundNumber(
            all_assets[i].agg_control_score.score,
            2
          );
          all_assets[i].efficiency_score = GeneralUtils.roundNumber(
            all_assets[i].agg_control_score.efficiency_score,
            2
          );
          if (all_assets[i].efficiency_score > 92) {
            all_assets[i].efficiency_score = 90.5;
          }
          if (all_assets[i].efficiency_score === 0) {
            all_assets[i].efficiency_score = null;
          }
          all_assets[i].operation_indicator =
            all_assets[i].agg_control_score.operation_indicator;
        }

        if (GeneralUtils.getAssetType(all_assets[i]).toLowerCase() === "zone") {
          _zone_assets.push(all_assets[i]);
        } else if (
          GeneralUtils.getAssetType(all_assets[i]).toLowerCase() ===
          "air_handling"
        ) {
          _air_handling_assets.push(all_assets[i]);
        } else if (
          GeneralUtils.getAssetType(all_assets[i]).toLowerCase() === "heating"
        ) {
          _heating_assets.push(all_assets[i]);
        } else if (
          GeneralUtils.getAssetType(all_assets[i]).toLowerCase() === "cooling"
        ) {
          _cooling_assets.push(all_assets[i]);
        } else if (
          GeneralUtils.getAssetType(all_assets[i]).toLowerCase() === "hdc"
        ) {
          _heating_circ_assets.push(all_assets[i]);
        } else if (
          GeneralUtils.getAssetType(all_assets[i]).toLowerCase() === "cdc"
        ) {
          _cooling_circ_assets.push(all_assets[i]);
        }
      }
    }
    this.emit(CHANGE);
  }

  _storeAssetsType(json) {
    _asset_types = json;
    this.emit(CHANGE);
  }

  _saveAsset() {
    this.emit(ASSET_OPERATION);
  }

  _updateAsset(json) {
    if (json.asset_id) {
      for (let i = 0; i < _assets.length; i++) {
        if (_assets[i].asset_id === json.asset_id) {
          _assets[i] = json;
          break;
        }
      }
    }
    this.emit(ASSET_OPERATION);
  }
  _deleteAsset(json) {
    this.emit(ASSET_OPERATION);
  }

  _setAssetChartData(json) {
    _controlData = [];
    _runningCostData = [];
    _runningStatusData = [];
    _efficiencyData = [];

    let control_name =
      json.control_scores &&
      json.control_scores[0] &&
      json.control_scores[0].control_value_property_name
        ? json.control_scores[0].control_value_property_name
        : "Control Value";
    let control_setpoint =
      json.control_scores &&
      json.control_scores[0] &&
      json.control_scores[0].control_value_property_name
        ? json.control_scores[0].control_setpoint_property_name
        : "Control Setpoint Target";
    let controlTemp = ChartUtils.getLineConsumptionSeries(
      control_name,
      Constants.YELLOW,
      Constants.SOLID,
      2,
      true,
      true
    );
    let controlSetpoint = ChartUtils.getLineConsumptionSeries(
      control_setpoint,
      Constants.GREEN_TRANSPARENT,
      Constants.DASH,
      2,
      true,
      true
    );

    let gasRunningCost = ChartUtils.getColumnConsumptionSeries(
      "Gas Cost",
      Constants.ORANGE,
      true,
      true
    );
    let elecRunningCost = ChartUtils.getColumnConsumptionSeries(
      "Electrical Cost",
      Constants.BLUE,
      true,
      true
    );

    let scheduledStatus = ChartUtils.getAreaConsumptionSeries(
      "Scheduled Runtime",
      Constants.GREEN_TRANSPARENT,
      true,
      true
    );
    let downTimeStatus = ChartUtils.getColumnConsumptionSeries(
      "Inactive Status",
      Constants.RED_TRANSPARENT,
      true,
      true
    );

    let efficiencyScores = ChartUtils.getColumnConsumptionSeries(
      "Efficiency Score",
      Constants.GREEN,
      true,
      true
    );

    for (let i = 0; i < json.control_scores.length; i++) {
      controlTemp.data.push([
        moment(json.control_scores[i].timestamp).valueOf(),
        GeneralUtils.roundNumber(json.control_scores[i].control_value, 2),
      ]);
      controlSetpoint.data.push([
        moment(json.control_scores[i].timestamp).valueOf(),
        GeneralUtils.roundNumber(json.control_scores[i].control_setpoint, 2),
      ]);

      gasRunningCost.data.push([
        moment(json.control_scores[i].timestamp).valueOf(),
        json.control_scores[i].gas_running_cost,
      ]);
      elecRunningCost.data.push([
        moment(json.control_scores[i].timestamp).valueOf(),
        json.control_scores[i].elec_running_cost,
      ]);

      scheduledStatus.data.push([
        moment(json.control_scores[i].timestamp).valueOf(),
        json.control_scores[i].is_scheduled > 0 ? 1 : 0,
      ]);
      downTimeStatus.data.push([
        moment(json.control_scores[i].timestamp).valueOf(),
        json.control_scores[i].is_down > 0 ? 1 : 0,
      ]);

      let eff_score = GeneralUtils.roundNumber(
        json.control_scores[i].efficiency_score,
        2
      );
      if (eff_score > 92) {
        eff_score = 90.5;
      }
      efficiencyScores.data.push([
        moment(json.control_scores[i].timestamp).valueOf(),
        eff_score,
      ]);
    }

    _controlData.push(controlTemp, controlSetpoint);
    _runningCostData.push(gasRunningCost, elecRunningCost);
    _runningStatusData.push(scheduledStatus, downTimeStatus);
    _efficiencyData.push(efficiencyScores);
  }

  getAssets() {
    return _assets;
  }

  getHeatingAssets() {
    return _heating_assets;
  }

  getCoolingAssets() {
    return _cooling_assets;
  }

  getHeatingCircAssets() {
    return _heating_circ_assets;
  }

  getCoolingCircAssets() {
    return _cooling_circ_assets;
  }

  getZoneAssets() {
    return _zone_assets;
  }

  getAirHandlingAssets() {
    return _air_handling_assets;
  }

  getAssetTypes() {
    return _asset_types;
  }

  getControlData() {
    return _controlData;
  }

  getEfficiencyData() {
    return _efficiencyData;
  }

  getRunningCostData() {
    return _runningCostData;
  }

  getRunningStatusData() {
    return _runningStatusData;
  }
}

export default new AssetsStore();
