import React from "react";
import _ from "lodash";

//components
import { DocumentTitle } from "../../components/DocumentTitle";
import { LogoSpinner } from "../../components/LogoSpinner";
import { PageTitle } from "../../components/PageTitle";
import { AnalysisCard } from "../../components/AnalysisCard";

import { Row, Col } from 'antd';

class Analysis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    title: "Out-of-Hours Energy Analysis",
                    description:
                        "This feature analyses energy consumption outside of your building’s normal operating hours and compares it against best practice benchmarks and typical levels. A traffic light system is used to present the results clearly and simply.",
                    imgsrc: "/img/analysisThumbnails/consumption-analysis.png",
                    type: "energy",
                    disabled: false,
                    link: "/consumption-analysis",
                },
                {
                    title: "Energy Analysis",
                    description:
                        "Track performance of your selected meters over given time periods, in contrast with their respective baselines.",
                    imgsrc: "/img/analysisThumbnails/energy-analysis.png",
                    type: "energy",
                    disabled: false,
                    link: "/energy-analysis",
                },
                {
                    title: "Building Benchmark",
                    description:
                        "Benchmark the energy consumption of your building by comparing it with similar buildings based on type, consumption, and usage (e.g., business hours).",
                    imgsrc: "/img/analysisThumbnails/building-benchmark.png",
                    type: "energy",
                    disabled: false,
                    link: "/building-benchmark",
                }
            ],
            selected_filter: null
        };
    }

    cardClickHandler(link) {
        this.props.history.push("/analysis" + link);
    }

    getCards() {
        const filter = this.state.selected_filter;
        let list = this.state.items;

        if (filter) {
            list = list.filter((db) => {
                return db.type.includes(filter);
            });
        }
        list = _.sortBy(list, "disabled");

        return list.map((db, i) => {
            return (
                <Col span={24} xxl={12} key={db.title}>
                    <AnalysisCard
                        title={db.title}
                        imgsrc={db.imgsrc}
                        description={db.description}
                        clickHandler={() => this.cardClickHandler(db.link)}
                        disabled={db.disabled}
                    />
                </Col>
            );
        });
    }



    selectFilter(filter) {
        const selected_filter = this.state.selected_filter;
        this.setState({
            selected_filter: selected_filter === filter ? null : filter,
        });
    }

    render() {
        return (
            <div
                id="Dashboards"
                className="br-mainpanel br-profile-page floorplan-background"
                style={{ scrollY: "scroll" }}
            >
                <DocumentTitle title="Analysis" />
                <LogoSpinner loading={this.state.loading} />
                <div className="br-container">
                    <div className="row mg-b-10">
                        <div className="col mg-t-30">
                            <PageTitle
                                buttons={[
                                    {
                                        label: "All",
                                        trueCondition: this.state.selected_filter === null,
                                        clickHandler: () => this.selectFilter(null),
                                    },
                                    {
                                        label: "Energy",
                                        trueCondition: this.state.selected_filter === "energy",
                                        clickHandler: () => this.selectFilter("energy"),
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <Row gutter={[50, 50]}>
                        {this.getCards()}
                    </Row>
                </div>
            </div>
        );
    }
}

export default Analysis;
