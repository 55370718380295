import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";

const CHANGE = "UTILIZATION_CHANGE";

let _live_zone = {
  name: "",
  zone_id: 0,
  description: "",
  sub_zones: [],
  current_count: 0,
  threshold_target: 0,
  conn_id: "",
  conn_token: "",
};
let _current_occupancy_level = 0;
let _current_occupancy_count = 0;

class UtilizationStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Handles action dispatches
  _registerToActions(action) {
    switch (action.actionType) {
      case Constants.LIVE_ZONE_FETCHED:
        this._storeLiveZone(action.payload);
        break;
      default:
        break;
    }
  }

  // Store live zone information from the payload
  _storeLiveZone(json) {
    _live_zone = {
      name: json.name || "",
      zone_id: json.zone_id || 0,
      description: json.description || "",
      sub_zones: json.sub_zones || [],
      current_count: json.current_count || 0,
      threshold_target: json.threshold_target || 0,
      conn_id: json.conn_id || "",
      conn_token: json.conn_token || "",
    };
    _current_occupancy_count = json.current_count || 0;
    _current_occupancy_level = json.threshold_target
      ? Math.round((_current_occupancy_count / json.threshold_target) * 100)
      : 0;
    this.emit(CHANGE);
  }

  // Event listeners for React components
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }

  // Clear store data
  clear() {
    _live_zone = {
      name: "",
      zone_id: 0,
      description: "",
      sub_zones: [],
      current_count: 0,
      threshold_target: 0,
      conn_id: "",
      conn_token: "",
    };
    _current_occupancy_level = 0;
    _current_occupancy_count = 0;
  }

  // Getters
  getLiveZone() {
    return _live_zone;
  }

  getCurrentOccupancyLevel() {
    return _current_occupancy_level;
  }

  getCurrentOccupancyCount() {
    return _current_occupancy_count;
  }
}

export default new UtilizationStore();
