import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to managing energy reviews, including configuration, lists, meters, and reports.
 * This class interfaces with the API to retrieve, create, update, and delete data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const energyReviewActions = new EnergyReviewActions();
 * energyReviewActions.getBuildings();
 */
class EnergyReviewActions {
  // ################
  // #### Config ####
  // ################

  /**
   * Fetches the list of allowed buildings from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildings() {
    return GeneralUtils.get({
      url: "building/allowed",
      actionType: ActionTypes.ENERGY_REVIEW_BUILDINGS_FETCHED,
    });
  }

  /**
   * Fetches the list of meters from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getMeters() {
    return GeneralUtils.get({
      url: "meter/",
      actionType: ActionTypes.ENERGY_REVIEW_METERS_FETCHED,
    });
  }

  // Lists

  /**
   * Fetches the lists of energy reviews for a specific organization from the API and dispatches an action to store this data.
   *
   * @param {string} orgId - The unique identifier of the organization.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getLists(orgId) {
    return GeneralUtils.get({
      url: `energy-review-list?organisationId=${orgId}`,
      actionType: ActionTypes.ENERGY_REVIEW_LISTS_FETCHED,
    });
  }

  /**
   * Fetches a specific energy review list by its ID from the API and dispatches an action to store this data.
   *
   * @param {string} listId - The unique identifier of the list.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getList(listId) {
    return GeneralUtils.get({
      url: `energy-review-list/${listId}`,
      actionType: ActionTypes.ENERGY_REVIEW_LIST_FETCHED,
    });
  }

  /**
   * Creates a new energy review list by sending the provided list object to the API and dispatches an action to store this data.
   *
   * @param {Object} newList - The new list object to be created.
   * @returns {Promise} - A promise that resolves with the response of the create operation.
   */
  createList(newList) {
    return GeneralUtils.post({
      url: "energy-review-list",
      object: newList,
      actionType: ActionTypes.ENERGY_REVIEW_LIST_CREATED,
      successToast: "List saved successfully",
      failToast: "Unable to save list, please try again",
    });
  }

  /**
   * Updates an existing energy review list by sending the updated list object to the API and dispatches an action to store this data.
   *
   * @param {Object} updatedList - The updated list object.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateList(updatedList) {
    return GeneralUtils.put({
      url: "energy-review-list",
      object: updatedList,
      actionType: ActionTypes.ENERGY_REVIEW_LIST_UPDATED,
      successToast: "List updated successfully",
      failToast: "Unable to update the list, please try again",
    });
  }

  /**
   * Deletes a specific energy review list by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} listId - The unique identifier of the list.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteList(listId) {
    return GeneralUtils.delete({
      url: `energy-review-list/${listId}`,
      actionType: ActionTypes.ENERGY_REVIEW_LIST_DELETED,
      successToast: "List deleted successfully",
      failToast: "Unable to delete list, please try again",
    });
  }

  // Meters

  /**
   * Fetches the review meters for a specific organization from the API and dispatches an action to store this data.
   *
   * @param {string} orgId - The unique identifier of the organization.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getReviewMeters(orgId) {
    return GeneralUtils.get({
      url: `energy-review-meter?organisationId=${orgId}`,
      actionType: ActionTypes.ENERGY_REVIEW_REVIEW_METERS_FETCHED,
    });
  }

  /**
   * Fetches a specific review meter by its ID from the API and dispatches an action to store this data.
   *
   * @param {string} meterId - The unique identifier of the meter.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getReviewMeter(meterId) {
    return GeneralUtils.get({
      url: `energy-review-meter/${meterId}`,
      actionType: ActionTypes.ENERGY_REVIEW_REVIEW_METER_FETCHED,
    });
  }

  /**
   * Creates a new review meter by sending the provided meter object to the API and dispatches an action to store this data.
   * Optionally selects the meter after creation.
   *
   * @param {Object} newMeter - The new meter object to be created.
   * @param {boolean} [selectMeter=false] - Whether to select the meter after creation.
   * @returns {Promise} - A promise that resolves with the response of the create operation.
   */
  createReviewMeter(newMeter, selectMeter = false) {
    return GeneralUtils.post({
      url: "energy-review-meter",
      object: newMeter,
      actionType: ActionTypes.ENERGY_REVIEW_REVIEW_METER_CREATED,
      successToast: "Meter baselines created successfully",
      failToast: "Unable to update meter baselines, please try again",
      modifyResponse: (response) => {
        return { ...response, selectMeter };
      },
    });
  }

  /**
   * Updates an existing review meter by sending the updated meter object to the API and dispatches an action to store this data.
   *
   * @param {Object} updatedMeter - The updated meter object.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateReviewMeter(updatedMeter) {
    return GeneralUtils.put({
      url: "energy-review-meter",
      object: updatedMeter,
      actionType: ActionTypes.ENERGY_REVIEW_REVIEW_METER_UPDATED,
      successToast: "Review meter updated successfully",
      failToast: "Unable to update review meter, please try again",
    });
  }

  /**
   * Deletes a specific review meter by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} meterId - The unique identifier of the meter.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteReviewMeter(meterId) {
    return GeneralUtils.delete({
      url: `energy-review-meter/${meterId}`,
      actionType: ActionTypes.ENERGY_REVIEW_REVIEW_METER_DELETED,
      successToast: "Review Meter deleted successfully",
      failToast: "Unable to delete Review Meter, please try again",
    });
  }

  // ################
  // #### Report ####
  // ################

  /**
   * Fetches an energy review report for a specific token within an optional time range from the API
   * and dispatches an action to store this data.
   *
   * @param {string} token - The token used to fetch the report.
   * @param {string} [tsStart] - The optional start timestamp of the report range.
   * @param {string} [tsEnd] - The optional end timestamp of the report range.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getReport(token, tsStart, tsEnd) {
    return GeneralUtils.get({
      url: `energy-review-report/${token}${tsStart && tsEnd ? `?from=${tsStart}&to=${tsEnd}` : ""}`,
      actionType: ActionTypes.ENERGY_REVIEW_REPORT_FETCHED,
    });
  }
}

export default new EnergyReviewActions();
