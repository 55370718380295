import React, { Component } from 'react';
import './ManageAppliances.scss';

import OrganisationActions from '../../actions/organisationActions';
import OrganisationStore from '../../stores/organisationStore';
import UserStore from '../../stores/userStore';

import { Appliances } from './Appliances'
import { SearchSelect } from '../../components/SearchSelect'
import { DocumentTitle } from '../../components/DocumentTitle'

class ManageAppliances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: OrganisationStore.getBuildings(),
      selected_building: null
      }

    // listener events
    this._onOrganisationChange = this._onOrganisationChange.bind(this);

    // // filter setters
    this._setTargetBuilding = this._setTargetBuilding.bind(this);
  }

  componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
  }

  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
  }

  _onOrganisationChange() {
    this.setState({
      buildings: OrganisationStore.getBuildings(),
    })
  }

  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
  }

  _setTargetBuilding(selected_building) {

    this.setState({
      selected_building: selected_building,
     });
  }

  render() {
    let buildingsFormattedArray = this.state.buildings.map(building => {
      return {...building, name: building.name + ` (building id: ${building.building_id})`}
    })


    return (
      <div className="br-mainpanel pd-15">
        <DocumentTitle title='Manage Appliances' />
        <div className="br-pagetitle mg-b-0">
          <div>
            <h4>Update Appliances Details</h4>
            <p className="mg-b-0">Update and create new Appliances. Select the building to continue.
            </p>
          </div>
        </div>
        <div>
          <div className="col-12">
          <div>
          <div className="br-pagetitle pd-x-0 pd-b-0 mg-b-10" style={{ width: '100%' }}>
              <span className='col-6 col-md-4 pd-l-0'>
                <SearchSelect limit={100} options={buildingsFormattedArray} placeholder={"Select Building"} defaultValue={this.state.selected_building} actionOnSelectedOption={this._setTargetBuilding} />
              </span>              
          </div>
          {this.state.selected_building && <Appliances building={this.state.selected_building}/>}
        </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageAppliances;