import React, { Component } from "react";
import "./MeterTree.scss";
import _ from "lodash";
import PropTypes from "prop-types";

import EnergyReviewStore from "../../stores/energyReviewStore";
import EnergyReviewActions from "../../actions/energyReviewActions";

import { LogoSpinner } from "../LogoSpinner";

import { Filter } from "../../components/Filter";

class MeterTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      catalogue: [],
      review_meters: [],
      selected_meters: [],
      selected_type: { label: "All", accessor: "" },
      loading: true,
      meter_types: [
        { label: "Electric", accessor: "em" },
        { label: "Gas", accessor: "gm" },
        { label: "Heat", accessor: "hm" },
        { label: "All", accessor: "" },
      ],
    };

    this.onCatalogueFetched = this.onCatalogueFetched.bind(this);
  }

  componentDidMount() {
    if (this.state.catalogue.length === 0) {
      EnergyReviewActions.getMeters();
      EnergyReviewActions.getBuildings();
    }
  }

  componentWillMount() {
    EnergyReviewStore.addCatalogueCreatedListener(this.onCatalogueFetched);
  }

  componentWillUnmount() {
    EnergyReviewStore.removeCatalogueCreatedListener(this.onCatalogueFetched);
  }

  // Callback Listeners

  onCatalogueFetched() {
    const parentFilters = this.props.filterMeterTypes;
    const meters = EnergyReviewStore.getRawMeters();
    let catalogue = EnergyReviewStore.getCatalogue();

    if (parentFilters && parentFilters.includes("wm")) {
      catalogue = EnergyReviewStore.getCatalogueNoWater();
    }

    this.setState({ catalogue: catalogue, meters: meters, loading: false });
  }

  toggleBuildingExpansion(building_id) {
    const modified_catalogue = _.cloneDeep(this.state.catalogue);

    let building = modified_catalogue.find(
      (b) => b.building_id === building_id
    );
    building.expanded = !building.expanded;
    this.setState({ catalogue: modified_catalogue });
  }

  toggleMeterExpansion(meter_id, building_id) {
    let modified_catalogue = _.cloneDeep(this.state.catalogue);
    let meters = modified_catalogue.find(
      (e) => e.building_id === building_id
    ).meters;

    const findIdAndToggle = (id, arr) => {
      arr.forEach((el) => {
        if (el.meter_id === id) {
          el.expanded = !el.expanded;
          return;
        } else {
          findIdAndToggle(id, el.submeters);
        }
      });
    };

    findIdAndToggle(meter_id, meters);
    this.setState({ catalogue: modified_catalogue });
  }

  toggleMeter(meter_id, building_id) {
    let modified_catalogue = _.cloneDeep(this.state.catalogue);
    let meters = modified_catalogue.find(
      (e) => e.building_id === building_id
    ).meters;

    const findIdAndSelect = (id, arr) => {
      arr.forEach((el) => {
        if (el.meter_id === id) {
          let modified_selected_meters = _.cloneDeep(
            this.state.selected_meters
          );

          if (modified_selected_meters.find((m) => m.meter_id === meter_id)) {
            this.setState(
              {
                selected_meters: modified_selected_meters.filter(
                  (m) => m.meter_id !== meter_id
                ),
              },
              () => this.makeListChange()
            );
          } else {
            const review_meter = this.state.review_meters.find(
              (rm) => rm.meter_id === el.meter_id
            );
            this.setState(
              {
                selected_meters: [...modified_selected_meters, review_meter],
              },
              () => this.makeListChange()
            );
          }
        } else {
          findIdAndSelect(id, el.submeters);
        }
      });
    };

    findIdAndSelect(meter_id, meters);

    this.setState({ catalogue: modified_catalogue });
  }

  collapseAllMeters() {
    let modified_catalogue = _.cloneDeep(this.state.catalogue);

    function collapseAllRecursive(meter) {
      meter.expanded = false;
      if (meter.submeters) {
        for (const submeter of meter.submeters) {
          collapseAllRecursive(submeter);
        }
      }
    }

    modified_catalogue.forEach((b) => {
      b.expanded = false;

      b.meters.forEach((m) => {
        collapseAllRecursive(m);
      });
    });

    this.setState({ catalogue: modified_catalogue });
  }

  clearAllFilters() {
    this.setState({
      selected_type: { label: "All", accessor: "" },
      filter: "",
    });
  }

  selectMeter(meter) {
    if (this.props.toggleHandler) {
      this.props.toggleHandler(meter);
    }
  }

  filterTree(array, fn) {
    return array.reduce((r, o) => {
      let submeters = this.filterTree(o.submeters || [], fn);
      if (fn(o) || submeters.length) {
        r.push(Object.assign({}, o, submeters.length && { submeters }));
      }
      return r;
    }, []);
  }

  getSubMeters(parent_meter, count, isDisabled = false) {
    if (
      (parent_meter.expanded || this.state.filter.length > 0) &&
      parent_meter.submeters.length > 0
    ) {
      let indent = count * 15;
      count++;
      return parent_meter.submeters.map((meter, key) => {
        return (
          <div key={key} style={{ opacity: isDisabled ? 0.2 : 1 }}>
            {this.getRow(meter, indent, isDisabled)}
            {this.getSubMeters(meter, count, isDisabled)}
          </div>
        );
      });
    }
  }

  getRow(meter, indent, isDisabled = false) {
    const meterSelected = this.props.selected_meters.find(
      (m) => m.meter_id === meter.meter_id
    );

    const virtualTag = meter.virtual ? (
      <div
        className="label-vr"
        data-rh="Virtually Calculated Meter"
        title="Virtually Calculated Meter"
      >
        VR
      </div>
    ) : undefined;

    let expandIcon = <div style={{ width: "18px" }}></div>;
    if (meter.submeters.length > 0)
      expandIcon = (
        <ion-icon
          onClick={this.toggleMeterExpansion.bind(
            this,
            meter.meter_id,
            meter.building_id
          )}
          name={meter.expanded ? "remove-circle-outline" : "add-circle-outline"}
        ></ion-icon>
      );
    if (this.state.filter !== "") expandIcon = <ion-icon name="return-right" />;

    return (
      <div
        className="row-wrapper"
        style={{
          marginLeft: indent + "px",
          display: isDisabled ? "none" : "",
        }}
      >
        <div className="row-icon">{expandIcon}</div>
        <div
          className={`row-label`}
          onClick={() => this.selectMeter(meter)}
          title={meter.description}
        >
          {virtualTag}
          {meter.description}
        </div>
        <div className="row-checkbox" onClick={() => this.selectMeter(meter)}>
          <div className="checkbox-outline">
            {meterSelected && <div className="checkbox-fill"></div>}
          </div>
        </div>
      </div>
    );
  }

  getMeterTree(meters, building_id) {
    const filter = this.state.filter;

    if (filter) {
      const list_result = this.state.meters
        .filter((meter) => meter.building_id === building_id)
        .filter((meter) =>
          meter.type.includes(this.state.selected_type.accessor)
        )
        .filter((meter) => meter.description.toLowerCase().includes(filter));

      return list_result.map((meter, key) => {
        return (
          <div key={key} style={{ marginLeft: "20px", position: "relative" }}>
            {this.getRow(meter, 0)}
          </div>
        );
      });
    }

    let result = this.filterTree(meters, ({ description }) =>
      description.toLowerCase().includes(filter)
    );

    return result.map((meter, key) => {
      let disabled = false;
      // if type is selected, grey out every other type
      if (meter.type.includes(this.state.selected_type.accessor) === false) {
        disabled = true;
      }

      return (
        <div
          key={key}
          style={{
            marginLeft: "20px",
            position: "relative",
          }}
        >
          {this.getRow(meter, 0, disabled)}
          {this.getSubMeters(meter, 1, disabled)}
        </div>
      );
    });
  }

  getBuildingMeters(building) {
    const filter = this.state.filter;
    const buildingHasSelectedType = building.meters.find((meter) =>
      meter.type.includes(this.state.selected_type.accessor)
    );

    const buildingFilteredMetersLackSelectedType =
      this.getMeterTree(building.meters, building.building_id).length === 0;

    const buildingFulfillsFilterCriteria =
      this.filterTree(building.meters, ({ description }) =>
        description.toLowerCase().includes(filter)
      ).length > 0;

    const building_is_disabled = building.status === "disabled";

    let expandIcon = (
      <ion-icon
        name={
          building.expanded ? "remove-circle-outline" : "add-circle-outline"
        }
      ></ion-icon>
    );

    if (this.state.filter !== "")
      expandIcon = <ion-icon name="business"></ion-icon>;

    if (
      buildingFilteredMetersLackSelectedType ||
      buildingHasSelectedType === undefined ||
      buildingFulfillsFilterCriteria === false ||
      building_is_disabled
    )
      return null;

    return (
      <div key={building.building_id} className="building-tree">
        <div
          className="building-wrapper"
          onClick={this.toggleBuildingExpansion.bind(
            this,
            building.building_id
          )}
        >
          {expandIcon} <span className="building-label">{building.name}</span>
        </div>
        {(building.expanded || this.state.filter !== "") &&
          this.getMeterTree(building.meters, building.building_id)}
      </div>
    );
  }

  render() {
    return (
      <div
        className="MeterTree"
        style={{
          height: this.props.height ? this.props.height : "100%",
          width: this.props.width ? this.props.width : "100%",
        }}
      >
        <LogoSpinner loading={this.state.loading} />
        <div className="meter-tree card">
          <div className="title-type-wrapper">
            <div className="label">Meter Tree</div>
            <div className="meter-type-wrapper">
              {this.state.meter_types.map((t) => {
                return (
                  <button
                    key={t.accessor}
                    className={`btn ${
                      this.state.selected_type.accessor === t.accessor
                        ? "btn-secondary"
                        : "btn-outline-secondary"
                    }`}
                    onClick={() => this.setState({ selected_type: t })}
                  >
                    {t.label}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="filter">
            <div className="filter-wrapper">
              <Filter
                value={this.state.filter}
                placeholder={"Filter by Meter Name"}
                background="white"
                setFilter={(query) => this.setState({ filter: query })}
              />
            </div>
            <div className="clear-all-wrapper">
              <button
                className="clear-all-button"
                onClick={() => this.clearAllFilters()}
              >
                Clear Filters
              </button>
            </div>
          </div>
          <div className="tree-selector">
            <div className="inner-selector">
              {this.state.catalogue.map((building) =>
                this.getBuildingMeters(building)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MeterTree.propTypes = {
  height: PropTypes.string,
  toggleHandler: PropTypes.func,
  clearAllMetersHandler: PropTypes.bool,
  filterMeterTypes: PropTypes.arrayOf(PropTypes.string),
  selected_meters: PropTypes.arrayOf(
    PropTypes.shape({
      meter_id: PropTypes.number.isRequired,
    })
  ),
};

export default MeterTree;
