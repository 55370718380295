import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./ZoneConditionsDashboard.scss";
import moment from "moment";
import _ from "lodash";
import DashboardsStore from "../../../stores/dashboardsStore";
import DashboardsActions from "../../../actions/dashboardsActions";
import { Spinner } from "../../../components/Spinner";
import { TickingTime } from "../../../components/TickingTime";

class ZoneConditionsDashboard extends Component {
  interval = 0;
  constructor(props) {
    super(props);
    this.state = {
      zone_kpi: {},
      refressRateSeconds: 120,
      active_tab: "air_quality",
      activeTabLock: false,
      loading: true,
      refreshing: false,
      isDesktopView: window.innerWidth > 1000,
      isMobile:
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
    };

    this._onFetch = this._onFetch.bind(this);
    this._onResize = this._onResize.bind(this);
    this._fetchData = this._fetchData.bind(this);
  }
  _onFetch() {
    const zone_kpi = DashboardsStore.getZoneConditionsData();
    const air_quality_available = zone_kpi.co2_value !== null;
    const temperature_available = zone_kpi.temperature_value !== null;
    const occupancy_available = zone_kpi.occupancy_count !== null;
    const tabLock = this.state.activeTabLock;

    let active_tab = this.state.active_tab;
    if (!tabLock) active_tab = "air_quality";
    if (!air_quality_available && !tabLock) active_tab = "temperature";
    if (!air_quality_available && !temperature_available && !tabLock)
      active_tab = "occupancy";

    this.setState({
      zone_kpi: zone_kpi,
      air_quality_available,
      temperature_available,
      occupancy_available,
      active_tab,
      loading: false,
      refreshing: false,
      activeTabLock: true,
    });
  }

  _onResize() {
    this.setState({ isDesktopView: window.innerWidth > 1000 });
  }

  _fetchData(manualRefresh = false) {
    const token = _.get(this.props, "match.params.token");

    if (manualRefresh) {
      this.setState(
        {
          refreshing: true,
        },
        () => {
          DashboardsActions.getDataWithToken(token);
        }
      );
    } else {
      DashboardsActions.getDataWithToken(token);
    }
  }

  componentWillMount() {
    DashboardsStore.addDashboardDataFetchedListener(this._onFetch);
    window.addEventListener("resize", this._onResize);
  }
  componentWillUnmount() {
    DashboardsStore._clear();
    DashboardsStore.removeDashboardDataFetchedListener(this._onFetch);
    window.removeEventListener("resize", this._onResize);
    clearInterval(this.interval);
  }
  componentDidMount() {
    this._fetchData();
    let refreshRate = this.state.refressRateSeconds * 1000;
    if (this.interval === 0) {
      this.interval = setInterval(() => this._fetchData(), refreshRate);
    }
  }

  getColor(active_tab_or_color) {
    let color;
    const zone_kpi = this.state.zone_kpi;

    const gray = "gray";
    const red = "#dc3646";
    const orange = "#f59918";
    const green = "#44a047";

    if (active_tab_or_color === "air_quality") {
      const warning_threshold = Math.floor(zone_kpi.co2_target * 1.25);
      if (this.state.air_quality_available === false) {
        color = gray;
      } else if (zone_kpi.co2_value && zone_kpi.co2_target === null) {
        color = green;
      } else if (zone_kpi.co2_value < zone_kpi.co2_target) {
        color = green;
      } else if (zone_kpi.co2_value >= warning_threshold) {
        color = red;
      } else if (
        zone_kpi.co2_value < warning_threshold &&
        zone_kpi.co2_value >= zone_kpi.co2_target
      ) {
        color = orange;
      }
    } else if (active_tab_or_color === "temperature") {
      if (this.state.temperature_available === false) {
        color = gray;
      } else if (
        zone_kpi.temperature_value <= zone_kpi.temperature_upper_target &&
        zone_kpi.temperature_value >= zone_kpi.temperature_lower_target
      ) {
        color = green;
      } else if (
        zone_kpi.temperature_value <= zone_kpi.temperature_upper_target &&
        zone_kpi.temperature_lower_target === null
      ) {
        color = green;
      } else if (
        zone_kpi.temperature_value >= zone_kpi.temperature_lower_target &&
        zone_kpi.temperature_upper_target === null
      ) {
        color = green;
      } else {
        color = red;
      }
    } else if (active_tab_or_color === "occupancy") {
      const warning_threshold = Math.floor(
        zone_kpi.occupancy_target_limit * 0.9
      );
      if (this.state.occupancy_available === false) {
        color = gray;
      } else if (
        zone_kpi.occupancy_count >= 0 &&
        zone_kpi.occupancy_count < warning_threshold
      ) {
        color = green;
      } else if (zone_kpi.occupancy_count > zone_kpi.occupancy_target_limit) {
        color = red;
      } else if (
        zone_kpi.occupancy_count >= warning_threshold &&
        zone_kpi.occupancy_count <= zone_kpi.occupancy_target_limit
      ) {
        color = orange;
      } else {
        color = green;
      }
    } else if (active_tab_or_color === "green") {
      color = green;
    } else if (active_tab_or_color === "red") {
      color = red;
    } else if (active_tab_or_color === "orange") {
      color = orange;
    }

    return color;
  }

  getTabbedViewHeader() {
    let description = (
      <h5 className="description">{this.state.zone_kpi.description}</h5>
    );
    if (this.state.zone_kpi.description === this.state.zone_kpi.name) {
      description = null;
    }
    return (
      <div
        className="row top-section"
        style={{ marginTop: "5px", marginBottom: "8px" }}
      >
        <div className="col-12 tx-center d-flex justify-content-center align-items-center flex-column">
          <h5 className="title">{this.state.zone_kpi.name}</h5>
          {description}
        </div>
      </div>
    );
  }

  getTabbedViewContent() {
    const mobile_view = <>{this.getKpiCard()}</>;

    const desktop_view = (
      <>
        {this.state.air_quality_available && this.getKpiCard("air_quality")}
        {this.state.temperature_available && this.getKpiCard("temperature")}
        {this.state.occupancy_available && this.getKpiCard("occupancy")}
        {!this.state.occupancy_available &&
          !this.state.temperature_available &&
          !this.state.air_quality_available &&
          !this.state.temperature_available && (
            <div
              style={{ height: "62vh", display: "flex", alignItems: "center" }}
            >
              <h1>No data currently available.</h1>
            </div>
          )}
      </>
    );

    const view = this.state.isDesktopView ? desktop_view : mobile_view;

    return (
      <div className="row mid-section">
        <div className="col-12 d-flex justify-content-center">{view}</div>
      </div>
    );
  }

  getTabbedViewFooter() {
    const timestamp = this.state.zone_kpi.timestamp;
    const mobileFooter = (
      <div className="row bottom-section mobile">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div className="timestamp">
            <div className="label">Last Sensor reading</div>
            <div className="value">
              {moment(timestamp).format("DD MMM, h:mm a")}
            </div>
          </div>
          <div className="powered-by">
            <div className="label">Powered by</div>
            <Link
              to={{ pathname: "https://www.opnbuildings.com" }}
              target="_blank"
            >
              <img alt="OPNBuildings Logo" src="/img/OPNLogo.png" />
            </Link>
          </div>
        </div>
      </div>
    );

    const desktopFooter = (
      <div className="row bottom-section">
        <div className="col-12">
          <div style={{ background: "transparent", height: "13vh" }}></div>
          <div className="desktop-footer">
            <div className="clock-wrapper">
              <TickingTime format="MMMM Do YYYY, h:mm:ss a" />
              <h5>
                Last Sensor Reading:{" "}
                {moment(timestamp).format("MMMM Do YYYY, h:mm:ss a")}
              </h5>
            </div>
            <div className="powered-by">
              <h3>
                Powered By
                <Link
                  to={{ pathname: "https://www.opnbuildings.com" }}
                  target="_blank"
                >
                  <img alt="OPNBuildings Logo" src="/img/OPNLogo.png" />
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </div>
    );

    const footer = this.state.isDesktopView ? desktopFooter : mobileFooter;

    return this.state.zone_kpi.name && footer;
  }

  getKpiCard(tab = null) {
    const active_tab = tab || this.state.active_tab;

    let title = "";
    if (active_tab === "occupancy") title = "Occupancy Levels";
    if (active_tab === "air_quality") title = "CO2 Levels";
    if (active_tab === "temperature") title = "Temperature";

    return (
      <div className="card shadow-base">
        <div className="card-header">
          <div></div>
          <h5 className="header-title">{title}</h5>
          <h5
            className={`header-refresh ${this.state.refreshing ? "" : "spin"}`}
          >
            <ion-icon name="refresh" onClick={() => this._fetchData(true)} />
          </h5>
        </div>
        {this.getKpiTab(active_tab)}
        {tab === null && (
          <div className="card-footer d-flex">
            <button
              disabled={this.state.air_quality_available === false}
              onClick={() => this.setState({ active_tab: "air_quality" })}
            >
              <ion-icon
                style={{ color: this.getColor("air_quality") }}
                name="leaf"
              />
              <h6>CO2 Levels</h6>
            </button>
            <button
              disabled={this.state.temperature_available === false}
              onClick={() => this.setState({ active_tab: "temperature" })}
            >
              <ion-icon
                style={{ color: this.getColor("temperature") }}
                name="thermometer"
              />
              <h6>Temperature</h6>
            </button>

            <button
              disabled={this.state.occupancy_available === false}
              onClick={() => this.setState({ active_tab: "occupancy" })}
            >
              <ion-icon
                style={{ color: this.getColor("occupancy") }}
                name="people"
              />
              <h6>Occupancy</h6>
            </button>
          </div>
        )}
      </div>
    );
  }

  getKpiTab(tab) {
    const zone_kpi = this.state.zone_kpi;
    const active_tab = tab;

    const getAirQualityTab = () => {
      const mainDisplay =
        zone_kpi.co2_value !== null ? (
          <div
            className="air-quality-tab"
            style={{ backgroundColor: this.getColor("air_quality") }}
          >
            {zone_kpi.co2_value.toFixed()}
            <span
              style={{ fontSize: "32px", marginTop: "16px", marginLeft: "5px" }}
            >
              ppm
            </span>
          </div>
        ) : (
          <div
            className="air-quality-tab"
            style={{ backgroundColor: this.getColor("air_quality") }}
          >
            Offline
          </div>
        );

      const legend = zone_kpi.co2_target ? (
        <>
          <p>THRESHOLDS (PPM):</p>
          <div className="legend-wrapper">
            <p
              style={{ backgroundColor: this.getColor("green") }}
            >{`< ${Math.floor(zone_kpi.co2_target)} `}</p>
            <p
              style={{
                backgroundColor: this.getColor("orange"),
                padding: "5px 7px",
                borderRadius: "5px",
              }}
            >{`${zone_kpi.co2_target} - ${Math.floor(
              zone_kpi.co2_target * 1.25
            )} `}</p>
            <p
              style={{ backgroundColor: this.getColor("red") }}
            >{`> ${Math.floor(zone_kpi.co2_target * 1.25)} `}</p>
          </div>
        </>
      ) : (
        <div className="tx-center wd-80p">
          <h3>No thresholds set for this metric.</h3>
        </div>
      );

      const getInstructions = () => {
        const orange = "#f2a975";
        const red = "#f8837f";
        if (this.getColor("air_quality") === red) {
          return (
            <p className="instructions tx-center tx-12">
              Please ensure windows are opened to the maximum extent and doors
              opened to encourage air flow through the space. Check back in 15
              minutes to see if the reading has reduced or increased.
            </p>
          );
        } else if (this.getColor("air_quality") === orange) {
          return (
            <p className="instructions tx-center tx-12">
              Please open windows and doors to a tolerable level to freshen up
              the room and continue monitoring co2 levels.
            </p>
          );
        }

        return null;
      };

      return (
        <div className="card-body">
          <div className="body-main">{mainDisplay}</div>
          <div className="body-bottom">
            {legend}
            {getInstructions()}
          </div>
        </div>
      );
    };

    const getTemperatureTab = () => {
      const mainDisplay =
        zone_kpi.temperature_value !== null ? (
          <div
            className="temperature-tab"
            style={{ backgroundColor: this.getColor("temperature") }}
          >
            {zone_kpi.temperature_value.toFixed(1)}
            <span
              style={{
                fontSize: "32px",
                marginLeft: "0",
                marginBottom: "16px",
              }}
            >
              °C
            </span>
          </div>
        ) : (
          <div
            className="temperature-tab"
            style={{ backgroundColor: this.getColor("temperature") }}
          >
            Offline
          </div>
        );

      const getLegend = () => {
        let legend = (
          <div className="tx-center wd-80p">
            <h3>No thresholds set for this metric.</h3>
          </div>
        );
        if (
          zone_kpi.temperature_lower_target !== null &&
          zone_kpi.temperature_upper_target !== null
        ) {
          legend = (
            <>
              <p>THRESHOLDS (°C):</p>
              <div className="legend-wrapper">
                <p
                  style={{ backgroundColor: this.getColor("red") }}
                >{`< ${zone_kpi.temperature_lower_target} `}</p>
                <p
                  style={{ backgroundColor: this.getColor("green") }}
                >{`${zone_kpi.temperature_lower_target} - ${zone_kpi.temperature_upper_target} `}</p>
                <p
                  style={{ backgroundColor: this.getColor("red") }}
                >{`> ${zone_kpi.temperature_upper_target} `}</p>
              </div>
            </>
          );
        } else if (
          zone_kpi.temperature_lower_target === null &&
          zone_kpi.temperature_upper_target !== null
        ) {
          legend = (
            <>
              <p>THRESHOLDS (°C):</p>
              <div className="legend-wrapper">
                <p
                  style={{ backgroundColor: this.getColor("green") }}
                >{`< ${zone_kpi.temperature_upper_target} `}</p>
                <p
                  style={{ backgroundColor: this.getColor("red") }}
                >{`> ${zone_kpi.temperature_upper_target} `}</p>
              </div>
            </>
          );
        } else if (
          zone_kpi.temperature_lower_target !== null &&
          zone_kpi.temperature_upper_target === null
        ) {
          legend = (
            <>
              <p>THRESHOLDS (°C):</p>
              <div className="legend-wrapper">
                <p
                  style={{ backgroundColor: this.getColor("red") }}
                >{`< ${zone_kpi.temperature_lower_target} `}</p>
                <p
                  style={{ backgroundColor: this.getColor("green") }}
                >{`> ${zone_kpi.temperature_lower_target} `}</p>
              </div>
            </>
          );
        }
        return legend;
      };

      return (
        <div className="card-body">
          <div className="body-main">{mainDisplay}</div>
          <div className="body-bottom">{getLegend()}</div>
        </div>
      );
    };

    const getOccupancyTab = () => {
      const getMainDisplay = () => {
        if (
          zone_kpi.occupancy_count !== null &&
          zone_kpi.occupancy_percent !== null &&
          zone_kpi.occupancy_target_limit !== null
        ) {
          return (
            <>
              <div>{`${zone_kpi.occupancy_percent}%`}</div>
              <div className="occupancy-percentage">
                {zone_kpi.occupancy_count} / {zone_kpi.occupancy_target_limit}{" "}
                <span
                  style={{
                    fontSize: "16px",
                    marginLeft: "0",
                    marginBottom: "16px",
                  }}
                >
                  pax
                </span>
              </div>
            </>
          );
        } else if (
          zone_kpi.occupancy_count >= 0 &&
          zone_kpi.occupancy_target_limit === null &&
          (zone_kpi.occupancy_percent === 0 ||
            zone_kpi.occupancy_percent === null)
        ) {
          return (
            <div>
              {zone_kpi.occupancy_count}
              <span
                style={{
                  fontSize: "32px",
                  marginBottom: "16px",
                  marginLeft: "5px",
                }}
              >
                pax
              </span>
            </div>
          );
        } else if (this.state.occupancy_available === false) {
          return <div>Offline</div>;
        }
      };

      const legend = zone_kpi.occupancy_target_limit ? (
        <>
          <p>THRESHOLDS (PAX):</p>
          <div className="legend-wrapper">
            <p
              style={{
                backgroundColor: this.getColor("green"),
                padding: "5px 7px",
                borderRadius: "5px",
              }}
            >{`< ${Math.floor(zone_kpi.occupancy_target_limit * 0.9)} `}</p>
            <p
              style={{
                backgroundColor: this.getColor("orange"),
                padding: "5px 7px",
                borderRadius: "5px",
              }}
            >{`${Math.floor(zone_kpi.occupancy_target_limit * 0.9)} - ${
              zone_kpi.occupancy_target_limit
            } `}</p>
            <p
              style={{
                backgroundColor: this.getColor("red"),
                padding: "5px 7px",
                borderRadius: "5px",
              }}
            >{`> ${zone_kpi.occupancy_target_limit} `}</p>
          </div>
        </>
      ) : (
        <div className="tx-center wd-80p">
          <h3>No thresholds set for this metric.</h3>
        </div>
      );

      return (
        <div className="card-body">
          <div className="body-main">
            <div
              className="occupancy-tab"
              style={{ backgroundColor: this.getColor("occupancy") }}
            >
              {getMainDisplay()}
            </div>
          </div>
          <div className="body-bottom">{legend}</div>
        </div>
      );
    };

    if (active_tab === "air_quality") return getAirQualityTab();
    if (active_tab === "temperature") return getTemperatureTab();
    if (active_tab === "occupancy") return getOccupancyTab();
  }

  getTabbedView() {
    return (
      <>
        {this.getTabbedViewHeader()}
        {this.getTabbedViewContent()}
        {this.getTabbedViewFooter()}
      </>
    );
  }

  render() {
    return (
      <div
        id="ZoneConditionsDashboard"
        className="row no-gutters ht-100v wd-100v floorplan-background"
      >
        {this.state.loading ? <Spinner /> : this.getTabbedView()}
      </div>
    );
  }
}

export default ZoneConditionsDashboard;
