import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';
import GeneralUtils from '../utils/GeneralUtils';

/**
 * Actions related to fetching and managing properties. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const propertyActions = new PropertyActions();
 * propertyActions.getAllProperties();
 */
class PropertyActions {
  /**
   * Fetches all properties from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAllProperties() {
    return GeneralUtils.get({
      url: "property/",
      actionType: ActionTypes.ALL_PROPERTIES_FETCHED,
      failToast: "Unable to get all properties, please try again",
    });
  }

  /**
   * Fetches available properties for a specific entity type and data source from the API and dispatches an action to store this data.
   *
   * @param {string} entityTypeId - The unique identifier of the entity type.
   * @param {string} dataSourceId - The unique identifier of the data source.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAvailableProperties(entityTypeId, dataSourceId) {
    return GeneralUtils.get({
      url: `property/entity-type/${entityTypeId}/data-source/${dataSourceId}`,
      actionType: ActionTypes.AVAILABLE_PROPERTIES_FETCHED,
      failToast: "Unable to get available properties, please try again",
    });
  }

  /**
   * Fetches available properties for a specific entity type across multiple data sources from the API
   * and dispatches an action to store this data.
   *
   * @param {string} entityTypeId - The unique identifier of the entity type.
   * @param {string[]} dataSourceIdArray - An array of unique identifiers for the data sources.
   */
  getAvailablePropertiesForEntity(entityTypeId, dataSourceIdArray) {
    let propertiesWithCompatibleDataSources = [];
    let indexedProperties = [];

    dataSourceIdArray.forEach((dataSourceId, index) => {
      GeneralUtils.get({
        url: `property/entity-type/${entityTypeId}/data-source/${dataSourceId}`,
        failToast: "Unable to get available properties, please try again",
      }).then((response) => {
        if (response) {
          response.forEach((prop) => {
            if (indexedProperties.includes(prop.property_id)) {
              propertiesWithCompatibleDataSources.forEach((el) => {
                if (el.property_id === prop.property_id) {
                  el.found_in_data_sources.push(dataSourceId);
                }
              });
            } else {
              indexedProperties.push(prop.property_id);
              propertiesWithCompatibleDataSources.push({
                ...prop,
                found_in_data_sources: [dataSourceId],
              });
            }
          });

          if (index === dataSourceIdArray.length - 1) {
            Dispatcher.dispatch({
              actionType: ActionTypes.AVAILABLE_PROPERTIES_FOR_ENTITY_FETCHED,
              payload: propertiesWithCompatibleDataSources,
            });
          }
        }
      });
    });
  }
}

export default new PropertyActions();
