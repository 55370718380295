import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching and managing appliance data. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const applianceActions = new ApplianceActions();
 * applianceActions.getAppliancePoints('dataSource123');
 */
class ApplianceActions {
  /**
   * Fetches the unique points for a specific data source from the API and dispatches an action to store this data.
   *
   * @param {string} dataSourceId - The unique identifier of the data source.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAppliancePoints(dataSourceId) {
    return GeneralUtils.get({
      url: `appliance/unique/data-source/${dataSourceId}`,
      actionType: ActionTypes.APPLIANCE_POINTS_FETCHED,
      failToast: "Unable to get appliance points, please try again",
    });
  }
}

export default new ApplianceActions();
