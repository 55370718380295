import React from "react";
import GeneralUtils from "../../../../utils/GeneralUtils";
import UrlManager from "../../../../utils/UrlManager";
import moment from "moment";
import _ from "lodash";

import "./ZoneConditionsConfig.scss";
import DashboardsActions from "../../../../actions/dashboardsActions";
import { PageTitle } from "../../../../components/PageTitle";
import { ZoneSelect } from "../../../../components/ZoneSelect";

class ZoneConditionsConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_zone: null,
      token: null,
      tokenStale: true,
    };

    this.copyUrl = this.copyUrl.bind(this);
    this.fetchToken = this.fetchToken.bind(this);
    this.viewDashboard = this.viewDashboard.bind(this);
    this.selectZone = this.selectZone.bind(this);
  }

  componentDidUpdate(_, prevState) {
    const prev_id = prevState.selected_zone && prevState.selected_zone.id;
    const current_id = this.state.selected_zone && this.state.selected_zone.id;

    const inputsChanged = prev_id !== current_id;

    if (inputsChanged) {
      this.setState({ tokenStale: true });
    }
  }

  async fetchToken() {
    const zone_id = _.get(this.state, "selected_zone.id");

    if (zone_id) {
      const object = {
        type: "ZONE_CONDITIONS_DASHBOARD",
        zone_id: zone_id,
      };

      try {
        const token = await DashboardsActions.generateToken(object);
        this.setState({ token: token, tokenStale: false });
        return token;
      } catch (error) {
        console.error("Error generating token");
        throw error;
      }
    }
  }

  async copyUrl() {
    const token =
      this.state.tokenStale || !this.state.token
        ? await this.fetchToken()
        : this.state.token;
    const url = UrlManager.getUIUrl() + `dashboards/zone-conditions/${token}`;
    GeneralUtils.copyToClipboard(url);
  }

  async viewDashboard() {
    const token =
      this.state.tokenStale || !this.state.token
        ? await this.fetchToken()
        : this.state.token;
    const url = `dashboards/zone-conditions/${token}`;
    window.open(url, "_blank");
  }

  selectZone(zone) {
    this.setState({ selected_zone: zone });
  }

  render() {
    const generationDisabled = !this.state.selected_zone;
    const yesterdayTimestamp = moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    const tomorrowTimestamp = moment().add(1, "days").format("YYYY-MM-DD");

    return (
      <div id="ZoneConditionsConfig">
        <div className="row mg-b-20">
          <div className="col-12">
            <PageTitle title={"Zone Conditions - Dashboard Configuration"} />
          </div>
        </div>
        <hr></hr>
        <div className="row mg-b-20">
          <div className="col-12 col-lg-6">
            <div className="left-panel">
              <div>
                <div className="label">
                  Target Zone <span className="field-status">(required)</span>
                </div>
                <ZoneSelect
                  selectedZone={this.state.selected_zone}
                  onZoneSelect={this.selectZone}
                  filter={(zone) =>
                    _.get(zone, "wellness.temperature") ||
                    _.get(zone, "wellness.co2") ||
                    _.get(zone, "wellness.humidity")
                  }
                  ts_start={yesterdayTimestamp}
                  ts_end={tomorrowTimestamp}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="right-panel">
              <div className="label">Preview</div>
              <div className="placeholder">
                <div className="image">
                  <img
                    alt="dashboard"
                    src="/img/dashboardsThumbnails/zone-conditions.png"
                  />
                  <div className="select-left"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="button-bottom-panel">
              <div className="buttons">
                <button
                  className={`btn btn-info copy-button ${
                    generationDisabled ? "disabled" : ""
                  }`}
                  disabled={generationDisabled}
                  onClick={this.copyUrl}
                >
                  Copy Link
                </button>
                <button
                  className={`btn btn-info view-button ${
                    generationDisabled ? "disabled" : ""
                  }`}
                  disabled={generationDisabled}
                  onClick={this.viewDashboard}
                >
                  View Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ZoneConditionsConfig;
