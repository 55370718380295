import React, { Component } from "react";
import "./EnergyReviewReport.scss";

import _ from "lodash";
import moment from "moment";
import GeneralUtils from "../../../utils/GeneralUtils";

import { DocumentTitle } from "../../../components/DocumentTitle";
import { LogoSpinner } from "../../../components/LogoSpinner";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { TimeframePicker } from "../../../components/TimeframePicker";
import { Icon } from "../../../components/Icon";
import { Button, Tooltip } from 'antd';

import UserStore from "../../../stores/userStore";
import EnergyReviewStore from "../../../stores/energyReviewStore";
import EnergyReviewActions from "../../../actions/energyReviewActions";



const DATE_FORMAT = "YYYY-MM-DD";

class EnergyReviewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: null,
      meters: [],
      list: null,
      loading: true,

      ts_start: moment()
        .subtract(1, "weeks")
        .startOf("isoWeek")
        .format(DATE_FORMAT),

      ts_end: moment()
        .subtract(1, "weeks")
        .endOf("isoWeek")
        .format(DATE_FORMAT),

      sortBy: "meter.description",
      sortDirection: "asc",

      // Analysis mode related state elements
      isAnalysisMode: window.location.pathname.includes("analysis/energy-analysis"),
      lists: [],
      selected_list: null,
      menuOpen: true,
    };
    this.onReportFetch = this.onReportFetch.bind(this);
    this.changeSortBy = this.changeSortBy.bind(this);
    this.selectList = this.selectList.bind(this);
    this.handleTimeframeChange = this.handleTimeframeChange.bind(this);
  }

  componentWillMount() {
    EnergyReviewStore.addReportFetchedListener(this.onReportFetch);
  }

  componentWillUnmount() {
    EnergyReviewStore.removeReportFetchedListener(this.onReportFetch);
  }

  componentDidMount() {
    if (this.props.token) {
      this.fetchReport();
    } else if (this.state.isAnalysisMode) {
      this.fetchLists();
    }
  }

  fetchReport() {
    // Add 1 day to end date as the end date is not inclusive
    const start = this.state.ts_start;
    const end = moment(this.state.ts_end).add(1, "days").format(DATE_FORMAT);
    const token = this.props.token || this.state.selected_list;

    EnergyReviewActions.getReport(token, start, end);
    this.setState({ loading: true });
  }

  fetchLists() {
    const org_id = UserStore.getUser().fk_organisation_id;
    EnergyReviewActions.getLists(org_id)
      .then((lists) => {
        const modified_lists = lists.map((list) => ({ ...list, label: list.name, value: list.ref }))
        this.setState({ lists: modified_lists });
        if (modified_lists.length) {
          this.setState({
            selected_list: modified_lists[0].value,
          }, this.fetchReport);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  onReportFetch() {
    const report = EnergyReviewStore.getReport();
    this.setState({
      report: report,
      meters: report.energy_review_meters,
      list: report.energy_review_list,
      loading: false,
    });
  }

  // #######################
  // ### Table Functions ###
  // #######################

  getTable() {
    return (
      <div className="card bd-0 shadow-base wd-100p table-container">
        <table className="table mg-b-0 table-contact">
          {this.getTableHeader()}
          <tbody>{this.getMeterRows()}</tbody>
        </table>
      </div>
    );
  }

  getTableHeader() {
    const meter = "meter.description";
    const timeframe_baseline = "baseline_consumption.interval_consumption";
    const timeframe_actual = "current_consumption.interval_consumption";
    const timeframe_performance = "interval_consumption_change.relative";
    const ytd_baseline = "baseline_consumption.ytd_consumption";
    const ytd_actual = "current_consumption.ytd_consumption";
    const ytd_performance = "ytd_consumption_change.relative";
    const cost = "baseline_cost.ytd_cost";

    const getSortIcon = (column) => {
      if (this.state.sortBy === column && this.state.sortDirection === "asc")
        return (
          <ion-icon
            name="arrow-dropup"
            size="small"
            style={{ verticalAlign: "top" }}
          />
        );
      if (this.state.sortBy === column && this.state.sortDirection === "desc")
        return (
          <ion-icon
            name="arrow-dropdown"
            size="small"
            style={{ verticalAlign: "top" }}
          />
        );
      return null;
    };

    const sortClickHandler = (sortBy) => {
      this.changeSortBy(sortBy);
    };

    return (
      <thead>
        <tr>
          <th
            className="wd-15p tx-mont tx-medium cursor-pointer sticky"
            onClick={() => sortClickHandler(meter)}
          >
            Meter {getSortIcon(meter)}
          </th>
          <th
            className="wd-10p tx-10-force tx-mont tx-medium tx-center cursor-pointer"
            onClick={() => sortClickHandler(timeframe_baseline)}
          >
            Baseline {getSortIcon(timeframe_baseline)}
          </th>
          <th
            className="wd-10p tx-10-force tx-mont tx-medium tx-center cursor-pointer"
            onClick={() => sortClickHandler(timeframe_actual)}
          >
            Actual {getSortIcon(timeframe_actual)}
          </th>
          <th
            className="wd-10p tx-10-force tx-mont tx-medium tx-center cursor-pointer"
            onClick={() => sortClickHandler(timeframe_performance)}
          >
            Performance {getSortIcon(timeframe_performance)}
          </th>
          <th
            className="wd-10p tx-10-force tx-mont tx-medium tx-center cursor-pointer"
            onClick={() => sortClickHandler(ytd_baseline)}
          >
            YTD Baseline {getSortIcon(ytd_baseline)}
          </th>
          <th
            className="wd-10p tx-10-force tx-mont tx-medium tx-center cursor-pointer"
            onClick={() => sortClickHandler(ytd_actual)}
          >
            YTD Actual {getSortIcon(ytd_actual)}
          </th>
          <th
            className="wd-10p tx-10-force tx-mont tx-medium tx-center cursor-pointer"
            onClick={() => sortClickHandler(ytd_performance)}
          >
            YTD Performance {getSortIcon(ytd_performance)}
          </th>
          <th
            className="wd-10p tx-10-force tx-mont tx-medium tx-center cursor-pointer"
            onClick={() => sortClickHandler(cost)}
          >
            YTD Cost {getSortIcon(cost)}
          </th>
        </tr>
      </thead>
    );
  }

  getMeterRows() {
    const sort_by_value = this.state.sortBy;
    const sort_direction = this.state.sortDirection;
    const meters = this.state.meters;

    if (meters.length === 0) {
      return (
        <tr>
          <td colSpan={9}>
            <div className="d-flex align-items-center">
              <div>
                <div className="tx-inverse">No baseline meters available.</div>
              </div>
            </div>
          </td>
        </tr>
      );
    }

    const list = _.orderBy(meters, sort_by_value, sort_direction).map(
      (meter, index) => {
        const name = _.get(meter, "meter.description");
        const type = _.get(meter, "meter.type");
        const building_name = _.get(meter, "building.name");
        const currency = _.get(meter, "building.currency");
        const interval_consumption = _.get(
          meter,
          "current_consumption.interval_consumption"
        );
        const ytd_consumption = _.get(
          meter,
          "current_consumption.ytd_consumption"
        );
        const baseline_interval_consumption = _.get(
          meter,
          "baseline_consumption.interval_consumption"
        );
        const baseline_ytd_consumption = _.get(
          meter,
          "baseline_consumption.ytd_consumption"
        );
        const interval_absolute_change = _.get(
          meter,
          "interval_consumption_change.absolute"
        );
        const interval_relative_change = _.get(
          meter,
          "interval_consumption_change.relative"
        );
        const ytd_absolute_change = _.get(
          meter,
          "ytd_consumption_change.absolute"
        );
        const ytd_relative_change = _.get(
          meter,
          "ytd_consumption_change.relative"
        );
        const ytd_cost = _.get(meter, "baseline_cost.ytd_cost");
        const ytd_monetary_change = _.get(meter, "ytd_cost_change.absolute");

        return (
          <tr className="hover-row" key={name + index}>
            {this.getNameCell(name, building_name, type)}
            {this.getKwhCell(baseline_interval_consumption)}
            {this.getKwhCell(interval_consumption)}
            {this.getChangeCell(
              interval_relative_change,
              interval_absolute_change
            )}
            {this.getKwhCell(baseline_ytd_consumption)}
            {this.getKwhCell(ytd_consumption)}
            {this.getChangeCell(ytd_relative_change, ytd_absolute_change)}
            {this.getCostCell(ytd_cost, ytd_monetary_change, currency)}
          </tr>
        );
      }
    );

    return list;
  }

  getNameCell(name, building_name, type = "") {
    let icon;
    if (type.includes("em"))
      icon = <ion-icon name="flash" style={{ color: "#e67e22" }} />;
    if (type.includes("hm"))
      icon = <ion-icon name="thermometer" style={{ color: "#e74c3c" }} />;
    if (type.includes("gm"))
      icon = <ion-icon name="flame" style={{ color: "#3498db" }} />;

    return (
      <td className="valign-middle name-cell">
        <div className="d-flex align-items-center cell-container">
          <div className="icon">{icon}</div>
          <div className="labels">
            <div className="tx-inverse column-emphasis name">{name}</div>
            <div className=" building">{building_name}</div>
          </div>
        </div>
      </td>
    );
  }

  getKwhCell(value) {
    let formatted_value = "-";
    if (value !== undefined && value !== null) {
      formatted_value = GeneralUtils.getFormattedNumberWithUnit(
        value,
        " kWh",
        0
      );
    }
    return (
      <td className="valign-middle tx-center kwh-cell">{formatted_value}</td>
    );
  }

  getChangeCell(value, secondary_value) {
    let formatted_value = "-";
    let formatted_secondary_value = "-";
    let color;

    if (value !== undefined && value !== null && value > 0) {
      // red - negative
      formatted_value =
        "+" + GeneralUtils.getFormattedNumberWithUnit(value, "%", 1);
      formatted_secondary_value =
        "+" +
        GeneralUtils.getFormattedNumberWithUnit(secondary_value, " kWh", 0);
      // red
      color = "#fc777750";
    } else if (value !== undefined && value !== null && value < 0) {
      // green - positive
      formatted_value = GeneralUtils.getFormattedNumberWithUnit(value, "%", 1);
      formatted_secondary_value = GeneralUtils.getFormattedNumberWithUnit(
        secondary_value,
        " kWh",
        0
      );

      color = "#a9d18e50";
    }

    return (
      <td className="valign-middle change-cell" style={{ background: color }}>
        <div className="align-items-center cell-container">
          <div className="labels">
            <div className="tx-inverse column-emphasis value">
              {formatted_value}
            </div>
            {secondary_value && (
              <div className="secondary-value">{formatted_secondary_value}</div>
            )}
          </div>
        </div>
      </td>
    );
  }

  getCostCell(value, secondary_value, currency) {
    let formatted_value = "-";
    let formatted_secondary_value = "-";
    let unit = "€";
    if (currency === "GBP") {
      unit = "£";
    }

    if (value !== undefined && value !== null) {
      formatted_value =
        unit + GeneralUtils.getFormattedNumberWithUnit(value, "", 0);
    }

    if (
      secondary_value !== undefined &&
      secondary_value !== null &&
      secondary_value > 0
    ) {
      formatted_secondary_value =
        unit +
        "+" +
        GeneralUtils.getFormattedNumberWithUnit(
          Math.abs(secondary_value),
          "",
          0
        );
    } else if (
      secondary_value !== undefined &&
      secondary_value !== null &&
      secondary_value < 0
    ) {
      formatted_secondary_value =
        unit +
        "-" +
        GeneralUtils.getFormattedNumberWithUnit(
          Math.abs(secondary_value),
          "",
          0
        );
    }

    return (
      <td className="valign-middle change-cell">
        <div className="align-items-center cell-container">
          <div className="labels">
            <div className="tx-inverse column-emphasis value">
              {formatted_value}
            </div>
            {secondary_value && (
              <div className="secondary-value">{formatted_secondary_value}</div>
            )}
          </div>
        </div>
      </td>
    );
  }

  changeSortBy(sortBy) {
    if (this.state.sortBy !== sortBy) {
      this.setState({ sortBy: sortBy, sortDirection: "asc" });
    } else {
      if (this.state.sortDirection === "asc") {
        this.setState({ sortDirection: "desc" });
      } else {
        this.setState({ sortDirection: "asc" });
      }
    }
  }

  // #####################
  // ### Summary Cards ###
  // #####################

  getSummaryCards() {
    return (
      <div className="summary-cards">
        {this.getTimeframePerformanceCard()}
        {this.getYTDPerformanceCard()}
        {this.getYTDCostCard()}
      </div>
    );
  }

  sumUpProperties(array, property) {
    const keys = property.split(".");
    const reducer = (accumulator, currentValue) => {
      if (currentValue !== undefined && currentValue !== null) {
        return accumulator + currentValue;
      }
      return accumulator;
    };

    return array.reduce((accumulator, currentValue) => {
      let nestedValue = currentValue;

      for (let i = 0; i < keys.length; i++) {
        try {
          nestedValue = nestedValue[keys[i]];
        } catch { }

        if (nestedValue === undefined) break;
      }

      return reducer(accumulator, nestedValue);
    }, 0);
  }

  getYTDPerformanceCard() {
    let relative = "-";
    let absolute = "-";
    let bgcolor = "";
    let unit = "";

    const meters = this.state.meters;

    if (meters.length) {
      absolute = this.sumUpProperties(
        meters,
        "ytd_consumption_change.absolute"
      );

      const totalBaseline = this.sumUpProperties(
        meters,
        "baseline_consumption.ytd_consumption"
      );

      relative = (absolute / totalBaseline) * 100;
      relative = GeneralUtils.getFormattedNumberWithUnit(relative, "%", 1);
      if (absolute < 0) {
        bgcolor = "#e5f1dd";
      } else if (absolute > 0) {
        bgcolor = "#fed7d6";
        unit = "+";
      }
      absolute = GeneralUtils.getFormattedNumberWithUnit(absolute, " kWh", 0);
    }

    return (
      <div
        className="card with-icon shadow-base"
        style={{ backgroundColor: bgcolor }}
      >
        <div className="title tx-mont">YTD Performance</div>
        <div className="summary-card-body">
          <div className="data">
            <div className="primary">
              {unit}
              {relative}
            </div>
            <div className="secondary">
              {unit}
              {absolute}
            </div>
          </div>
        </div>
      </div>
    );
  }
  getTimeframePerformanceCard() {
    let relative = "-";
    let absolute = "-";
    let bgcolor = "";
    let unit = "";

    const meters = this.state.meters;

    if (meters.length) {
      absolute = this.sumUpProperties(
        meters,
        "interval_consumption_change.absolute"
      );

      const totalBaseline = this.sumUpProperties(
        meters,
        "baseline_consumption.interval_consumption"
      );

      relative = (absolute / totalBaseline) * 100;
      relative = GeneralUtils.getFormattedNumberWithUnit(relative, "%", 1);
      if (absolute < 0) {
        bgcolor = "#e5f1dd";
      } else if (absolute > 0) {
        bgcolor = "#fed7d6";
        unit = "+";
      }
      absolute = GeneralUtils.getFormattedNumberWithUnit(absolute, " kWh", 0);
    }

    return (
      <div
        className="card with-icon shadow-base"
        style={{ backgroundColor: bgcolor }}
      >
        <div className="title tx-mont">Performance</div>
        <div className="summary-card-body">
          <div className="data">
            <div className="primary">
              {unit}
              {relative}
            </div>
            <div className="secondary">
              {unit}
              {absolute}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getYTDCostCard() {
    let total_cost = "-";
    let total_change = "";
    let unit = "";
    let bgcolor = "";

    const meters = this.state.meters;

    if (meters.length) {
      const currency = _.get(this.state, "meters[0].building.currency");
      if (currency === "EUR") {
        unit = "€";
      } else if (currency === "GBP") {
        unit = "£";
      }

      total_cost = this.sumUpProperties(meters, "baseline_cost.ytd_cost");
      total_cost = GeneralUtils.getFormattedNumberWithUnit(total_cost, "", 0);
      total_cost = unit + total_cost;

      total_change = this.sumUpProperties(meters, "ytd_cost_change.absolute");
      if (total_change < 0) {
        unit = unit + "-";
        bgcolor = "#e5f1dd";
      } else if (total_change > 0) {
        unit = unit + "+";
        bgcolor = "#fed7d6";
      }
      total_change = Math.abs(total_change);
      total_change = GeneralUtils.getFormattedNumberWithUnit(
        total_change,
        "",
        0
      );
      total_change = unit + total_change;
    }

    return (
      <div
        className="card with-icon shadow-base"
        style={{ backgroundColor: bgcolor }}
      >
        <div className="title tx-mont">YTD Cost</div>
        <div className="summary-card-body">
          <div className="data">
            <div className="primary">{total_cost}</div>
            <div className="secondary">{total_change}</div>
          </div>
        </div>
      </div>
    );
  }

  getTimeframePicker() {
    return (
      <div>
        <TimeframePicker
          start={this.state.ts_start}
          end={this.state.ts_end}
          defaultGranularity={'week'}
          onChange={this.handleTimeframeChange}
          granularities={['custom', 'day', 'week', 'month']}
          spacing="10px"
        />
      </div>

    )
  }

  handleTimeframeChange(start, end) {
    this.setState(
      {
        ts_start: start,
        ts_end: end
      },
      this.fetchReport
    );

  }

  selectList(list) {
    this.setState({ selected_list: list }, this.fetchReport);
  }

  getContextualMenu() {
    if (!this.state.isAnalysisMode) return null;
    // const isAdminOrSuper = UserStore.isAdmin() || UserStore.isSuper();

    return <div className={`contextualMenu ${this.state.menuOpen ? '' : 'closed'}`}>
      <div className='label'>Energy Analysis<Tooltip placement='right' style={{ textAlign: 'center' }} title="Collections of meters can be configured by your site's administrator in Estate Energy Review section in Report Center"><span style={{ marginLeft: '10px', verticalAlign: 'top' }}><Icon name='Info' color='#ffffff' size={20} /></span></Tooltip></div>
      {/* {isAdminOrSuper && <div className='buttons'>
        <Button
          className='newListButton'
          icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
          size="small"
          onClick={() => this.props.history.push('/energy-review-config')}
        >Create New List</Button>
      </div>} */}
      <div className='lists'>
        {this.state.lists.map((list) => (
          <div key={list.ref} className={`listItem ${this.state.selected_list === list.ref ? 'highlighted' : ''}`} onClick={() => this.selectList(list.ref)}>
            <div className='listName'>{list.name}</div>
          </div>
        ))}
      </div>
      <Button
        className={`menuButton ${this.state.menuOpen ? 'open' : ''}`}
        onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
        icon={<Icon name={this.state.menuOpen ? 'ArrowLeft' : 'ArrowRight'} size={30} />}
        size="small"
        style={{ border: 'none', outline: 'none' }}
      />
    </div >
  }

  getReportArea() {
    const loading = this.state.loading;
    const lists = this.state.lists;

    if (lists.length === 0 && !loading && this.state.isAnalysisMode) {
      return <div className={`mainArea ${this.state.menuOpen ? '' : 'menuClosed'}`}>
        <div className="row mg-b-10">
          <div className="col">
            <div className="card" style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100px',
              fontSize: '20px'
            }}>
              No Lists Available.
            </div>
          </div>
        </div>
      </div>
    }


    return <div className={`mainArea ${this.state.menuOpen ? '' : 'menuClosed'}`}>
      <div className="row mg-b-10">
        <div className="col">
          <PageTitle
            title={
              this.state.report && this.state.report.energy_review_list.name
            }
          />
        </div>
      </div>
      <div className="top-row row mg-b-20">
        <div className="cards col ">{this.getSummaryCards()}</div>
        <div className="selector col ">{this.getTimeframePicker()}</div>
      </div>
      <div className="row">
        <div className="col">
          {this.getTable(this.state.report)}
        </div>
      </div>
    </div>

  }

  render() {
    let classes = 'floorplan-background'
    if (this.state.isAnalysisMode) {
      classes = 'br-mainpanel br-profile-page floorplan-background analysis'
    }
    return (
      <div id="EnergyReviewReport" className={classes}>
        <DocumentTitle title="Energy Review Report" />
        <LogoSpinner loading={this.state.loading} />
        {this.getContextualMenu()}
        {this.getReportArea()}
      </div>
    );
  }
}

export default EnergyReviewReport;
