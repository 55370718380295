import API from "../api";
import Dispatcher from '../dispatcher';
import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to administrative tasks and appliance management. This class interfaces with the API
 * to perform various actions such as submitting change requests, managing appliances, and fetching data.
 *
 * @class
 * @example
 * const adminActions = new AdminActions();
 * adminActions.getAppliances('building123');
 */
class AdminActions {
  /**
   * Submits a change request to the API and dispatches an action to store this request in the application's state.
   *
   * @param {Object} changeRequest - The change request object to be submitted.
   * @returns {Promise} - A promise that resolves with the response of the submission.
   */
  submitChangeRequest(changeRequest) {
    return GeneralUtils.post({
      url: "admin/change-request",
      object: changeRequest,
      actionType: ActionTypes.CHANGE_REQUEST_SENT,
      failToast: "Unable to send request, please try again or contact support",
    });
  }

  /**
   * Fetches a list of appliances for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAppliances(buildingId) {
    return GeneralUtils.get({
      url: `appliance/building/${buildingId}`,
      actionType: ActionTypes.APPLIANCES_FETCHED,
      failToast: "API Request Failed, Please Try Again",
    });
  }

  /**
   * Saves an appliance to the API and dispatches an action to store this data. Optionally saves configuration and blacklist files.
   *
   * @param {Object} appliance - The appliance object to be saved.
   * @param {File} [configFile] - Optional configuration file to be saved with the appliance.
   * @param {File} [blacklistFile] - Optional blacklist file to be saved with the appliance.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  async saveAppliance(appliance) {
    const response = await GeneralUtils.post({
      url: "appliance",
      object: appliance,
      actionType: ActionTypes.APPLIANCE_SAVED,
      successToast: "Appliance saved successfully",
      failToast: "Unable to save appliance, please try again with a different identifier",
    });

    return response;
  }

  /**
   * Updates an existing appliance in the API and dispatches an action to store the updated data.
   *
   * @param {Object} appliance - The appliance object to be updated.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateAppliance(appliance) {
    return GeneralUtils.put({
      url: "appliance",
      object: appliance,
      actionType: ActionTypes.APPLIANCE_UPDATED,
      successToast: "Appliance updated successfully",
      failToast: "Unable to update appliance, please try again",
    });
  }

  /**
   * Deletes an appliance from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} applianceId - The unique identifier of the appliance to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteAppliance(applianceId) {
    return GeneralUtils.delete({
      url: `appliance/${applianceId}`,
      actionType: ActionTypes.APPLIANCE_DELETED,
      successToast: "Appliance deleted successfully",
      failToast: "Unable to delete appliance, please try again",
    });
  }

  /**
   * Fetches the point controllers for a specific appliance from the API and dispatches an action to store this data.
   *
   * @param {string} applianceId - The unique identifier of the appliance.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getApplianceControllers(applianceId) {
    return GeneralUtils.get({
      url: `admin/appliance/${applianceId}/point-controllers`,
      actionType: ActionTypes.APPLIANCE_CONTROLLERS_FETCHED,
      failToast: "API Request Failed, Please Try Again",
    });
  }

  /**
   * Fetches the unique points for a specific appliance and controller from the API and dispatches an action to store this data.
   *
   * @param {string} applianceId - The unique identifier of the appliance.
   * @param {string} controllerName - The name of the controller.
   * @param {string} tsStart - The start timestamp for fetching the unique points.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getUniquePoints(applianceId, controllerName, tsStart) {
    return GeneralUtils.get({
      url: `admin/appliance/${applianceId}/unique-points/${tsStart}?controllerName=${encodeURIComponent(controllerName)}`,
      actionType: ActionTypes.UNIQUE_POINTS_FETCHED,
      failToast: "API Request Failed, Please Try Again",
    });
  }

  /**
   * Fetches unique points for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getUniquePointsByBuildingId(buildingId) {
    return GeneralUtils.get({
      url: `admin/building/${buildingId}/unique-points`,
      actionType: ActionTypes.UNIQUE_POINTS_FETCHED,
      failToast: "API Request Failed, Please Try Again",
    });
  }

  /**
   * Fetches the point values for a specific unique point from the API and dispatches an action to store this data.
   *
   * @param {Object} point - The unique point object.
   * @param {string} tsStart - The start timestamp for fetching the point values.
   * @param {string} tsEnd - The end timestamp for fetching the point values.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getPointValues(point, tsStart, tsEnd) {
    return GeneralUtils.get({
      url: `admin/appliance/unique/${point.unique_appliance_point_id}/point-values/${tsStart}/${tsEnd}`,
      actionType: ActionTypes.POINT_VALUES_FETCHED,
      failToast: "API Request Failed, Please Try Again",
      modifyResponse: (response) => {
        return {
          point: point,
          values: response,
        };
      }
    });
  }

  /**
   * Fetches the datasources for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildingDatasources(buildingId) {
    return GeneralUtils.get({
      url: `admin/building/${buildingId}/datasource`,
      actionType: ActionTypes.DATASOURCES_FETCHED,
      failToast: "API Request Failed, Please Try Again",
    });
  }

  /**
   * Fetches the mappings for a specific datasource from the API and dispatches an action to store this data.
   *
   * @param {string} dataSourceId - The unique identifier of the datasource.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getDataSourceMappings(dataSourceId) {
    return GeneralUtils.get({
      url: `admin/datasource/${dataSourceId}`,
      actionType: ActionTypes.DATASOURCE_MAPPINGS_FETCHED,
      failToast: "API Request Failed, Please Try Again",
    });
  }

  getDataSourceMappingsByBuildingId(buildingId) {
    return GeneralUtils.get({
      url: `admin/building/${buildingId}/mappings`,
      actionType: ActionTypes.DATASOURCE_MAPPINGS_FETCHED,
      failToast: "API Request Failed, Please Try Again",
    });
  }

  /**
   * Fetches the mapped point values for a specific mapping from the API and dispatches an action to store this data.
   *
   * @param {string} mappingId - The unique identifier of the mapping.
   * @param {string} tsStart - The start timestamp for fetching the mapped point values.
   * @param {string} tsEnd - The end timestamp for fetching the mapped point values.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getMappedPointValues(mappingId, tsStart, tsEnd) {
    return GeneralUtils.get({
      url: `admin/mapping/${mappingId}/values/${tsStart}/${tsEnd}`,
      actionType: ActionTypes.MAPPED_POINT_VALUES_FETCHED,
      failToast: "API Request Failed, Please Try Again",
    });
  }

  /**
   * Fetches the health checks from the API and dispatches an action to store this data.
   *
   * @param {boolean} sensorsOnly - Whether to fetch health checks for sensors only.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getHealthChecks(sensorsOnly) {
    let queryParam = sensorsOnly ? "?sensorsOnly=true" : "?sensorsOnly=false";
    return GeneralUtils.get({
      url: `admin/healthchecks${queryParam}`,
      actionType: ActionTypes.ADMIN_HEALTH_CHECKS_FETCHED,
      failToast: "API Request Failed, Please Try Again",
    });
  }

  /**
   * Uploads an energy CSV file for a specific meter to the API and dispatches an action to store this data.
   *
   * @param {string} meterId - The unique identifier of the meter.
   * @param {string} csvType - The type of the CSV file.
   * @param {File} csvFile - The CSV file to be uploaded.
   * @param {string} [timezone=""] - The timezone for the data. Default is an empty string.
   * @param {string} [timestampColumn="0"] - The column index for the timestamp. Default is "0".
   * @param {string} [valueColumn="1"] - The column index for the values. Default is "1".
   * @param {string} [startLine="1"] - The line number where data starts. Default is "1".
   * @returns {Promise} - A promise that resolves with the response of the upload operation.
   */
  uploadEnergyCSV(meterId, type, file, timezone = "", timestampColumn = "0", valueColumn = "1", startLine = "1") {
    if (!meterId || !type || !file) {
      throw new Error("meterId, csvType, and csvFile are required parameters.");
    }

    const formData = new FormData();
    let url = "";

    if (type === "COMMON_VERTICAL") {
      url = "import/meter";
      formData.append("meter_id", meterId);
      formData.append("timezone", timezone);
      formData.append("timestamp_column", timestampColumn);
      formData.append("value_column", valueColumn);
      formData.append("start_line", startLine);
    } else {
      url = `import/meter/${meterId}/${type}`;
    }

    formData.append("file", file);

    API.postFileUpload(url, formData).then(
      (response) => {
        if (typeof response.code !== "undefined") {
          if (response.code === 200) {
            Dispatcher.dispatch({
              actionType: ActionTypes.CSV_FILE_UPLOADED,
              payload: response,
            });
          } else {
            Dispatcher.dispatch({
              actionType: ActionTypes.CSV_FILE_UPLOAD_FAILED,
              payload: response,
            });
          }
        }
      }
    );;
  }
}

export default new AdminActions();
