import React, { useEffect, useState } from 'react';
import styles from './BuildingBenchmark.module.scss';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import BuildingBenchmarkActions from '../../../actions/buildingBenchmarkActions';
import UserStore from '../../../stores/userStore';

import { DocumentTitle } from '../../../components/DocumentTitle';
import { BenchmarkQuestionnaire } from './BenchmarkQuestionnaire';
import { BenchmarkReport } from './BenchmarkReport';
import { LogoSpinner } from '../../../components/LogoSpinner';
import { Icon } from '../../../components/Icon';
import { Row, Col, Button } from 'antd';

const BuildingBenchmark = () => {
    const { token } = useParams();
    const [isLoggedIn] = useState(UserStore.loggedIn());
    const [loading, setLoading] = useState(true);
    const [reports, saveReports] = useState([]);
    const [reportData, setReportData] = useState(null);
    const [menuOpen, setMenuOpen] = useState(true);

    useEffect(() => {
        if (isLoggedIn) fetchReports();
        if (token) fetchReportViaToken(token);

    }, [isLoggedIn, token]);

    const fetchReports = async () => {
        setLoading(true);
        try {
            const response = await BuildingBenchmarkActions.getReports();
            saveReports(response);
        } catch (error) {
            console.error('Failed to fetch configurations:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchReport = async (ref) => {
        setLoading(true);
        try {
            const response = await BuildingBenchmarkActions.getReport(ref);
            setReportData(response);
        } catch (error) {
            console.error('Failed to fetch configuration:', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchReportViaToken = async (token) => {
        setLoading(true);
        try {
            const response = await BuildingBenchmarkActions.getReportViaToken(token);
            setReportData(response);
        } catch (error) {
            console.error('Failed to fetch configuration:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (formData) => {
        setLoading(true);
        try {
            const response = await BuildingBenchmarkActions.getBuildingBenchmarkReport(formData);
            setReportData(response);
        } catch (error) {
            console.error('Error fetching benchmark report:', error);
        } finally {
            fetchReports();
            setLoading(false);
        }
    };

    const deleteReport = (ref) => {
        try {
            if (window.confirm('Are you sure you want to delete this report?')) {
                BuildingBenchmarkActions.deleteReport(ref)
                    .then(() => fetchReports())
                    .then(() => {
                        if (_.get(reportData, 'user_data.ref') === ref) {
                            cleanUp();
                        }
                    });
            }
        } catch (error) {
            console.error('Failed to delete configuration:', error);
        }
    }

    const cleanUp = () => {
        setReportData(null);
    }

    const getContextualMenu = () => {

        if (token) return null;


        const isSelected = (report) => {
            return _.get(reportData, 'user_data.ref') === report.ref;
        }

        return (
            <div className={`${styles.contextualMenu} ${menuOpen ? styles.open : styles.closed}`}>
                <div className={styles.label}>Benchmark Reports</div>
                <div className={styles.reportButtons}>
                    <Button
                        className="button light-blue"
                        icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
                        size="small"
                        onClick={cleanUp}
                        disabled={!reportData}
                    >New Report</Button>
                </div>
                <div className={styles.reportList}>
                    {reports.map((report) => (
                        <div key={report.ref} className={`${styles.reportItem} ${isSelected(report) ? styles.highlighted : ''}`} onClick={() => fetchReport(report.ref)}>
                            <div>
                                <div className={styles.reportName}>{report.name}</div>
                                <div className={styles.reportDescription}>{report.description}</div>
                            </div>

                            <div className={styles.reportDelete}>
                                <Button
                                    icon={<Icon name="Delete" color={'#fff'} size={20} />}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteReport(report.ref);
                                    }}
                                />
                            </div>

                        </div>
                    ))}
                </div>
                {!token && <Button
                    className={`${styles.menuButton} ${menuOpen ? styles.open : styles.close}`}
                    onClick={() => setMenuOpen(!menuOpen)}
                    icon={<Icon name={menuOpen ? 'ArrowLeft' : 'ArrowRight'} size={30} />}
                    size="small"
                    style={{ border: 'none', outline: 'none' }}
                />}
            </div >
        );
    }

    return (
        <div className={`${styles.BuildingBenchmark} ${!isLoggedIn && styles.outOfLogin} br-mainpanel br-profile-page floorplan-background`}>
            <DocumentTitle title="Benchmark Analysis" />
            <LogoSpinner loading={loading} />
            {getContextualMenu()}
            <div className={`${styles.mainWrapper} ${menuOpen && !token ? '' : styles.menuClosed}`}>
                <Row gutter={[20, 20]} justify={'center'} className={styles.mainRow}>
                    <Col span={24}>
                        {reportData || token ?
                            <BenchmarkReport data={reportData} isLoggedIn={isLoggedIn} token={token} loading={loading} /> :
                            <BenchmarkQuestionnaire onSubmit={handleSubmit} />}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default BuildingBenchmark;
