import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";

const CHANGE = "DOORWAY_CHANGE";
const DOORWAY_OPERATION = "DOORWAY_OPERATION";
const LINK_OPERATION = "LINK_OPERATION";

let _doorways = [];

class DoorwayStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.DOORWAYS_FETCHED:
        this._storeDoorways(action.payload);
        break;
      case ActionTypes.DOORWAY_SAVED:
        this._saveDoorway(action.payload);
        break;
      case ActionTypes.DOORWAY_UPDATED:
        this._updateDoorway(action.payload);
        break;
      case ActionTypes.DOORWAY_DELETED:
        this._deleteDoorway(action.payload);
        break;
      case ActionTypes.LINK_SAVED:
        this._saveLink(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }
  addDoorwayOperationListener(callback) {
    this.on(DOORWAY_OPERATION, callback);
  }
  addLinkOperationListener(callback) {
    this.on(LINK_OPERATION, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }
  removeDoorwayOperationListener(callback) {
    this.removeListener(DOORWAY_OPERATION, callback);
  }
  removeLinkOperationListener(callback) {
    this.removeListener(LINK_OPERATION, callback);
  }

  clear() {
    _doorways = [];
  }

  _storeDoorways(json) {
    _doorways = json;
    this.emit(CHANGE);
  }

  _saveDoorway() {
    this.emit(DOORWAY_OPERATION);
  }

  _saveLink() {
    this.emit(LINK_OPERATION);
  }

  _updateDoorway(json) {
    if (json.doorway_id) {
      for (let i = 0; i < _doorways.length; i++) {
        if (_doorways[i].doorway_id === json.doorway_id) {
          _doorways[i] = json;
          break;
        }
      }
    }
    this.emit(DOORWAY_OPERATION);
  }
  _deleteDoorway(json) {
    this.emit(DOORWAY_OPERATION);
  }

  // Getters
  getDoorways() {
    return _doorways;
  }
}

export default new DoorwayStore();
