import React, { Component } from 'react';
import './ManageCounters.scss';

import ZonesStore from '../../stores/zonesStore';
import CounterActions from '../../actions/counterActions';
import DoorwayStore from '../../stores/doorwayStore';
import DoorwayActions from '../../actions/doorwayActions';
import CounterStore from '../../stores/counterStore';
import OrganisationActions from '../../actions/organisationActions';
import OrganisationStore from '../../stores/organisationStore';
import UserStore from '../../stores/userStore';

import GeneralUtils from '../../utils/GeneralUtils';
import Constants from '../../constants/index';
import { SearchSelect } from '../../components/SearchSelect';
import { DocumentTitle } from '../../components/DocumentTitle';
import { ModifyCounterPanel } from './ModifyCounterPanel';
import _ from 'lodash';
import { v4 } from 'uuid';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ManageCounters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: ZonesStore.getZones(),
      buildings: OrganisationStore.getBuildings(),
      doorways: [],
      counter_creation: false,
      selected_building: null,
      selected_zone: null,
      items_expanded: [],
      counters: [],
      new_counter: {
        name: '',
        description: '',
        location: '',
        device_id: '',
        uuid: v4(),
        mac_addr: '',
      }
    };
    // listener events
    this._onOrganisationChange = this._onOrganisationChange.bind(this);
    this._onDoorwaysChange = this._onDoorwaysChange.bind(this);
    this._onCounterChange = this._onCounterChange.bind(this);
    this._onCounterOperation = this._onCounterOperation.bind(this);

    // filter setters
    this._setTargetBuilding = this._setTargetBuilding.bind(this);

    //helpers and controllers
    this._changeCounterValues = this._changeCounterValues.bind(this);
    this._validateCounterInput = this._validateCounterInput.bind(this);
    this._toggleCounterCreation = this._toggleCounterCreation.bind(this);
    this._expansionHandler = this._expansionHandler.bind(this);
    this._massExpansionHandler = this._massExpansionHandler.bind(this);

    // Operation actions
    this._saveCounter = this._saveCounter.bind(this);
    this._updateCounter = this._updateCounter.bind(this);
    this._deleteCounter = this._deleteCounter.bind(this);

  }

  componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
    DoorwayStore.addChangeListener(this._onDoorwaysChange)

    CounterStore.addChangeListener(this._onCounterChange)
    CounterStore.addCounterOperationListener(this._onCounterOperation);
  }

  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
  }
  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
    DoorwayStore.removeChangeListener(this._onDoorwaysChange)
    CounterStore.removeChangeListener(this._onCounterChange)
    CounterStore.removeCounterOperationListener(this._onCounterOperation);

    CounterStore.clear()
    DoorwayStore.clear()
  }

  _expansionHandler(counter_id = null) {
    if (this.state.items_expanded.includes(counter_id)) {
      this.setState({ items_expanded: this.state.items_expanded.filter(item => item !== counter_id) })
    } else {
      this.setState({ items_expanded: [...this.state.items_expanded, counter_id] })
    }
  }

  _massExpansionHandler() {
    if (this.state.items_expanded.length > 0) {
      this.setState({ items_expanded: [] })
    } else {
      this.setState({ items_expanded: [...this.state.counters.map(el => el.counter_id)] })
    }
  }

  _onOrganisationChange() {
    this.setState({
      buildings: OrganisationStore.getBuildings(),
    })
  }

  _onCounterChange() {
    this.setState({ counters: CounterStore.getCounters() })
  }

  _onDoorwaysChange() {
    this.setState({ doorways: DoorwayStore.getDoorways() })
  }

  _onCounterOperation() {
    this._toggleCounterCreation(false);
    CounterActions.getBuildingCounters(this.state.selected_building && this.state.selected_building.building_id)
  }

  _setTargetBuilding(selected_building) {
    CounterActions.getBuildingCounters(selected_building.building_id);
    DoorwayActions.getBuildingDoorways(selected_building.building_id);


    this.setState({
      selected_building: selected_building,
      new_counter: { ...this.state.new_counter, building_id: selected_building.building_id }
    });
  }

  noRowsAvailable(numRows) {
    let rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(<td key={i} className="valign-middle"></td>);
    }
    return <tr>
      <td className="valign-middle" colSpan={4}>
        <div className="d-flex align-items-center">
          <div>
            <div className="tx-inverse">{this.state.selected_building == null ? "No counters available, please select a target building." : "No counters available, check zone and type filters. Please contact support to add new counters."}</div>
          </div>
        </div>
      </td>
      {rows}
    </tr>
  }

  _validateCounterInput(counter) {
    const validBuilding = parseInt(counter.building_id) > 0;
    const validDeviceID = GeneralUtils.stringNotEmpty(counter.device_id);
    const validUUID = Constants.UUID_PATTERN.test(counter.uuid);
    const validMAC = Constants.MAC_ADDRESS_PATTERN.test(counter.mac_addr);
    const validName = GeneralUtils.stringNotEmpty(counter.name);
    const validLocation = GeneralUtils.stringNotEmpty(counter.location);
    const validDescription = GeneralUtils.stringNotEmpty(counter.description);
    return validBuilding && validName && validLocation && validDescription && validDeviceID && validUUID && validMAC;
  }

  _changeCounterValues(counter, target_property, event) {

    let counters = this.state.counters;
    let new_counter = this.state.new_counter;
    const string_target_properties = ['name', 'location', 'description', 'mac_addr', 'device_id', 'uuid']
    let value = string_target_properties.includes(target_property) ? event.target.value : +event.target.value
    
    let editExisting = false;
    for (let i = 0; i < counters.length; i++) {
      if (counters[i].counter_id === counter.counter_id) {
        counters[i][target_property] = value;
        editExisting=true;
        break;
      }
    }
    if (!editExisting) {
      new_counter[target_property] = value
    }

    this.setState({ new_counter: new_counter, counters: counters })
  }

  _saveCounter(counter) {
    if (this._validateCounterInput(counter)) {
      const newCounter = {
        ...counter,
        doorway_id: counter.doorway_id > 0 ? counter.doorway_id : null
      }
      CounterActions.saveCounter(newCounter);
    } else {
      toast("Invalid Input, Please check fields and try again", { type: toast.TYPE.WARNING, autoClose: 3000, preventDuplicated: true });
    }
  }

  _updateCounter(counter) {
    if (this._validateCounterInput(counter) && counter.counter_id) {
      const updatedCounter = {
        device_id: counter.device_id,
        uuid: counter.uuid,
        mac_addr: counter.mac_addr,
        name: counter.name,
        description: counter.description,
        location: counter.location,
        counter_id: counter.counter_id,
        doorway_id: counter.doorway_id,
      }

      CounterActions.updateCounter(updatedCounter);
    } else {
      toast("Invalid Input, Please check fields and try again", { type: toast.TYPE.WARNING, autoClose: 3000, preventDuplicated: true });
    }
  }

  _deleteCounter(device_id) {
    if (window.confirm('Are you sure you wish to delete this counter?')) {
      CounterActions.deleteCounter(device_id)
    }
  }

  _toggleCounterCreation(target_state = null) {
    if (this.state.counter_creation || target_state === false) {
      this.setState({
        counter_creation: false,
        new_counter: {
          name: '',
          description: '',
          location: '',
          device_id: '',
          doorway_id: 0,
          uuid: v4(),
          mac_addr: '',
          building_id: this.state.selected_building.building_id
        }
      });
    } else {
      this.setState({ counter_creation: true });
    }
  }

  _resetFiltersHandler() {
    this._toggleCounterCreation(false);
  }

  getCounterTable() {
    let counterRows = this.noRowsAvailable(4);
    let newCounterRow = null;
    const usedDoorwayIds = this.state.counters.map(counter => counter.doorway_id).filter(el => el);
    const doorways = this.state.doorways.map(doorway => {
      return {
        ...doorway,
        disabled: usedDoorwayIds.includes(doorway.doorway_id)
      }
    })


    let buildingsFormattedArray = this.state.buildings.map(building => {
      return { ...building, name: building.name + ` (building id: ${building.building_id})` }
    })

    let counters = _.orderBy(this.state.counters, 'name', 'asc');
    let button = <button onClick={this._toggleCounterCreation} style={{ cursor: 'pointer' }} className="btn btn-outline-info pd-t-2 pd-b-2 tx-12 mg-l-15 ht-35 tx-14">Add New Counter</button>;
    if (this.state.counter_creation) {
      button = <button onClick={this._toggleCounterCreation} style={{ cursor: 'pointer' }} className="btn btn-outline-warning pd-t-2 pd-b-2 tx-12 mg-l-15 ht-35 tx-14">Cancel</button>;
    }


    if (this.state.counter_creation) {
      newCounterRow = <ModifyCounterPanel
        key={'new_counter'}
        building={this.state.selected_building}
        changeCounterValues={this._changeCounterValues}
        expanded={true}
        doorways={doorways}
        counter={this.state.new_counter}
        updateZone={this._updateCounter}
        saveCounter={this._saveCounter}
        cancelCounter={this._toggleCounterCreation}
      />;
    }

    if (counters.length > 0) {
      counterRows = counters.reverse().map((counter, key) => {

        if (counter.counter_id) {
          return <ModifyCounterPanel
            key={counter.counter_id}
            changeCounterValues={this._changeCounterValues}
            deleteCounter={this._deleteCounter}
            counter={counter}
            doorways={doorways}
            expanded={this.state.items_expanded.includes(counter.counter_id)}
            expansionHandler={this._expansionHandler}
            building={this.state.selected_building}
            updateCounter={this._updateCounter}
            saveCounter={this._saveCounter} />
        } else {
          return null;
        }
      })
    }

    return <div>
      <div className="br-pagetitle pd-x-0 pd-b-0 mg-b-10" style={{ width: '100%' }}>
          <span className='col-6 col-md-4 pd-l-0'><SearchSelect limit={100} options={buildingsFormattedArray} placeholder={"Select Building"} defaultValue={this.state.selected_building} actionOnSelectedOption={this._setTargetBuilding} /></span>
          <div className='col-6 mg-l-auto pd-0 tx-right'>
            {this.state.selected_building && button}
          </div>
      </div>
      <div className="card bd-0 shadow-base mg-t-5 overflow-auto">
        <table className="table mg-b-0 table-contact">
          <thead>
            <tr>
              <th className="wd-15p tx-mont tx-medium">Name<span style={{color: 'red'}}>*</span></th>
              <th className="wd-15p tx-10-force tx-mont tx-medium">Description<span style={{color: 'red'}}>*</span></th>
              <th className="wd-15p tx-10-force tx-mont tx-medium">Location<span style={{color: 'red'}}>*</span></th>
              <th className="wd-10p tx-10-force tx-mont tx-medium text-nowrap">Device ID<span style={{color: 'red'}}>*</span></th>
              <th className="wd-10p tx-10-force tx-mont tx-medium text-nowrap">Doorway ID</th>
              <th className="wd-20p tx-10-force tx-mont tx-medium">UUID<span style={{color: 'red'}}>*</span></th>
              <th className="wd-10p tx-10-force tx-mont tx-medium">MAC<span style={{color: 'red'}}>*</span></th>
              <th style={{ paddingBottom: '5px' }}><button className='btn btn-info ht-30 d-flex align-items-center' onClick={this._massExpansionHandler}>{this.state.items_expanded.length > 0 ? '-' : '+'}</button></th>
            </tr>
          </thead>
          <tbody>
            {newCounterRow}
            {counterRows}
          </tbody>
        </table>
      </div>
    </div>
  }

  render() {
    return (
      <div className="br-mainpanel pd-15">
        <DocumentTitle title='Manage Counters' />
        <div className="br-pagetitle mg-b-0">
          <div>
            <h4>Update Counter Details</h4>
            <p className="mg-b-0">Update and create new counters. Select the building to continue.
            </p>
          </div>
        </div>
        <div>
          <div className="col-12">
            {this.getCounterTable()}
          </div>
        </div>
      </div>
    );
  }
}

export default ManageCounters;
