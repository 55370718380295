import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";

const TENANTS_FETCHED = ActionTypes.TENANTS_FETCHED;
const TENANT_SAVED = ActionTypes.TENANT_SAVED;
const TENANT_UPDATED = ActionTypes.TENANT_UPDATED;
const TENANT_DELETED = "TENANT_DELETED";

const BUILDING_LINKED = "BUILDING_LINKED";
const LINKED_BUILDING_UPDATED = "LINKED_BUILDING_UPDATED";
const LINKED_BUILDING_DELETED = "LINKED_BUILDING_DELETED";

const LINKABLE_ZONES_FETCHED = "LINKABLE_ZONES_FETCHED";
const ZONE_LINKED = "ZONE_LINKED";
const LINKED_ZONE_DELETED = "LINKED_ZONE_DELETED";

const LINKABLE_METERS_FETCHED = "LINKABLE_METERS_FETCHED";
const METER_LINKED = "METER_LINKED";
const LINKED_METER_DELETED = "LINKED_METER_DELETED";

let _tenants = [];
let _linkable_zones = [];
let _linkable_meters = [];

class TenantStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      // TENANT
      case TENANTS_FETCHED:
        this._storeTenants(action.payload);
        break;
      case TENANT_SAVED:
        this._saveTenant(action.payload);
        break;
      case TENANT_UPDATED:
        this._updateTenant(action.payload);
        break;
      case TENANT_DELETED:
        this._deleteTenant(action.payload);
        break;
      // BUILDINGS
      case BUILDING_LINKED:
        this._linkBuilding(action.payload);
        break;
      case LINKED_BUILDING_UPDATED:
        this._linkedBuildingUpdated(action.payload);
        break;
      case LINKED_BUILDING_DELETED:
        this._linkedBuildingDeleted(action.payload);
        break;
      // ZONES
      case LINKABLE_ZONES_FETCHED:
        this._linkableZonesFetched(action.payload);
        break;
      case ZONE_LINKED:
        this._linkZone(action.payload);
        break;
      case LINKED_ZONE_DELETED:
        this._unlinkZone(action.payload);
        break;
      // METERS
      case LINKABLE_METERS_FETCHED:
        this._linkableMetersFetched(action.payload);
        break;
      case METER_LINKED:
        this._linkMeter(action.payload);
        break;
      case LINKED_METER_DELETED:
        this._unlinkMeter(action.payload);
        break;
      default:
        break;
    }
  }

  // Clear function
  clear() {
    _tenants = [];
    _linkable_zones = [];
    _linkable_meters = [];
  }

  // TENANTS FETCHED
  addTenantsFetchedListener(callback) {
    this.on(TENANTS_FETCHED, callback);
  }
  removeTenantsFetchedListener(callback) {
    this.removeListener(TENANTS_FETCHED, callback);
  }
  _storeTenants(json) {
    _tenants = json;
    this.emit(TENANTS_FETCHED);
  }
  getTenants() {
    return _tenants;
  }

  // TENANT SAVED
  addTenantSavedListener(callback) {
    this.on(TENANT_SAVED, callback);
  }
  removeTenantSavedListener(callback) {
    this.removeListener(TENANT_SAVED, callback);
  }
  _saveTenant() {
    this.emit(TENANT_SAVED);
  }

  // TENANT UPDATED
  addTenantUpdatedListener(callback) {
    this.on(TENANT_UPDATED, callback);
  }
  removeTenantUpdatedListener(callback) {
    this.removeListener(TENANT_UPDATED, callback);
  }
  _updateTenant() {
    this.emit(TENANT_UPDATED);
  }

  // TENANT DELETED
  addTenantDeletedListener(callback) {
    this.on(TENANT_DELETED, callback);
  }
  removeTenantDeletedListener(callback) {
    this.removeListener(TENANT_DELETED, callback);
  }
  _deleteTenant() {
    this.emit(TENANT_DELETED);
  }

  // BUILDING LINKED
  addBuildingLinkedListener(callback) {
    this.on(BUILDING_LINKED, callback);
  }
  removeBuildingLinkedListener(callback) {
    this.removeListener(BUILDING_LINKED, callback);
  }
  _linkBuilding() {
    this.emit(BUILDING_LINKED);
  }

  // BUILDING UPDATED
  addLinkedBuildingUpdatedListener(callback) {
    this.on(LINKED_BUILDING_UPDATED, callback);
  }
  removeLinkedBuildingUpdatedListener(callback) {
    this.removeListener(LINKED_BUILDING_UPDATED, callback);
  }
  _linkedBuildingUpdated() {
    this.emit(LINKED_BUILDING_UPDATED);
  }

  // BUILDING DELETED
  addLinkedBuildingDeletedListener(callback) {
    this.on(LINKED_BUILDING_DELETED, callback);
  }
  removeLinkedBuildingDeletedListener(callback) {
    this.removeListener(LINKED_BUILDING_DELETED, callback);
  }
  _linkedBuildingDeleted() {
    this.emit(LINKED_BUILDING_DELETED);
  }

  // LINKABLE ZONES FETCHED
  addLinkableZonesFetchedListener(callback) {
    this.on(LINKABLE_ZONES_FETCHED, callback);
  }
  removeLinkableZonesFetchedListener(callback) {
    this.removeListener(LINKABLE_ZONES_FETCHED, callback);
  }
  _linkableZonesFetched(json) {
    _linkable_zones = json;
    this.emit(LINKABLE_ZONES_FETCHED);
  }
  getLinkableZones() {
    return _linkable_zones;
  }

  // ZONE LINKED
  addZoneLinkedListener(callback) {
    this.on(ZONE_LINKED, callback);
  }
  removeZoneLinkedListener(callback) {
    this.removeListener(ZONE_LINKED, callback);
  }
  _linkZone() {
    this.emit(ZONE_LINKED);
  }

  // LINKED ZONE DELETED
  addZoneUnlinkedListener(callback) {
    this.on(LINKED_ZONE_DELETED, callback);
  }
  removeZoneUnlinkedListener(callback) {
    this.removeListener(LINKED_ZONE_DELETED, callback);
  }
  _unlinkZone() {
    this.emit(LINKED_ZONE_DELETED);
  }

  // LINKABLE METERS FETCHED
  addLinkableMetersFetchedListener(callback) {
    this.on(LINKABLE_METERS_FETCHED, callback);
  }
  removeLinkableMetersFetchedListener(callback) {
    this.removeListener(LINKABLE_METERS_FETCHED, callback);
  }
  _linkableMetersFetched(json) {
    _linkable_meters = json;
    this.emit(LINKABLE_METERS_FETCHED);
  }
  getLinkableMeters() {
    return _linkable_meters;
  }

  // METER LINKED
  addMeterLinkedListener(callback) {
    this.on(METER_LINKED, callback);
  }
  removeMeterLinkedListener(callback) {
    this.removeListener(METER_LINKED, callback);
  }
  _linkMeter() {
    this.emit(METER_LINKED);
  }

  // LINKED ZONE DELETED
  addMeterUnlinkedListener(callback) {
    this.on(LINKED_METER_DELETED, callback);
  }
  removeMeterUnlinkedListener(callback) {
    this.removeListener(LINKED_METER_DELETED, callback);
  }
  _unlinkMeter() {
    this.emit(LINKED_METER_DELETED);
  }
}

export default new TenantStore();
