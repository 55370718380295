import React, { Component } from "react";
import "./ModifyMeterPanel.scss";
import { Button } from 'antd';
import { Icon } from '../../../components/Icon';

const METER_TYPES = [
  { label: "Main Electrical Meter", value: "mem" },
  { label: "Electrical Meter", value: "em" },
  { label: "Main Gas Meter", value: "mgm" },
  { label: "Gas Meter", value: "gm" },
  { label: "Main Water Meter", value: "mwm" },
  { label: "Water Meter", value: "wm" },
  { label: "Main Heat Meter", value: "mhm" },
  { label: "Heat Meter", value: "hm" },
  { label: "Main Renewable Meter", value: "mrm" },
];

class ModifyMeterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      building: this.props.building,
      meter: this.props.meter,
      inputValue: "",
      expanded: this.props.expanded,
      meters: this.props.meters,
      tenants: this.props.tenants,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      building: nextProps.building,
      meter: nextProps.meter,
      expanded: nextProps.expanded,
      tenants: nextProps.tenants,
    });
  }

  getMeterRow(meter) {
    const vr_tag = <span className="tag-vr">{meter.meter_id} VR</span>;

    const isMainMeter =
      meter.type === "mem" ||
      meter.type === "mgm" ||
      meter.type === "mwm" ||
      meter.type === "mhm" ||
      meter.type === "mrm";

    return (
      <tr>
        <td style={{ paddingBottom: "5px" }}>
          {meter.virtual ? vr_tag : meter.meter_id}
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <select
            style={{ height: "25px" }}
            onChange={this.props.changeMeterValues.bind(this, meter, "type")}
            value={meter.type}
            className="inline-single-input"
          >
            <option value={0}>Select Meter Type</option>
            {METER_TYPES.map((type) => {
              return (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              );
            })}
          </select>
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Description"
            type="text"
            className="inline-single-input"
            defaultValue={meter.description}
            onChange={this.props.changeMeterValues.bind(
              this,
              meter,
              "description"
            )}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <select
            style={{ height: "25px" }}
            disabled={isMainMeter}
            onChange={this.props.changeMeterValues.bind(
              this,
              meter,
              "parent_id"
            )}
            value={isMainMeter ? 0 : meter.parent_id || 0}
            className="inline-single-input"
          >
            <option value={null}>Select the Parent</option>
            {this.props.meters.map((meter) => {
              return (
                <option key={meter.meter_id} value={meter.meter_id}>
                  {meter.description}
                </option>
              );
            })}
          </select>
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Conversion Rate"
            type="number"
            className="inline-single-input"
            defaultValue={meter.conversion_rate}
            onChange={this.props.changeMeterValues.bind(
              this,
              meter,
              "conversion_rate"
            )}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Virtual Calc"
            type="text"
            className="inline-single-input"
            style={{ width: "100%" }}
            defaultValue={meter.virtual_calc}
            onChange={this.props.changeMeterValues.bind(
              this,
              meter,
              "virtual_calc"
            )}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <button
            className="btn btn-outline-info ht-20 d-flex align-items-center"
            onClick={() =>
              meter.meter_id && this.props.expansionHandler(meter.meter_id)
            }
          >
            {this.state.expanded ? "-" : "+"}
          </button>
        </td>
      </tr>
    );
  }

  render() {
    let meter = this.state.meter;

    return (
      <React.Fragment>
        {this.getMeterRow(meter)}
        {this.state.expanded && (
          <tr>
            <td
              className="valign-middle"
              colSpan="1"
              style={{
                border: "none",
              }}
            ></td>
            <td style={{ paddingBottom: "5px", border: "none" }}>
              <label
                className="tx-mont tx-medium tx-12"
                style={{ textTransform: "uppercase" }}
              >
                Ext. Reference
              </label>
              <input
                placeholder="Ext. Reference"
                type="text"
                className="inline-single-input"
                defaultValue={meter.ext_ref}
                onChange={this.props.changeMeterValues.bind(
                  this,
                  meter,
                  "ext_ref"
                )}
              />
            </td>
            <td style={{ paddingBottom: "5px", border: "none" }}>
              <label
                className="tx-mont tx-medium tx-12"
                style={{ textTransform: "uppercase" }}
              >
                Tenant
              </label>
              <select
                style={{ height: "25px" }}
                onChange={this.props.changeMeterValues.bind(
                  this,
                  meter,
                  "tenant_id"
                )}
                value={meter.tenant_id}
                className="inline-single-input"
              >
                <option value={null}>No Tenants Assigned</option>
                {this.state.tenants.map((tenant) => {
                  return (
                    <option key={tenant.tenant_id} value={tenant.tenant_id}>
                      {tenant.name}
                    </option>
                  );
                })}
              </select>
            </td>
            <td style={{ paddingBottom: "5px", border: "none" }}>
              <label
                className="tx-mont tx-medium tx-12"
                style={{ textTransform: "uppercase" }}
              >
                Category
              </label>
              <select
                style={{ height: "25px" }}
                onChange={this.props.changeMeterValues.bind(
                  this,
                  meter,
                  "category"
                )}
                value={meter.category || ""}
                className="inline-single-input"
              >
                <option value="">Unspecified Category</option>
                {this.props.categories.map((category) => {
                  return (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  );
                })}
              </select>
            </td>
            <td
              className=" button-wrapper"
              colSpan={2}
              style={{
                border: "none",
              }}
            >
              {meter.meter_id ? (
                <Button
                  className="button orange"
                  icon={<Icon name="Edit" color={'#fff'} size={18} />}
                  size="small"
                  onClick={this.props.updateMeter.bind(this, meter)}
                  style={{ marginLeft: '15px' }}
                >Update</Button>
              ) : (
                <Button
                  className="button green"
                  icon={<Icon name="Save" color={'#fff'} size={18} />}
                  size="small"
                  onClick={this.props.saveMeter.bind(this, meter)}
                  style={{ marginLeft: '15px' }}
                >Save</Button>
              )}
              {meter.meter_id ? (
                <Button
                  className="button red"
                  icon={<Icon name="Delete" color={'#fff'} size={18} />}
                  size="small"
                  onClick={this.props.deleteMeter.bind(this, meter.meter_id)}
                  style={{ marginLeft: '15px' }}
                >Delete</Button>
              ) : null}
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}

export default ModifyMeterPanel;
